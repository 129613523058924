import React, {useState} from 'react';
import styles from './OptionNumber.module.scss';
import {AMAOption, utilsAnnotation, utilsEditor} from "@amaspace-editor/editor-3d";
import {ProductAttributeType} from "../../../api/shareApi";
import OptionNumberOption from "./OptionNumber.option";

type TOptionsGroup = {
  attribute: ProductAttributeType
  options: AMAOption[]
}

interface AMAOptionUpdated extends AMAOption {
  visible: boolean
  enabled: boolean
  tooltip?: string
}

interface IProps {
  allUpdatedPCOs: AMAOption[]
  excludeItemsWithMessage: AMAOption[]
  maxValue: number
  minValue: number
  optionsGroup: TOptionsGroup
  selectedPCOs: AMAOption[]
  selectOptionWithRules: any
}

const OptionNumber = ({
    allUpdatedPCOs,
    excludeItemsWithMessage,
    maxValue,
    minValue,
    optionsGroup,
    selectedPCOs,
    selectOptionWithRules,
}: IProps) => {

  const editorAMA = utilsEditor()
  const annotation = utilsAnnotation()
  const [quantity, setQuantity] = useState<number>(
    parseFloat(optionsGroup.options[0].updated.quantity || optionsGroup.attribute.default_value || 0)
  )

  const getUpdatedPCOs = (optionsGroup: TOptionsGroup, newQuantity: number) => {
    return editorAMA.getCurrent().optionsAMAUpdated.map(option => {
      if (option.parent === optionsGroup.attribute.id) {
        return {
          ...option,
          updated: {
            ...option.updated,
            quantity: newQuantity
          }
        }
      }
      return option
    })
  }

  const handleOptionClick = (newQuantity: number, isSelected: boolean, option: AMAOption, onComplete: () => void) => {
    setQuantity(newQuantity)
    if ((newQuantity === 1 && !isSelected) || (newQuantity === 0 && isSelected)) {
      const cloneOption = structuredClone(option)
      if (cloneOption.updated.quantity) {
        cloneOption.updated.quantity = newQuantity
      } else {
        cloneOption.updated.quantity = 0
      }

      selectOptionWithRules({
        id: cloneOption.id,
        option: cloneOption,
        onComplete: () => {
          const newOptions = getUpdatedPCOs(optionsGroup, newQuantity)
          editorAMA.setAMAOptionsUpdated(newOptions)
          if (isSelected) {
            annotation.removeAnnotation(option.id)
            onComplete && onComplete()
            return
          }
          onComplete && onComplete()
        }
      })
      return
    }
    const newOptions = getUpdatedPCOs(optionsGroup, newQuantity)
    editorAMA.setAMAOptionsUpdated(newOptions)
    // annotation.updateAnnotationProperty(option.id, { count: newQuantity })
  }



  return (
    <div className={styles.component}>
      {optionsGroup.options.map(option => {
        const isSelected = !!selectedPCOs.find(selectedPCO => selectedPCO.id === option.id)?.checked
        const updatedData: AMAOptionUpdated = {
          visible: true,
          enabled: true,
          value: '',
          options: [],
          color: null,
          remove: isSelected,
          ...(allUpdatedPCOs.find(updatedPCO => updatedPCO.id === option.id)?.updated || {})
        }

        return (
          <OptionNumberOption
            key={option.id}
            option={option}
            updatedData={updatedData}
            quantity={quantity}
            minValue={minValue}
            maxValue={maxValue}
            isSelected={isSelected}
            handleOptionClick={handleOptionClick}
            setQuantity={setQuantity}
          />
        )
      })}
    </div>
  );
};

export default OptionNumber;
import {
    isCustomShapes,
    isMeshAMA,
    isSpecificObject,
    textureManager,
    utilsEditor,
    utilsMaterial
} from '@amaspace-editor/editor-3d'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Select } from 'antd'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import assetApi, { AssetType, ASSET_TYPE, useGetAssetByIdQuery, useGetAssetsQuery } from '../../../../api/assetApi'
import { useAppDispatch } from '../../../../hooks'
import {
    changeDeleteConnections,
    changeNewConnections,
    modelDeleteConnections,
    modelNewConnections
} from '../../../../slices/modelSlice'
import { TAsset } from '../../../../types'

interface AttachAssetProps {
    keyMap: string
    value: any
    onPropChange: (key: string, value: AttachAssetProps['value']) => void
    asset?: any
}

interface RenderMapOption {
    label?: ReactNode
    value?: string
}

interface RenderMapProps {
    currentValue?: RenderMapOption
    nodeAsset: AMAModelConfig | null
    asset: any
    activeNode: any
}

interface RenderMapIdProps {
    value?: AttachAssetProps['value']
    activeNode: any
    asset: any
}

export type ModelConnections = {
    asset_from: string
    asset_to: string
    type?: string
}
export type AMAModelConfig = {
    assets: TAsset[]
    connections: ModelConnections[]
}

const RenderMap = ({ currentValue = {}, nodeAsset, asset, activeNode }: RenderMapProps) => {
    const [value, setValue] = useState<RenderMapOption | null>(null)
    const editor = utilsEditor()
    const materialEditor = utilsMaterial()
    const dispatch = useAppDispatch()
    const newModelConnections = useSelector(modelNewConnections)
    const deleteModelConnections = useSelector(modelDeleteConnections)
    const { data: assetsData, isFetching: isFetchingAssets, refetch } = useGetAssetsQuery({ type: ASSET_TYPE.Material })

    const updateMap = async (event: any, assetMap: AssetType) => {
        event.stopPropagation()
        const res = (await dispatch(assetApi.endpoints.getAssetById.initiate({ id: assetMap.id }))) as any
        const { data } = (await dispatch(assetApi.endpoints.getAssetById.initiate({ id: activeNode.id }))) as any

        const nodeAsset = data
        const targetAsset = nodeAsset.assets[0]
        const configMaterial = editor?.activeMesh && editor.getCurrentConfigObj(editor.activeMesh)

        if (!data.connections) {
            const mapAsset = res.data

            const connectModelToMaterial = {
                asset_to: asset.id,
                asset_from: targetAsset.id
            }
            const connectMaterialToTexture = {
                asset_to: targetAsset.id,
                asset_from: assetMap.id
            }
            const objForUpdate = materialEditor.createMaterialFromAsset(mapAsset)

            textureManager.preloadTextures(objForUpdate.textures.map((item: any) => item.url)).then(() => {
                if (!isCustomShapes(configMaterial) && !isSpecificObject(configMaterial, 'Mesh')) return
                if (!configMaterial.material) {
                    console.log('configMaterial', configMaterial)
                    editor.updateObjectData(configMaterial, { material: objForUpdate })
                    editor.setSceneMaterials([...editor.sceneMaterials, objForUpdate])
                } else {
                    editor.changeMaterial(configMaterial, objForUpdate)
                }
                dispatch(
                    changeNewConnections({
                        ...newModelConnections,
                        [targetAsset.id]: [connectModelToMaterial, connectMaterialToTexture]
                    })
                )
                // editor.updateObjectData(configMaterial, {
                //     updatedMaterial: { type: AMAMaterialType.MeshStandardMaterial, ...objForUpdate.properties }
                // })
                console.log('newModelConnections', { connectModelToMaterial, connectMaterialToTexture })
            })

            return
        }
        const connect = {
            asset_to: targetAsset.id,
            asset_from: assetMap.id
        }

        const isConnections = res.data.connections ? res.data.connections : []
        const newConnections = [connect, ...isConnections]
        const mapAsset = res.data as any

        const objForUpdate = materialEditor.createMaterialFromAsset(mapAsset)
        const oldConnections = nodeAsset.connections.filter((item: ModelConnections) => {
            if (item.asset_to === targetAsset.id) return item
        })

        dispatch(changeDeleteConnections({ ...deleteModelConnections, [targetAsset.id]: oldConnections }))
        dispatch(changeNewConnections({ ...newModelConnections, [targetAsset.id]: newConnections }))

        textureManager.preloadTextures(objForUpdate.textures.map((item: any) => item.url)).then(() => {
            if (!isCustomShapes(configMaterial) && !isSpecificObject(configMaterial, 'Mesh')) return
            if (!configMaterial.material) {
                console.log('configMaterial', configMaterial)
                editor.updateObjectData(configMaterial, { material: objForUpdate })
                editor.setSceneMaterials([...editor.sceneMaterials, objForUpdate])
            } else {
                editor.changeMaterial(configMaterial, objForUpdate).then(() => {
                    const mesh = editor.activeMesh
                    if (isMeshAMA(mesh)) {
                        mesh.material.needsUpdate = true
                    }
                })
            }
            // editor.updateObjectData(configMaterial, {
            //     updatedMaterial: { type: AMAMaterialType.MeshStandardMaterial, ...objForUpdate.properties }
            // })
        })
    }

    return (
        <Select
            value={value || currentValue}
            options={
                !isFetchingAssets && assetsData
                    ? assetsData.map(item => {
                          return {
                              children: item.name,
                              label: (
                                  <TextureItem key={item.id} onClick={e => updateMap(e, item)}>
                                      <MapIcon url={item.preview!} />
                                      <TextureTitle>{item.name}</TextureTitle>
                                  </TextureItem>
                              ),
                              value: item.id
                          }
                      })
                    : []
            }
            filterOption={(input, option) => {
                return option?.children ? option?.children.toLowerCase().includes(input.toLowerCase()) : false
            }}
            optionFilterProp="label"
            optionLabelProp="children"
            showSearch={true}
            style={{ width: '100%' }}
            onChange={(value, option) => {
                // @ts-ignore
                setValue(option)
            }}
        />
    )
}

const RenderEmptyMap = ({ activeNode, asset }: RenderMapIdProps) => {
    const [nodeAsset, setNodeAsset] = useState<AMAModelConfig | null>(null)
    const nodeAssetResponse = useGetAssetByIdQuery({ id: activeNode.id as string })

    useEffect(() => {
        if (nodeAssetResponse) {
            const { data } = nodeAssetResponse as { data: AMAModelConfig }
            if (!data) return

            setNodeAsset(data)
        }
    }, [nodeAssetResponse])
    return <RenderMap nodeAsset={nodeAsset} asset={asset} activeNode={activeNode} />
}

const RenderMapId = ({ value, activeNode, asset }: RenderMapIdProps) => {
    const [currentValue, setCurrentValue] = useState<RenderMapOption>({
        label: '',
        value: ''
    })
    const [nodeAsset, setNodeAsset] = useState<AMAModelConfig | null>(null)
    const fetchAssetByIdResponse = useGetAssetByIdQuery({ id: value?.db_id as string })
    const nodeAssetResponse = useGetAssetByIdQuery({ id: activeNode.id as string })

    useEffect(() => {
        if (fetchAssetByIdResponse) {
            const { data } = fetchAssetByIdResponse

            if (!data) return
            const { assets } = data as { assets: TAsset[] }
            const targetAsset = assets[0]

            setCurrentValue({
                label: (
                    <TextureItem key={targetAsset.id}>
                        <MapIcon url={targetAsset.preview!} />
                        <TextureTitle>{targetAsset.name}</TextureTitle>
                    </TextureItem>
                ),
                value: targetAsset.id || ''
            })
        }
    }, [fetchAssetByIdResponse])

    useEffect(() => {
        if (nodeAssetResponse) {
            const { data } = nodeAssetResponse as { data: AMAModelConfig }
            if (!data) return
            setNodeAsset(data)
        }
    }, [nodeAssetResponse])

    return <RenderMap currentValue={currentValue} nodeAsset={nodeAsset} asset={asset} activeNode={activeNode} />
}

const RenderAttachAsset = ({ keyMap, value, onPropChange, asset }: AttachAssetProps) => {
    const editor = utilsEditor()
    const navigate = useNavigate()

    const configId = useMemo(() => {
        if (!editor.activeMesh) return ''
        return editor.getCurrentConfigObj(editor.activeMesh) || ''
    }, [editor.activeMesh])

    const editMaterialHandler = () => {
        navigate(`/assets/edit/${value.db_id}`)
    }

    return (
        <PropsItem span={16}>
            {value?.id && configId ? (
                <>
                    <RenderMapId asset={asset} value={value} activeNode={configId} />
                    <Button onClick={editMaterialHandler}>
                        <EditOutlined />
                    </Button>
                </>
            ) : (
                <RenderEmptyMap activeNode={configId} asset={asset} />
            )}
        </PropsItem>
    )
}

export default RenderAttachAsset

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`
const TextureTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const TextureItem = styled(PropsItem)`
    transition: background-color 0.3s ease-in-out;
    gap: 10px;
    cursor: pointer;

    &:hover {
        background-color: rgb(236 236 236);
    }
`
const MapIcon = styled.div<{ url: string }>`
    width: 50px;
    height: 32px;
    border: none;
    border-radius: 4px;
    background: ${props => `no-repeat center/cover url(${props?.url})`};
    flex-shrink: 0;
`

import { TextureAMA, utilsEditor, utilsMaterial } from '@amaspace-editor/editor-3d'
import { Col, Select, Switch } from 'antd'
import { useAppDispatch } from 'hooks'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    changeDeleteMaterialConnections,
    changeNewMaterialConnections,
    materialDeleteConnections,
    materialNewConnections
} from 'slices/modelSlice'
import styled from 'styled-components'
import _ from 'underscore'
import assetApi, { AssetsType, ASSET_TYPE, useGetAssetByIdQuery, useGetAssetsQuery } from '../../../../api/assetApi'
import { TAsset } from '../../../../types'
import { ModelConnections } from './renderAttachAsset'

interface AttachAssetProps {
    asset?: TAsset
    keyMap: string
    value: any
    onPropChange: (key: string, value: AttachAssetProps['value']) => void
    isEnv?: boolean
}

interface RenderMapIdProps {
    asset: any
    keyMap: any
    value?: AttachAssetProps['value']
    onPropChange?: AttachAssetProps['onPropChange']
    isEnv?: boolean
}

interface RenderMapProps {
    asset: any
    currentValue?: RenderMapOption
    keyMap: any
    onPropChange?: AttachAssetProps['onPropChange']
    isEnv?: boolean
    assetsData: AssetsType | undefined
    isFetchingAssets: boolean
}

type RenderMapOption = {
    children?: string
    label?: ReactNode
    value: string
    url?: string
}
const RenderMap = ({
    asset,
    currentValue = {
        value: ''
    },
    keyMap,
    onPropChange,
    isEnv,
    assetsData,
    isFetchingAssets
}: RenderMapProps) => {
    const materialUtils = utilsMaterial()
    const utils = utilsEditor()
    const dispatch = useAppDispatch()

    const newModelConnections = useSelector(materialNewConnections)
    const deleteModelConnections = useSelector(materialDeleteConnections)

    const [value, setValue] = useState<RenderMapOption | undefined>(currentValue)
    const [updating, setUpdating] = useState<boolean>(false)
    const once = useRef(false)

    useEffect(() => {
        if (currentValue.value && !once.current) {
            once.current = true
            setValue(currentValue)
        }
    }, [currentValue])

    const updateConnections = (
        materialAsset: any,
        newConnect: ModelConnections,
        mapType: string,
        needUpdate = false
    ) => {
        const oldConnections: ModelConnections[] = []
        const material = materialAsset.assets[0]
        const defaultTextures = material.patch.textures
        const updatedConnections: ModelConnections[] = []

        for (const key in defaultTextures) {
            const find = _.find(materialAsset.assets, { id: defaultTextures[key] })
            if (find) {
                updatedConnections.push({
                    asset_from: defaultTextures[key],
                    asset_to: material.id,
                    type: key
                })
            }
        }

        updatedConnections.forEach((item: ModelConnections) => {
            if (item.type === mapType) oldConnections.push(item)
        })

        const newConnectionsUpdate = [...newModelConnections].filter(item => item.type !== mapType)
        const oldConnectionsUpdate = [...deleteModelConnections].filter(item => item.type !== mapType)
        console.log('oldConnections', updatedConnections, newConnectionsUpdate, oldConnectionsUpdate, oldConnections)

        if (!needUpdate) {
            dispatch(changeNewMaterialConnections([...newConnectionsUpdate, newConnect]))
        }
        dispatch(changeDeleteMaterialConnections([...oldConnectionsUpdate, ...oldConnections]))
    }
    const updateMap = async (id: any, needUpdate?: boolean, env: string = '') => {
        if (updating) return
        setUpdating(true)
        const { data: mapAsset } = (await dispatch(assetApi.endpoints.getAssetById.initiate({ id: id }))) as any
        const { data: materialAsset } = (await dispatch(
            assetApi.endpoints.getAssetById.initiate({ id: asset?.id }, { forceRefetch: true })
        )) as any

        const name = keyMap.split('_')
        let type = name.length > 1 ? name[0] + 'Map' : name[0]
        if (name[0] === 'matCap') type = 'matcap'
        if (env) type = 'envMap'

        const targetAsset = materialAsset?.assets[0]
        const cons: ModelConnections[] = materialAsset?.connections || []

        if (!mapAsset) return
        if (cons.length) {
            const connect = {
                asset_to: targetAsset.id,
                asset_from: id,
                type
            }

            const map = mapAsset.assets[0]

            const final = {
                ...map,
                type
            } as any

            if (env) {
                updateEnv(env)
                materialUtils.updateMaterialTextures(new TextureAMA(final)).then(() => {
                    updateConnections(materialAsset, connect, type, needUpdate)
                })

                setUpdating(false)
                return
            }

            try {
                await fetch(mapAsset.url)
                if (needUpdate) {
                    materialUtils.updateMaterialTextures(new TextureAMA(final), needUpdate)
                    updateConnections(materialAsset, connect, type, needUpdate)

                    return
                }
                materialUtils.updateMaterialTextures(new TextureAMA(final))
                updateConnections(materialAsset, connect, type, needUpdate)
            } catch (error) {
                console.log('Error fetching asset', error)
            }
        } else {
            const map = mapAsset.assets[0]

            const connectMaterialToTexture = {
                asset_to: targetAsset?.id,
                asset_from: id,
                type
            }

            const final = {
                ...map,
                type
            } as any

            const newConnectionsUpdate = newModelConnections.filter(item => item.type !== type)

            targetAsset && dispatch(changeNewMaterialConnections([...newConnectionsUpdate, connectMaterialToTexture]))
            if (env) {
                updateEnv(env)
                materialUtils.updateMaterialTextures(new TextureAMA(final))
                setUpdating(false)
                return
            }
            try {
                await fetch(mapAsset.url)
                if (needUpdate) {
                    materialUtils.updateMaterialTextures(new TextureAMA(final), needUpdate)
                    return
                }
                materialUtils.updateMaterialTextures(new TextureAMA(final))
            } catch (error) {
                console.log('Error fetching asset', error)
            }
        }
        setUpdating(false)
    }

    const updateEnv = (url?: RenderMapOption['url']) => {
        if (onPropChange) {
            onPropChange(keyMap, url)
        }
    }

    const onClearInput = () => {
        updateMap(correctValue?.value, true)
        setValue(undefined)
        utils.setActiveEnvironment({
            url: '',
            intensity: 2.5
            // background: false
        })
    }

    const renderOption = (): RenderMapOption[] => {
        if (!isFetchingAssets && assetsData) {
            if (isEnv) {
                const acceptedTypes = ['jpg', 'jpeg']
                return assetsData
                    .filter(
                        i => i.url_original && acceptedTypes.some(type => type === i.url_original!.split('.').pop())
                    )
                    .map(item => {
                        return {
                            children: item.name,
                            label: (
                                <TextureItem key={item.id}>
                                    <MapIcon url={item.preview!} />
                                    <TextureTitle>{item.name}</TextureTitle>
                                </TextureItem>
                            ),
                            value: item.id,
                            url: item.url_original
                        }
                    })
            } else {
                return assetsData.map(item => {
                    return {
                        children: item.name,
                        label: (
                            <TextureItem key={item.id}>
                                <MapIcon url={item.preview!} />
                                <TextureTitle>{item.name}</TextureTitle>
                            </TextureItem>
                        ),
                        value: item.id
                    }
                })
            }
        }
        return []
    }
    const correctValue = typeof value === 'undefined' || value?.value ? value : currentValue

    return (
        <Select
            allowClear
            defaultValue={currentValue}
            value={correctValue}
            options={renderOption()}
            filterOption={(input, option) => {
                return option?.children ? option?.children.toLowerCase().includes(input.toLowerCase()) : false
            }}
            optionFilterProp="label"
            optionLabelProp="children"
            showSearch={true}
            style={{ flex: 1, overflow: 'hidden' }}
            onClear={onClearInput}
            onChange={(value, option) => {
                if (!option || Array.isArray(option)) return
                setValue(option)
                updateMap(value, false, option?.url)
            }}
        />
    )
}

const RenderMapId = ({ asset, keyMap, value, onPropChange, isEnv }: RenderMapIdProps) => {
    const [currentValue, setCurrentValue] = useState<RenderMapOption>({
        label: '',
        value: ''
    })
    const fetchAssetByIdResponse = useGetAssetByIdQuery({ id: value?.id as string })
    const { data: assetsData, isFetching: isFetchingAssets, refetch } = useGetAssetsQuery({ type: ASSET_TYPE.Texture })

    useEffect(() => {
        if (fetchAssetByIdResponse) {
            const { data } = fetchAssetByIdResponse
            if (!data) return
            const { assets } = data as { assets: TAsset[] }
            const targetAsset = assets[0]
            setCurrentValue({
                label: (
                    <TextureItem key={targetAsset.id}>
                        <MapIcon url={targetAsset.preview!} />
                        <TextureTitle>{targetAsset.name}</TextureTitle>
                    </TextureItem>
                ),
                value: targetAsset.id || ''
            })
        }
    }, [fetchAssetByIdResponse])

    useEffect(() => {
        if (isEnv && assetsData && !isFetchingAssets) {
            const target = assetsData.find(i => i.url_original && i.url_original === value)
            if (target) {
                setCurrentValue({
                    label: (
                        <TextureItem key={target.id}>
                            <MapIcon url={target.preview!} />
                            <TextureTitle>{target.name}</TextureTitle>
                        </TextureItem>
                    ),
                    value: target.id || ''
                })
            }
        }
    }, [isEnv, assetsData, isFetchingAssets])

    return (
        <RenderMap
            currentValue={currentValue}
            asset={asset}
            keyMap={keyMap}
            onPropChange={onPropChange}
            isEnv={isEnv}
            assetsData={assetsData}
            isFetchingAssets={isFetchingAssets}
        />
    )
}

const RenderAttachTexture = ({ keyMap, value, onPropChange, asset, isEnv }: AttachAssetProps) => {
    return (
        <PropsItem span={16}>
            <RenderMapId asset={asset} keyMap={keyMap} value={value} onPropChange={onPropChange} isEnv={isEnv} />
            {!isEnv && (
                <Switch
                    checked={value?.patch?.visible}
                    disabled={!value}
                    onChange={flag => {
                        onPropChange(keyMap, { ...value, patch: { ...value.patch, visible: flag } })
                    }}
                />
            )}
        </PropsItem>
    )
}

export default RenderAttachTexture

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`
const TextureTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const TextureItem = styled(PropsItem)`
    transition: background-color 0.3s ease-in-out;
    gap: 10px;
    cursor: pointer;
    &:hover {
        background-color: rgb(236 236 236);
    }
`
const MapIcon = styled.div<{ url: string }>`
    width: 50px;
    height: 32px;
    border: none;
    border-radius: 4px;
    background: ${props => `no-repeat center/cover url(${props?.url})`};
    flex-shrink: 0;
`

import {useContext, useEffect} from 'react'
import PageBuilder from '../../components/panel/PageBuilder'
import { Button, Table } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { ModalConfirmContext } from '../../providers/ModalConfirmProvider'
import {
    PaymentCardIDType,
    PaymentCardType,
    useUpdatePaymentSessionMutation
} from '../../api/paymentApi'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'
import {useSearchParams} from "react-router-dom";

type PaymentProps = {
    deleteCard: ({ id }: PaymentCardIDType) => Promise<any>
    addCard: () => Promise<any>
    isLoadingAddCard: boolean
    isLoadingCards: boolean
    refetchCards: () => void
    cards: PaymentCardType[]
}

const Payment = ({ deleteCard, addCard, isLoadingCards, refetchCards, cards }: PaymentProps) => {
    const [searchParams] = useSearchParams();
    const { showModal } = useContext(ModalConfirmContext)
    const [updateSession] = useUpdatePaymentSessionMutation()

    useEffect(() => {
        const type = searchParams.get('type')
        const status = searchParams.get('status')

        if (type && status) {
            if (status === '1') {
                toast.success('Payment Card is successfully added!')
            } else {
                toast.error('Something went wrong, try to add card again!')
            }

            updateSession({type, status})
        }
    }, []);

    const columns = [
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand'
        },
        {
            title: 'Last 4',
            dataIndex: 'last4',
            key: 'last4'
        },
        {
            title: 'Exp Month',
            dataIndex: 'exp_month',
            key: 'exp_month'
        },
        {
            title: 'Exp Year',
            dataIndex: 'exp_year',
            key: 'exp_year'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, card: PaymentCardType) => (
                <>
                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const loader = toast.loading('Progressing...')
                                const res = await deleteCard({ id: card.id })
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Payment Method is successfully deleted!')
                                refetchCards()
                            }

                            showModal('delete payment card', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    const handleAddCard = async () => {
        const res = await addCard()
        window.open(res.data.link,'_blank');
    }

    return (
        <PageBuilder title="Payment" documentTitle="Payment">
            <div style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={handleAddCard}>
                    <PlusOutlined /> Add Payment Card
                </Button>
            </div>

            <Table
                rowKey={() => uuid()}
                loading={isLoadingCards}
                pagination={false}
                dataSource={cards}
                columns={columns}
            />

            {/*{isModalOpenAddCard && (*/}
            {/*    <Modal*/}
            {/*        title="Add Payment Card"*/}
            {/*        open={isModalOpenAddCard}*/}
            {/*        onCancel={() => setIsModalOpenAddCard(false)}*/}
            {/*        footer={null}>*/}
            {/*        <Elements stripe={stripePromise}>*/}
            {/*            <PaymentCard isLoading={isLoadingAddCard} addCard={handleAddCard} />*/}
            {/*        </Elements>*/}
            {/*    </Modal>*/}
            {/*)}*/}
        </PageBuilder>
    )
}

export default Payment

import React, { useContext, useEffect, useState } from 'react'
import PageBuilder from '../../../components/panel/PageBuilder'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { useNavigate, useParams } from 'react-router-dom'
import {
    useCloneCompanyProductMutation,
    useDeleteCompanyProductMutation,
    useGetCompanyProductMutation
} from '../../../api/companyCatalogApi'
import { CatalogPreviewItemType } from '../../../api/shareApi'
import { Button, Space, Spin } from 'antd'
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import toast from 'react-hot-toast'
import { routesUrl } from '../../../router/urls'
import CompanyPreview from './CompanyPreview'

const CompanyProductView = () => {
    const { showModal } = useContext(ModalConfirmContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [product, setProduct] = useState<CatalogPreviewItemType | null>(null)
    const [allProducts, setAllProducts] = useState<CatalogPreviewItemType[]>([])
    const [modelSizeInAR, setModelSizeInAR] = useState(1)

    const [getProduct, resultsProduct] = useGetCompanyProductMutation()
    const [deleteItem] = useDeleteCompanyProductMutation()
    const [cloneItem] = useCloneCompanyProductMutation()

    useEffect(() => {
        getProduct({ id: id! })
    }, [])

    useEffect(() => {
        if (resultsProduct.isSuccess) {
            const { data } = resultsProduct
            const product = data as unknown as CatalogPreviewItemType
            setProduct({
                ...product,
                price: Array.isArray(product?.price) ? undefined : product?.price
            })
        }
    }, [resultsProduct.isSuccess])

    const productName = product?.name ?? ''

    if (resultsProduct.isLoading) {
        return <Spin size="large" />
    }

    return (
        <PageBuilder
            breadcrumbs={['catalog', `View: ${productName}`]}
            title={`View: ${productName}`}
            documentTitle={`View: ${productName}`}>
            {product && (
                <>
                    <Space style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                navigate(`${routesUrl.companyCatalogEdit}/${id}`)
                            }}
                            type="primary">
                            <EditOutlined /> Edit Product
                        </Button>
                        <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const loader = toast.loading('Progressing...')
                                    const res = await deleteItem([id!])
                                    toast.dismiss(loader)
                                    if ('error' in res) return
                                    toast.success('Product is successfully deleted!')
                                    navigate(routesUrl.companyCatalog)
                                }

                                showModal('delete product', handleDelete)
                            }}
                            type="primary">
                            <DeleteOutlined /> Delete Product
                        </Button>
                        <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const loader = toast.loading('Progressing...')
                                    const res = await cloneItem([id!])
                                    toast.dismiss(loader)
                                    if ('error' in res) return
                                    toast.success('Product is successfully duplicated!')
                                    navigate(routesUrl.companyCatalog)
                                }

                                showModal('duplicate product', handleDelete)
                            }}
                            type="primary">
                            <CopyOutlined /> Duplicate Product
                        </Button>
                    </Space>
                    <div style={{ marginTop: '16px' }}>
                        <CompanyPreview product={product} />
                    </div>
                </>
            )}
        </PageBuilder>
    )
}

export default CompanyProductView

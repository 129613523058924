import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import {
    APOPriceType,
    APOType,
    APOUpdateType,
    useCreateAPOMutation,
    useGetAmaProductCompanyTypeQuery,
    useUpdateAPOMutation,
    useUpdateAPOPriceMutation
} from '../../../api/amaProductOwnershipApi'
import { useParams } from 'react-router-dom'
import PageBuilder from '../../../components/panel/PageBuilder'
import { Button, Space, Table } from 'antd'
import { DollarOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import ModalWithForm from '../../users/ModalWithForm'
import { useGetCompanyByIdQuery } from '../../../api/companyApi'
import ApoCreateForm from '../compnents/ApoCreateForm'
import ApoEditForm from '../compnents/ApoEditForm'
import ApoPriceForm from '../compnents/ApoPriceForm'
import { isArray } from 'underscore'

const ApoCompany = () => {
    const [formCreate] = useForm<APOType>()
    const [formUpdate] = useForm<APOUpdateType>()
    const [formUpdatePrice] = useForm<APOPriceType>()

    const { id } = useParams()

    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false)
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false)
    const [isOpenModalUpdatePrice, setIsOpenModalUpdatePrice] = useState(false)

    const { data: userInfo, refetch, isSuccess } = useGetCompanyByIdQuery({ id: id! })
    const { data: userType } = useGetAmaProductCompanyTypeQuery(null)
    const [create, resultsCreate] = useCreateAPOMutation()
    const [update, resultsUpdate] = useUpdateAPOMutation()
    const [updatePrice, resultsUpdatePrice] = useUpdateAPOPriceMutation()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Current Price',
            dataIndex: 'current_price',
            key: 'current_price'
        },
        {
            title: 'Current Currency',
            dataIndex: 'current_currency',
            key: 'current_currency'
        }
    ]

    return (
        <PageBuilder
            title={`${userInfo?.name ?? ''} AMA Product Ownership`}
            documentTitle={`Company AMA Product Ownership`}>
            {isSuccess && userInfo && userInfo.how_pay !== 0 ? (
                <>
                    <Space style={{ marginBottom: 20 }}>
                        {isArray(userInfo.payment) && userInfo.payment.length === 0 ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsOpenModalCreate(true)
                                    formCreate.setFieldsValue({ connection_id: id!, connection_type: 2 })
                                }}>
                                <PlusOutlined /> Create Product
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setIsOpenModalUpdate(true)
                                        formUpdate.setFieldsValue({
                                            apo_id: userInfo.payment.id,
                                            product_id: userType?.filter(item => item.name === userInfo.payment.name)[0]
                                                .id
                                        })
                                    }}>
                                    <EditOutlined /> Update Product
                                </Button>

                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setIsOpenModalUpdatePrice(true)
                                        formUpdatePrice.setFieldsValue({
                                            apo_id: userInfo.payment.id,
                                            amount: userInfo.payment.current_price,
                                            type_debit: userInfo.payment.current_debit
                                        })
                                    }}>
                                    <DollarOutlined /> Update Price
                                </Button>
                            </>
                        )}
                    </Space>

                    <Table columns={columns} pagination={false} dataSource={[userInfo.payment]} />
                </>
            ) : (
                'Nothing...'
            )}

            <ModalWithForm
                title="Create APO"
                isOpen={isOpenModalCreate}
                setIsOpen={setIsOpenModalCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <ApoCreateForm
                    form={formCreate}
                    setIsModalOpen={setIsOpenModalCreate}
                    onFinish={create}
                    refetch={refetch}
                    userType={userType ?? []}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update APO"
                isOpen={isOpenModalUpdate}
                setIsOpen={setIsOpenModalUpdate}
                isLoading={resultsUpdate.isLoading}
                submit={formUpdate.submit}>
                <ApoEditForm
                    form={formUpdate}
                    setIsModalOpen={setIsOpenModalUpdate}
                    onFinish={update}
                    refetch={refetch}
                    userType={userType ?? []}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Price"
                isOpen={isOpenModalUpdatePrice}
                setIsOpen={setIsOpenModalUpdatePrice}
                isLoading={resultsUpdatePrice.isLoading}
                submit={formUpdatePrice.submit}>
                <ApoPriceForm
                    form={formUpdatePrice}
                    onFinish={updatePrice}
                    refetch={refetch}
                    setIsModalOpen={setIsOpenModalUpdatePrice}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default ApoCompany

import PageBuilder from '../../../components/panel/PageBuilder'
import { useAppSelector } from '../../../hooks'
import AvailableLicensesTable from './AvailableLicensesTable'

const AvailableLicenses = () => {
    const { id } = useAppSelector(state => state.user)

    return (
        <PageBuilder title="Available Licenses" documentTitle="Available Licenses">
            <AvailableLicensesTable dealerId={id.toString()} />
        </PageBuilder>
    )
}

export default AvailableLicenses

import { Form } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useSetPasswordMutation } from 'api/userApi'
import AuthWrapper from './components/AuthWrapper'
import AuthSuccessAlert from './components/AuthSuccessAlert'
import { StyledAuthButton } from './styles'
import AuthConfirmPassword from './components/AuthConfirmPassword'
import toast from 'react-hot-toast'
import { routesUrl } from '../../router/urls'

const messages = ['You have updated your password.', 'You can now return to the sign in page.']

const SetPassword = () => {
    const { token } = useParams()

    const [setPassword, result] = useSetPasswordMutation()

    return (
        <AuthWrapper title="New Password">
            {result.isSuccess ? (
                <AuthSuccessAlert messages={messages} link={routesUrl.login} />
            ) : (
                <Form
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    onFinish={async form => {
                        const loader = toast.loading('Progressing...')
                        const { password } = form
                        const res = await setPassword({ new_password: password, restore_token: token as string })
                        toast.dismiss(loader)
                        if ('error' in res) return
                    }}>
                    <AuthConfirmPassword />
                    <StyledAuthButton type="primary" htmlType="submit" loading={result.isLoading}>
                        Update Password
                    </StyledAuthButton>
                    Or <Link to={routesUrl.login}>sign in now!</Link>
                </Form>
            )}
        </AuthWrapper>
    )
}

export default SetPassword

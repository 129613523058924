import React, { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Cascader, Input, InputNumber, Slider } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import {
    ASSET_TYPE,
    AssetAttributeType,
    AssetRuleActionType,
    AssetType,
    useGetAssetsQuery
} from '../../../../../../api/assetApi'
import { TAsset } from '../../../../../../types'
import { TextureAMA, utilsEditor } from '@amaspace-editor/editor-3d'
import styled from 'styled-components'
import { uniq } from 'underscore'
const { Option } = Select

export const mapsTextures = {
    map: undefined,
    roughnessMap: undefined,
    alphaMap: undefined,
    metalnessMap: undefined,
    normalMap: undefined,
    emissiveMap: undefined,
    aoMap: undefined
}

const options: Option[] = [
    {
        value: 'transform',
        label: 'Transform',
        children: [
            {
                value: 'position',
                label: 'Position',
                children: [
                    {
                        value: 'vec3',
                        label: 'Set XYZ'
                    }
                ]
            },
            {
                value: 'rotation',
                label: 'Rotation',
                children: [
                    {
                        value: 'vec3',
                        label: 'Set XYZ'
                    }
                ]
            },
            {
                value: 'visible',
                label: 'Visible',
                children: [
                    {
                        value: 'boolean',
                        label: 'Change'
                    }
                ]
            },
            {
                value: 'morphTarget',
                label: 'Morph',
                children: [
                    {
                        value: 'string',
                        label: 'Change'
                    }
                ]
            }
        ]
    },
    {
        value: 'material',
        label: 'Material',
        children: [
            {
                value: 'property',
                label: 'Property',
                children: [
                    {
                        value: 'metalness_range',
                        label: 'Metalness'
                    },
                    {
                        value: 'roughness_range',
                        label: 'Roughness'
                    },
                    {
                        value: 'bloom',
                        label: 'Bloom',
                        children: [
                            {
                                value: 'boolean',
                                label: 'Change'
                            }
                        ]
                    }
                ]
            },
            {
                value: 'color',
                label: 'Color',
                children: [
                    {
                        value: 'vec3',
                        label: 'RGB'
                    }
                ]
            },
            {
                value: 'texture',
                label: 'Texture',
                children: [
                    {
                        value: 'map',
                        label: 'Map'
                    },
                    {
                        value: 'roughnessMap',
                        label: 'RoughMap'
                    },
                    {
                        value: 'alphaMap',
                        label: 'AlphaMap'
                    },
                    {
                        value: 'metalnessMap',
                        label: 'MetalMap'
                    },
                    {
                        value: 'normalMap',
                        label: 'NormalMap'
                    },
                    {
                        value: 'emissiveMap',
                        label: 'EmissiveMap'
                    },
                    {
                        value: 'aoMap',
                        label: 'aoMap'
                    },
                    {
                        value: 'blendMap',
                        label: 'BlendMap'
                    }
                ]
            }
        ]
    }
]

type ActionActivateAnimationProps = {
    item: AssetRuleActionType
    modelPart: TAsset[]
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
    attributes: AssetAttributeType[]
}

type ChangeObjectPropertyType = {
    modelPart: NodeData | null
    path: (string | number)[]
    value: any
    attribute: AssetAttributeType | null
}
type NodeData = {
    value: string
    label: string
}
interface Option {
    value: string | number
    label: string
    children?: Option[]
    type?: string
}

const ActionChangeObjectProperty = ({
    item,
    deleteAction,
    modelPart,
    changeAction,
    attributes
}: ActionActivateAnimationProps) => {
    const { data: assetsData, isFetching: isFetchingAssets, refetch } = useGetAssetsQuery({ type: ASSET_TYPE.Texture })

    const editor3D = utilsEditor()

    const [objectPropertyType, setObjectPropertyType] = useState<string | number>('')
    const [action, setAction] = useState<ChangeObjectPropertyType>(
        item.value
            ? JSON.parse(item.value)
            : {
                  modelPart: null,
                  value: null,
                  path: [],
                  attribute: null
              }
    )
    const [vec3, setVec3] = useState<[number, number, number]>(action.value || [0, 0, 0])
    const [value, setValue] = useState(action.value)
    console.log('@action', action)

    const nodesData = React.useMemo((): NodeData[] => {
        const getCorrectNodes = (): NodeData[] => {
            if (editor3D.uploadedModelsConfig.length) {
                const objects = editor3D.uploadedModelsConfig.map(config => {
                    return config.objects.map(item => ({ value: item.userData.id, label: item.name }))
                })
                const custom = editor3D.uploadedModelsConfig.map(config => {
                    return config.customObjects.map(item => ({ value: item.userData.id, label: item.name }))
                })
                const innerConfig = [...editor3D?.config?.objects, ...editor3D?.config?.customObjects].map(item => ({
                    value: item.userData.id,
                    label: item.name
                }))
                return [...objects.flat(), ...custom.flat(), ...innerConfig]
            }
            return [...editor3D?.config?.objects, ...editor3D?.config?.customObjects].map(item => ({
                value: item.userData.id,
                label: item.name
            }))
        }

        return getCorrectNodes()
    }, [editor3D.uploadedModelsConfig])

    const createMapOption = (target: AssetType) => {
        return {
            children: target?.name,
            label: (
                <TextureItem key={target?.id}>
                    <MapIcon url={target?.url} />
                    <TextureTitle>{target?.name}</TextureTitle>
                </TextureItem>
            ),
            value: target?.id || ''
        }
    }
    const renderOption = React.useMemo(() => {
        if (!isFetchingAssets && assetsData) {
            return assetsData.map(item => {
                return createMapOption(item)
            })
        }
        return []
    }, [assetsData])

    const renderMorphOption = React.useMemo(() => {
        const list = editor3D.morphTargetsList
        if (!list) return []
        const unique = uniq(list.map(item => item?.key))

        return unique.map(item => {
            return {
                children: item,
                label: <TextureTitle>{item}</TextureTitle>,
                value: item || ''
            }
        })
    }, [editor3D.morphTargetsList])

    const changeInputValue = (value: any) => {
        setValue(value)
        setAction(prev => ({ ...prev, value: value }))
    }

    const resetValue = () => {
        setObjectPropertyType('')
        setVec3([0, 0, 0])
        setAction({ ...action, value: '', path: [] })
        setValue(undefined)
    }

    const correctPropertyType = (type?: string | number) => {
        const property = ['map', 'range', 'vec3']
        let correctType = type

        property.forEach(prop => {
            if (typeof type === 'string' && type.toLowerCase().includes(prop)) correctType = prop
        })
        return correctType
    }

    const onChange = (value: (string | number)[]) => {
        if (!value) {
            resetValue()
            return
        }
        setValue(null)
        setVec3([0, 0, 0])
        setAction(prev => ({ ...prev, path: value, value: null }))
        const type = correctPropertyType([...value].pop())

        if (type) setObjectPropertyType(type)
    }
    const correctPath = (path: (number | string)[]) => {
        return path.map((item, inx) => {
            if (inx === path.length - 1) {
                return typeof item === 'string' ? item.split('_')[0] : item
            }
            return item
        })
    }
    const changeMaterialMap = (value: any) => {
        if (!value) {
            setValue(null)
            return
        }
        const current = assetsData?.find(i => i?.id === value)
        const type = [...action.path].pop()
        if (!current) return

        const final = {
            ...current,
            patch: JSON.parse(current.patch),
            type: type
        } as any

        const texture = new TextureAMA(final)

        changeInputValue(texture)
        setValue(createMapOption(current))
    }

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    useEffect(() => {
        if (action.path.includes('vec3')) changeInputValue(vec3)
    }, [vec3])

    useEffect(() => {
        if (action.path.length) {
            const type = correctPropertyType([...action.path].pop())

            if (type) setObjectPropertyType(type)
        }
        if (action.path.includes('texture')) {
            setValue(createMapOption(action.value))
        }
    }, [])

    const renderCorrectInput = (type: string | number) => {
        switch (type) {
            case 'map':
                return (
                    <Select
                        value={value}
                        allowClear
                        options={renderOption}
                        filterOption={(input, option) => {
                            return option?.children
                                ? option?.children.toLowerCase().includes(input.toLowerCase())
                                : false
                        }}
                        optionFilterProp="label"
                        optionLabelProp="children"
                        showSearch={true}
                        onChange={changeMaterialMap}
                    />
                )

            case 'string':
                return (
                    <Select
                        value={value}
                        allowClear
                        options={renderMorphOption}
                        filterOption={(input, option) => {
                            return option?.children
                                ? option?.children.toLowerCase().includes(input.toLowerCase())
                                : false
                        }}
                        optionFilterProp="label"
                        optionLabelProp="children"
                        showSearch={true}
                        onChange={changeInputValue}
                    />
                )
            case 'range':
                return (
                    <Slider
                        style={{ flex: 1 }}
                        min={0}
                        max={1}
                        step={0.01}
                        value={value ?? 0}
                        onChange={changeInputValue}
                    />
                )
            case 'boolean':
                return (
                    <Col span={26}>
                        <Row>
                            <PropsItem span={26}>
                                <Checkbox
                                    checked={value}
                                    onChange={e => {
                                        changeInputValue(e.target.checked)
                                    }}
                                />
                            </PropsItem>
                        </Row>
                    </Col>
                )
            case 'vec3':
                return (
                    <Col span={26}>
                        <Row>
                            <PropsItem span={8}>
                                <InputNumber
                                    value={vec3[0]}
                                    onChange={e => {
                                        if (typeof e !== 'number') return
                                        setVec3(prev => [e, prev[1], prev[2]])
                                    }}
                                    step={0.01}
                                    maxLength={50}
                                />
                            </PropsItem>
                            <PropsItem span={8}>
                                <InputNumber
                                    value={vec3[1]}
                                    onChange={e => {
                                        if (typeof e !== 'number') return
                                        setVec3(prev => [prev[0], e, prev[2]])
                                    }}
                                    step={0.01}
                                    maxLength={50}
                                />
                            </PropsItem>
                            <PropsItem span={8}>
                                <InputNumber
                                    value={vec3[2]}
                                    onChange={e => {
                                        if (typeof e !== 'number') return
                                        setVec3(prev => [prev[0], prev[1], e])
                                    }}
                                    step={0.01}
                                    maxLength={50}
                                />
                            </PropsItem>
                        </Row>
                    </Col>
                )

            default:
                return null
        }
    }

    return (
        <Row style={{ width: '100%', margin: 0 }} gutter={[4, 8]}>
            <StyledRow>
                <StyledCascader>
                    <Cascader value={action?.path} options={options} onChange={onChange} placeholder="Please select" />
                </StyledCascader>
                <StyledCorrectInput>{renderCorrectInput(objectPropertyType)}</StyledCorrectInput>
            </StyledRow>
            <StyledRow>
                <Col span={11}>
                    <Select
                        value={action.modelPart?.value}
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Model Part"
                        onChange={value => {
                            const foundModel = nodesData && nodesData.find(item => item.value === value)
                            setAction({ ...action, modelPart: foundModel ? foundModel : null })
                        }}>
                        {nodesData &&
                            nodesData.map(item => (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                    </Select>
                </Col>
                <Col span={11}>
                    <Select
                        value={action.attribute?.id}
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Attribute"
                        onChange={value => {
                            const item = attributes.filter(attr => attr.id === value)
                            setAction({ ...action, attribute: item[0] })
                        }}>
                        {attributes.map(item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col span={2}>
                    <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
                </Col>
            </StyledRow>
        </Row>
    )
}
const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`
const StyledCascader = styled.div`
    max-width: 200px;
    flex: 1.3;
`
const StyledCorrectInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.7;
`
const StyledRow = styled(Row)`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
`

const TextureTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const TextureItem = styled(PropsItem)`
    transition: background-color 0.3s ease-in-out;
    gap: 10px;
    cursor: pointer;
    &:hover {
        background-color: rgb(236 236 236);
    }
`
const MapIcon = styled.div<{ url?: string }>`
    width: 50px;
    height: 32px;
    border: none;
    border-radius: 4px;
    background: ${props => `no-repeat center/cover url(${props?.url})`};
    flex-shrink: 0;
`

export default ActionChangeObjectProperty

import { Button, Space, Tag } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import PageBuilder from '../../../components/panel/PageBuilder'
import {
    DealerProductFilter,
    useDeleteDealerProductMutation,
    useGetDealerProductsQuery,
    useGetDealerProductTagsQuery
} from '../../../api/dealerCatalogApi'
import {DeleteOutlined, EditOutlined, EyeOutlined, FolderFilled, PlusOutlined} from '@ant-design/icons'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import ExtendedTable from '../../../components/ExtendedTable'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import toast from 'react-hot-toast'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { ProductType } from '../../../providers/CatalogProvider'
import { routesUrl } from '../../../router/urls'

const DealerProducts = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams()
    const { showModal } = useContext(ModalConfirmContext)
    const navigate = useNavigate()

    const { data: productTags } = useGetDealerProductTagsQuery(null)

    const [filter, setFilter] = useState<DealerProductFilter>({
        page: 1,
        name: '',
        name_sort: '',
        tags: [],
        license_id: id ?? '',

        date_created_range_start: '',
        date_created_range_end: '',
        date_range_created_sort: '',

        date_updated_range_start: '',
        date_updated_range_end: '',
        date_range_updated_sort: ''
    })

    useEffect(() => {
        const license_id = searchParams.get("license_id")
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const name_sort = searchParams.get("name_sort")
        const date_created_range_start = searchParams.get("date_created_range_start")
        const date_created_range_end = searchParams.get("date_created_range_end")
        const date_range_created_sort = searchParams.get("date_range_created_sort")

        const date_updated_range_start = searchParams.get("date_updated_range_start")
        const date_updated_range_end = searchParams.get("date_updated_range_end")
        const date_range_updated_sort = searchParams.get("date_range_updated_sort")
        const product_group_id = searchParams.get("product_group_id")

        const tags = searchParams.get("tags")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            license_id: license_id ?? '',
            name: name ?? '',
            name_sort: name_sort ?? '',

            product_group_id: product_group_id ?? '',

            date_created_range_start: date_created_range_start ?? '',
            date_created_range_end: date_created_range_end ?? '',
            date_range_created_sort: date_range_created_sort ?? '',

            date_updated_range_start: date_updated_range_start ?? '',
            date_updated_range_end: date_updated_range_end ?? '',
            date_range_updated_sort: date_range_updated_sort ?? '',

            tags: tags ? tags.split(',') : [],
        })
    }, [searchParams]);

    const [deleteItems] = useDeleteDealerProductMutation()
    const { data, isLoading, refetch } = useGetDealerProductsQuery(filter)

    const [filterByText, filterByData, filterBySelect] = useFilterTable<DealerProductFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name', 'name_sort'),
            render: (name: string, product: ProductType) => {
                if (product.type_object === 'lproduct_group') {
                    return (
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                navigate(`${routesUrl.dealerCatalog}?product_group_id=${product.id}`)
                            }}>
                            <FolderFilled style={{marginRight: '10px'}} className={'table__name-icon'}/>
                            {name}
                        </Button>
                    )
                }

                return name
            }
        },
        {
            title: 'Date created',
            dataIndex: 'date_created',
            ...filterByData('date_created_range_start', 'date_created_range_end', 'date_range_created_sort')
        },
        {
            title: 'Date updated',
            dataIndex: 'date_updated',
            ...filterByData('date_updated_range_start', 'date_updated_range_end', 'date_range_updated_sort')
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            ...filterBySelect('tags', productTags!, 'name'),
            render: (tags: string[]) => (
                <Space size={[0, 'small']} wrap>
                    {tags.map(tag => (
                        <Tag key={tag} color="processing">
                            {tag}
                        </Tag>
                    ))}
                </Space>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, product: ProductType) => (
                <>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            navigate(`${routesUrl.dealerCatalogEdit}/${product.id}`)
                        }}>
                        <EditOutlined />
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            navigate(`${routesUrl.dealerCatalogView}/${product.id}`)
                        }}>
                        <EyeOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const loader = toast.loading('Progressing...')
                                const res = await deleteItems([product.id!.toString()])
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Product is successfully deleted!')
                                refetch()
                            }

                            showModal('delete product', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder breadcrumbs={['products', 'pco']} title="Catalog" documentTitle="Catalog">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => navigate(routesUrl.dealerCatalogCreate)}>
                    <PlusOutlined /> Create Product
                </Button>

                <Button type={'primary'} onClick={() => navigate(routesUrl.dealerCatalogArchive)}>
                    Archived Products
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="products"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />
        </PageBuilder>
    )
}

export default DealerProducts

import api from './index'

export const PAYMENT = 'payment'

const PAYMENT_DEALER = `${PAYMENT}/dealer`
const PAYMENT_COMPANY = `${PAYMENT}/company`
const UPDATE_SESSION = `${PAYMENT}/update-session`

const paymentApi = api.injectEndpoints({
    endpoints: build => ({
        getDealerCard: build.query<PaymentCardType[], null>({
            query: () => PAYMENT_DEALER
        }),
        addDealerCard: build.mutation<{link: string}, null>({
            query: body => ({
                url: PAYMENT_DEALER,
                method: 'POST',
            })
        }),
        deleteDealerCard: build.mutation<null, PaymentCardIDType>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ id })

                return {
                    url: `${PAYMENT_DEALER}?${params}`,
                    method: 'DELETE'
                }
            }
        }),

        updatePaymentSession: build.mutation<null, {type: string, status: string}>({
            query: (body) => {
                const params = new URLSearchParams(body)

                return {
                    url: `${UPDATE_SESSION}?${params}`,
                    method: 'GET'
                }
            }
        }),

        getCompanyCard: build.query<PaymentCardType[], null>({
            query: () => PAYMENT_COMPANY
        }),
        addCompanyCard: build.mutation<{link: string}, null>({
            query: body => ({
                url: PAYMENT_COMPANY,
                method: 'POST',
            })
        }),
        deleteCompanyCard: build.mutation<null, PaymentCardIDType>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ id })

                return {
                    url: `${PAYMENT_COMPANY}?${params}`,
                    method: 'DELETE'
                }
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetCompanyCardQuery,
    useAddCompanyCardMutation,
    useDeleteCompanyCardMutation,

    useUpdatePaymentSessionMutation,

    useGetDealerCardQuery,
    useDeleteDealerCardMutation,
    useAddDealerCardMutation
} = paymentApi

export type PaymentCardType = {
    id: string
    brand: string
    last4: string
    exp_month: number
    exp_year: number
}

export type PaymentCardIDType = Pick<PaymentCardType, 'id'>

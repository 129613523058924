import { Checkbox } from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { ProductAttributeType } from '../../tabs/ProductAttributes'

type ProductAttributeIsSomeProps = {
    attr: ProductAttributeType
    keyValue: keyof ProductAttributeType
    title: string
}

const ProductAttributeIsSome = ({ attr, keyValue, title }: ProductAttributeIsSomeProps) => {
    const { attributes, setAttributes } = useContext(CatalogContext)

    const handleChangeVisible: HandleChangeAttributeVisible = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                [keyValue]: item.id === attr.id ? (value ? 1 : 0) : item[keyValue],
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    return (
        <Checkbox checked={!!attr[keyValue]} onChange={e => handleChangeVisible(e.target.checked)}>
            {title}
        </Checkbox>
    )
}

export default ProductAttributeIsSome

type HandleChangeAttributeVisible = (value: boolean) => void

import PageBuilder from 'components/panel/PageBuilder'
import ProductTabs from '../components/tabs/ProductTabs'
import CatalogDealerProvider from '../../../providers/CatalogDealerProvider'

const DealerProductCreate = () => {
    return (
        <PageBuilder breadcrumbs={['products', 'create']} title="Create Product" documentTitle="Create Product">
            <CatalogDealerProvider>
                <ProductTabs type="dealer" isCreating={true} />
            </CatalogDealerProvider>
        </PageBuilder>
    )
}

export default DealerProductCreate

import { parentDomain } from 'utils'
import { ModelConnections } from '../pages/assets/editor/editor3D/renderAttachAsset'
import { ProductActionTypeId } from '../pages/assets/editor/logic/LogicEditor'
import { TAsset } from '../types'
import api from './index'
import { CurrencyType } from './priceApi'

export const shareApi = api.injectEndpoints({
    endpoints: build => ({
        applyRules: build.mutation<ApplyRulesResponse[], { id: number; rule: ApplyRulesType[] }>({
            query: body => {
                return {
                    url: `product/apply-rules?id=${body.id}`,
                    method: 'POST',
                    body: body.rule
                }
            }
        }),
        applyRulesAdmin: build.mutation<ApplyRulesResponse[], CatalogShareApplyRulesBody>({
            query: body => {
                return {
                    url: `product-rule/apply-rules`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        applyRulesShare: build.mutation<ApplyRulesResponse[], CatalogShareApplyRulesBody>({
            query: body => {
                return {
                    url: `share/apply-rules`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        getProductShare: build.mutation<CatalogShareResponse, CatalogShareBody>({
            query: body => ({
                url: 'share/get-product',
                method: 'POST',
                body
            })
        }),
        getLicenseShare: build.mutation<CatalogLicenseShareResponse, CatalogShareLicenseBody>({
            query: body => ({
                url: 'share/get-license',
                method: 'POST',
                body
            })
        }),
        sendLeadShare: build.mutation<CatalogShareResponse, CatalogShareBodyLead>({
            query: body => ({
                url: 'share/lead',
                method: 'POST',
                headers: {
                    'X-Shared-Domain': parentDomain,
                    'X-Shared-Access-Token': body.accessToken
                },
                body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useApplyRulesMutation,
    useGetProductShareMutation,
    useApplyRulesShareMutation,

    useGetLicenseShareMutation,
    useSendLeadShareMutation,
    useApplyRulesAdminMutation
} = shareApi

export type ProductAttributeConnectionOptions = {
    id: number
    image_url: string
    name: string
    description: string
    disabled?: boolean
    customizer_id: string
    price?: PriceBook
    exclude?: []
    remove?: []
    checked?: boolean
}

export type CatalogShareBody = {
    domain: string
    accessToken: string
    product_id: string | number
}

export type CatalogShareLicenseBody = Omit<CatalogShareBody, 'product_id'> & {
    license_id: string
}

export type CatalogShareApplyRulesBody = CatalogShareBody & {
    attributes: ApplyRulesType[]
}

export type ApplyRulesType = {
    attribute_id: number | string
    value: string
}

export type ProductRuleAction = {
    id?: number
    type?: string
    attribute_id: number
    type_action: ProductRuleTypeAction | ''
    rule_id: number
    option_id: string
    value: string
}

type ProductRuleTypeAction = {
    [K in keyof typeof ProductActionTypeId]: (typeof ProductActionTypeId)[K]
}[keyof typeof ProductActionTypeId]

export type ApplyRulesResponse = {
    attribute_id: number
    value: string
    actions: ProductRuleAction[]
}

export type CatalogLicensePackages = {
    id: string
    name: string
    image: string
    price: string
    currency: string
}
export type CatalogLicenseShareResponse = {
    products: CatalogLicensePackages[]
    style_guides: string
}

export type CatalogShareBodyLead = {
    accessToken: string | undefined
    attributes: { name: string; value: string }[]
    domain: string
    email: string
    lastname: string
    message: string
    name: string
    phone: string
    product_id: number | string
}

export type CatalogPreviewItemType = {
    id: number | string
    name: string
    model: string
    year: string
    make: string
    type: 1 | 2
    description: string
    image_url: string
    asset_id: string
    asset_type: 'model' | 'texture' | 'folder' | 'material'
    asset_customizer_id: null | string
    attributes: ProductAttributeType[]
    tags: TagsType
    keywords: TagsType
    price?: string
    category: CatalogPreviewItemCategory[]
    color?: string
}

export type CatalogPreviewItemCategory = {
    id: number
    name: string
    parent_id: number
    description: string
    annotation_id: number | null
}

export type PriceBook = {
    id: number
    name: string
    currency: CurrencyType[] | ReturnedCurrencyType[]
}

export type ReturnedCurrencyType = Omit<CurrencyType, 'name'> & {
    value: string
}

export type TagsType = { id: number; name: string }[]

export type ProductAttributeConnection = {
    id: number
    value: string
    type: string
    connection_id: number
    customizer_id: string
    products?: ProductAttributeConnectionOptions[]
}

export type ProductAttributeType = {
    attrType?: string
    id: number | string
    product_id: number
    name: string
    type: string
    parent_id?: number | string | null
    imageName?: string
    sorting: number
    replacement_attribute_id?: number
    default_value?: string
    value: any
    options?: any
    parent?: number | string
    price?: string
    connections?: ProductAttributeConnection[]
    is_color?: boolean
    is_morph_target?: boolean
}

export type ProductAttributeExclude = {
    attribute: ProductAttributeType
    value: number
    exclude: ProductAttributeResponse
}

export type ProductAttributeResponse = ProductAttributeType & {
    default: boolean
    afterUpdate?: boolean
    checked: boolean
    customizer_id?: string
    exclude: {
        attribute?: ProductAttributeResponse
        exclude?: ProductAttributeResponse
        id?: string | number
    }[]
    remove: any[]
    confirmationMessage?: boolean
    category?: number
    empty?: boolean
    newValue?: string
    updated: {
        enabled: boolean
        visible: boolean
        value: string
        options: ProductAttributeConnectionOptions[]
        exclude?: ProductAttributeExclude[]
        color?: any
        remove?: boolean
    }
}

export type CatalogShareResponse = {
    product: CatalogPreviewItemType
    assets: TAsset[]
    connections: ModelConnections[]
}

import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const ACCOUNT_MANAGER = 'account-manager'

const accountManagerApi = api.injectEndpoints({
    endpoints: build => ({
        getAccountManagers: build.query<GetAccountManagersType, AccountManagerFilter>({
            query: body => {
                const { page, name, lastname, email, status } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    lastname,
                    email,
                    status: status ?? ''
                })

                return `${ACCOUNT_MANAGER}?${params}`
            }
        }),
        createAccountManager: build.mutation<null, AccountManagerInfoType>({
            query: body => ({
                url: ACCOUNT_MANAGER,
                method: 'POST',
                body
            })
        }),
        updateAccountManager: build.mutation<null, AccountManagerInfoType>({
            query: body => {
                const { id, ...newBody } = body

                return {
                    url: `${ACCOUNT_MANAGER}/${id}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),
        changeAccountManagerStatus: build.mutation<null, UserStatusInfo>({
            query: body => {
                const { status, id } = body

                return {
                    url: `${ACCOUNT_MANAGER}/${id}/change-status`,
                    method: 'PUT',
                    body: { status }
                }
            }
        }),

        deleteAccountManager: build.mutation<null, { id: number }>({
            query: body => ({
                url: `${ACCOUNT_MANAGER}/${body.id}`,
                method: 'DELETE'
            })
        }),

        changeAccountManagerEmail: build.mutation<null, { id: string | number, email: string }>({
            query: body => {
                const {id, email} = body
                return {
                    url: `${ACCOUNT_MANAGER}/${id}/email-change`,
                    method: 'POST',
                    body: {email}
                }
            }
        }),

        resendAccountManagerEmailConfirmation: build.mutation<null, { id: number }>({
            query: body => {
                const {id} = body
                return {
                    url: `${ACCOUNT_MANAGER}/${id}/confirmation-resend`,
                    method: 'POST',
                }
            }
        }),
    }),
    overrideExisting: false
})

export type AccountManagerType = {
    id: number
    name: string
    lastname: string
    email: string
    status: UserStatus
    companies: { id: number; name: string }[]
}

export type UserStatus = 'Active' | 'Inactive' | 'Sign up email confirmation' | 'Wait approving' | 'Deleted'

type GetAccountManagersType = {
    items: AccountManagerType[]
    _meta: TableMetaType
}

export type AccountManagerFilter = {
    page: number
    email: string
    name: string
    lastname: string
    status: UserStatus | null
}

export type UserStatusInfo = { id: number | string; status: UserStatus }

export type AccountManagerInfoType = Omit<AccountManagerType, 'status' | 'companies'> & { companies: number[] }

export const {
    useDeleteAccountManagerMutation,
    useCreateAccountManagerMutation,
    useChangeAccountManagerStatusMutation,
    useUpdateAccountManagerMutation,
    useGetAccountManagersQuery,
    useChangeAccountManagerEmailMutation,
    useResendAccountManagerEmailConfirmationMutation,
} = accountManagerApi

export default accountManagerApi

import Title from 'antd/lib/typography/Title'
import React, { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { TAnnotationParams, TPatchParams } from './AnnotationsEditor'
import AnnotationTextureEditor from './AnnotationTextureEditor'

export type TextureFiltersConfig = {
    brightness: number
    contrast: number
    hue: number
    invert: boolean
    saturation: number
}

type Props = {
    annotationPatch: TPatchParams<TAnnotationParams>
    setAnnotationPatch: Dispatch<SetStateAction<TPatchParams<TAnnotationParams>>>
}

const AnnotationTexture = ({ annotationPatch, setAnnotationPatch }: Props) => {
    const [textureEditorOpened, setTextureEditorOpened] = useState<boolean>(false)

    const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64String = reader.result as string
                setAnnotationPatch(current => {
                    return {
                        ...current,
                        textureOriginal: base64String,
                        textureModified: base64String,
                        brightness: 100,
                        contrast: 100,
                        hue: 0,
                        invert: false,
                        saturation: 100
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    return (
        <Component>
            <Title level={5} children="Texture" />
            <Content>
                <InputWrapper>
                    {annotationPatch.textureModified || annotationPatch.textureOriginal ? (
                        <ImagePreview
                            alt="uploaded"
                            src={String(annotationPatch.textureModified) || String(annotationPatch.textureOriginal)}
                        />
                    ) : null}
                    <InputUpload type="file" accept=".jpg, .png, .jpeg" onChange={onUpload} />
                </InputWrapper>
                <ButtonEdit
                    disabled={Boolean(!annotationPatch.textureOriginal)}
                    onClick={() => {
                        setTextureEditorOpened(true)
                    }}>
                    Edit
                </ButtonEdit>
            </Content>
            {textureEditorOpened && annotationPatch.textureOriginal ? (
                <TextureEditorWrapper>
                    <AnnotationTextureEditor
                        annotationPatch={annotationPatch}
                        close={() => {
                            setTextureEditorOpened(false)
                        }}
                        imageSource={String(annotationPatch.textureOriginal)}
                        setAnnotationPatch={setAnnotationPatch}
                    />
                </TextureEditorWrapper>
            ) : null}
        </Component>
    )
}

export default AnnotationTexture

const ButtonEdit = styled.button`
    align-items: center;
    background-color: #efefef !important;
    border: none;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    opacity: ${props => (props.disabled ? 0.75 : 1)};
    padding: 0 24px;
    pointer-events: ${props => (props.disabled ? 'none' : null)};
`

const Component = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 16px 0;
    position: relative;
`

const Content = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
`

const ImagePreview = styled.img`
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 64px;
`

const InputWrapper = styled.div`
    background-color: #efefef !important;
    height: 40px;
    position: relative;
    width: 64px;
`

const InputUpload = styled.input`
    height: 40px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 64px;
`

const TextureEditorWrapper = styled.div`
    background-color: #efefef !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
    z-index: 1;
`

import PageBuilder from '../../../components/panel/PageBuilder'
import CatalogCompanyProvider from '../../../providers/CatalogCompanyProvider'
import ProductTabs from '../components/tabs/ProductTabs'
import {useParams} from "react-router-dom";
import {routesUrl} from "../../../router/urls";

const CompanyProductEdit = () => {
    const {id} = useParams()
    return (
        <PageBuilder breadcrumbs={['catalog', {id: `${routesUrl.companyCatalogView.substring(1)}/${id!}`, name: 'view'}, `Edit`]} title="Edit Product" documentTitle="Edit Product">
            <CatalogCompanyProvider>
                <ProductTabs type="company" isCreating={false} />
            </CatalogCompanyProvider>
        </PageBuilder>
    )
}

export default CompanyProductEdit

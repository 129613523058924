import { AMAMode, utilsAnnotation, utilsEditor } from '@amaspace-editor/editor-3d'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, List, Typography } from 'antd'
import { AssetAnnotationType, useDeleteAssetAnnotationsMutation } from 'api/assetApi'
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { ModalConfirmContext } from '../../../../providers/ModalConfirmProvider'
import { CurrentAnnotationIdType } from '../AssetAnnotations'

const { Title } = Typography
const { Item } = List

type AnnotationsListProps = {
    assetId: number
    loading: boolean
    setShowEditor: Dispatch<SetStateAction<boolean>>
    editorAnnotationId: CurrentAnnotationIdType
    setEditorAnnotationId: Dispatch<SetStateAction<CurrentAnnotationIdType>>
    data: AssetAnnotationType[]
    setData: Dispatch<SetStateAction<AssetAnnotationType[]>>
}

const AnnotationsList = ({
    assetId,
    data,
    loading,
    setShowEditor,
    setData,
    setEditorAnnotationId,
    editorAnnotationId
}: AnnotationsListProps) => {
    const { showModal } = useContext(ModalConfirmContext)
    const [deleteAnnotations] = useDeleteAssetAnnotationsMutation()
    const editor3D = utilsEditor()
    const annotationUtils = utilsAnnotation()
    const wrapper = document.createElement('div')

    const newItem = useMemo((): AssetAnnotationType => {
        return {
            id: uuid(),
            asset_id: assetId,
            name: 'New Annotation',
            value: '',
            position: '[0,0,0]',
            patch: '',
            type: 'new'
        }
    }, [])

    const handleNewAnnotation = () => {
        editor3D.setMode(AMAMode.EDITOR)
        setData(prevState => [...prevState, newItem])
    }

    const handleDeleteAnnotations = (item: AssetAnnotationType) => {
        if (item.type !== 'new') {
            deleteAnnotations([item.id as string])
            if (editor3D) editor3D.deleteModelPart(item.id as string)
        }
        const filtered = data.filter(dataItem => dataItem.id !== item.id)
        setData(filtered)

        setEditorAnnotationId(null)
        setShowEditor(false)
    }

    const handleEditAnnotation = (item: AssetAnnotationType) => {
        setShowEditor(true)
        setEditorAnnotationId(item.id)
    }

    const startCreateAnnotation = (event: React.PointerEvent<HTMLDivElement>, item: AssetAnnotationType) => {
        if (item.type === 'new') {
            createDDItem(event)
            annotationUtils.startCreateAnnotation()

            document.addEventListener('pointermove', dragMove)
            document.addEventListener('pointerup', dragEnd)
        }
    }

    const setActiveAnnotation = (item: AssetAnnotationType) => {
        editor3D.unselect()
        editor3D.changeMode(AMAMode.EDITOR)
        editor3D.setActiveById(item.id as string)
    }

    const createDDItem = (event: React.PointerEvent<HTMLDivElement>) => {
        wrapper.classList.add('drag-item')
        wrapper.style.left = `${event.clientX}px`
        wrapper.style.top = `${event.clientY}px`
        wrapper.style.background = `center / contain no-repeat url('https://i.ibb.co/b3WBdwg/hotspot.png')`
        document.body.appendChild(wrapper)
    }

    const dragMove = (event: PointerEvent) => {
        wrapper.style.left = `${event.clientX}px`
        wrapper.style.top = `${event.clientY}px`
    }

    const dragEnd = () => {
        cleanerWrapper()
        handleEditAnnotation(newItem)
    }

    const disposeListener = () => {
        document.removeEventListener('pointermove', dragMove)
        document.removeEventListener('pointerup', dragEnd)
    }

    const disposeDDPreview = () => {
        document.querySelectorAll('.drag-item').forEach(el => {
            el.remove()
        })
    }

    const cleanerWrapper = () => {
        disposeDDPreview()
        disposeListener()
    }

    useEffect(() => {
        document.addEventListener('pointermove', dragMove)
    }, [])

    return (
        <div>
            <Title level={3} children="Annotations List" />

            <Button onClick={handleNewAnnotation} type="primary">
                + New Annotation
            </Button>

            <List
                style={{ marginTop: 16 }}
                itemLayout="horizontal"
                loading={loading}
                dataSource={data}
                renderItem={item => {
                    const selectedItem = (): boolean => {
                        return item.id === editorAnnotationId
                    }
                    return (
                        <StyledItem
                            isNewAnnotation={item.type === 'new'}
                            selected={selectedItem()}
                            onPointerDown={e => startCreateAnnotation(e, item)}>
                            <span style={{ wordBreak: 'break-all' }}>{item.name}</span>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onPointerDown={event => event.stopPropagation()}
                                    onClick={() => {
                                        handleEditAnnotation(item)
                                        setActiveAnnotation(item)
                                    }}
                                    type="link"
                                    size="small">
                                    <EditOutlined />
                                </Button>

                                <Button
                                    onPointerDown={event => event.stopPropagation()}
                                    onClick={() => {
                                        const callback = () => handleDeleteAnnotations(item)
                                        showModal('delete this annotation', callback)
                                    }}
                                    type="link"
                                    size="small">
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        </StyledItem>
                    )
                }}
            />
        </div>
    )
}

export default AnnotationsList

const StyledItem = styled(Item)<{ isNewAnnotation: boolean; selected: boolean }>`
    background: ${props => (props?.selected ? '#ede9e9' : '')};
    cursor: ${props => (props?.isNewAnnotation ? 'move' : 'default')};
    user-select: none;
`

import api from './index'

const ANALYTICS = 'analytics'

const analyticsApi = api.injectEndpoints({
    endpoints: build => ({
        getDealerAnalytics: build.mutation<DealerAnalyticsType[], DealerAnalyticsFilter>({
            query: body => {
                const params = new URLSearchParams(body)

                return `${ANALYTICS}/dealer?${params}`
            }
        }),

        getCompanyAnalytics: build.mutation<CompanyAnalyticsType, CompanyAnalyticsFilter>({
            query: body => {
                const params = new URLSearchParams(body)

                return `${ANALYTICS}/company?${params}`
            }
        })
    }),
    overrideExisting: false
})

export const { useGetDealerAnalyticsMutation, useGetCompanyAnalyticsMutation } = analyticsApi

export default analyticsApi

export type DealerAnalyticsType = {
    product_id: number
    product_name: string
    page_visit: number
    lead_generate: number
    lead_conversion: number
}

export type DealerAnalyticsFilter = {
    date_start: string
    date_end: string
}

export type CompanyAnalyticsType = {
    page_visit: number
    lead_generate: number
    lead_conversion: number
}

export type CompanyAnalyticsFilter = {
    date_start: string
    date_end: string
    country_id: string
    state_id: string
    city: string
    dealer_id: string
    product_year: string
    product_make: string
    product_model: string
    product_name: string
}

import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const BRAND = 'brand'

const brandApi = api.injectEndpoints({
    endpoints: build => ({
        getBrands: build.query<GetBrandsType, BrandFilter>({
            query: body => {
                const { page, name, industry_id } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    industry_id: industry_id ? industry_id.toString() : ''
                })

                return `${BRAND}?${params}`
            }
        }),

        getBrandList: build.mutation<GetBrandsType, BrandFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${BRAND}?${params}`
            }
        }),

        createBrand: build.mutation<null, BrandInfoType>({
            query: body => ({
                url: BRAND,
                method: 'POST',
                body
            })
        }),

        updateBrand: build.mutation<null, BrandInfoType>({
            query: body => {
                const { name, description, style_guide_id, id, industry_id } = body
                return {
                    url: BRAND + '/' + id,
                    method: 'PUT',
                    body: { name, description, industry_id, style_guide_id }
                }
            }
        }),

        deleteBrand: build.mutation<null, { id: number }>({
            query: body => ({
                url: `${BRAND}/${body.id}`,
                method: 'DELETE'
            })
        })
    }),
    overrideExisting: false
})

export type BrandType = {
    id: number
    name: string
    description: string
    industry: { id: number; name: string }
    style_guide: { id: number; name: string }
}

export type BrandInfoType = Pick<BrandType, 'id' | 'name' | 'description'> & {
    industry_id: number
    style_guide_id: number
}

export type GetBrandsType = {
    items: BrandType[]
    _meta: TableMetaType
}

export type BrandFilter = {
    page: number
    name: string
    industry_id: number | string | null
}

export const {
    useGetBrandsQuery,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useDeleteBrandMutation,
    useGetBrandListMutation
} = brandApi

export default brandApi

import { createSlice } from '@reduxjs/toolkit'
import assetApi from 'api/assetApi'
import { TAsset } from '../types'

interface AssetsSlice {
    assets: TAsset[]
    selectedRows: TAsset[]
}

const initialState = {
    assets: [],
    selectedRows: []
} as AssetsSlice

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        setSelectedRows(state, action) {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder.addMatcher(assetApi.endpoints.getAsset.matchFulfilled, (state, action) => {
            const assets = (action.payload as any).root as TAsset[]
            state.assets = assets
        })
    }
})

export const { setSelectedRows } = assetsSlice.actions

export default assetsSlice

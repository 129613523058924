import { utilsEditor } from '@amaspace-editor/editor-3d'
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import { pxToEm } from 'theme/stylesMixins'
import { TAsset } from 'types'
import AssetEditorProps, { TAssetEditorProp, TAssetEditorSection } from './assetEditorProps'

type ConfiguratorViewerProps = {
    asset: TAsset
}

export const ConfiguratorViewer = ({ asset }: ConfiguratorViewerProps) => {
    const editorUtils = utilsEditor()

    const propsList = (): TAssetEditorSection[] => {
        const animationsList = editorUtils.animationList()
        const morphTargetsList = editorUtils.morphTargetsList

        const renderAnimations = () => {
            if (!animationsList?.length) return []
            return animationsList.map(el => {
                const animation = editorUtils.getAnimation(el)

                return {
                    type: 'button',
                    label: el,
                    value: animation?.playClick ? <StopOutlined /> : <PlayCircleOutlined />,
                    key: `animation#${el}`
                } as TAssetEditorProp
            })
        }
        const renderMorphTargets = (): TAssetEditorSection['props'] => {
            if (!morphTargetsList?.length) return []
            return _.uniqBy(morphTargetsList, x => x.key).map(el => {
                const activeMorph = editorUtils.activeMorphTargets.find(x => x.key === el.key)
                return {
                    type: 'range',
                    label: el.key,
                    value: activeMorph?.options?.value || el.influence,
                    key: `morphTargets#${el.key}`,
                    misc: { max: 1, min: 0, step: 0.01 }
                }
            })
        }
        return [
            {
                name: 'Animations',
                props: [...renderAnimations()]
            },
            {
                name: 'Morph targets',
                props: [...renderMorphTargets()]
            }
        ]
    }

    const changeProps = (key: string, value: TAssetEditorProp['value']) => {
        const keyProp = key.split('#').slice(-1)[0]
        const keyValue = key.split('#')[0]

        if (keyValue === 'animation') {
            const animation = editorUtils.getAnimation(keyProp)

            if (animation?.playClick) {
                animation.stop()
                editorUtils.setActiveAnimation(null)
            } else {
                editorUtils.playAnimation(keyProp)
            }
        }
        if (keyValue === 'morphTargets') {
            editorUtils.applyMorphTargets(keyProp, { value })
        }
    }
    return (
        <StyledTreeWrapper>
            <AssetEditorProps asset={asset} sectionsList={propsList()} onPropChange={changeProps} />
        </StyledTreeWrapper>
    )
}
const StyledTreeWrapper = styled(Col)`
    height: 100%;
    padding: ${pxToEm(15)};

    overflow: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #646667;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #383838;
    }
`

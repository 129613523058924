import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import { useVerifyEmailMutation } from '../../api/userApi'
import AuthWrapper from './components/AuthWrapper'
import AuthSuccessAlert from './components/AuthSuccessAlert'
import { StyledAuthButton } from './styles'
import AuthConfirmPassword from './components/AuthConfirmPassword'
import toast from 'react-hot-toast'
import { routesUrl } from '../../router/urls'

const title = 'Complete Account'

const messages = ['You have completed your account registration.', 'You can now sign in to your account.']

const VerifyEmail = () => {
    const { token } = useParams()

    const [verifyEmail, result] = useVerifyEmailMutation()
    const { isSuccess, isLoading } = result

    return (
        <AuthWrapper title={title}>
            {isSuccess ? (
                <AuthSuccessAlert messages={messages} link={routesUrl.login} />
            ) : (
                <Form
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    onFinish={async form => {
                        const loader = toast.loading('Progressing...')
                        const { password } = form
                        const res = await verifyEmail({ email_access_token: token as string, password })
                        toast.dismiss(loader)
                        if ('error' in res) return
                    }}>
                    <AuthConfirmPassword />

                    <StyledAuthButton type="primary" htmlType="submit" loading={isLoading}>
                        Complete Account Registration
                    </StyledAuthButton>
                </Form>
            )}
        </AuthWrapper>
    )
}

export default VerifyEmail

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, List, Select, Space } from 'antd'
import { useGetAssetByIdQuery } from 'api/assetApi'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { CatalogContext, ProductType } from '../../../../providers/CatalogProvider'
import { StyledAttribute } from '../tabs/ProductAttributes'
import { MetadataType } from './ProductMetadata'
import Item = List.Item

type ProductMetadataItemProps = {
    item: MetadataType
    setProduct: Dispatch<SetStateAction<ProductType>>
}

const typeList: { id: MetadataType['type']; label: string }[] = [
    { id: 1, label: 'System defined' },
    { id: 2, label: 'User defined' }
]

const keyList: string[] = ['activeAnimation', 'activeAnimationValue', 'morphTarget', 'morphTargetValue']
let index = 0

const ProductMetadataItem = ({ item, setProduct }: ProductMetadataItemProps) => {
    const [keyValue, setKeyValue] = useState<MetadataType['keyValue']>(item.keyValue)
    const [value, setValue] = useState<MetadataType['value'][]>(item.value ? [item.value] : [])
    const [valueState, setValueState] = useState<MetadataType['valueState']>(item.valueState)
    const [assetData, setAssetData] = useState<Record<string, any> | null>(null)
    const [name, setName] = useState('')

    const { product } = useContext(CatalogContext)

    const dataRequest = useGetAssetByIdQuery({ id: product?.asset_customizer_id })

    const debouncedValue = useDebouncedCallback((value, key) => {
        const changeMetadataList = (prevState: ProductType) => {
            const newMetadata = prevState.meta_data.map(state =>
                state.id === item.id ? ({ ...state, [key]: value } as MetadataType) : state
            )

            return { ...prevState, meta_data: newMetadata }
        }

        setProduct(changeMetadataList)
    }, 500)
    const handleChangeType = (type: MetadataType['type']) => {
        const keyValue: MetadataType['keyValue'] = type === 1 ? null : ''

        const changeMetadataList = (prevState: ProductType) => {
            const newMetadata = prevState.meta_data.map(state =>
                state.id === item.id ? { ...state, type, key: keyValue } : state
            )

            return { ...prevState, meta_data: newMetadata }
        }

        setKeyValue(keyValue)
        setProduct(changeMetadataList)
    }

    const handleChangeKey = (value: MetadataType['keyValue']) => {
        setKeyValue(value)
        debouncedValue(value, 'keyValue')
    }

    const handleChangeValueState = (value: MetadataType['valueState']) => {
        setValueState(value)
        debouncedValue(value, 'valueState')
    }

    const handleChangeValue = (value: MetadataType['value']) => {
        debouncedValue(value, 'value')
    }

    const handleDeleteItem = (id: MetadataType['id']) => {
        const changeMetadataList = (prevState: ProductType) => {
            const newMetadata = prevState.meta_data.filter(item => item.id !== id)
            return { ...prevState, meta_data: newMetadata }
        }

        setProduct(changeMetadataList)
    }
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }
    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault()
        setValue([...value, name || `New item ${index++}`])
        setName('')
    }

    useEffect(() => {
        if (dataRequest.isSuccess) {
            const { data } = dataRequest
            const animations = data?.assets[0]?.props?.animations?.map((item: any) => item.name ?? item) || []
            const morphTargets = data?.assets[0]?.props?.morphTargets || []
            setAssetData({
                animations,
                morphTargets
            })
        }
    }, [dataRequest.isSuccess])

    useEffect(() => {
        if (!keyValue) return
        if (keyValue.toLowerCase().includes('animation')) {
            setValue(Array.from(new Set([item.value, ...(assetData?.animations || [])].filter(Boolean))))
            return
        }
        if (keyValue.toLowerCase().includes('morph')) {
            setValue(Array.from(new Set([item.value, ...(assetData?.morphTargets || [])].filter(Boolean))))
            return
        }
        setValue(Array.from(new Set([item.value].filter(Boolean))))
    }, [keyValue, assetData])

    return (
        <Item>
            <StyledAttribute>
                <div style={{ flexGrow: 1 }}>
                    <span>Type</span>
                    <Select
                        placeholder="Choose type"
                        value={item.type}
                        onChange={(type: MetadataType['type']) => handleChangeType(type)}>
                        {typeList.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div style={{ flexGrow: 1 }}>
                    <span>Key</span>

                    {item.type === 1 ? (
                        <Select
                            placeholder="Choose key"
                            value={keyValue as string}
                            onChange={(key: MetadataType['keyValue']) => handleChangeKey(key)}>
                            {keyList.map(item => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <Input
                            maxLength={255}
                            placeholder="Key"
                            value={keyValue as string}
                            onChange={e => handleChangeKey(e.target.value)}
                        />
                    )}
                </div>

                <div style={{ flexGrow: 1 }}>
                    <span>Value</span>

                    <Select
                        placeholder="Set value"
                        onSelect={handleChangeValue}
                        defaultValue={value[0]}
                        allowClear
                        onClear={() => handleChangeValue('')}
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder="Please enter item"
                                        value={name}
                                        onChange={onNameChange}
                                        onKeyDown={e => e.stopPropagation()}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                        Add item
                                    </Button>
                                </Space>
                            </>
                        )}
                        options={value.map(item => ({ label: item, value: item }))}
                    />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <span>Value state</span>

                    <Input
                        maxLength={255}
                        placeholder="Key"
                        value={valueState}
                        onChange={e => handleChangeValueState(e.target.value)}
                    />
                </div>
                <Button
                    style={{ alignSelf: 'flex-end', marginBottom: 4 }}
                    type="link"
                    size="small"
                    onClick={() => handleDeleteItem(item.id)}>
                    <DeleteOutlined />
                </Button>
            </StyledAttribute>
        </Item>
    )
}

export default ProductMetadataItem

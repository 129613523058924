import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from 'router/Router'
import { ThemeProvider } from 'styled-components'
import theme from './theme/mainTheme'
import { useSelector } from 'react-redux'
import { themeMode } from './slices/themeSlice'
import * as amplitude from '@amplitude/analytics-browser'

const App = () => {
    const themeValue = useSelector(themeMode)

    useEffect(() => {
        amplitude.init('bd0c384ab82731bc49e23f9dbfb2ba7d', undefined, {
            serverZone: 'EU',
            defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }
        })
    }, [])

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme[themeValue]}>
                <Router />
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App

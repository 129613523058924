import { Spin } from 'antd'
import { StyledPanelLoader } from '../../components/panel/styles'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useResetTFAQuery } from '../../api/tfaApi'

const ResetTFA = () => {
    const navigate = useNavigate()
    const { token } = useParams()

    const { isSuccess, isError } = useResetTFAQuery({ token: token! })

    useEffect(() => {
        if (isSuccess) {
            toast.success('2FA reset is successfully completed')
            navigate('/')
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            navigate('/')
        }
    }, [isError])

    return (
        <StyledPanelLoader>
            <Spin size="large" />
        </StyledPanelLoader>
    )
}

export default ResetTFA

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../redux/store'

interface IThemeState {
    mode: 'light' | 'dark'
}

const initialState: IThemeState = {
    mode: 'light'
}

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeMode: (state, action: { payload: IThemeState['mode'] }) => {
            state.mode = action.payload
        }
    }
})

export const { changeMode } = themeSlice.actions

export const themeMode = (state: RootState): IThemeState['mode'] => state.theme.mode

export default themeSlice

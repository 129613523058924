import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, DollarOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../../hooks'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import ModalWithForm from '../../users/ModalWithForm'
import ActivatedLicensePriceForm from './ActivatedLicensePriceForm'
import { useForm } from 'antd/lib/form/Form'
import {useParams, useSearchParams} from 'react-router-dom'
import ExtendedTable from '../../../components/ExtendedTable'
import {
    ActivatedLicenseFilter,
    ActivatedLicenseType,
    ActivatedLicensePriceType,
    useDeleteActivatedLicenseMutation,
    useGetActivatedLicensesQuery,
    useUpdateActivatedLicensePriceMutation
} from '../../../api/licenseApi'
import toast from 'react-hot-toast'
import {useUpdatePaymentSessionMutation} from "../../../api/paymentApi";

const ActivatedLicensesTable = ({ dealerId }: { dealerId: string }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams()
    const [formUpdatePrice] = useForm<ActivatedLicensePriceType>()
    const { role } = useAppSelector(state => state.user)
    const { showModal } = useContext(ModalConfirmContext)

    const [isModalOpenPrice, setIsModalOpenPrice] = useState(false)

    const [filter, setFilter] = useState<ActivatedLicenseFilter>({
        id: dealerId,
        page: 1
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const page = parseInt(pageParam ?? '1')

        setFilter(prevState => ({
            ...prevState,
            page: Number.isNaN(page) ? 1 : page,
        }))
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetActivatedLicensesQuery(filter)
    const [updatePrice, resultsUpdatePrice] = useUpdateActivatedLicensePriceMutation()
    const [deleteItem] = useDeleteActivatedLicenseMutation()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Date Start',
            dataIndex: 'date_start',
            key: 'date_start'
        },
        {
            title: 'Date End',
            dataIndex: 'date_end',
            key: 'date_end'
        },
        {
            title: 'Current Price',
            dataIndex: 'current',
            key: 'current',
            render: (_: void, license: ActivatedLicenseType) => (
                <span>
                    {license.current.price} {license.current.currency}
                </span>
            )
        },
        {
            title: 'Next Price',
            dataIndex: 'next',
            key: 'next',
            render: (_: void, license: ActivatedLicenseType) => (
                <span>
                    {license.next.price} {license.next.currency}
                </span>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, license: ActivatedLicenseType) => (
                <>
                    {role === 'dealer' && license.is_can_delete && (
                        <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const { id } = license
                                    const res = await deleteItem({ id })
                                    if ('error' in res) return
                                    toast.success('License is successfully deactivated')
                                    refetch()
                                }

                                showModal('deactivate this license', handleDelete)
                            }}
                            type="link"
                            size="small">
                            <DeleteOutlined />
                        </Button>
                    )}

                    {role === 'account manager' && (
                        <Button
                            onClick={() => {
                                formUpdatePrice.setFieldsValue({
                                    dealer_id: id!,
                                    dealer_license_id: license.id,
                                    amount: license.next.price ?? '',
                                    description: license.description
                                })
                                setIsModalOpenPrice(true)
                            }}
                            type="link"
                            size="small">
                            <DollarOutlined />
                        </Button>
                    )}
                </>
            )
        }
    ]

    return (
        <>
            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="license"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Update Price"
                isOpen={isModalOpenPrice}
                setIsOpen={setIsModalOpenPrice}
                isLoading={resultsUpdatePrice.isLoading}
                submit={formUpdatePrice.submit}>
                <ActivatedLicensePriceForm
                    refetch={refetch}
                    setIsModalOpen={setIsModalOpenPrice}
                    form={formUpdatePrice}
                    onFinish={updatePrice}
                />
            </ModalWithForm>
        </>
    )
}

export default ActivatedLicensesTable

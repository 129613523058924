import { Checkbox, Modal } from 'antd'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { StyledAuthAgreement } from '../styles'

type RegistrationAgreementType = {
    isAgreement: boolean
    setIsAgreement: Dispatch<SetStateAction<boolean>>
}

const RegistrationAgreement = ({ isAgreement, setIsAgreement }: RegistrationAgreementType) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setIsModalOpen(true)
    }

    return (
        <>
            <StyledAuthAgreement disabled={!isAgreement} checked={isAgreement} onChange={() => setIsAgreement(false)}>
                I accept and agree to the terms of the <a onClick={handleOpenModal}>Master Service Agreement</a>
            </StyledAuthAgreement>

            <Modal title="Master Agreement" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, consequuntur ex facere labore
                    magni non odio quas saepe. Accusantium consequuntur excepturi molestias officia quaerat unde.
                    Dolorum eligendi maxime minima obcaecati.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, consequuntur ex facere labore
                    magni non odio quas saepe. Accusantium consequuntur excepturi molestias officia quaerat unde.
                    Dolorum eligendi maxime minima obcaecati.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, consequuntur ex facere labore
                    magni non odio quas saepe. Accusantium consequuntur excepturi molestias officia quaerat unde.
                    Dolorum eligendi maxime minima obcaecati.
                </p>

                <Checkbox
                    checked={isAgreement}
                    onChange={e => {
                        setIsAgreement(e.target.checked)
                        setIsModalOpen(false)
                    }}>
                    I accept and agree to the terms of the Master Service Agreement
                </Checkbox>
            </Modal>
        </>
    )
}

export default RegistrationAgreement

import PageBuilder from '../../../components/panel/PageBuilder'
import ProductTabs from '../components/tabs/ProductTabs'
import CatalogDealerProvider from '../../../providers/CatalogDealerProvider'
import {useParams} from "react-router-dom";
import {routesUrl} from "../../../router/urls";

const DealerProductEdit = () => {
    const {id} = useParams()
    return (
        <PageBuilder breadcrumbs={['catalog', {id: `${routesUrl.dealerCatalogView.substring(1)}/${id!}`, name: 'view'}, `Edit`]} title="Edit Product" documentTitle="Edit Product">
            <CatalogDealerProvider>
                <ProductTabs type="dealer" isCreating={false} />
            </CatalogDealerProvider>
        </PageBuilder>
    )
}

export default DealerProductEdit

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from 'api/url'
import { RootState } from 'redux/store'
import Utils from 'utils'
import { ApiTags } from '../types'

const baseFetch = fetchBaseQuery({
    baseUrl,

    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState

        const token = state.auth.accessToken

        headers.set('Authorization', `Bearer ${token}`)

        return headers
    }
})

const api = createApi({
    reducerPath: 'api',
    keepUnusedDataFor: 0,

    tagTypes: Object.values(ApiTags),

    baseQuery: async (args, api, extraOptions: any) => {
        const result = await baseFetch(args, api, extraOptions)

        if (!extraOptions?.ignoreErrors) Utils.getErrorMessage(result)

        if (result?.error?.status === 401) {
            localStorage.clear()
            window.location.reload()
            console.log('Unauthorized')
        }

        return result
    },

    endpoints: () => ({})
})

export default api

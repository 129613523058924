import { Form, FormInstance, Input, Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { AdminInfoType } from '../../../api/adminApi'
import { CompanyType, useGetCompaniesListMutation } from '../../../api/companyApi'
import { useFetchByPagination } from '../../../hooks'

type AdminFormProps = {
    form: FormInstance<AdminInfoType>
    onFinish: (e: AdminInfoType) => Promise<any>
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    refetch: () => void
}

const AdminForm = ({ form, refetch, setIsModalOpen, onFinish }: AdminFormProps) => {
    const [companies] = useFetchByPagination<CompanyType>(useGetCompaniesListMutation)

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="First Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="First Name" />
            </Form.Item>

            <Form.Item label="Last Name" name="lastname" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Last Name" />
            </Form.Item>

            <Form.Item label="Company" name="company_id" rules={[{ required: true }]}>
                <Select showSearch allowClear optionFilterProp="children" placeholder="Company">
                    {companies.map(company => {
                        return (
                            <Select.Option key={company.id} value={company.id}>
                                {company.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                <Input
                    maxLength={40}
                    type="email"
                    placeholder="Email"
                />
            </Form.Item>
        </Form>
    )
}

export default AdminForm

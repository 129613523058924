import React, { ChangeEvent } from 'react'
import { Form, Input, Select } from 'antd'
import { AssetAttributeType } from '../../../../../api/assetApi'

type LogicAttributeStringProps = {
    attribute: AssetAttributeType
    changeName: (e: ChangeEvent<HTMLInputElement>) => void
    changeValue: (e: string[]) => void
}

const LogicAttributeString = ({ attribute, changeValue, changeName }: LogicAttributeStringProps) => {
    return (
        <div style={{ marginBottom: 16 }}>
            <Form.Item>
                <Input maxLength={50} value={attribute.name} onChange={changeName} placeholder="Name" />
            </Form.Item>

            <Form.Item>
                <Select
                    mode="tags"
                    maxTagCount="responsive"
                    placeholder="Values"
                    showSearch
                    allowClear
                    onChange={changeValue}
                    optionFilterProp="children"></Select>
            </Form.Item>
        </div>
    )
}

export default LogicAttributeString

import { AMAMeshType, AMAObject, ModelConfig, utilsEditor } from '@amaspace-editor/editor-3d'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select } from 'antd'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { AssetAnnotationType, AssetRuleConditionType, AssetRuleType } from '../../../../../api/assetApi'
import { TAsset } from '../../../../../types'
import { LogicEditorTypeId } from '../LogicEditor'

const { Option } = Select

type RulesConditionItemProps = {
    item: AssetRuleConditionType
    setRule: Dispatch<SetStateAction<AssetRuleType>>
    deleteCondition: (item: AssetRuleConditionType) => void
    annotations: AssetAnnotationType[]
    modelPart: TAsset[]
}
type SelectedLogicType = {
    value: number | null
    disabled: boolean
}

type SelectedValueOneType = number | null

type SelectedValueTwoType = {
    value: number | string | null
    options: { value: string | number; label: string }[]
    disabled: boolean
}

const RulesConditionItem = ({ annotations, item, setRule, deleteCondition, modelPart }: RulesConditionItemProps) => {
    const editor3D = utilsEditor()

    const logicalOperatorOptions = [
        { label: '=', value: LogicEditorTypeId['Equal'] },
        { label: '!=', value: LogicEditorTypeId['NotEqual'] },
        { label: '>=', value: LogicEditorTypeId['GreaterOrEqual'] },
        { label: '<=', value: LogicEditorTypeId['LessOrEqual'] },
        { label: '>', value: LogicEditorTypeId['GreaterThen'] },
        { label: '<', value: LogicEditorTypeId['LessThen'] }
    ]
    const eventsOptions = [
        { label: 'On Click Annotation', value: LogicEditorTypeId['OnClickAnnotation'] },
        { label: 'On Click Model Part', value: LogicEditorTypeId['OnClickModelPart'] },
        { label: 'On Page Load', value: LogicEditorTypeId['OnPageLoad'] },
        { label: 'OnHoverModelPart', value: LogicEditorTypeId['OnHoverModelPart'] }
    ]
    const pageLoadOptions = [{ value: 'load', label: 'load' }]

    const [selectedValueOne, setSelectedValueOne] = useState<SelectedValueOneType>(null)
    const [selectedLogic, setSelectedLogic] = useState<SelectedLogicType>({
        value: LogicEditorTypeId['Equal'],
        disabled: false
    })
    const [selectedValueTwo, setSelectedValueTwo] = useState<SelectedValueTwoType>({
        value: null,
        options: [],
        disabled: true
    })
    const modelsParts = useMemo(() => {
        const result: AMAObject[] = []
        const partsCheck = (config: ModelConfig) => {
            if (!config?.objects.length) return
            config.objects.forEach(obj => {
                if (obj.type !== AMAMeshType.Bone) result.push(obj)
            })
        }
        partsCheck(editor3D.config)
        editor3D.uploadedModelsConfig.forEach(partsCheck)

        return result
    }, [editor3D.config, editor3D.uploadedModelsConfig])

    const modelPartOptions = useMemo(() => {
        return modelsParts.map(part => ({
            value: part.dbId as number,
            label: part.name ? part.name : `${part.type} ${part.id}`
        }))
    }, [modelsParts])

    const annotationsOptions = useMemo(() => {
        return annotations.map(annotation => ({ value: annotation.id, label: annotation.name }))
    }, [annotations])

    const handleChangeRule = (cb: (condition: AssetRuleConditionType) => AssetRuleConditionType) => {
        setRule(prevState => {
            const state = prevState.condition.map(condition => {
                if (condition.appId) {
                    if (condition.appId === item.appId) {
                        return cb(condition)
                    }
                }

                if (condition.id) {
                    if (condition.id === item.id) {
                        return cb(condition)
                    }
                }

                return condition
            })

            return { ...prevState, condition: state }
        })
    }

    const handleChangeValueOne = (value: number) => {
        setSelectedValueOne(value)

        if (value === LogicEditorTypeId['OnPageLoad']) {
            const state = { value: 'load', options: pageLoadOptions, disabled: true }
            setSelectedValueTwo(state)

            handleChangeRule(condition => ({
                ...condition,
                value: 'load',
                type_condition: value,
                connection_id: 0,
                connection_type: 0
            }))
        }

        if (value === LogicEditorTypeId['OnClickAnnotation']) {
            const state = { value: null, options: annotationsOptions, disabled: false }
            setSelectedValueTwo(state)

            handleChangeRule(condition => ({
                ...condition,
                value: '',
                type_condition: value,
                connection_id: 0,
                connection_type: LogicEditorTypeId['Annotation']
            }))
        }

        if (value === LogicEditorTypeId['OnClickModelPart']) {
            const state = { value: null, options: modelPartOptions, disabled: false }
            setSelectedValueTwo(state)

            handleChangeRule(condition => ({
                ...condition,
                value: '',
                type_condition: value,
                connection_id: 0,
                connection_type: LogicEditorTypeId['CustomizerId']
            }))
        }
        if (value === LogicEditorTypeId['OnHoverModelPart']) {
            const state = { value: null, options: modelPartOptions, disabled: false }
            setSelectedValueTwo(state)

            handleChangeRule(condition => ({
                ...condition,
                value: '',
                type_condition: value,
                connection_id: 0,
                connection_type: LogicEditorTypeId['CustomizerId']
            }))
        }
    }

    const handleChangeValueTwo = (value: number | string) => {
        setSelectedValueTwo(prevState => ({ ...prevState, value }))

        if (selectedValueOne === LogicEditorTypeId['OnClickAnnotation']) {
            handleChangeRule(condition => ({ ...condition, connection_id: value as number }))
        }

        if (selectedValueOne === LogicEditorTypeId['OnClickModelPart']) {
            handleChangeRule(condition => ({ ...condition, connection_id: value as number }))
        }
        if (selectedValueOne === LogicEditorTypeId['OnHoverModelPart']) {
            handleChangeRule(condition => ({ ...condition, connection_id: value as number }))
        }
    }

    useEffect(() => {
        const isAnnotationClickStatus = item.type_condition === LogicEditorTypeId['OnClickAnnotation']
        const isModelPartClickStatus = item.type_condition === LogicEditorTypeId['OnClickModelPart']
        const isPageLoadStatus = item.type_condition === LogicEditorTypeId['OnPageLoad']
        const isModelPartHoverStatus = item.type_condition === LogicEditorTypeId['OnHoverModelPart']

        if (isAnnotationClickStatus || isModelPartClickStatus || isPageLoadStatus) {
            setSelectedValueOne(item.type_condition)
        }

        if (isAnnotationClickStatus) {
            setSelectedValueTwo({ value: item.connection_id, options: annotationsOptions, disabled: false })
        }

        if (isModelPartClickStatus) {
            setSelectedValueTwo({ value: item.connection_id, options: modelPartOptions, disabled: false })
        }
        if (isModelPartHoverStatus) {
            setSelectedValueTwo({ value: item.connection_id, options: modelPartOptions, disabled: false })
        }
        if (isPageLoadStatus) {
            setSelectedValueTwo({ value: item.value, options: pageLoadOptions, disabled: true })
        }
    }, [])

    return (
        <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
            <Col span={8}>
                <Select
                    showSearch
                    optionFilterProp="children"
                    value={selectedValueOne === 0 ? null : selectedValueOne}
                    onChange={handleChangeValueOne}>
                    {eventsOptions.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                            {opt.label}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col span={5}>
                <Select value={selectedLogic.value} disabled={selectedLogic.disabled}>
                    {logicalOperatorOptions.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                            {opt.label}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col span={8}>
                <Select
                    value={selectedValueTwo.value === 0 ? null : selectedValueTwo.value}
                    disabled={selectedValueTwo.disabled}
                    onChange={handleChangeValueTwo}
                    options={selectedValueTwo.options}
                />
            </Col>
            <Col span={3}>
                <Button size="small" type="link" onClick={() => deleteCondition(item)} icon={<DeleteOutlined />} />
            </Col>
        </Row>
    )
}

export default RulesConditionItem

import { Button, Form, Upload } from 'antd'
import styled from 'styled-components'
import Utils from '../../../../utils'
import { RcFile } from 'antd/es/upload'
import { UploadOutlined, CloseOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'

const ProductImageItem = () => {
    const { product, setProduct } = useContext(CatalogContext)

    const [image, setImage] = useState<string>(product.image_url || '')

    const props = {
        showUploadList: { showRemoveIcon: false },
        accept: '.png,.svg,.jpeg,.webp'
    }

    const beforeUpload = (file: RcFile) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = async () => {
            if (!reader.result || !(typeof reader.result === 'string')) return

            const data = await Utils.createAssetsFromFile(file)

            setImage(reader.result)

            setProduct({ ...product, image_url: data[0]?.url || '' })
        }

        return false
    }
    const removePreview = () => {
        setImage('')
        setProduct({ ...product, image_url: '' })
    }
    useEffect(() => {
        if (product.image_url) {
            setImage(product.image_url)
        }
    }, [product.image_url])

    return (
        <Form.Item label="Image">
            <Upload {...props} beforeUpload={beforeUpload}>
                <Button style={{ width: '100%' }} icon={<UploadOutlined />}>
                    Select Image
                </Button>
            </Upload>
            {image && (
                <StyledPreview preview={image}>
                    <StyledClose onClick={removePreview} />
                </StyledPreview>
            )}
        </Form.Item>
    )
}

export default ProductImageItem

const StyledClose = styled(CloseOutlined)`
    position: absolute;
    right: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white);

    padding: 0.3rem;
    cursor: pointer;

    z-index: -1;
    opacity: 0;
`

const StyledPreview = styled.div<{ preview: string }>`
    position: relative;
    width: 100px;
    height: 100px;
    background: ${props => `center / contain no-repeat url('${props?.preview}')`};
    transition: all 0.5s;
    display: flex;
    &:hover {
        backdrop-filter: blur(0.5);
        ${StyledClose} {
            z-index: 1;
            opacity: 1;
        }
    }
`

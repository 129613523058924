// @ts-ignore
import { Editor2DComponent as Editor2D } from '@amaspace-editor/editor-2d/dist/editor-2d.esm'
import { AMAOption, CanvasTextureAMA, isMeshAMA } from '@amaspace-editor/editor-3d'
import { CopyOutlined, DeleteOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { ProductAttributeType } from 'api/shareApi'
import { fabric } from 'fabric'
import AssetEditorTree from 'pages/assets/editor/assetEditorTree'
import { useEffect, useMemo, useState } from 'react'
import ColorPicker from 'react-best-gradient-color-picker'
import * as THREE from 'three'

type TOptionsGroup = {
    attribute: ProductAttributeType
    options: AMAOption[]
}

type OptionImageProps = {
    optionsGroup: TOptionsGroup
}
const CANVAS_WIDTH = 305
const CANVAS_HEIGHT = 305

export const OptionImage = ({ optionsGroup }: OptionImageProps) => {
    const [editorAPI, setEditorAPI] = useState<any>()
    const [tree, setTree] = useState([])
    const [selectedUid, setSelectedUid] = useState(null)

    const applyChanges = (isImage?: fabric.Object, color?: string) => {
        window.three?.scene.traverse(item => {
            if (isMeshAMA(item)) {
                if (item.material.userData.id === optionsGroup.options[0].customizer_id) {
                    var invertedCanvas = getCanvasWithoutActiveObjectInverted(
                        editorAPI.application.canvas,
                        isImage,
                        3,
                        color
                    )
                    const d = new CanvasTextureAMA(item, invertedCanvas)

                    d.update()
                }
            }
        })
    }

    const mapTree = (objects: any[]) => {
        return objects.map((object, index) => {
            return {
                uid: object.uid,
                title: (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span
                            style={{
                                width: '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                marginRight: '15px'
                            }}>
                            {object?._NAME || object.uid}
                        </span>
                        <span style={{ display: 'flex', gap: '5px' }}>
                            <EyeInvisibleOutlined onClick={e => updateVisible(object.uid, e)} />
                            <CopyOutlined onClick={e => dublicateObj(object.uid, e)} />
                            <DeleteOutlined onClick={e => removeObj(object.uid, e)} />
                        </span>
                    </span>
                ),
                key: `${index}-0`
            }
        })
    }

    function getCanvasWithoutActiveObjectInverted(
        originalCanvas: fabric.Canvas,
        addImage?: fabric.Object,
        scaleFactor = 3,
        color?: string
    ) {
        var clonedCanvas = document.createElement('canvas')
        clonedCanvas.width = originalCanvas.width! * scaleFactor
        clonedCanvas.height = originalCanvas.height! * scaleFactor
        var clonedContext = clonedCanvas.getContext('2d')!

        var tempCanvas = new fabric.StaticCanvas(null, {
            width: clonedCanvas.width,
            height: clonedCanvas.height,
            backgroundColor: color || originalCanvas.backgroundColor
        })

        const objectsToClone = originalCanvas.getObjects()

        if (objectsToClone.length === 0) {
            if (color) {
                clonedContext.fillStyle = color
                clonedContext.fillRect(0, 0, clonedCanvas.width, clonedCanvas.height)
            }

            return clonedCanvas
        }

        let cloneCount = 0

        objectsToClone.forEach(obj => {
            const clone = fabric.util.object.clone(obj)
            clone.scaleX *= scaleFactor
            clone.scaleY *= scaleFactor
            clone.left *= scaleFactor
            clone.top *= scaleFactor
            if (obj === addImage) tempCanvas.centerObject(clone)
            tempCanvas.add(clone)
            cloneCount++
            if (cloneCount === objectsToClone.length) {
                tempCanvas.renderAll()

                clonedContext?.translate(0, clonedCanvas.height)
                clonedContext?.scale(1, -1)
                clonedContext?.drawImage(tempCanvas.getElement(), 0, 0)
            }
        })

        return clonedCanvas
    }
    useEffect(() => {
        if (editorAPI) {
            editorAPI.objects.on.selected((data: any) => {
                setSelectedUid(data[0].uid)
            })
            editorAPI.objects.on.deselected(() => {
                setSelectedUid(null)
            })

            editorAPI.objects.on.treeUpdated((data: any) => {
                setTree(mapTree(data) as any)
            })

            editorAPI.system.onLoad(() => {
                setTree(mapTree(editorAPI.application.requestTreeConfig()) as any)
            })

            editorAPI.application.canvas.off('mouse:wheel')
            editorAPI.application.canvas.on('object:added', (e: any) => applyChanges(e.target))
            editorAPI.application.canvas.on('object:removed', applyChanges)
            editorAPI.application.canvas.on('mouse:up', applyChanges)
            editorAPI.application.canvas.setHeight(CANVAS_HEIGHT)
            editorAPI.application.canvas.setWidth(CANVAS_WIDTH)
        }
    }, [editorAPI])

    const selectFile = () => {
        editorAPI.objects.preset.image()
    }
    const selectText = () => {
        editorAPI.objects.preset.text()
    }

    const processTreeSelection = (selectedKeys: React.Key[], info: any) => {
        console.log('selectedKeys', selectedKeys)

        // if (!selectedKeys.length) {
        //     // TODO: deselect API call
        //     editorAPI.application.canvas.discardActiveObject()
        //     editorAPI.application.renderFrame()
        //     return
        // }

        if (info?.node?.uid) {
            editorAPI.objects.select(info.node.uid)
        }
    }
    const updateVisible = (uid: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation()
        const objects = editorAPI.application.canvas.getObjects() as (fabric.Object & { uid: string })[]
        const find = objects.find(i => i.uid === uid)
        if (find) {
            find.visible = !find.visible
            editorAPI.application.renderFrame()
            applyChanges()
        }
    }
    const dublicateObj = (uid: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation()
        const objects = editorAPI.application.canvas.getObjects() as (fabric.Object & { uid: string })[]
        const find = objects.find(i => i.uid === uid)

        var object = fabric.util.object.clone(find)
        object.set('top', object.top + 5)
        object.set('left', object.left + 5)
        object.uid = THREE.MathUtils.generateUUID()

        editorAPI.application.canvas.add(object)
        editorAPI.application.renderFrame()
        editorAPI.application.eventsObserver.fireEvent('TreeUpdated', editorAPI.application.requestTreeConfig())
    }
    const removeObj = (uid: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation()
        editorAPI.objects.remove(uid)
    }
    const onDragEndCb = (info: any) => {
        const objects = editorAPI.application.canvas.getObjects() as (fabric.Object & { uid: string })[]
        const current = objects.find(i => i.uid === info?.node?.uid)
        const index = info.node.pos.split('-')[1]

        current?.moveTo(index)
        editorAPI.application.canvas.discardActiveObject()
        editorAPI.application.renderFrame()
        applyChanges()
    }
    const selected = useMemo(() => {
        const m = tree
            .filter((element: any) => {
                return element.uid === selectedUid
            })
            .map((element: any) => {
                return element.key
            })

        return !selectedUid ? [] : m
    }, [tree, selectedUid])

    return (
        <div>
            <div
                style={{
                    width: `${CANVAS_WIDTH}px`,
                    height: `${CANVAS_HEIGHT}px`,
                    background: 'rgb(248, 248, 248)',
                    border: '1px solid rgba(0,0,0,0.2)'
                }}>
                <Editor2D
                    config={{
                        loadType: '',
                        data: '',
                        mode: 'EDIT_MODE',
                        applyViewBoundsToCanvas: false
                    }}
                    setEditorAPI={(API: any) => {
                        console.log('setEditorApi', API)
                        setEditorAPI(API)
                    }}
                />
            </div>
            <Tabs animated={true} defaultActiveKey={'library'} destroyInactiveTabPane>
                <TabPane key={'library'} tab={<div style={{ width: '130px', textAlign: 'center' }}>library</div>}>
                    <div>
                        <Button onClick={selectFile}>selectFile</Button>
                        <Button onClick={selectText}>selectText</Button>
                        <Button onClick={selectText}>selectText</Button>
                        <ColorPicker
                            hideOpacity
                            hideGradientAngle
                            hidePresets
                            hideEyeDrop
                            hideGradientType
                            hideAdvancedSliders
                            hideColorGuide
                            hideInputType
                            hideColorTypeBtns
                            onChange={value => {
                                editorAPI.canvas.update.backgroundColor(value)
                                applyChanges(undefined, value)
                            }}
                        />
                    </div>
                </TabPane>
                <TabPane key={'layers'} tab={<div style={{ width: '130px', textAlign: 'center' }}>layers</div>}>
                    <AssetEditorTree
                        // key={selected.length}
                        title={''}
                        draggable={true}
                        nodes={tree}
                        onSelect={processTreeSelection}
                        onDragEndCb={onDragEndCb}
                        selectedKeys={selected}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const DEALER_DOMAIN = 'dealer-domain'

const dealerDomainApi = api.injectEndpoints({
    endpoints: build => ({
        getDealerDomains: build.query<GetDealerDomainsType, DealerDomainFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${DEALER_DOMAIN}?${params}`
            }
        }),

        createDealerDomain: build.mutation<null, DealerDomainInfoType>({
            query: body => ({
                url: DEALER_DOMAIN,
                method: 'POST',
                body: { domain: body.name }
            })
        }),

        updateDealerDomain: build.mutation<null, DealerDomainInfoType>({
            query: body => ({
                url: DEALER_DOMAIN,
                method: 'PUT',
                body
            })
        }),

        deleteDealerDomain: build.mutation<null, Pick<DealerDomainType, 'id'>>({
            query: body => ({
                url: DEALER_DOMAIN,
                method: 'DELETE',
                body: { domain_id: body.id }
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetDealerDomainsQuery,
    useDeleteDealerDomainMutation,
    useCreateDealerDomainMutation,
    useUpdateDealerDomainMutation
} = dealerDomainApi

export default dealerDomainApi

export type DealerDomainType = {
    id: number
    name: string
}

export type DealerDomainInfoType = DealerDomainType

type GetDealerDomainsType = {
    domain: DealerDomainType[]
    _meta: TableMetaType
}

export type DealerDomainFilter = { page: number }

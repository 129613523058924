import { AMAMode, EditorConstructor } from '@amaspace-editor/editor-3d'
import { Card, Space } from 'antd'
import assetApi from 'api/assetApi'
import { useGetShareCompanyProductMutation } from 'api/companyCatalogApi'
import { useAppDispatch } from 'hooks'
import React from 'react'
import styled from 'styled-components'
import { CatalogPreviewItemType, useApplyRulesAdminMutation } from '../../../api/shareApi'
import ProductAttributesSelect from '../../../components/ProductAttributesSelect/ProductAttributesSelect'
import { Asset3DType } from '../components/share/ShareProductCode'

const Render3D = React.memo(
    ({ id, product, type }: { id: string | null; product: CatalogPreviewItemType; type: Asset3DType }): JSX.Element => {
        const [getProduct] = useGetShareCompanyProductMutation()
        const [applyRules] = useApplyRulesAdminMutation()

        const dispatch = useAppDispatch()

        const getProductCb = async (id: string | number) => {
            if (typeof id === 'number') id = id.toString()
            const res = await getProduct({ id })
            if ('error' in res) {
                console.error(res.error)
                const { data } = await dispatch(assetApi.endpoints.getAssetById.initiate({ id: id }))
                return structuredClone(data)
            }
            return structuredClone(res.data)
        }
        const applyRulesCb = async (body: any) => {
            body.product_id = product.id
            const res = await applyRules(body)
            if ('error' in res) {
                return null
            }
            return res.data
        }

        if (product.id) {
            return (
                <EditorConstructor
                    type={type}
                    assetId={product.id}
                    customRequests={{ fetchDataCb: getProductCb, applyRulesCb }}
                    presetConfig={{
                        applyProductRules: true,
                        grid: false,
                        startAnimation: false,
                        mode: AMAMode.VIEWER,
                        customDomain: 'localhost',
                        isProd: true
                    }}
                />
            )
        }
        return <></>
    }
)

type CompanyPreviewProps = {
    product: CatalogPreviewItemType
}

const CompanyPreview = ({ product }: CompanyPreviewProps) => {
    return (
        <Card style={{ marginBottom: 8 }} title="Preview">
            <StyledViewerWrapper block>
                <StyledEditorViewer>
                    <React.Suspense fallback={null}>
                        <Render3D
                            id={product.asset_customizer_id}
                            product={product}
                            type={product.asset_type as Asset3DType}
                        />
                    </React.Suspense>
                </StyledEditorViewer>
                <StyledEditorAttributes>
                    <ProductAttributesSelect product={product} />
                </StyledEditorAttributes>
            </StyledViewerWrapper>
        </Card>
    )
}

export default CompanyPreview

const StyledViewerWrapper = styled(Space.Compact)`
    display: flex;
    gap: 10px;
    height: calc(100vh - 500px);
`
const StyledEditorViewer = styled.div`
    position: relative;
    width: 60vw;
`
const StyledEditorAttributes = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 320px;

    height: 100%;
`

import React, { ChangeEvent } from 'react'
import { Form, Input, Select } from 'antd'
import { AssetAttributeType } from '../../../../../api/assetApi'

const { Option } = Select

type LogicAttributeBooleanProps = {
    attribute: AssetAttributeType
    changeName: (e: ChangeEvent<HTMLInputElement>) => void
    changeValue: (e: string) => void
}

const LogicAttributeBoolean = ({ attribute, changeValue, changeName }: LogicAttributeBooleanProps) => {
    const options = [
        {
            label: 'False',
            key: '0'
        },
        {
            label: 'True',
            key: '1'
        }
    ]

    return (
        <div style={{ marginBottom: 16 }}>
            <Form.Item>
                <Input maxLength={50} value={attribute.name} onChange={e => changeName(e)} placeholder="Name" />
            </Form.Item>

            <Form.Item>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp="children"
                    defaultValue={attribute.value ? (attribute.value as string) : null}
                    onChange={value => {
                        changeValue(value)
                    }}
                    placeholder="Select Default Value">
                    {options.map(option => (
                        <Option value={option.key} key={option.key}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    )
}

export default LogicAttributeBoolean

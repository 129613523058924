import {Input} from 'antd'
import {ProductAttributeType} from '../../tabs/ProductAttributes'
import {useContext, useState} from 'react'
import {CatalogContext} from '../../../../../providers/CatalogProvider'
import {useDebouncedCallback} from "use-debounce";

type ProductAttributeNameItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeNameItem = ({ attr }: ProductAttributeNameItemProps) => {
    const { attributes, setAttributes } = useContext(CatalogContext)
    const [value, setValue] = useState(attr.name || '');

    const debounced = useDebouncedCallback(
        (name) => setAttributes(
            attributes.map(item => ({
                ...item,
                name: item.id === attr.id ? name : item.name,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        ),
        500
    );

    const handleChangeName: HandleChangeAttributeName = name => {
        setValue(name)
        debounced(name)
    }

    return (
        <Input maxLength={100} placeholder="Name" value={value} onChange={e => handleChangeName(e.target.value)} />
    )
}

export default ProductAttributeNameItem

type HandleChangeAttributeName = (value: string) => void

import React from 'react'

const Context = React.createContext<Value | null>(null)

interface Props {
    children: React.ReactElement
    value: Value
}

interface Value {
    moveAssets: (id: string) => void
}

function AssetsListProvider(props: Props) {
    return <Context.Provider value={props.value}>{props.children}</Context.Provider>
}

function useAssetsListContext(): Value {
    const value = React.useContext(Context)

    if (value === null) {
        throw new Error('useAssetsListContext should only be used inside of AssetsListProvider')
    }

    return value
}

export { AssetsListProvider, useAssetsListContext }

import { Tabs } from 'antd'
import {useContext, useState} from 'react'
import CompanyProductInfo from '../../company/CompanyProductInfo'
import ProductAttributes from './ProductAttributes'
import ProductCategories from './ProductCategories'
import ProductRules from './ProductRules'
import DealerProductInfo from '../../dealer/DealerProductInfo'
import CompanyPreview from "../../company/CompanyPreview";
import {CatalogContext} from "../../../../providers/CatalogProvider";
import {CatalogPreviewItemType} from "../../../../api/shareApi";

type ProductTabsProps = {
    isCreating: boolean
    type: 'dealer' | 'company'
}

const ProductTabs = ({ isCreating, type }: ProductTabsProps) => {
    const [activeTab, setActiveTab] = useState<ProductTabsType>('info')
    const { product } = useContext(CatalogContext)

    const tabs: Tab[] = [
        {
            key: 'info',
            label: 'Info',
            children: type === 'company' ? <CompanyProductInfo /> : <DealerProductInfo />
        },
        {
            key: 'attributes',
            label: 'Attributes',
            disabled: isCreating,
            children: <ProductAttributes isHiddenAssetAttributes={type === 'dealer'} />
        },
        {
            key: 'categories',
            label: 'Categories',
            disabled: isCreating,
            children: <ProductCategories />
        },
        {
            key: 'rules',
            label: 'Rules',
            disabled: isCreating,
            children: <ProductRules />
        },
        {
            key: 'preview',
            label: 'Preview',
            disabled: type === 'dealer',
            children: <CompanyPreview product={product as unknown as CatalogPreviewItemType} />
        }
    ]

    return <Tabs defaultActiveKey={activeTab} onChange={key => setActiveTab(key as ProductTabsType)} items={tabs} />
}

export default ProductTabs

type Tab = { key: string; label: string; children: JSX.Element; disabled?: boolean }
type ProductTabsType = 'info' | 'attributes' | 'rules' | 'categories' | 'preview'

import api from './index'

const COUNTRY = 'country'

const countryApi = api.injectEndpoints({
    endpoints: build => ({
        getCountry: build.query<CountriesList, void>({
            query: () => COUNTRY
        }),

        getStatesByCountry: build.mutation<StatesList, { id: number }>({
            query: body => `${COUNTRY}/${body.id}/states`
        })
    }),
    overrideExisting: false
})

export const { useGetCountryQuery, useGetStatesByCountryMutation } = countryApi

export default countryApi

type CountriesList = { id: number; name: string }[]
type StatesList = { id: number; name: string }[]

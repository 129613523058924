import React from 'react'
import AssetEditor2D from './assetEditor2D'
import { TAsset } from '../../../types'
import { ASSET_TYPE } from '../../../api/assetApi'
import AssetConstructor3D from './editor3D/assetConstructor3D'
import { AssetsForAttributes } from './assetEditor'

type AssetLayoutProps = {
    asset: TAsset
    allAssets: AssetsForAttributes
}

const AssetLayout = ({ asset, allAssets }: AssetLayoutProps) => {
    const is2D = asset?.type === ASSET_TYPE.Texture
    const is3D =
        asset?.type === ASSET_TYPE.Scene || asset?.type === ASSET_TYPE.Model || asset?.type === ASSET_TYPE.Material

    return (
        <>
            {is2D ? <AssetEditor2D leftMenu="nodes" rightMenu="properties" asset={asset} /> : null}
            {is3D ? <AssetConstructor3D asset={asset} allAssets={allAssets} /> : null}
        </>
    )
}

export default AssetLayout

import {
    AMAPPEffectsTypes,
    AMAPPUpdate,
    BlendFunction,
    BLOOM_EFFECT,
    BRIGHTNESS_CONTRAST_EFFECT,
    CHROMATIC_ABERRATION_EFFECT,
    DOF_EFFECT,
    HUE_SATURATION_EFFECT,
    NOISE_EFFECT,
    POST_PROCESSING_NAME,
    SMAA_EFFECT,
    SSAO_EFFECT,
    TONE_MAPPING_EFFECT,
    useBoundStore,
    utilsEditor
} from '@amaspace-editor/editor-3d'

import { Button, Col, InputNumber, Row, Select, Slider, Switch } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

interface IPIProps {
    data: AMAPPUpdate<AMAPPEffectsTypes>
}

const BLEND_FUNCTIONS_LIST = Object.keys(BlendFunction).map(i => {
    const key = i as keyof typeof BlendFunction
    return {
        label: key,
        value: BlendFunction[key],
        key: BlendFunction[key]
    }
})

const EFFECT_DEFAULTS = [
    NOISE_EFFECT,
    BLOOM_EFFECT,
    DOF_EFFECT,
    CHROMATIC_ABERRATION_EFFECT,
    BRIGHTNESS_CONTRAST_EFFECT,
    SMAA_EFFECT,
    SSAO_EFFECT,
    TONE_MAPPING_EFFECT,
    HUE_SATURATION_EFFECT,
    POST_PROCESSING_NAME
]

const getType = (name: string, element: any) => {
    if (typeof element.min !== 'undefined' && typeof element.max !== 'undefined') {
        return 'range'
    }

    if (name === 'blendFunction') {
        return 'blendFunction'
    }

    if (typeof element.value === 'boolean') {
        return 'switch'
    }

    if (name === 'kernelSize') {
        return 'range'
    }

    if (name === 'resolution') {
        return 'resolution'
    }

    if (typeof element.value.x !== 'undefined') {
        return 'vector'
    }

    if (typeof element.value === 'number') {
        return 'number'
    }

    return ''
}

const PostProcessingItem: React.FC<IPIProps> = ({ data }) => {
    const options = useMemo(() => Object.keys(data.effect).filter(i => i !== 'name'), [data.effect])
    const { updatePostProcessingParams } = utilsEditor()
    const state = useBoundStore()

    const enabled = data.enabled
    const enabledOnMobile = !!(data as any).enabledOnMobile

    const handleChange = (prop: string, value: any) => {
        if (prop === 'enabled') {
            state.setPostProcessingList(
                state.postProcessingList.map(element => {
                    if (element.name === data.name) {
                        return {
                            ...element,
                            enabled: value
                        }
                    }

                    // TODO FIX THAT HARDCODED TOGGLE
                    if (data.name === 'chromatic_aberration' && element.name === 'smaa') {
                        return {
                            ...element,
                            enabled: false
                        }
                    }
                    if (data.name === 'smaa' && element.name === 'chromatic_aberration') {
                        return {
                            ...element,
                            enabled: false
                        }
                    }

                    return element
                })
            )
        } else if (prop === 'enabledOnMobile') {
            state.setPostProcessingList(
                state.postProcessingList.map(element => {
                    if (element.name === data.name) {
                        return {
                            ...element,
                            enabledOnMobile: value
                        }
                    }

                    // TODO FIX THAT HARDCODED TOGGLE
                    if (data.name === 'chromatic_aberration' && element.name === 'smaa') {
                        return {
                            ...element,
                            enabledOnMobile: false
                        }
                    }
                    if (data.name === 'smaa' && element.name === 'chromatic_aberration') {
                        return {
                            ...element,
                            enabledOnMobile: false
                        }
                    }

                    return element
                })
            )
        } else {
            state.setPostProcessingList(
                state.postProcessingList.map(element => {
                    if (element.name === data.name) {
                        return {
                            ...element,
                            effect: {
                                ...element.effect,
                                [prop]: {
                                    //@ts-ignore
                                    ...element.effect[prop],
                                    value: value
                                }
                            }
                        }
                    }

                    return element
                })
            )
        }
    }

    const renderEnabled = () => {
        return (
            <PropsItem span={16}>
                <Switch
                    checked={enabled}
                    onChange={value => {
                        handleChange('enabled', value)
                    }}
                />
            </PropsItem>
        )
    }

    const renderEnabledOnMobile = () => {
        return (
            <PropsItem span={16}>
                <Switch
                    checked={enabledOnMobile}
                    onChange={value => {
                        handleChange('enabledOnMobile', value)
                    }}
                />
            </PropsItem>
        )
    }

    const renderBlendFunction = (name: string, data: any) => {
        return (
            <SelectItem
                options={BLEND_FUNCTIONS_LIST}
                value={data.value}
                onChange={value => {
                    handleChange(name, value)
                }}
            />
        )
    }

    const renderRange = (name: string, data: any) => {
        let precision = 0.01
        let min = data?.min || 0
        let max = data?.max || 100

        if (name === 'kernelSize') {
            precision = 1
            min = 0
            max = 5
        }

        return (
            <>
                <PropsItem span={5}>
                    <InputNumber
                        min={0}
                        max={1}
                        step={0.01}
                        value={data.value}
                        onChange={value => {
                            // onPropChange(prop.key, value as number)
                            handleChange(name, value)
                        }}
                    />
                </PropsItem>
                <PropsItem span={11}>
                    <Slider
                        style={{ flex: 1 }}
                        min={min}
                        max={max}
                        step={precision}
                        value={data.value}
                        onChange={value => {
                            handleChange(name, value)
                            // onPropChange(prop.key, value)
                        }}
                    />
                </PropsItem>
            </>
        )
    }

    const renderSwitch = (name: string, data: any) => {
        return (
            <PropsItem span={16}>
                <Switch
                    checked={data.value}
                    onChange={value => {
                        handleChange(name, value)
                    }}
                />
            </PropsItem>
        )
    }

    const renderNumber = (name: string, data: any) => {
        return (
            <PropsItem span={16}>
                <InputNumber
                    min={-400}
                    max={400}
                    step={0.01}
                    value={data.value}
                    onChange={value => {
                        handleChange(name, value)
                    }}
                />
            </PropsItem>
        )
    }

    const renderVector = (name: string, data: any) => {
        return (
            <Col span={16}>
                <Row>
                    <PropsItem span={8}>
                        <InputNumber
                            min={-2}
                            max={2}
                            step={0.001}
                            value={data.value.x}
                            onChange={e => {
                                handleChange(name, { x: e as number, y: data.value.y })
                            }}
                        />
                    </PropsItem>
                    <PropsItem span={8}>
                        <InputNumber
                            min={-2}
                            max={2}
                            step={0.01}
                            value={data.value.y}
                            onChange={e => {
                                handleChange(name, { y: e as number, x: data.value.x })
                            }}
                        />
                    </PropsItem>
                </Row>
            </Col>
        )
    }

    const renderResolution = (name: string, data: any) => {
        return (
            <SelectItem
                options={['256', '512', '1024', '2048'].map(item => {
                    return {
                        label: item,
                        value: item,
                        key: item
                    }
                })}
                value={data.value}
                onChange={value => {
                    handleChange(name, value)
                }}
            />
        )
    }

    const renderItem = (name: string, type?: string) => {
        const effectData = data.effect[name as keyof typeof data.effect] as any

        if (!type) {
            type = getType(name, data.effect[name as keyof typeof data.effect] as any)
        }

        switch (type) {
            case 'resolution':
                return renderResolution(name, effectData)
            case 'enabled':
                return renderEnabled()
            case 'enabledOnMobile':
                return renderEnabledOnMobile()
            case 'range':
                return renderRange(name, effectData)
            case 'switch':
                return renderSwitch(name, effectData)
            case 'number':
                return renderNumber(name, effectData)
            case 'vector':
                return renderVector(name, effectData)
            case 'blendFunction':
                return renderBlendFunction(name, effectData)

            default:
                return type
        }
    }

    const resetEffect = (name: string) => () => {
        state.setPostProcessingList(
            state.postProcessingList.map(item => {
                if (item.name === name) {
                    return {
                        ...item,
                        effect: EFFECT_DEFAULTS.find(el => (el as any).name === item.name)! as any
                    }
                } else {
                    return item
                }
            })
        )
    }

    return (
        <div>
            <Row style={{ height: 44 }} key={'enabled'}>
                <PropsItem span={8}>
                    <span>enabled</span>
                </PropsItem>
                {renderItem('enabled', 'enabled')}
            </Row>

            <Row style={{ height: 44 }} key={'enabledMobile'}>
                <PropsItem span={8}>
                    <span>enabled on mobile</span>
                </PropsItem>
                {renderItem('enabledOnMobile', 'enabledOnMobile')}
            </Row>

            <Row style={{ height: 44 }} key={'reset'}>
                <PropsItem span={8}>
                    <span>Reset</span>
                </PropsItem>
                <Button onClick={resetEffect(data.name)}>Reset</Button>
            </Row>
            {options.map(el => {
                return (
                    <Row style={{ height: 44 }} key={el}>
                        <PropsItem span={8}>
                            <span>{el}</span>
                        </PropsItem>

                        {renderItem(el)}
                    </Row>
                )
            })}
        </div>
    )
}

const PostProcessingController = () => {
    const { postProcessingList, updatePostProcessingParams } = utilsEditor()
    const [selectedItem, setSelectedItem] = useState('bloom')

    const optionsForSelect = useMemo(() => {
        const list: BaseOptionType[] = postProcessingList.map(el => ({
            label: el.name,
            value: el.name
        }))

        return list
    }, [postProcessingList])

    // const selectedItem = useMemo(() => postProcessingList.filter(i => i.enabled).map(i => i.name), [postProcessingList])

    const selectedItemData = useMemo(
        () => postProcessingList.find(el => el.name === selectedItem),
        [selectedItem, postProcessingList]
    )

    // const handleSelectChange = (data: string[]) => {
    //   data.forEach(el => {
    //     updatePostProcessingParams(el as any, true, { name: el as any })
    //   })
    // }а заб

    return (
        <div style={{ width: '100%' }}>
            <SelectItem
                // mode="multiple"
                options={optionsForSelect}
                onChange={event => {
                    // handleSelectChange(event as string[])
                    setSelectedItem(event as string)
                }}
                placeholder="Select..."
                value={selectedItem}
            />

            {selectedItemData && <PostProcessingItem data={selectedItemData} />}
        </div>
    )
}

const SelectItem = styled(Select)`
    width: 100%;
    minwidth: 400px;
`

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`

export { PostProcessingController }

import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { ProductRuleType } from '../pages/products/components/tabs/ProductRules'
import { ProductAttributeType } from '../pages/products/components/tabs/ProductAttributes'
import { useParams } from 'react-router-dom'
import { FetchFunctionReturn } from '../utils'
import { ProductTagType } from '../api/dealerCatalogApi'
import { TableMetaType } from '../components/ExtendedTable'
import { MetadataType } from '../pages/products/components/metadata/ProductMetadata'

const initProduct: ProductType = {
    newProduct: 'new',

    id: Date.now(),
    license_id: null,
    product_id: null,
    asset_id: null,
    meta_data: [],

    name: '',
    description: '',
    image_url: '',
    make: '',
    model: '',
    year: '',
    type: 1,
    color: '',

    tags: [],
    price: [],
    discount: {
        type: 0,
        percentage: 0,
        is_child_use_discount: false
    },
    keywords: []
}

const CatalogProvider = ({ children, requests }: { children: ReactNode; requests: CatalogRequestsContextType }) => {
    const { id: productId } = useParams()

    const { getProduct, getAttributes, getCategories, getRules } = requests

    const [isLoadingProduct, setIsLoadingProduct] = useState(true)
    const [isLoadingCategories, setIsLoadingCategories] = useState(true)
    const [isLoadingAttributes, setIsLoadingAttributes] = useState(true)
    const [isLoadingRules, setIsLoadingRules] = useState(true)

    const [product, setProduct] = useState(initProduct)
    const [categories, setCategories] = useState<ProductAttributeType[]>([])
    const [attributes, setAttributes] = useState<ProductAttributeType[]>([])
    const [rules, setRules] = useState<ProductRuleType[]>([])

    const fetchCategories = async (getCategories: GetAttributesType) => {
        setIsLoadingCategories(true)

        if (!productId) {
            setIsLoadingCategories(false)
            return
        }

        const res = await getCategories({ id: productId })
        if ('error' in res) {
            setIsLoadingCategories(false)
            return
        }

        setCategories(res.data)

        setIsLoadingCategories(false)
    }

    const fetchRules = async (getRules: GetRulesType) => {
        setIsLoadingRules(true)

        if (!productId) {
            setIsLoadingRules(false)
            return
        }

        const res = await getRules({ id: productId })
        if ('error' in res) return

        setRules(res.data)

        setIsLoadingRules(false)
    }

    const fetchProduct = async (getProduct: GetProductType) => {
        setIsLoadingProduct(true)

        if (!productId) {
            setIsLoadingProduct(false)
            return
        }

        const res = await getProduct({ id: productId })
        if ('error' in res) {
            setIsLoadingProduct(false)
            return
        }
        setProduct({ ...initProduct, ...res.data })

        setIsLoadingProduct(false)
    }

    const fetchAttributes = async (getAttributes: GetAttributesType) => {
        setIsLoadingAttributes(true)

        if (!productId) {
            setIsLoadingAttributes(false)
            return
        }

        const res = await getAttributes({ id: productId })
        if ('error' in res) {
            setIsLoadingAttributes(false)
            return
        }
        setAttributes(res.data)

        setIsLoadingAttributes(false)
    }

    useEffect(() => {
        fetchProduct(getProduct)
        fetchAttributes(getAttributes)
        fetchCategories(getCategories)
        fetchRules(getRules)
    }, [])

    const value: CatalogContextType = {
        product,
        setProduct,

        attributes,
        setAttributes,

        categories,
        setCategories,

        rules,
        setRules,

        fetchProduct,
        isLoadingProduct,

        fetchCategories,
        isLoadingCategories,

        fetchAttributes,
        isLoadingAttributes,

        fetchRules,
        isLoadingRules,

        requests
    }

    return <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>
}

export default CatalogProvider

export const CatalogContext = createContext({} as CatalogContextType)

type CatalogContextType = {
    product: ProductType
    setProduct: Dispatch<SetStateAction<ProductType>>

    categories: ProductAttributeType[]
    setCategories: Dispatch<SetStateAction<ProductAttributeType[]>>

    attributes: ProductAttributeType[]
    setAttributes: Dispatch<SetStateAction<ProductAttributeType[]>>

    rules: ProductRuleType[]
    setRules: Dispatch<SetStateAction<ProductRuleType[]>>

    fetchProduct: (getProduct: GetProductType) => Promise<void>
    isLoadingProduct: boolean

    fetchCategories: (getCategories: GetAttributesType) => Promise<void>
    isLoadingCategories: boolean

    fetchAttributes: (getAttributes: GetAttributesType) => Promise<void>
    isLoadingAttributes: boolean

    fetchRules: (getRules: GetRulesType) => Promise<void>
    isLoadingRules: boolean

    requests: CatalogRequestsContextType
}

export type ProductDiscountType = {
    type: number
    percentage: number
    is_child_use_discount: boolean
}

export type ProductType = {
    asset_customizer_id?: string
    newProduct?: 'new'
    id: number
    license_id: number | null
    product_id: string | null
    name: string
    description: string
    image_url: string
    meta_data: MetadataType[]
    type_object?: 'license' | 'product' | 'lproduct_group'

    price: ProductPriceType[] | string
    discount: ProductDiscountType
    tags: string[]
    keywords: string[]
    make: string
    model: string
    year: string
    type: 1 | 2
    asset_id: number | null
    color: string
}

export type ProductPriceType = { id: number; amount: string; code: string; discount: number }

export type GetProductType = ({ id }: { id: string }) => Promise<{ data: ProductType } | { error: any }>
export type GetAttributesType = ({ id }: { id: string }) => Promise<{ data: ProductAttributeType[] } | { error: any }>
export type GetRulesType = ({ id }: { id: string }) => Promise<{ data: ProductRuleType[] } | { error: any }>
export type GetProductsByTags = ({
    page,
    tags
}: {
    page: number
    tags: string[]
}) => Promise<{ data: { products: ProductType[]; _meta: TableMetaType } } | { error: any }>

export type CatalogRequestsContextType = {
    getProduct: GetProductType
    createProduct: (product: ProductType) => FetchFunctionReturn<{ id: number }>
    updateProduct: (product: ProductType) => FetchFunctionReturn<string | boolean>

    getCategories: GetAttributesType
    getAttributes: GetAttributesType
    createAttributes: (attribute: ProductAttributeType) => FetchFunctionReturn<null>
    updateAttributes: (attribute: ProductAttributeType) => FetchFunctionReturn<string | boolean>
    deleteAttributes: (attributes: number[]) => FetchFunctionReturn<null>

    getRules: GetRulesType
    createRules: (rule: ProductRuleType) => FetchFunctionReturn<null>
    updateRules: (rule: ProductRuleType) => FetchFunctionReturn<null>
    deleteRules: (rules: number[]) => FetchFunctionReturn<null>
    cloneRule: (obj: { rule_id: string }) => FetchFunctionReturn<null>

    products: ProductType[]

    getProductsByTags: GetProductsByTags

    productTags: ProductTagType[]
    isLoadingProductTags: boolean

    fetchInfoItems: () => void

    productKeywords?: ProductTagType[]
    isLoadingProductKeywords?: boolean
}

import api from './index'

export const AMA_PRODUCT_OWNERSHIP = 'ama-product-ownership'
export const AMA_PRODUCT_DEALER = `${AMA_PRODUCT_OWNERSHIP}/type-dealer`
export const AMA_PRODUCT_COMPANY = `${AMA_PRODUCT_OWNERSHIP}/type-company`
export const AMA_PRODUCT_OWNERSHIP_PRICE = `${AMA_PRODUCT_OWNERSHIP}/price`

const amaProductOwnerShip = api.injectEndpoints({
    endpoints: build => ({
        getAmaProductDealerType: build.query<AMAProductUserType[], null>({
            query: () => AMA_PRODUCT_DEALER
        }),

        getAmaProductCompanyType: build.query<AMAProductUserType[], null>({
            query: () => AMA_PRODUCT_COMPANY
        }),

        createAPO: build.mutation<null, APOType>({
            query: body => {
                return {
                    url: AMA_PRODUCT_OWNERSHIP,
                    method: 'POST',
                    body
                }
            }
        }),

        updateAPO: build.mutation<null, APOUpdateType>({
            query: body => {
                const { apo_id, product_id, connection_id } = body
                return {
                    url: AMA_PRODUCT_OWNERSHIP + `?id=${apo_id}`,
                    method: 'PUT',
                    body: { product_id }
                }
            }
        }),

        updateAPOPrice: build.mutation<null, APOPriceType>({
            query: body => {
                const { apo_id, description, amount, type_debit } = body
                return {
                    url: AMA_PRODUCT_OWNERSHIP_PRICE + `?id=${apo_id}`,
                    method: 'POST',
                    body: { description, amount, type_debit }
                }
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetAmaProductCompanyTypeQuery,
    useCreateAPOMutation,
    useUpdateAPOMutation,
    useUpdateAPOPriceMutation,
    useGetAmaProductDealerTypeQuery
} = amaProductOwnerShip

export default amaProductOwnerShip

export type AMAProductUserType = {
    id: number
    name: string
}

export type APOPriceType = {
    apo_id: number
    amount: string
    type_debit: number
    description: string
}

export type APOType = {
    product_id: number | string
    connection_id: string
    connection_type: number
}

export type APOUpdateType = { product_id: number; apo_id: number; connection_id: string }

function createTagsForList(result, tag, listTag = tag, listId = 'LIST') {
    // console.log(result)
    return [
        {
            type: listTag,
            id: listId
        },
        ...(result && result.root ? result.root : []).map(item => ({
            type: tag,
            id: String(item.id)
        }))
    ]
}

export default createTagsForList

import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { BrandType, useGetBrandListMutation } from '../../../api/brandApi'
import toast from 'react-hot-toast'
import { CompanyInfoType, CompanyType } from 'api/companyApi'
import { useGetCountryQuery, useGetStatesByCountryMutation } from '../../../api/countryApi'
import { useFetchByPagination } from '../../../hooks'

type CompanyFormProps = {
    form: FormInstance<CompanyInfoType>
    onFinish: (e: CompanyInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const CompanyForm = ({ refetch, onFinish, setIsModalOpen, form }: CompanyFormProps) => {
    const [brands] = useFetchByPagination<BrandType>(useGetBrandListMutation)

    const { data: countries } = useGetCountryQuery()
    const [getStatesByCountry, resultsStatesByCountry] = useGetStatesByCountryMutation()
    const { data: states } = resultsStatesByCountry

    useEffect(() => {
        const countryId = form.getFieldValue('country_id')

        if (typeof countryId === 'number') {
            getStatesByCountry({ id: countryId })
        }
    }, [form.getFieldValue('country_id')])

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Name" />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                <Input maxLength={40} type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item label="Lead Email" name="leads_email">
                <Input maxLength={40} type="email" placeholder="Lead Email" />
            </Form.Item>

            {
                <Form.Item label="Country" name="country_id" rules={[{ required: true }]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Country"
                        onChange={(id: number) => {
                            getStatesByCountry({ id })
                            form.setFieldValue('country_id', id)
                            form.setFieldValue('state_id', null)
                        }}>
                        {countries
                            ? countries.map(country => (
                                  <Select.Option key={country.id} value={country.id}>
                                      {country.name}
                                  </Select.Option>
                              ))
                            : null}
                    </Select>
                </Form.Item>
            }

            {states && states.length !== 0 && (
                <Form.Item label="State/Province" name="state_id" rules={[{ required: true }]}>
                    <Select showSearch optionFilterProp="children" placeholder="State/Province">
                        {states.map(country => (
                            <Select.Option key={country.id} value={country.id}>
                                {country.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            <Form.Item label="Zip Code" name="zip_code" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Zip Code" />
            </Form.Item>

            <Form.Item label="City" name="city" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="City" />
            </Form.Item>

            <Form.Item label="Street" name="street" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Street" />
            </Form.Item>

            <Form.Item label="How To Pay" name="how_pay" rules={[{ required: true }]}>
                <Select showSearch allowClear optionFilterProp="children" placeholder="How to pay">
                    {howToPayOptions.map(opt => {
                        return (
                            <Select.Option key={opt.value} value={opt.value}>
                                {opt.label}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Brands" name="brands" rules={[{ required: true }]}>
                <Select showSearch allowClear optionFilterProp="children" placeholder="Brands" mode="multiple">
                    {brands.map(brand => {
                        return (
                            <Select.Option key={brand.id} value={brand.id}>
                                {brand.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
        </Form>
    )
}

export default CompanyForm

export const howToPayOptions: { value: CompanyType['how_pay']; label: string }[] = [
    { value: 0, label: 'Pay Dealer' },
    { value: 1, label: 'Company License' },
    { value: 2, label: 'Company Dealer' },
    { value: 3, label: 'Company All Dealer' },
    { value: 4, label: 'Company Subscription' }
]

import { Avatar, Table, Tag, Image, Button, Typography, Pagination, Space, Dropdown } from 'antd'
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import type { FilterValue } from 'antd/es/table/interface'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    ASSET_TYPE,
    TGetAssetResponse,
    useAddTagsToAssetsMutation,
    useCloneAssetsMutation,
    useDeleteAssetMutation,
    useDeleteAssetsMutation,
    useGetAssetQuery,
    useMoveAssetsMutation,
    useCheckDeleteAssetsMutation,
    useCreateAssetMutation,
    useUpdateAssetMutation,
    useGetAssetsTagsQuery,
    useCreateAssetsProductsMutation
} from 'api/assetApi'
import toast from 'react-hot-toast'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import DraggableBodyRow from './DraggableBodyRow'
import { AssetDataType, TAsset } from '../../../types'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedRows } from '../../../selectors/assetsSliceSelectors'
import { setSelectedRows } from '../../../slices/assetsSlice'
import { AssetsListProvider } from './useAssetsListContext'
import { DeleteOutlined, DownOutlined, FolderFilled } from '@ant-design/icons'
import BulkActions, { TBulkAction } from './BulkActions'
import TagsSelector from './TagsSelector'
import ModalAlertDeleteAsset from '../../../components/panel/ModalAlertDeleteAsset'
import PageBuilder from '../../../components/panel/PageBuilder'
import Utils, { getBreadcrumbsByPath } from '../../../utils'
import { BreadcrumbsType } from '../../../components/panel/Breadcrumbs'
import { utilsEditor, ViewerForPreview } from '@amaspace-editor/editor-3d'
import { ActualFileObject, FilePondErrorDescription, FilePondFile } from 'filepond'
import { TableMetaType } from '../../../components/ExtendedTable'
import { routesUrl } from '../../../router/urls'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { AssetDataFilterType } from '../../../types/AssetDataType'
import JSZip from 'jszip'

const { Link } = Typography

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)

interface TableParams {
    pagination?: TablePaginationConfig
    sortField?: string
    sortOrder?: string
    filters?: Record<string, FilterValue | null>
}

const AssetsList = () => {
    const location = useLocation()

    const [searchParams, setSearchParams] = useSearchParams()

    const [isDownloadingAssets, setIsDownloadingAssets] = useState(false)
    const [data, setData] = useState<AssetDataType[]>([])
    const [loading, setLoading] = useState(false)
    const [previewModel, setPreviewModel] = useState<Record<string, TAsset[]> | null>(null)
    const [uploadingAssets, setUploadingAssets] = useState<Record<string, TAsset[]> | null>(null)

    const [tagsSelectorShown, setTagsSelectorShown] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState<AssetsConnectionsInProductsType>({ assets: [], connections: [] })
    const filePondRef = useRef<FilePond | null>(null)
    const [files, setFiles] = useState([])
    const selectedRows = useSelector(selectSelectedRows)

    const getAssetsTags = useGetAssetsTagsQuery()
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>(['assets'])

    const dispatch = useDispatch()
    const viewer3D = utilsEditor()
    const components = { body: { row: DraggableBodyRow } }

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow]
                    ]
                })
            )
        },
        [data]
    )
    const { '*': splat } = useParams()
    const list = splat?.split('/')
    const lastId = list?.pop()

    const navigate = useNavigate()

    const [pagination, setPagination] = useState<TableMetaType | null>(null)
    const [sort, setSort] = useState<AssetDataFilterType>({
        page: 1,
        name_sort: '',
        type: [],
        tags: [],
        name: '',
        created_at: '',
        created_at_sort: ''
    })
    const [assetTags, setAssetTags] = useState<{ id: number; name: string }[]>([])

    useEffect(() => {
        const pageParam = searchParams.get('page')
        const name = searchParams.get('name')
        const name_sort = searchParams.get('name_sort')

        const created_at = searchParams.get('created_at')
        const created_at_sort = searchParams.get('created_at_sort')

        const tags = searchParams.get('tags')
        const type = searchParams.get('type')

        const page = parseInt(pageParam ?? '1')

        setSort({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            name_sort: name_sort ?? '',

            created_at: created_at ?? '',
            created_at_sort: created_at_sort ?? '',

            type: type ? type.split(',') : [],
            tags: tags ? tags.split(',') : []
        })
    }, [searchParams])

    const getAssetQueryResponse = useGetAssetQuery({
        parentId: lastId,
        ...sort
    })
    const [createAssetsProducts, { isLoading: isCreateAssetsProductsLoading }] = useCreateAssetsProductsMutation()
    const [deleteAssetRequest] = useDeleteAssetMutation()
    const [deleteAssetsRequest] = useDeleteAssetsMutation()
    const [cloneAssetsRequest] = useCloneAssetsMutation()
    const [addTagsToAssetsRequest] = useAddTagsToAssetsMutation()
    const [checkDeleteAssets] = useCheckDeleteAssetsMutation()
    const [createAssetRequest] = useCreateAssetMutation()
    const [updateAssetRequest] = useUpdateAssetMutation()
    const [triggerMoveAssets, { isLoading: isMoveItemsLoading }] = useMoveAssetsMutation()

    const reFetchAssets = () => {
        setLoading(true)
        getAssetQueryResponse.refetch()
    }

    useEffect(reFetchAssets, [lastId])

    useEffect(() => {
        uploadingPreview()
    }, [previewModel])

    useEffect(() => {
        if (getAssetQueryResponse.data) {
            const response = getAssetQueryResponse.data as TGetAssetResponse

            if (response.path) {
                const [paths, folderPaths] = getBreadcrumbsByPath(response.path, splat as string)

                setBreadcrumbs(() => {
                    const newState = paths.map((path, index) => {
                        return { id: `assets${folderPaths[index]}`, name: path }
                    })

                    return ['assets', ...newState]
                })
            } else {
                setBreadcrumbs(['assets'])
            }

            if (!response.root) {
                setLoading(false)
                setData([])
                return
            }

            const assets = response.root.map((asset: TAsset) => {
                return processAsset({
                    ...asset
                })
            })

            setData(assets)

            setPagination(response._meta)
            setLoading(false)
        }
    }, [getAssetQueryResponse])

    const uploadingPreview = () => {
        if (!previewModel || !uploadingAssets) return
        const previewKeys = Object.keys(previewModel)
        const assetKeys = Object.keys(uploadingAssets)

        Promise.all(
            assetKeys.map((item: any) => {
                const preview = previewKeys.find(key => key === item)
                if (!preview) return
                const asset = uploadingAssets[item]
                const previewModelItem = previewModel[preview]

                if (asset?.length === previewModelItem?.length) {
                    return Promise.all(
                        previewModelItem.map(async item => {
                            await updateAssetRequest({
                                id: item!.id,
                                asset: item
                            })
                        })
                    ).then(res => {
                        let parts = item.split('_')
                        let toastId = parts[parts.length - 1]

                        toast.success('Upload success!', {
                            id: toastId
                        })
                        return res
                    })
                }
            })
        )
            .then(res => {
                if (res.some(i => !i)) return
                setPreviewModel(null)
                setUploadingAssets(null)
                reFetchAssets()
            })
            .catch(e => {
                console.log(e)
            })
    }

    const deleteAsset = (id: string) => {
        deleteAssetRequest({ id }).then((response: any) => {
            if (response && response.error) {
                toast.error('Could not delete asset')
            } else {
                reFetchAssets()
            }
        })
    }

    const deleteAssets = (ids: string[]) => {
        deleteAssetsRequest({ ids })
            .then(() => {
                toast.success('Assets are successfully deleted')
                dispatch(setSelectedRows([]))
                reFetchAssets()
            })
            .catch(e => {
                console.log(e)
                toast.error('Could not delete some of assets')
            })
    }

    const viewAsset = (id: string) => {
        const targetAsset = data.find(asset => {
            return asset.id === id
        })

        if (!targetAsset) {
            console.log('Could not find target asset')
            return '#'
        }

        const isFolder = targetAsset.type === ASSET_TYPE.Folder

        if (isFolder) {
            const pathname = !splat ? id : `${splat}/${id}`
            return `${routesUrl.assets}/${pathname}`
        } else {
            const str = !splat ? id : `${splat}/${id}`
            return `${routesUrl.assetsView}/${str}`
        }
    }

    const createAsset = () => {
        navigate(`${routesUrl.assetsCreate}/${splat}`)
    }

    const findConnectionsInProducts = async (assets: string[]): Promise<AssetsConnectionsInProductsType> => {
        const { data }: any = await checkDeleteAssets(assets)

        const connections = []

        for (const key in data) {
            if (data[key].products[0] !== -1) connections.push(key)
        }

        return { assets, connections }
    }

    const rowsHaveFolders = selectedRows.length > 0 ? !!selectedRows.filter(row => row.type === 'folder').length : null

    const canBeDownloaded =
        selectedRows.length > 0
            ? !selectedRows.filter(row => row.type !== 'model' && row.type !== 'texture').length
            : null
    const [filterByText, _, selectFilter] = useFilterTable<AssetDataFilterType>()

    function convertToLocalTime(serverDate: Date) {
        const serverOffset = serverDate.getTimezoneOffset() * 60000
        const localDate = new Date(serverDate.getTime() - serverOffset)
        return localDate
    }

    const columns: ColumnsType<AssetDataType> = [
        {
            // @ts-ignore
            title: 'Name',
            dataIndex: 'name',
            width: '40%',
            ellipsis: true,
            ...filterByText('name', 'name_sort'),
            render: (name, rec) => {
                return (
                    <div
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                        onClick={() => navigate(viewAsset(rec.id.toString()))}>
                        {rec.preview && (
                            <Avatar
                                style={{ marginRight: '15px' }}
                                shape="square"
                                size={64}
                                src={<Image src={rec.preview} />}
                            />
                        )}
                        <Link href={viewAsset(rec.id.toString())} onClick={e => e.preventDefault()}>
                            {rec.type === ASSET_TYPE.Folder && !rec.preview && (
                                <FolderFilled style={{ marginRight: '10px' }} className={'table__name-icon'} />
                            )}
                            {name}
                        </Link>
                    </div>
                )
            }
        },
        {
            // @ts-ignore
            title: 'Tags',
            dataIndex: 'tags',
            width: '20%',
            ...selectFilter('tags', assetTags),
            render: (tags: AssetTagsType) => (
                <span>
                    {tags &&
                        tags?.map(tag => (
                            <Tag color="default" key={tag.id}>
                                {tag?.name}
                            </Tag>
                        ))}
                </span>
            )
        },
        {
            // @ts-ignore
            title: 'Type',
            dataIndex: 'type',
            width: '20%',
            ...selectFilter(
                'type',
                [
                    { name: 'Folder', id: 'folder' },
                    { name: 'Model', id: 'model' },
                    { name: 'Material', id: 'material' },
                    { name: 'Texture', id: 'texture' },
                    { name: 'Scene', id: 'scene' }
                ],
                undefined,
                false
            ),
            render: (type, rec) => {
                switch (type) {
                    case 'texture':
                        return <Tag color={'geekblue'}>Texture</Tag>
                    case 'model':
                        return <Tag color={'green'}>Model</Tag>
                    case 'material':
                        return <Tag color={'volcano'}>Material</Tag>
                    case 'folder':
                        return <Tag color={'magenta'}>Folder</Tag>
                    case 'scene':
                        return <Tag color={'gold'}>Scene</Tag>
                    default:
                        return <Tag color={'cyan'}>Undefined</Tag>
                }
            }
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            width: '20%',
            render: createdAt => {
                const serverDate = new Date(createdAt)
                const localDate = convertToLocalTime(serverDate)

                return moment(localDate).calendar()
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: '10%',
            render: (row, rec) => (
                <Button
                    type={'link'}
                    size={'small'}
                    onClick={async () => {
                        const response = await findConnectionsInProducts([rec.id])
                        setModalInfo(response)
                        setModalIsOpen(true)
                    }}>
                    <DeleteOutlined />
                </Button>
            )
        }
    ]

    const processAsset = (asset: TAsset): AssetDataType => {
        return Object.assign(asset, {
            deleting: false,
            key: asset.id
        })
    }
    function isFileTypeValid(file: ActualFileObject, acceptedTypes: string[]) {
        return acceptedTypes.some(type => file.name.endsWith(`.${type}`))
    }

    async function handleAddFile(error: FilePondErrorDescription | null, fileData: FilePondFile) {
        const toastId = toast.loading('Uploading...')
        const { file } = fileData

        const acceptedTypes = ['glb', 'gltf', 'jpg', 'jpeg', 'png', 'webp']
        if (!isFileTypeValid(file, acceptedTypes)) {
            errorWhenUpload('File format not allowed', fileData, toastId)
            return
        }

        try {
            const assets = await Utils.createAssetsFromFile(file as File, lastId)
            await createAssetRequest({ assets })
            updateUIAfterUpload(assets, file as File, toastId)
        } catch (e) {
            console.error(e)
            errorWhenUpload('File upload error!', fileData, toastId)
        }
    }
    const updateUIAfterUpload = (assets: TAsset[], file: File, toastId: string) => {
        const materials = assets.filter(i => i.type === ASSET_TYPE.Material)
        const model = assets.filter(i => i.type === ASSET_TYPE.Model)
        const updatedFiles = files.filter((fileData: any) => {
            return fileData.file !== file
        })
        setFiles(updatedFiles)

        if (!model.length) {
            refetchAssets()
            toast.success('File upload!', {
                id: toastId
            })
            return
        }

        setUploadingAssets(prev =>
            prev
                ? { ...prev, [`${file.name}_${toastId}`]: [...materials, ...model] }
                : { [`${file.name}_${toastId}`]: [...materials, ...model] }
        )
    }
    function handleOnRow(_: any, index: number | undefined) {
        const attr = {
            index,
            moveRow
        }
        return attr as React.HTMLAttributes<any>
    }

    function handleMoveAssets(id: string) {
        triggerMoveAssets({
            assets: selectedRows,
            parentId: id
        })
        dispatch(setSelectedRows([]))
    }

    const generateBulkActions = () => {
        const actions: TBulkAction[] = []

        if (!selectedRows.length) {
            return []
        }

        const ids = selectedRows.map(row => {
            return row.id
        })

        actions.push({
            label: 'Delete',
            onclick: async () => {
                const response = await findConnectionsInProducts(ids)
                setModalInfo(response)
                setModalIsOpen(true)
            }
        })

        actions.push({
            label: 'Clone',
            onclick: () => {
                cloneAssetsRequest({ ids })
                    .then((response: any) => {
                        toast.success('Assets are successfully cloned')

                        const newAssets: AssetDataType[] = []

                        if (response && response.data && response.data.assets) {
                            response.data.assets.forEach((asset: any) => {
                                newAssets.push(
                                    processAsset({
                                        ...asset
                                    })
                                )
                            })
                        }

                        setData([...data, ...newAssets])
                    })
                    .catch(e => {
                        console.log(e)
                        toast.error('Could not delete clone some of assets')
                    })
            }
        })

        actions.push({
            label: 'Tag',
            onclick: () => {
                setTagsSelectorShown(true)
            }
        })

        return actions
    }

    const refetchAssets = () => {
        setLoading(true)
        getAssetQueryResponse.refetch()
    }
    const errorWhenUpload = (e: any, fileData: FilePondFile, toastId: string) => {
        console.error(e)
        const error = e ? e : 'File upload error!'
        toast.error(error, {
            id: toastId
        })
        if (filePondRef.current) filePondRef.current.removeFile(fileData)
    }

    useEffect(() => {
        if (getAssetsTags.isSuccess) {
            setAssetTags(getAssetsTags.data)
        }
    }, [getAssetsTags.isSuccess])

    return (
        <PageBuilder breadcrumbs={breadcrumbs} documentTitle="Assets" title="Assets">
            {tagsSelectorShown ? (
                <TagsSelector
                    assets={selectedRows}
                    onClose={() => {
                        setTagsSelectorShown(false)
                    }}
                    onApply={({ assets, tagsToApply, tagsToRemove, tagsList }) => {
                        const tagIdsToRemove: number[] = []
                        const newTags: string[] = []
                        const tagsIdsToApply: number[] = []

                        tagsToApply.forEach(newTagName => {
                            const existingTag = tagsList.find(({ name }) => {
                                return name === newTagName
                            })

                            if (existingTag) {
                                tagsIdsToApply.push(existingTag.id)
                            } else {
                                newTags.push(newTagName)
                            }
                        })

                        tagsToRemove.forEach(tagName => {
                            const targetTag = tagsList.find(({ name }) => {
                                return name === tagName
                            })

                            if (!targetTag) {
                                console.log('Could not find tag in tag list to remove', {
                                    name: tagName,
                                    list: tagsList.slice()
                                })
                            } else {
                                tagIdsToRemove.push(targetTag.id)
                            }
                        })

                        addTagsToAssetsRequest({
                            ids: assets.map(asset => {
                                return asset.id
                            }),
                            tagsToAdd: tagsIdsToApply,
                            tagsToCreate: newTags,
                            tagsToRemove: tagIdsToRemove
                        })
                            .then(() => {
                                setTagsSelectorShown(false)

                                reFetchAssets()
                            })
                            .catch(e => {
                                console.log('Could not commit addTagsToAssetsRequest', e)
                            })
                    }}
                />
            ) : null}
            {uploadingAssets ? (
                <div
                    style={{
                        position: 'fixed',
                        width: '100px',
                        height: '100px',
                        top: '-5000px'
                    }}>
                    {Object.keys(uploadingAssets).map(key => {
                        const allAssets = uploadingAssets[key] as any
                        return (
                            <ViewerForPreview name={key} allAssets={allAssets} updatePreview={setPreviewModel as any} />
                        )
                    })}
                </div>
            ) : null}
            <FilePond
                ref={ref => (filePondRef.current = ref)}
                files={files}
                // @ts-ignore
                onupdatefiles={setFiles}
                allowMultiple={true}
                maxFiles={10}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                onaddfile={handleAddFile}
            />
            <div>
                <Space size={[8, 0]}>
                    <Button type="primary" onClick={createAsset}>
                        Create asset
                    </Button>

                    <BulkActions selection={selectedRows} actions={generateBulkActions()} />

                    {rowsHaveFolders !== null && !rowsHaveFolders && (
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: 'PCO',
                                        key: '2'
                                    },
                                    {
                                        label: 'Product',
                                        key: '1'
                                    }
                                ],
                                onClick: async e => {
                                    const type_product = +e.key
                                    const assets = selectedRows.filter(row => row.type !== 'folder')
                                    const asset_id = assets.map(asset => asset.db_id)
                                    const res = await createAssetsProducts({ type_product, asset_id })
                                    if ('error' in res) return
                                    toast.success(`Products by Assets are successfully created`)
                                }
                            }}>
                            <Button
                                loading={isCreateAssetsProductsLoading}
                                type={'primary'}
                                onClick={e => e.preventDefault()}>
                                <Space>
                                    Create Products
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    )}

                    {canBeDownloaded !== null && canBeDownloaded && (
                        <Button
                            loading={isDownloadingAssets}
                            type={'primary'}
                            onClick={async () => {
                                setIsDownloadingAssets(true)
                                const loader = toast.loading(`Downloading...`)
                                const assets = selectedRows.filter(
                                    row => row.type === 'texture' || row.type === 'model'
                                )
                                const zip = new JSZip()
                                const folder = zip.folder('assets')

                                for (let i = 0; i < assets.length; i++) {
                                    try {
                                        const url = assets[i].url as string
                                        if (url) {
                                            const response = await fetch(url)
                                            const prefix = 'https://amaspace-dev.s3.amazonaws.com/client777/'
                                            const name = url.startsWith(prefix) ? url.slice(prefix.length) : url
                                            const blob = await response.blob()
                                            folder!.file(`${name}`, blob)
                                        }
                                    } catch (e) {
                                        toast.error(`File (${assets[i].name}) cannot be downloaded, try again later.`)
                                    }
                                }

                                const zipData = await zip.generateAsync({
                                    type: 'blob',
                                    streamFiles: true
                                })
                                const link = document.createElement('a')
                                link.href = window.URL.createObjectURL(zipData)
                                link.download = 'assets.zip'
                                link.click()
                                setIsDownloadingAssets(false)
                                toast.dismiss(loader)
                                toast.success(`Assets are successfully downloaded.`)
                            }}>
                            Download Assets
                        </Button>
                    )}
                </Space>
            </div>
            <DndProvider backend={HTML5Backend}>
                <AssetsListProvider value={{ moveAssets: handleMoveAssets }}>
                    <Table
                        className="dnd-table table"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRows.map(r => r.id),
                            ...createRawSelectionConfig(rows => dispatch(setSelectedRows(rows)))
                        }}
                        components={components}
                        onRow={handleOnRow}
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={data}
                        loading={loading || isMoveItemsLoading || getAssetsTags.isLoading}
                        pagination={false}
                    />
                </AssetsListProvider>
            </DndProvider>

            {pagination !== null && (
                <Pagination
                    onChange={page => {
                        const newSearchParams = new URLSearchParams(location.search)
                        newSearchParams.set('page', page.toString())
                        setSearchParams(newSearchParams.toString())
                    }}
                    disabled={loading}
                    style={{ marginTop: 16 }}
                    showSizeChanger={false}
                    current={pagination.current_page}
                    pageSize={pagination.page_size}
                    total={pagination.total_count}
                />
            )}

            {
                <ModalAlertDeleteAsset
                    assets={modalInfo.assets}
                    connections={modalInfo.connections}
                    deleteAssets={deleteAssets}
                    isOpen={modalIsOpen}
                    setIsOpen={setModalIsOpen}
                />
            }
        </PageBuilder>
    )
}

export default AssetsList

function createRawSelectionConfig(setSelectedRows: (a: AssetDataType[]) => void) {
    return {
        onChange: (selectedRowKeys: React.Key[], selectedRows: AssetDataType[]) => {
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record: AssetDataType) => ({
            // @ts-ignore
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
        })
    }
}

type AssetTagsType = { id: number; name: string }[]

type AssetsConnectionsInProductsType = { assets: string[]; connections: string[] }

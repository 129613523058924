import React, { useContext } from 'react'
import { Checkbox } from 'antd'
import { ProductAttributeType } from '../../tabs/ProductAttributes'
import { CatalogContext } from '../../../../../providers/CatalogProvider'

type ProductAttributeIsHideItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeIsHideItem = ({ attr }: ProductAttributeIsHideItemProps) => {
    const { attributes, setAttributes } = useContext(CatalogContext)

    const handleChangeVisible: HandleChangeAttributeVisible = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                is_hide: item.id === attr.id ? (value ? 1 : 0) : item.is_hide,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    return (
        <Checkbox checked={!!attr.is_hide} onChange={e => handleChangeVisible(e.target.checked)}>
            is Hidden
        </Checkbox>
    )
}

export default ProductAttributeIsHideItem

type HandleChangeAttributeVisible = (value: boolean) => void

import {
    AMAContactShadowType,
    BackgroundSettings,
    DEFAULT_ENVIRONMENT_GROUND,
    HistoryController,
    isCamera,
    SceneFog,
    SetValueCommand,
    UpdateMainCommand,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import { AMASoftShadowType } from '@amaspace-editor/editor-3d/lib/types'
import _ from 'lodash'
import * as THREE from 'three'
import { TAssetEditorProp } from '../../assetEditorProps'

function transformLinearGradient(gradientStr: string) {
    const colorStopRegex = /rgba?\([\d,\s]+\)(?:\s*\d+%?|\s*,\s*\d+%?|\s*[-+]?[0-9]*\.?[0-9]+(?:e[-+]?[0-9]+)?%?)/gi
    const stopRegex = /\d+\.?\d*%/g
    const colorStops = gradientStr.match(colorStopRegex) || []
    const colors: string[] = []
    const stops: number[] = []

    colorStops.forEach(colorStop => {
        const colorMatch = colorStop.match(/rgba?\([\d,\s]+\)/i)
        if (colorMatch) {
            colors.push(colorMatch[0])
        }

        const stopMatch = colorStop.match(stopRegex)
        if (stopMatch) {
            const stopValue = parseFloat(stopMatch[0].replace('%', '')) / 100
            stops.push(stopValue)
        }
    })

    return {
        colors: colors,
        stops: stops
    }
}

const sceneActions = (key: string, value: TAssetEditorProp['value'], editor3D: ReturnType<typeof utilsEditor>) => {
    const keyProp = key.split('_').slice(-1)[0]
    const keyValue = key.split('_')[0]
    const activeCamera = window.customizer.activeCamera
    const History = new HistoryController()

    if (keyProp === 'camera') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeCamera)

        if (!isCamera(current)) return

        History.execute(new SetValueCommand(editor3D, current, value, ['updatedCamera', keyValue]))
    }
    if (keyProp === 'controls') {
        const current = editor3D.getCurrentConfigObj(activeCamera)
        if (!isCamera(current)) return
        History.execute(new SetValueCommand(editor3D, current, +value, ['updatedControls', keyValue]))
    }
    if (key === 'grid') {
        History.execute(new UpdateMainCommand(editor3D, 'grid', editor3D.toggleGrid, value))
    }
    if (key === 'animateCamera') {
        History.execute(new UpdateMainCommand(editor3D, 'animateCamera', editor3D.setAnimateCamera, value))
    }
    if (key === 'focalOffset') {
        History.execute(new UpdateMainCommand(editor3D, 'focalOffset', editor3D.setFocalOffset, value))
    }
    if (keyProp === 'env') {
        editor3D.setActiveEnvironment({
            ...editor3D.activeEnvironment,
            [keyValue]: value,
            ground: DEFAULT_ENVIRONMENT_GROUND
        })
    }
    if (keyProp === 'envGround') {
        if (keyValue === 'ground') {
            History.execute(
                new UpdateMainCommand(editor3D, 'activeEnvironment', editor3D.setActiveEnvironment, {
                    ...editor3D.activeEnvironment,
                    ground: value ? DEFAULT_ENVIRONMENT_GROUND : undefined
                })
            )
            return
        }

        History.execute(
            new UpdateMainCommand(editor3D, 'activeEnvironment', editor3D.setActiveEnvironment, {
                ...editor3D.activeEnvironment,
                ground: {
                    ...editor3D.activeEnvironment.ground,
                    [keyValue]: value
                }
            })
        )
    }

    // TODO same for soft-shadow
    if (keyValue === 'contactShadow') {
        editor3D.setContactShadow(value)
        if (keyProp === 'enabled') {
            if (value) {
                const defaultProps: AMAContactShadowType['params'] = {
                    near: 0,
                    far: 300,
                    opacity: 1,
                    blur: 0.5,
                    scale: 34,
                    resolution: 1024,
                    frames: 5,
                    color: '#5f5c5c'
                }
                History.execute(
                    new UpdateMainCommand(editor3D, 'contactShadow', editor3D.setContactShadow, {
                        enabled: true,
                        params: defaultProps
                    })
                )
            } else {
                History.execute(
                    new UpdateMainCommand(editor3D, 'contactShadow', editor3D.setContactShadow, {
                        enabled: false,
                        params: undefined
                    })
                )
            }
        } else if (editor3D.contactShadow.enabled) {
            const nextState: AMAContactShadowType['params'] = { ...editor3D.contactShadow.params }

            switch (keyProp) {
                case 'resolution':
                    nextState[keyProp] = +value.object
                    break

                default:
                    //@ts-ignore
                    nextState[keyProp] = value as any
                    break
            }
            History.execute(
                new UpdateMainCommand(editor3D, 'contactShadow', editor3D.setContactShadow, {
                    enabled: true,
                    params: nextState
                })
            )
        }
    }

    if (keyValue === 'softShadow') {
        History.execute(new UpdateMainCommand(editor3D, 'contactShadow', editor3D.setContactShadow, value))
        if (keyProp === 'enabled') {
            if (value) {
                const defaultProps: AMASoftShadowType['params'] = {
                    size: 0.25,
                    samples: 10,
                    opacity: 1,
                    focus: 1.7
                }
                History.execute(
                    new UpdateMainCommand(editor3D, 'softShadow', editor3D.setSoftShadow, {
                        enabled: true,
                        params: defaultProps
                    })
                )
            } else {
                History.execute(
                    new UpdateMainCommand(editor3D, 'softShadow', editor3D.setSoftShadow, {
                        enabled: false,
                        params: undefined
                    })
                )
            }
        } else if (editor3D.softShadow.enabled) {
            const nextState: AMASoftShadowType['params'] = { ...editor3D.softShadow.params }

            switch (keyProp) {
                default:
                    //@ts-ignore
                    nextState[keyProp] = value as any
                    break
            }
            History.execute(
                new UpdateMainCommand(editor3D, 'softShadow', editor3D.setSoftShadow, {
                    enabled: true,
                    params: nextState
                })
            )
        }
    }

    if (keyValue === 'fog') {
        // History.execute(new UpdateMainCommand(editor3D, 'fog', editor3D.setFog, value))
        if (keyProp === 'enabled') {
            if (value) {
                const defaultProps: SceneFog = {
                    color: 0xffffff,
                    near: 0,
                    far: 100
                }
                History.execute(new UpdateMainCommand(editor3D, 'fog', editor3D.setFog, defaultProps))
            } else {
                History.execute(new UpdateMainCommand(editor3D, 'fog', editor3D.setFog, undefined))
            }
        } else if (editor3D.fog) {
            const nextState = { ...editor3D.fog }

            switch (keyProp) {
                default:
                    //@ts-ignore
                    nextState[keyProp] = value as any
                    break
            }
            History.execute(new UpdateMainCommand(editor3D, 'fog', editor3D.setFog, nextState))
        }
    }

    if (keyProp === 'showroom') {
        editor3D.setSceneRoom(value ? 'showroom' : undefined)
    }

    if (keyValue === 'background') {
        if (keyProp === 'type') {
            const v = value.object as BackgroundSettings['type']

            // let transform: BackgroundSettings['transform'] = undefined
            // let settings: BackgroundSettings['settings'] = {}
            const nextObject: BackgroundSettings = {
                type: v,
                settings: {},
                transform: {
                    rotation: 0,
                    tiling: [1, 1],
                    offset: [0, 0],
                    resolutionScale: 1
                }
            } as BackgroundSettings

            switch (v) {
                case 'solid':
                    nextObject.settings = {
                        value: 'grey'
                    }

                    break
                case 'radial':
                    nextObject.settings = {
                        value: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%)',
                        colors: ['rgba(0,0,0,1)', 'rgba(255,255,255,1)'],
                        stops: [0, 1]
                    }
                    break

                case 'texture':
                    nextObject.settings = {
                        value: 'https://i.ibb.co/c1TpRZV/1572778667-3.jpg',
                        hue: 0,
                        saturation: 100,
                        brightness: 100,
                        contrast: 100,
                        invert: false
                    }

                    break
                case 'linear':
                    nextObject.settings = {
                        value: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%)',
                        colors: ['rgba(0,0,0,1)', 'rgba(255,255,255,1)'],
                        stops: [0, 1]
                    }

                    break

                default:
                    break
            }
            History.execute(
                new UpdateMainCommand(editor3D, 'backgroundSettings', editor3D.setBackgroundSettings, nextObject)
            )
        } else if (keyProp === 'envIntensity') {
            const nextObject = _.cloneDeep(editor3D.activeEnvironment) as any

            nextObject.intensity = value

            History.execute(
                new UpdateMainCommand(editor3D, 'activeEnvironment', editor3D.setActiveEnvironment, nextObject)
            )
        } else {
            const nextObject = _.cloneDeep(editor3D.backgroundSettings) as any

            switch (keyProp) {
                case 'color':
                    nextObject.settings.value = value
                    break
                case 'image':
                    nextObject.settings.value = value
                    break
                case 'saturation':
                    nextObject.settings.saturation = value
                    break
                case 'brightness':
                    nextObject.settings.brightness = value
                    break
                case 'contrast':
                    nextObject.settings.contrast = value
                    break
                case 'invert':
                    nextObject.settings.invert = value
                    break
                case 'tilingX':
                    nextObject.transform.tiling[0] = value
                    break
                case 'tilingY':
                    nextObject.transform.tiling[1] = value
                    break
                case 'offsetX':
                    nextObject.transform.offset[0] = value
                    break
                case 'offsetY':
                    nextObject.transform.offset[1] = value
                    break
                case 'rotation':
                    if (value > Math.PI) {
                        value = Math.PI
                    }
                    if (value < -Math.PI) {
                        value = -Math.PI
                    }

                    nextObject.transform.rotation = value
                    break
                case 'resolutionScale':
                    nextObject.transform.resolutionScale = value
                    break

                case 'gradient':
                    ;(nextObject.settings as any).value = value
                    const gradientData = transformLinearGradient(value)
                    // Object.assign(nextObject.settings as any, gradientData)
                    nextObject.settings.colors = gradientData.colors
                    nextObject.settings.stops = gradientData.stops

                    break
            }
            History.execute(
                new UpdateMainCommand(editor3D, 'backgroundSettings', editor3D.setBackgroundSettings, nextObject)
            )
        }

        return
    }

    if (keyProp === 'camera') {
        const camera = editor3D.getCurrentConfigObj(activeCamera)

        if (!isCamera(camera)) return

        switch (keyValue) {
            case 'focalLength':
            case 'bokehScale':
            case 'focusDistance':
                History.execute(new SetValueCommand(editor3D, camera, value, ['DOFParameters', keyValue]))
                break
            case 'focusDistanceMouse':
                editor3D.callSetFocusOnClick()
                break

            case 'dof':
                History.execute(new SetValueCommand(editor3D, camera, value, ['DOFEnabled']))

                break
        }
    }
    if (key === 'change') {
        const id = value.split('_')[0]
        const cameraNode = editor3D.getMeshById(id)
        if (!cameraNode) return

        History.execute(new UpdateMainCommand(editor3D, 'activeCamera', editor3D.changeCamera, cameraNode))
        editor3D.unselect()
    }
    if (keyProp === 'target') {
        const camera = editor3D.getCurrentConfigObj(editor3D.activeCamera)
        const target = editor3D.getMeshById(value.object)
        const newTargetPosition = target?.position.clone() || new THREE.Vector3(0, 0, 0)

        if (!isCamera(camera)) return

        const newValue = {
            object: value.object,
            position: newTargetPosition.clone()
        }
        const oldValue = {
            object: camera.target.object,
            position: camera.target.position.clone()
        }

        History.execute(new SetValueCommand(editor3D, camera, newValue, ['target'], oldValue))
    }
}

export { sceneActions }

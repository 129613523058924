import { Col, Divider, Row, TreeDataNode } from 'antd'
import { DataNode } from 'antd/lib/tree'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import {
    AMAMeshType,
    AMAMode,
    AMAObjectTree,
    AMAUtilsEditor,
    EventKeyDispatcher,
    customCameras,
    customLights,
    customObjects,
    isCustomShapes,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import AssetEditorProps, { TAssetEditorProp, TAssetEditorSection } from '../assetEditorProps'
import AssetEditorTree from '../assetEditorTree'
import { TAsset } from '../../../../types'
import AssetEditorActions from '../assetEditorActions'
import { useGetAccessTokenQuery } from '../../../../api/authApi'
import { currentGeometryMesh } from './editorProps/geometry.props'
import { currentObjectsData } from './editorProps/object.props'
import { currentMaterialMesh } from './editorProps/material.props'
import { sceneProps } from './editorProps/scene.props'
import { meshActions } from './editorActions/mesh.actions'
import { actionsHandlers, actionsUtils } from './editorActions/utils.actions'
import {
    useCreateAssetMutation,
    useDeleteAssetAnnotationsMutation,
    useDeleteAssetMutation,
    useUpdateAssetMutation
} from '../../../../api/assetApi'
import { pxToEm } from '../../../../theme/stylesMixins'
import {
    AlignCenterOutlined,
    BulbOutlined,
    CodeSandboxOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    NodeIndexOutlined,
    PlusOutlined,
    SettingOutlined,
    UploadOutlined,
    VideoCameraOutlined
} from '@ant-design/icons'
import { AssetsForAttributes } from '../assetEditor'
import AssetEditorAssets from '../assetEditorAssets'
import { sceneActions } from './editorActions/scene.actions'

type TAssetEditor3D = {
    asset: TAsset
    leftMenu: 'nodes' | JSX.Element
    rightMenu: 'properties' | JSX.Element
    mode: AMAMode
    allAssets?: AssetsForAttributes
    propsAreaOpened?: boolean
}

export const ENV_LIST = {
    none: '',
    MountainLandscape: 'https://amaspace-dev.s3.amazonaws.com/env_orig.jpg',
    jeep_env: 'https://amaspace-dev.s3.amazonaws.com/client777/jeep_env2.jpg',
    cadillac_env: 'https://amaspace-dev.s3.amazonaws.com/client777/cadillac_env3.jpg'
} as const

type EditorMenuType = {
    tree: boolean
    props: boolean
    assets: boolean
}
const AssetEditor3D = ({ asset, rightMenu, leftMenu, allAssets, propsAreaOpened }: TAssetEditor3D): JSX.Element => {
    const editor3D = utilsEditor()
    const newLights = customLights()
    const newItems = customObjects()
    const newCameras = customCameras()

    const editor3DStateRef = useRef(editor3D)

    useEffect(() => {
        editor3DStateRef.current = editor3D
    }, [editor3D])

    const [treeNodes, setTreeNodes] = useState<DataNode[]>([])
    const [windowVisibility, setWindowVisibility] = useState<EditorMenuType>({
        tree: false,
        props: false,
        assets: false
    })
    // const { data: authToken } = useGetAccessTokenQuery(null)
    const [updateAssetRequest] = useUpdateAssetMutation()
    const [deleteAnnotations] = useDeleteAssetAnnotationsMutation()
    const [createAsset] = useCreateAssetMutation()
    const [deleteAssetRequest] = useDeleteAssetMutation()

    const countStep = useRef<number>(0)
    const keyBinding = useMemo(() => new EventKeyDispatcher(), [])

    useMemo(() => {
        if (editor3D?.sceneTree) {
            const final: DataNode[] = [...editor3D.sceneTree].map((item, inx) => checkTree(item, inx))
            setTreeNodes(final)
        }
    }, [editor3D.sceneTree])

    const resetModel = async () => {
        const newModelData = editor3D.resetModel()
        if (!newModelData) return

        const annotationIds = newModelData.annotations.map(el => el.id)

        await toast.promise(
            Promise.all([
                updateAssetRequest({
                    id: asset.id,
                    asset: newModelData.model as any
                }),
                annotationIds.length ? deleteAnnotations(annotationIds) : null
            ]),
            {
                loading: 'Reset model...',
                success: 'Model reset!',
                error: 'Error when reseting!'
            }
        )
    }
    const removeCustomNode = (editor3D: AMAUtilsEditor) => {
        if (!editor3D.activeMesh) return
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (isCustomShapes(current)) {
            deleteAssetRequest({ id: current.id })
        }
    }
    const removeItem = () => {
        removeCustomNode(editor3D)
        editor3D.deleteModelPart(editor3D.activeMesh?.userData.id)
        editor3D.runUpdateTree({})
    }
    const handleTreeSelect = (selectedKeys: React.Key[], info: any) => {
        editor3D.setActiveById(info.node.id)
    }
    function renderIcon(type: AMAMeshType, expanded?: boolean) {
        switch (type) {
            case AMAMeshType.ViewportCamera:
            case AMAMeshType.Default_Camera:
            case AMAMeshType.PerspectiveCamera:
            case AMAMeshType.ConfigurationCamera:
                return <VideoCameraOutlined />
            case AMAMeshType.Directional_Light:
            case AMAMeshType.Spot_Light:
            case AMAMeshType.Hemisphere_Light:
            case AMAMeshType.Point_Light:
                return <BulbOutlined />
            case AMAMeshType.Box:
            case AMAMeshType.Sphere:
            case AMAMeshType.Plane:
                return <CodeSandboxOutlined />
            case AMAMeshType.Target:
                return <PlusOutlined />
            case AMAMeshType.Object3D:
            case AMAMeshType.Mesh:
            case AMAMeshType.Bone:
            case AMAMeshType.SkinnedMesh:
                return <NodeIndexOutlined />
            case AMAMeshType.Group:
            case AMAMeshType.Model_Group:
                return expanded ? <FolderOpenOutlined /> : <FolderOutlined />
            default:
                return null
        }
    }
    function checkTree(object: AMAObjectTree, inx: number): TreeDataNode & { id: string } {
        let step
        countStep.current++

        if (countStep.current) {
            step = new Array(countStep.current).fill('0')
        }
        const final = step?.join('-')
        return {
            title: object.object.name,
            key: `${final}-${inx}`,
            id: object.object.userData.id,
            children: object.children.map((item, inx) => checkTree(item, inx)),
            icon: ({ expanded }) => renderIcon(object.object.type, expanded)
        }
    }

    const propChange = (key: string, value: TAssetEditorProp['value']) => {
        meshActions(key, value, editor3D)
    }

    const defaultConfigScene = (): TAssetEditorSection[] => {
        return sceneProps(editor3D)
    }

    const dummyProps = (): TAssetEditorSection[] => {
        return [
            {
                name: 'Object',
                props: [...currentObjectsData(editor3D)]
            },
            {
                name: 'Geometry',
                props: [...currentGeometryMesh(editor3D)]
            },
            {
                name: 'Material',
                props: [...currentMaterialMesh(editor3D)]
            }
        ]
    }

    const configSceneProps = (key: string, value: TAssetEditorProp['value']) => {
        sceneActions(key, value, editor3DStateRef.current!)
    }

    const processActionUtils = (key: string) => {
        const action = actionsHandlers({
            newItems,
            newCameras,
            newLights,
            editor3D,
            customActions: { resetModel, removeItem },
            createAsset
        })

        if (!action) return
        const actionCall = action[key as keyof typeof action]

        if (actionCall) {
            actionCall()
        } else {
            console.log('Action', key, 'is not implemented')
        }
    }

    const handleToggleMenu = (menuName: keyof EditorMenuType) => {
        setWindowVisibility(prevState => ({ ...prevState, [menuName]: !prevState[menuName] }))
    }
    useEffect(() => {
        keyBinding.addBinding(
            'Delete',
            (event, editor) => {
                removeCustomNode(editor)
            },
            'deleteCustom',
            editor3D
        )
    }, [editor3D.activeMesh])

    useEffect(() => {
        setWindowVisibility(prevState => ({ ...prevState, props: Boolean(propsAreaOpened) }))
    }, [propsAreaOpened])

    return (
        <>
            <Row>
                <AssetEditorActions actions={actionsUtils} handler={processActionUtils} />
                <Divider />
            </Row>
            <StyledWrapper className={'editor_wrapper'}>
                <StyledLeftMenu span={7} visible={windowVisibility.tree}>
                    <StyledTreeBtn onClick={() => handleToggleMenu('tree')}>
                        <AlignCenterOutlined />
                    </StyledTreeBtn>

                    <StyledTreeWrapper className={'editor_menu'}>
                        {leftMenu === 'nodes' && treeNodes.length ? (
                            <AssetEditorTree
                                key={treeNodes.length}
                                draggable={true}
                                nodes={treeNodes}
                                onSelect={handleTreeSelect}
                            />
                        ) : (
                            leftMenu
                        )}
                    </StyledTreeWrapper>
                </StyledLeftMenu>
                <StyledRightMenu span={6} visible={windowVisibility.props}>
                    <StyledPropsBtn onClick={() => handleToggleMenu('props')}>
                        <SettingOutlined />
                    </StyledPropsBtn>

                    <StyledPropsWrapper className={'editor_menu'}>
                        {rightMenu === 'properties' ? (
                            editor3D.activeMesh ? (
                                <AssetEditorProps asset={asset} sectionsList={dummyProps()} onPropChange={propChange} />
                            ) : (
                                <AssetEditorProps sectionsList={defaultConfigScene()} onPropChange={configSceneProps} />
                            )
                        ) : (
                            rightMenu
                        )}
                    </StyledPropsWrapper>
                </StyledRightMenu>
                <StyledBottomMenu visible={windowVisibility.assets}>
                    <StyledAssetsBtn onClick={() => handleToggleMenu('assets')}>
                        <UploadOutlined />
                    </StyledAssetsBtn>
                    <AssetEditorAssets allAssets={allAssets} />
                </StyledBottomMenu>
            </StyledWrapper>
        </>
    )
}

export default AssetEditor3D

const StyledLeftMenu = styled(Col)<{ visible?: boolean }>`
    height: 100%;

    background-color: rgb(178 178 178 / 62%);
    backdrop-filter: blur(${pxToEm(5)});
    border-right: 1px solid rgba(0, 0, 0, 0.06);

    transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.6s cubic-bezier(0.32, 0, 0.67, 0);

    z-index: 1;
`
const StyledRightMenu = styled(Col)<{ visible?: boolean }>`
    height: 100%;

    background-color: rgb(178 178 178 / 62%);
    backdrop-filter: blur(${pxToEm(5)});
    border-right: 1px solid rgba(0, 0, 0, 0.06);

    transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.6s cubic-bezier(0.32, 0, 0.67, 0);

    z-index: 1;
`
const StyledBottomMenu = styled(Col)<{ visible?: boolean }>`
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 300px;

    background-color: rgb(178 178 178 / 62%);
    backdrop-filter: blur(${pxToEm(5)});
    border-right: 1px solid rgba(0, 0, 0, 0.06);

    transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(100%)')};
    transition: transform 0.6s cubic-bezier(0.32, 0, 0.67, 0);

    z-index: 1;
`
const StyledBtn = styled.div`
    position: absolute;
    top: 5%;

    font-size: ${pxToEm(20)};

    z-index: 9999;
    cursor: pointer;

    & > span {
        padding: ${pxToEm(5)};

        border: 1px solid rgba(0, 0, 0, 0.6);
        border-radius: ${pxToEm(5)};
        background-color: rgb(178 178 178 / 62%);
        backdrop-filter: blur(${pxToEm(5)});

        transition: background-color 0.3s cubic-bezier(0.32, 0, 0.67, 0);
    }
    &:hover > span {
        background-color: rgb(178 178 178 / 100%);
    }
`
const StyledTreeBtn = styled(StyledBtn)`
    right: -13%;
`
const StyledPropsBtn = styled(StyledBtn)`
    left: -13%;
`
const StyledAssetsBtn = styled(StyledBtn)`
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
`
const StyledWrapper = styled(Row)`
    height: calc(100vh - ${pxToEm(380)});
    justify-content: space-between;
    margin-top: ${pxToEm(-27)};
`
const StyledPropsWrapper = styled(Col)<{ visible?: boolean }>`
    height: 100%;
    padding: ${pxToEm(15)};
`
const StyledTreeWrapper = styled(Col)`
    height: 100%;
    padding: ${pxToEm(15)};

    overflow: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #646667;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #383838;
    }
`

import { ProductAttributeType } from '../../tabs/ProductAttributes'
import {Input, InputNumber, Select} from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import {isObject} from "underscore";

type ProductAttributeValueItemProps = {
    attr: ProductAttributeType
    type: 'input' | 'select' | 'number'
    field?: 'product_id' | 'min' | 'max' | 'default_value'
}

const ProductAttributeValueItem = ({ attr, type, field = 'min' }: ProductAttributeValueItemProps) => {
    const { attributes, setAttributes } = useContext(CatalogContext)

    const handleChangeValue: HandleChangeAttributeValue = value => {
        if (type === 'number') {
            setAttributes(
                  attributes.map(item => {

                      if (field === 'default_value') {
                          return ({
                              ...item,
                              default_value: item.id === attr.id ? value.toString() : item.default_value,
                              status: item.id === attr.id ? 'changed' : item.status
                          })
                      }

                      return ({
                          ...item,
                          value: (item.id === attr.id && isObject(item.value)) ? {...item.value, [field]: value} : item.value,
                          status: item.id === attr.id ? 'changed' : item.status
                      })
                  })
            )
        } else {
            setAttributes(
                  attributes.map(item => ({
                      ...item,
                      value: item.id === attr.id ? value.toString() : item.value,
                      status: item.id === attr.id ? 'changed' : item.status
                  }))
            )
        }
    }

    switch (type) {
        case 'input':
            return (
                <Input
                    maxLength={40}
                    placeholder="Value"
                    value={attr.value as string}
                    onChange={e => handleChangeValue(e.target.value)}
                />
            )
        case 'number':
            return (
              <InputNumber
                maxLength={40}
                placeholder={field}
                value={field === 'default_value' ? attr.default_value : (attr.value as {min: number; max: number; product_id: number})[field] as number}
                onChange={v => v ? handleChangeValue(v) : handleChangeValue(0)}
              />
            )
        case 'select':
            return (
                <Select
                    mode="tags"
                    maxTagTextLength={20}
                    maxTagCount="responsive"
                    placeholder="Values"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={(attr.value as string[]) ?? []}
                    onChange={handleChangeValue}
                />
            )
    }
}

export default ProductAttributeValueItem

type HandleChangeAttributeValue = (value: string | string[] | number) => void

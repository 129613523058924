import { AMADefaultSceneConfig, isCamera, utilsEditor } from '@amaspace-editor/editor-3d'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Row, Typography } from 'antd'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import Utils from 'utils'
import { useUpdateAssetAnnotationMutation, useUpdateAssetMutation } from '../../../api/assetApi'
import { modelDeleteConnections, modelNewConnections } from '../../../slices/modelSlice'
import { TAsset } from '../../../types'

type TopPanelProps = {
    asset: TAsset
}

const EditorTopPanel = ({ asset }: TopPanelProps) => {
    const editor3D = utilsEditor()
    const modelNewConnection = useSelector(modelNewConnections)
    const modelDeleteConnection = useSelector(modelDeleteConnections)

    const [updateAssetRequest] = useUpdateAssetMutation()
    const [updateAnnotation] = useUpdateAssetAnnotationMutation()

    const handleChangeCamera = () => {
        const defaultCamera = editor3D.config.customObjects.find(x => x.type === 'Default_Camera')
        const cameraConfig = editor3D.getCurrentConfigObj(editor3D.getCamera())
        if (!isCamera(defaultCamera)) return

        editor3D.setDefaultCameraPosition({
            cameraId: cameraConfig!.id,
            cameraPos: defaultCamera!.position.clone(),
            cameraTarget: defaultCamera!.target!.position!.clone()
        } as any)
    }
    const handleSave = async () => {
        handleChangeCamera()
        const newModelConfig = await editor3D.saveConfig()

        if (!newModelConfig) return

        const defaultSceneConfig: AMADefaultSceneConfig = {
            grid: editor3D.showGrid,
            backgroundColor: editor3D.backgroundColor,
            cameraPosition: editor3D.defaultCameraPosition,
            animateCamera: editor3D.animateCamera,
            activeEnvironment: editor3D.activeEnvironment,
            postProcessing: editor3D.postProcessingList,
            sceneRoom: editor3D.sceneRoom,
            contactShadow: editor3D.contactShadow as any,
            backgroundSettings: editor3D.backgroundSettings,
            softShadow: editor3D.softShadow,
            fog: editor3D.fog,
            focalOffset: editor3D.focalOffset
        }

        const preview = new Promise((resolve, reject) => {
            editor3D.unselect()
            setTimeout(() => {
                const name = asset.name.replace(/[^a-zA-Z_]/g, '')
                resolve(Utils.generateAndUploadThumbnail(name, editor3D.getScreenShot() || '', true))
            }, 100)
        })

        await toast.promise(preview, {
            loading: 'Updating preview...',
            success: 'Preview updated!',
            error: 'Error when updating preview!'
        })

        const model = {
            ...newModelConfig.model,
            connections: Object.values(modelNewConnection).flat(),
            connections_delete: Object.values(modelDeleteConnection).flat(),
            patch: {
                ...newModelConfig.model.patch,
                config: {
                    ...newModelConfig.model.patch.config,
                    defaultSceneConfig: defaultSceneConfig
                }
            },
            preview: await preview
        }

        const fixInfinity = JSON.stringify(model, function (key, value) {
            return value === Infinity || value === -Infinity ? value.toString() : value
        })
        const final = JSON.parse(fixInfinity)

        await toast.promise(
            Promise.all([
                updateAssetRequest({
                    id: asset!.id,
                    asset: final
                }),
                newModelConfig.annotations.map(async item => {
                    return await updateAnnotation(item as any)
                })
            ]),
            {
                loading: 'Updating config...',
                success: 'Config updated!',
                error: 'Error when updating!'
            }
        )
    }

    return (
        <Row justify="space-between" style={{ marginBottom: 24 }} align="middle">
            <Col span={12}>
                <Typography.Title level={4}>
                    <span>{asset.type}</span>
                    <Divider type="vertical"></Divider>
                    <span>{asset.name}</span>
                </Typography.Title>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
                <Button onClick={handleSave} icon={<SaveOutlined />}></Button>
            </Col>
        </Row>
    )
}

export default EditorTopPanel

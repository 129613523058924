import api from './index'
import { ProductAttributeType } from '../pages/products/components/tabs/ProductAttributes'
import { ProductRuleType } from '../pages/products/components/tabs/ProductRules'
import { TableMetaType } from '../components/ExtendedTable'
import { SortStatusType } from '../hooks/useTableFilter'
import { ProductType } from '../providers/CatalogProvider'

const DEALER_PRODUCT = 'dealer-product'
const DEALER_PRODUCT_ATTRIBUTE = `dealer-product-attribute`
const DEALER_PRODUCT_RULE = `dealer-product-rule`

const dealerCatalogApi = api.injectEndpoints({
    endpoints: build => ({
        getDealerProducts: build.query<GetDealerProductsType, DealerProductFilter>({
            query: body => {
                const {
                    page,
                    name,
                    name_sort,
                    date_created_range_start,
                    date_created_range_end,
                    date_range_created_sort,
                    date_updated_range_start,
                    date_updated_range_end,
                    date_range_updated_sort,
                    tags,
                    license_id,
                    product_group_id,
                } = body

                const params = new URLSearchParams({
                    page: page.toString(),
                    license_id: license_id ?? '',
                    product_group_id: product_group_id ?? '',
                    ...(name ? { name } : {}),
                    ...(date_created_range_start ? { date_created_range_start } : {}),
                    ...(date_created_range_end ? { date_created_range_end } : {}),
                    ...(date_updated_range_start ? { date_updated_range_start } : {}),
                    ...(date_updated_range_end ? { date_updated_range_end } : {})
                })

                params.set('name_sort', name_sort.toString())
                params.set('date_range_created_sort', date_range_created_sort)
                params.set('date_range_updated_sort', date_range_updated_sort)

                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''

                return `${DEALER_PRODUCT}?${params}${tagsValue}`
            }
        }),
        getDealerLicenses: build.query<GetDealerLicensesProductsType, DealerLicenseProductFilter>({
            query: body => {
                const { page, name, name_sort } = body

                const params = new URLSearchParams({
                    page: page.toString(),
                    ...(name ? { name } : {})
                })

                params.set('name_sort', name_sort)

                return `${DEALER_PRODUCT}/license?${params}`
            }
        }),

        getDealerProductList: build.mutation<GetDealerProductsType, Pick<DealerProductFilter, 'page' | 'tags'>>({
            query: body => {
                const { tags, page } = body
                const params = new URLSearchParams({ page: page.toString() })
                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''

                return `${DEALER_PRODUCT}?${params}${tagsValue}`
            }
        }),
        getDealerProductAndPCOList: build.mutation<GetDealerProductsType, Pick<DealerProductFilter, 'page' | 'tags'>>({
            query: body => {
                const { tags, page } = body
                const params = new URLSearchParams({ page: page.toString() })
                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''

                return `${DEALER_PRODUCT}/select?${params}${tagsValue}`
            }
        }),
        getDealerArchivedProducts: build.query<GetDealerProductsType, DealerProductFilter>({
            query: body => {
                const {
                    page,
                    name,
                    name_sort,
                    date_created_range_start,
                    date_created_range_end,
                    date_range_created_sort,
                    date_updated_range_start,
                    date_updated_range_end,
                    date_range_updated_sort,
                    tags
                } = body

                const params = new URLSearchParams({
                    page: page.toString(),
                    ...(name ? { name } : {}),
                    ...(date_created_range_start ? { date_created_range_start } : {}),
                    ...(date_created_range_end ? { date_created_range_end } : {}),
                    ...(date_updated_range_start ? { date_updated_range_start } : {}),
                    ...(date_updated_range_end ? { date_updated_range_end } : {})
                })

                params.set('name_sort', name_sort)
                params.set('date_range_created_sort', date_range_created_sort)
                params.set('date_range_updated_sort', date_range_updated_sort)

                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''

                return `${DEALER_PRODUCT}/archive?${params}${tagsValue}`
            }
        }),

        getDealerProduct: build.mutation<ProductType, { id: string }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id })
                return `${DEALER_PRODUCT}/view-one?${params}`
            }
        }),
        createDealerProduct: build.mutation<{id: number}, ProductType>({
            query: body => {
                const { price, ...newBody } = body
                return {
                    url: DEALER_PRODUCT,
                    method: 'POST',
                    body: { ...newBody }
                }
            }
        }),
        updateDealerProduct: build.mutation<boolean | string, ProductType>({
            query: body => {
                const { id, ...newBody } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${DEALER_PRODUCT}?${params}`,
                    method: 'PUT',
                    body: { ...newBody }
                }
            }
        }),
        deleteDealerProduct: build.mutation<null, string[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${DEALER_PRODUCT}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getDealerProductCategories: build.mutation<ProductAttributeType[], { id: string }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ product_id: id, show_category: '1' })

                return `${DEALER_PRODUCT_ATTRIBUTE}?${params}`
            }
        }),

        getDealerProductAttributes: build.mutation<ProductAttributeType[], { id: string }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ product_id: id })

                return `${DEALER_PRODUCT_ATTRIBUTE}?${params}`
            }
        }),
        createDealerProductAttribute: build.mutation<null, ProductAttributeType>({
            query: body => {
                const { id, ...newBody } = body

                return {
                    url: `${DEALER_PRODUCT_ATTRIBUTE}`,
                    method: 'POST',
                    body: newBody
                }
            }
        }),
        updateDealerProductAttribute: build.mutation<boolean | string, ProductAttributeType>({
            query: body => {
                const { id, ...attribute } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${DEALER_PRODUCT_ATTRIBUTE}?${params}`,
                    method: 'PUT',
                    body: attribute
                }
            }
        }),
        deleteDealerProductAttributes: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${DEALER_PRODUCT_ATTRIBUTE}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getDealerProductRules: build.mutation<ProductRuleType[], { id: string }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id })

                return `${DEALER_PRODUCT_RULE}?${params}`
            },
            transformResponse: (response: ProductRuleType[]) => {
                return response.map(rule => ({
                    ...rule,
                    action: rule.action.map(action => {
                        return { ...action, attribute_id: action.dealer_attribute_id! }
                    }),
                    condition: rule.condition.map(condition => ({
                        ...condition,
                        attribute_id: condition.dealer_attribute_id!
                    }))
                }))
            }
        }),
        createDealerProductRule: build.mutation<null, ProductRuleType>({
            query: body => {
                const { id, ...otherFields } = body

                const filteredAction = otherFields.action.map(({ newAction, id, attribute_id, ...item }) =>
                    newAction
                        ? { ...item, dealer_attribute_id: attribute_id }
                        : { ...item, id, dealer_attribute_id: attribute_id }
                )
                const filteredCondition = otherFields.condition.map(({ newCondition, id, attribute_id, ...item }) =>
                    newCondition
                        ? { ...item, dealer_attribute_id: attribute_id }
                        : { ...item, id, dealer_attribute_id: attribute_id }
                )

                const newBody = { ...otherFields, action: filteredAction, condition: filteredCondition }

                return {
                    url: DEALER_PRODUCT_RULE,
                    method: 'POST',
                    body: { ...newBody, dealer_product_id: otherFields.product_id }
                }
            }
        }),
        updateDealerProductRule: build.mutation<null, ProductRuleType>({
            query: body => {
                const { product_id, id, action, condition } = body

                const filteredAction = action.map(({ newAction, id, attribute_id, ...item }) =>
                    newAction
                        ? { ...item, dealer_attribute_id: attribute_id }
                        : { ...item, id, dealer_attribute_id: attribute_id }
                )
                const filteredCondition = condition.map(({ newCondition, id, attribute_id, ...item }) =>
                    newCondition
                        ? { ...item, dealer_attribute_id: attribute_id }
                        : { ...item, id, dealer_attribute_id: attribute_id }
                )

                const newBody = { ...body, action: filteredAction, condition: filteredCondition }

                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${DEALER_PRODUCT_RULE}?${params}`,
                    method: 'PUT',
                    body: { ...newBody, dealer_product_id: product_id }
                }
            }
        }),
        deleteDealerProductRules: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${DEALER_PRODUCT_RULE}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getDealerProductTagsAll: build.query<ProductTagType[], null>({
            query: () => `dealer-tags-all`
        }),

        getDealerProductTags: build.query<ProductTagType[], null>({
            query: () => `dealer-tags`
        })
    }),
    overrideExisting: false
})

export const {
    useGetDealerProductsQuery,
    useGetDealerProductListMutation,
    useGetDealerProductTagsAllQuery,
    useGetDealerArchivedProductsQuery,
    useGetDealerProductMutation,
    useGetDealerProductAndPCOListMutation,
    useCreateDealerProductMutation,
    useUpdateDealerProductMutation,
    useGetDealerProductAttributesMutation,
    useGetDealerProductCategoriesMutation,
    useCreateDealerProductAttributeMutation,
    useDeleteDealerProductAttributesMutation,
    useUpdateDealerProductAttributeMutation,
    useGetDealerProductTagsQuery,
    useGetDealerLicensesQuery,

    useGetDealerProductRulesMutation,
    useCreateDealerProductRuleMutation,
    useUpdateDealerProductRuleMutation,
    useDeleteDealerProductRulesMutation,
    useDeleteDealerProductMutation
} = dealerCatalogApi

export default dealerCatalogApi

export type GetDealerProductsType = {
    products: ProductType[]
    _meta: TableMetaType
}

export type GetDealerLicensesProductsType = {
    license: LicenseProductType[]
    _meta: TableMetaType
}

export type LicenseProductType = {
    name: string
    type: number
}

export type DealerLicenseProductFilter = {
    page: number
    name: string
    name_sort: string
}

export type ProductTagType = { id: number; name: string }

export type DealerProductFilter = {
    page: number
    name: string
    name_sort: string
    license_id?: string
    product_group_id?: string

    date_created_range_start: string
    date_created_range_end: string
    date_range_created_sort: string

    date_updated_range_start: string
    date_updated_range_end: string
    date_range_updated_sort: string

    tags: string[]
}

import { Form, Select, Spin } from 'antd'
import React, { useContext } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'

const ProductTagsItem = () => {
    const { product, setProduct, requests } = useContext(CatalogContext)

    const { productTags, isLoadingProductTags } = requests

    const handleAddTags = (tags: string[]) => {
        setProduct({ ...product, tags })
    }

    return (
        <Form.Item label="Tags">
            {isLoadingProductTags ? (
                <Spin size="small" />
            ) : (
                <Select
                    mode="tags"
                    maxTagTextLength={20}
                    maxTagCount="responsive"
                    placeholder="Tags"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={product.tags}
                    onChange={handleAddTags}>
                    {productTags.map(item => {
                        return (
                            <Select.Option key={item.id} value={item.name}>
                                {item.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            )}
        </Form.Item>
    )
}

export default ProductTagsItem

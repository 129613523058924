import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import api from 'api'
import authSlice from 'slices/authSlice'
import assetsSlice from 'slices/assetsSlice'
import userSlice from '../slices/userSlice'
import themeSlice from '../slices/themeSlice'
import modelSlice from '../slices/modelSlice'
import uploadSlice, { addFilesToUpload } from '../slices/uploadSlice'

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [assetsSlice.name]: assetsSlice.reducer,
    [themeSlice.name]: themeSlice.reducer,
    [modelSlice.name]: modelSlice.reducer,
    [uploadSlice.name]: uploadSlice.reducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [authSlice.name]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(api.middleware)
})

export const resetStore = async () => {
    await persistor.purge()
    await persistor.flush()
}

export const persistor = persistStore(store)
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

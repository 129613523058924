import React, { Dispatch, SetStateAction, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { ActivatedLicensePriceType, useGetTypeDebitQuery } from '../../../api/licenseApi'
import { useGetCurrencyQuery } from '../../../api/priceApi'

type ActivatedLicensePriceFormProps = {
    form: FormInstance<ActivatedLicensePriceType>
    onFinish: (e: ActivatedLicensePriceType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ActivatedLicensePriceForm = ({ form, refetch, setIsModalOpen, onFinish }: ActivatedLicensePriceFormProps) => {
    const { data: debits } = useGetTypeDebitQuery(null)

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
            }}>
            <Form.Item name="dealer_id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name="dealer_license_id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} placeholder="99.99" step="0.01" min={1} stringMode />
            </Form.Item>

            <Form.Item rules={[{ required: true }]} label="Debit Type" name="type_debit">
                <Select showSearch allowClear optionFilterProp="children" placeholder="Debit Type">
                    {debits
                        ? debits.map(debit => {
                              return (
                                  <Select.Option key={debit.id} value={debit.id}>
                                      {debit.name}
                                  </Select.Option>
                              )
                          })
                        : null}
                </Select>
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea placeholder="Description" maxLength={200} />
            </Form.Item>
        </Form>
    )
}

export default ActivatedLicensePriceForm

import React, { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { Form, FormInstance, Input } from 'antd'
import { UserPasswordInfoType, useUpdatePasswordMutation } from '../../api/userApi'
import { changeAccessToken } from '../../slices/authSlice'
import { useAppDispatch } from '../../hooks'
import ModalWithForm from '../users/ModalWithForm'

type AccountPasswordFormProps = {
    form: FormInstance<UserPasswordInfoType>
    isModalOpen: boolean
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const AccountPasswordForm = ({ form, isModalOpen, setIsModalOpen }: AccountPasswordFormProps) => {
    const dispatch = useAppDispatch()

    const [updatePassword, resultUpdatePassword] = useUpdatePasswordMutation()

    return (
        <ModalWithForm
            title={'Change Password'}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isLoading={resultUpdatePassword.isLoading}
            submit={form.submit}>
            <Form
                validateMessages={{ required: 'Field is required!' }}
                layout="vertical"
                form={form}
                onFinish={async e => {
                    const loader = toast.loading('Progressing...')
                    const res = await updatePassword(e)
                    toast.dismiss(loader)
                    if ('error' in res) return
                    toast.success('Password is successfully changed!')
                    dispatch(changeAccessToken(res.data.accessToken))
                    setIsModalOpen(false)
                    form.resetFields()
                }}>
                <Form.Item label="Current Password" name="password_recovery" rules={[{ required: true }]}>
                    <Input.Password maxLength={40} placeholder="Current Password" />
                </Form.Item>

                <Form.Item label="New Password" name="new_password" rules={[{ required: true }]}>
                    <Input.Password maxLength={40} placeholder="New Password" />
                </Form.Item>
            </Form>
        </ModalWithForm>
    )
}

export default AccountPasswordForm

import React, { Dispatch, SetStateAction } from 'react'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { APOPriceType } from '../../../api/amaProductOwnershipApi'
import toast from 'react-hot-toast'
import { useGetTypeDebitQuery } from '../../../api/licenseApi'

type ApoPriceFormProps = {
    form: FormInstance<APOPriceType>
    onFinish: (e: APOPriceType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ApoPriceForm = ({ form, refetch, setIsModalOpen, onFinish }: ApoPriceFormProps) => {
    const { data: typeDebitData } = useGetTypeDebitQuery(null)

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('APO price is successfully updated!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="apo_id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} placeholder="99.99" step="0.01" min={1} stringMode />
            </Form.Item>

            <Form.Item rules={[{ required: true }]} label="Debit Type" name="type_debit">
                <Select
                    options={
                        typeDebitData
                            ? typeDebitData.map(item => ({
                                  value: item.id,
                                  label: item.name
                              }))
                            : []
                    }
                />
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea maxLength={200} />
            </Form.Item>
        </Form>
    )
}

export default ApoPriceForm

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Select, Tooltip } from 'antd'
import { isArray, isObject } from 'underscore'
import { CatalogContext, GetProductsByTags, ProductType } from '../../../../../providers/CatalogProvider'
import { ProductAttributeType, ProductsAndTagsType } from '../../tabs/ProductAttributes'

type ProductAttributeProductsTagsItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeProductsTagsItem = ({ attr }: ProductAttributeProductsTagsItemProps) => {
    const { requests } = useContext(CatalogContext)
    const { products, productTags, getProductsByTags } = requests
    const [selectedProductsIDs, setSelectedProductsIDs] = useState<number[]>([])
    const selectedProducts = useMemo(() => {
        const newSelectedProducts = products.filter(product => selectedProductsIDs.includes(product.id))
        if (newSelectedProducts.length) {
            return newSelectedProducts
        }
        const connections = attr ? attr.connections : []
        if (!connections || !connections.length) {
            return []
        }
        const attributeSelectedProducts = products.filter(product =>
            connections.map(c => c.connection_id).includes(product.id)
        )
        return attributeSelectedProducts
    }, [attr, products, selectedProductsIDs])

    const [isLoading, setIsLoading] = useState(false)
    const { attributes, setAttributes } = useContext(CatalogContext)

    const [defaultProductsOptions, setDefaultProductsOptions] = useState<number[]>([])

    const handleChangeProductsValue: HandleChangeAttributeProductsValue = value => {
        setSelectedProductsIDs(value)
        setAttributes(
            attributes.map(item => {
                const itemValue = item.value as ProductsAndTagsType
                return {
                    ...item,
                    value: item.id === attr.id ? { products: value, tags: itemValue?.tags ?? [] } : item.value,
                    default_value: item.id === attr.id ? null : item.default_value,
                    status: item.id === attr.id ? 'changed' : item.status
                }
            })
        )
    }

    const handleChangeTagsValue: HandleChangeAttributeTagsValue = value => {
        setAttributes(
            attributes.map(item => {
                const itemValue = item.value as ProductsAndTagsType
                return {
                    ...item,
                    value: item.id === attr.id ? { products: itemValue?.products ?? [], tags: value } : item.value,
                    default_value: item.id === attr.id ? null : item.default_value,
                    status: item.id === attr.id ? 'changed' : item.status
                }
            })
        )
    }

    const handleChangeDefaultValue: HandleChangeAttributeDefaultValue = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                default_value: item.id === attr.id ? value : item.default_value,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    useEffect(() => {
        const fetchData = async () => {
            if (typeof attr.value !== 'string' && !isArray(attr.value)) {
                if (!attr.value) return

                let productsOptions = [] as number[]
                let tagsOptions = [] as number[]

                const { products: attrProducts, tags: attrTags } = attr.value as  ProductsAndTagsType

                if (attrProducts && attrProducts.length !== 0) {
                    const filtered = attrProducts.map(item => item)
                    productsOptions = [...filtered]
                }

                if (attrTags && attrTags.length !== 0) {
                    const fetchProducts = async () => {
                        setIsLoading(true)
                        const res = await fetchProductsByTags(getProductsByTags, attrTags)
                        if ('error' in res) {
                            setIsLoading(false)
                            return
                        }
                        const filtered = res.map(item => item.id!)
                        tagsOptions = [...filtered]
                    }

                    await fetchProducts()
                    setIsLoading(false)
                }

                const uniqueArray = [...productsOptions, ...tagsOptions].filter((value, index, self) => {
                    return self.indexOf(value) === index
                })

                setDefaultProductsOptions(uniqueArray)
            }
        }

        fetchData()
    }, [attr.value])

    return (
        <>
            <Tooltip
                placement="right"
                title={
                    selectedProducts.length ? (
                        <ul style={{ margin: 0, padding: '0 0 0 20px' }}>
                            {selectedProducts.map(product => {
                                return <li key={product.name + product.id}>{product.name}</li>
                            })}
                        </ul>
                    ) : null
                }>
                <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="Select Products"
                    value={isObject(attr.value) && 'products' in attr.value ? attr.value.products : []}
                    onChange={handleChangeProductsValue}>
                    {products.map(item => {
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Tooltip>

            <Select
                mode="multiple"
                maxTagCount="responsive"
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Select Tags"
                value={isObject(attr.value) && 'tags' in attr.value ? attr.value.tags : []}
                onChange={handleChangeTagsValue}>
                {productTags.map(tag => (
                    <Select.Option key={tag.id} value={tag.name}>
                        {tag.name}
                    </Select.Option>
                ))}
            </Select>

            <Select
                showSearch
                allowClear
                loading={isLoading}
                optionFilterProp="children"
                placeholder="Select Default Option"
                value={attr.default_value}
                onChange={handleChangeDefaultValue}>
                {defaultProductsOptions.map(item => {
                    const foundProduct = products.find(product => product.id === item)
                    if (!foundProduct) return

                    return (
                        <Select.Option key={foundProduct.id} value={foundProduct.id.toString()}>
                            {foundProduct.name}
                        </Select.Option>
                    )
                })}
            </Select>
        </>
    )
}

export default ProductAttributeProductsTagsItem

type HandleChangeAttributeDefaultValue = (value: string) => void
type HandleChangeAttributeProductsValue = (value: number[]) => void
type HandleChangeAttributeTagsValue = (value: string[]) => void

export const fetchProductsByTags = async (
    getProductsByTags: GetProductsByTags,
    tags: string[]
): Promise<ProductType[]> => {
    let products: ProductType[] = []

    const res = await getProductsByTags({ page: 1, tags })
    if ('error' in res) return products
    products = [...(res.data.products ?? [])]

    for (let i = 1; i < res.data._meta.page_count; i++) {
        const res = await getProductsByTags({ page: i + 1, tags })
        if ('error' in res) return products
        products = [...products, ...(res.data.products ?? [])]
    }

    return products
}

import React, { Dispatch, SetStateAction } from 'react'
import { Select } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'

const { Option } = Select

export type ShareProductType = 'Embed' | 'Iframe' | null

export type CatalogSharProps = {
    shareType: ShareProductType
    setShareType: Dispatch<SetStateAction<ShareProductType>>
}

const ShareProductSelect = ({ setShareType, shareType }: CatalogSharProps) => {
    const changeShareHandler = (type: NonNullable<ShareProductType>) => {
        setShareType(type)
    }

    return (
        <Select
            showSearch
            allowClear
            placeholder="Share"
            suffixIcon={<ShareAltOutlined />}
            onChange={changeShareHandler}
            value={shareType}>
            {shareOptions.map(opt => (
                <Option value={opt.value} key={opt.value}>
                    {opt.value}
                </Option>
            ))}
        </Select>
    )
}

export default ShareProductSelect

const shareOptions: { value: NonNullable<ShareProductType> }[] = [{ value: 'Iframe' }, { value: 'Embed' }]

import React from 'react'
import Invoices from './Invoices'
import { useCreateDealerInvoiceMutation, useGetDealerInvoicesQuery } from '../../api/invoiceApi'

const DealerInvoices = () => {
    return (
        <Invoices
            type="dealer"
            title="Dealer Invoices"
            getInvoices={useGetDealerInvoicesQuery}
            createInvoice={useCreateDealerInvoiceMutation}
        />
    )
}

export default DealerInvoices

import { AMAOption, utilsAnnotation, utilsEditor } from '@amaspace-editor/editor-3d'
import { Slider } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import styles from './OptionNumberOption.module.scss'

interface AMAOptionUpdated extends AMAOption {
    visible: boolean
    enabled: boolean
    tooltip?: string
}

interface IProps {
    option: AMAOption
    updatedData: AMAOptionUpdated
    quantity: number
    minValue: number
    maxValue: number
    isSelected: boolean
    handleOptionClick: (newQuantity: number, isSelected: boolean, option: AMAOption, onComplete: () => void) => void
    setQuantity: React.Dispatch<React.SetStateAction<number>>
}

const OptionNumberOption = ({
    option,
    updatedData,
    quantity,
    minValue,
    maxValue,
    isSelected,
    handleOptionClick,
    setQuantity
}: IProps) => {
    const editorAMA = utilsEditor()
    const annotation = utilsAnnotation()
    const [loading, setLoading] = React.useState(false)
    const product = editorAMA.cache.get('main_product')

    const handleClickRemove = () => {
        if (quantity <= minValue) {
            return
        }
        let result = quantity - 1
        result = result >= minValue ? result : minValue

        if ((result === 1 && !isSelected) || (result === 0 && isSelected)) {
            setLoading(true)
        }

        handleOptionClick(result, isSelected, option, () => setLoading(false))
    }

    const handleClickAdd = () => {
        if (quantity >= maxValue) {
            return
        }
        let result = quantity + 1
        result = result <= maxValue ? result : maxValue

        if ((result === 1 && !isSelected) || (result === 0 && isSelected)) {
            setLoading(true)
        }

        handleOptionClick(result, isSelected, option, () => setLoading(false))
        setQuantity(result <= maxValue ? result : maxValue)
    }
    const debounced = useDebouncedCallback(value => {
        const _value = value / maxValue
        editorAMA.applyMorphTargets(option.name, {
            value: _value
        })
    }, 500)

    const handleSliderChange = (value: number) => {
        if (option.parent === 3766) {
            const _value = value / maxValue

            editorAMA.animationList().forEach(i => {
                if (i.toLowerCase().includes('open')) return
                const anim = editorAMA.getAnimation(i)
                console.log('value', value, _value)

                anim?.play({ time: _value })
            })
            editorAMA.applyMorphTargets('Length accessory', {
                value: _value,
                animate: false
            })
            editorAMA.activeMorphTargets.forEach(target => {
                if (target.key === 'Length' || target.key === 'Length accessory') return
                editorAMA.applyMorphTargets(target.key, {
                    value: target?.options?.value,
                    animate: false
                })
            })
            return
        }
        debounced(value)
    }

    React.useEffect(() => {
        const updateQuantity = () => {
            const history = editorAMA.getCurrent().selectedPCOHistory.map(item => {
                if (item.id === option.id) {
                    return {
                        ...item,
                        updated: {
                            ...item.updated,
                            quantity: quantity
                        }
                    }
                }
                return item
            })

            editorAMA.setSelectedPCOHistory(history)
        }

        if (option && editorAMA.loadingProgress.sceneLoaded) {
            const current = editorAMA.getCurrent().config.annotations.filter(item => item.pco_id === option.id)
            updateQuantity()

            if (!current.length) {
                const object = editorAMA.getMeshById(option.customizer_id)
                if (!object) return
                annotation.setCountAnnotationToObject(object, quantity, option.id)
            } else {
                annotation.updateAnnotationProperty(option.id, {
                    count: quantity
                })
            }
        }
    }, [option, quantity, editorAMA.loadingProgress.sceneLoaded])
    const productsIds = ['parent_1510', 'parent_1643']
    return product && productsIds.includes(product.product.id) ? (
        <div className={styles.component}>
            <div>{option.name}:</div>
            <Slider
                style={{ flex: 1 }}
                min={minValue}
                max={maxValue}
                step={1}
                defaultValue={minValue}
                onChange={handleSliderChange}
            />
        </div>
    ) : (
        <div className={styles.component}>
            <button
                disabled={option.is_hide || !updatedData.enabled}
                className={classNames(
                    styles.component__button,
                    option.is_hide || !updatedData.enabled || quantity <= minValue
                        ? styles.component__button_disabled
                        : null
                )}
                onClick={handleClickRemove}
                type="button">
                <svg width="33" height="4" viewBox="0 0 33 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 1.83594H16.5H32.5" stroke="black" strokeWidth="2.66667" />
                </svg>
            </button>

            <div className={styles.component__text}>
                {loading ? (
                    <div className={styles.loading} />
                ) : (
                    <span>
                        {option.name}
                        {quantity > 0 ? ` x${quantity}` : null}
                    </span>
                )}
            </div>

            <button
                disabled={option.is_hide || !updatedData.enabled}
                className={classNames(
                    styles.component__button,
                    option.is_hide || !updatedData.enabled || quantity >= maxValue
                        ? styles.component__button_disabled
                        : null
                )}
                onClick={handleClickAdd}
                type="button">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.5 16.4588H16.5M32.5 16.4588H16.5M16.5 16.4588V0.835938V32.8359"
                        stroke="black"
                        strokeWidth="2.66667"
                    />
                </svg>
            </button>
        </div>
    )
}

export default OptionNumberOption

import { Card, Form, Row } from 'antd'
import ProductInputItem from '../components/items/ProductInputItem'
import ProductAssetsItem from '../components/items/ProductAssetsItem'
import ProductTypeItem from '../components/items/ProductTypeItem'
import ProductImageItem from '../components/items/ProductImageItem'
import ProductDescriptionItem from '../components/items/ProductDescriptionItem'
import ProductTagsItem from '../components/items/ProductTagsItem'
import ProductKeywordsItem from '../components/items/ProductKeywordsItem'
import ProductCurrenciesItem from '../components/items/ProductCurrenciesItem'
import ProductInfo, { StyledCatalog, StyledCatalogList } from '../components/tabs/ProductInfo'
import ProductColorItem from '../components/items/ProductColorItem'
import ProductMetadata from "../components/metadata/ProductMetadata";
import ProductDiscountItem from "../components/items/ProductDiscountItem";

const CompanyProductInfo = () => {
    return (
        <ProductInfo url="company">
            <Form layout="vertical">
                <StyledCatalog>
                    <StyledCatalogList>
                        <Card style={{ width: '100%' }}>
                            <ProductInputItem required={true} itemKey="name" maxLength={100} />
                            <ProductInputItem itemKey="make" maxLength={40} />
                            <ProductInputItem itemKey="year" maxLength={40} />
                            <ProductInputItem itemKey="model" maxLength={40} />
                            <ProductTypeItem />
                            <ProductDescriptionItem />
                        </Card>

                        <Card style={{ width: '100%' }}>
                            <ProductTagsItem />
                            <ProductKeywordsItem />
                            <ProductAssetsItem />
                            <ProductDiscountItem />
                            <ProductCurrenciesItem />
                            <Row style={{ gap: '10px' }}>
                                <ProductImageItem />
                                <ProductColorItem itemKey="color" />
                            </Row>
                        </Card>
                    </StyledCatalogList>

                    <Card>
                        <ProductMetadata/>
                    </Card>
                </StyledCatalog>
            </Form>
        </ProductInfo>
    )
}

export default CompanyProductInfo

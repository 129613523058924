import api from './index'
import { PAYMENT } from './paymentApi'
import { TableMetaType } from '../components/ExtendedTable'

const LICENSE = 'license'

const LICENSE_DEALER_ACCESS = `${LICENSE}/dealer-access`
const DEALER_LICENSE = `${LICENSE}/dealer-license`

const LICENSE_PRICE = `${LICENSE}/price`
const DEALER_LICENSE_PRICE = `${DEALER_LICENSE}/price`
const LICENSE_TYPE_DEBIT = `${LICENSE}/type-debit`

const licenseApi = api.injectEndpoints({
    endpoints: build => ({
        getLicenses: build.query<GetLicensesType, LicenseFilter>({
            query: body => {
                const { page, name } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name
                })

                return `${LICENSE}?${params}`
            }
        }),
        updateLicense: build.mutation<null, LicenseInfoType>({
            query: body => {
                const { id, ...newBody } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${LICENSE}?${params}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),
        createLicense: build.mutation<null, LicenseInfoType>({
            query: body => {
                return {
                    url: LICENSE,
                    method: 'POST',
                    body
                }
            }
        }),
        deleteLicense: build.mutation<null, { id: number }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${LICENSE}?${params}`,
                    method: 'DELETE'
                }
            }
        }),


        getAvailableLicenses: build.query<GetAvailableLicensesType, AvailableLicenseFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ page: page.toString(), id })

                return `${LICENSE_DEALER_ACCESS}?${params}`
            }
        }),
        deleteAvailableLicense: build.mutation<null, { license_id: number; dealer_id: string }>({
            query: body => {
                const { license_id, dealer_id } = body
                const params = new URLSearchParams({ dealer_id, license_id: license_id.toString() })

                return {
                    url: `${LICENSE_DEALER_ACCESS}?${params}`,
                    method: 'DELETE'
                }
            }
        }),

        getActivatedLicenses: build.query<GetActivatedLicensesType, ActivatedLicenseFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ page: page.toString(), id })

                return `${DEALER_LICENSE}?${params}`
            }
        }),
        createActivatedLicense: build.mutation<CreateActivatedLicenseResponse, { license_id: number }>({
            query: body => ({
                url: DEALER_LICENSE,
                method: 'POST',
                body: body
            })
        }),
        deleteActivatedLicense: build.mutation<null, { id: number }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id.toString() })

                return {
                    url: `${DEALER_LICENSE}?${params}`,
                    method: 'DELETE'
                }
            }
        }),
        updateActivatedLicensePrice: build.mutation<null, ActivatedLicensePriceType>({
            query: body => {
                return {
                    url: DEALER_LICENSE_PRICE,
                    method: 'PUT',
                    body
                }
            }
        }),

        getLicensePrices: build.query<GetLicensePriceType, LicensePriceFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ page: page.toString(), id })

                return `${LICENSE_PRICE}?${params}`
            }
        }),
        createLicensePrice: build.mutation<null, LicensePriceInfoType>({
            query: body => {
                const { license_id, ...newBody } = body
                const params = new URLSearchParams({ id: license_id })

                return {
                    url: `${LICENSE_PRICE}?${params}`,
                    method: 'POST',
                    body: newBody
                }
            }
        }),
        updateLicensePrice: build.mutation<null, LicensePriceInfoType>({
            query: body => {
                const { id, ...newBody } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${LICENSE_PRICE}?${params}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),
        deleteLicensePrice: build.mutation<null, { id: number }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${LICENSE_PRICE}?${params}`,
                    method: 'DELETE'
                }
            }
        }),

        getLicensesBilling: build.query<GetLicensesBillingType, LicenseBillingFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ id: id.toString(), page: page.toString() })

                return `${PAYMENT}/current-billing?${params}`
            }
        }),

        getTypeDebit: build.query<{ id: number; name: string }[], null>({
            query: () => LICENSE_TYPE_DEBIT
        })
    }),
    overrideExisting: false
})

export type LicenseBillingFilter = { page: number; id: number }

export type LicenseBillingType = {
    id: number
    name: string
    price: number
    tax: number
}

export type GetLicensesBillingType = {
    license: LicenseBillingType[]
    price: { subtotal: number; total: number }
    _meta: TableMetaType
}

export type LicenseFilter = {
    page: number
    name: string
}

export type LicenseType = {
    id: number
    name: string
    description: string
    interval: string
    is_show_all: 0 | 1
    company: string
    brand: { id: number; name: string }
    dealer: number[]
    product: { id: number; name: string }[]
}

export type LicenseInfoType = Omit<LicenseType, 'product' | 'interval' | 'brand'> & {
    interval: number
    brand_id: number
    product: number[]
}

export type GetLicensesType = {
    license: LicenseType[]
    _meta: TableMetaType
}

export type AvailableLicenseFilter = {
    id: string
    page: number
}

export type AvailableLicenseType = Pick<LicenseType, 'id' | 'description' | 'name'> & {
    interval: string
    is_show_all: 0 | 1
    price: string
}

export type GetAvailableLicensesType = {
    license: AvailableLicenseType[]
    _meta: TableMetaType
}

export type ActivatedLicenseFilter = {
    id: string
    page: number
}

export type ActivatedLicenseType = {
    id: number
    name: string
    description: string
    current: { price: null | string; currency: null | string }
    next: { price: null | string; currency: null | string }
    price: string
    date_start: string
    date_end: string
    is_can_delete: boolean
    status: string
}

export type GetActivatedLicensesType = {
    license: ActivatedLicenseType[]
    _meta: TableMetaType
}

export type ActivatedLicensePriceType = {
    dealer_id: string
    dealer_license_id: number
    amount: string
    description: string
    type_debit: number
}

export type LicensePriceFilter = {
    id: string
    page: number
}

export type LicensePriceType = {
    id: number
    amount: string
}

export type GetLicensePriceType = {
    license_price: LicensePriceType[]
    _meta: TableMetaType
}

export type CreateActivatedLicenseResponse = null | {url: string}

export type LicensePriceInfoType = {
    id: number
    license_id: string
    amount: string
}

export const {
    useGetLicensesQuery,
    useCreateLicenseMutation,
    useUpdateLicenseMutation,
    useDeleteLicenseMutation,

    useGetAvailableLicensesQuery,
    useDeleteAvailableLicenseMutation,

    useGetLicensePricesQuery,
    useCreateLicensePriceMutation,
    useUpdateLicensePriceMutation,
    useDeleteLicensePriceMutation,

    useGetActivatedLicensesQuery,
    useCreateActivatedLicenseMutation,
    useUpdateActivatedLicensePriceMutation,
    useDeleteActivatedLicenseMutation,

    useGetLicensesBillingQuery,
    useGetTypeDebitQuery
} = licenseApi

export default licenseApi

import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { Button, Modal } from 'antd'

type ModalWithFormProps = {
    title: string
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    children: ReactNode
    isLoading: boolean
    submit: () => void
    footerButton?: ReactNode[]
}

const ModalWithForm = ({ title, children, isOpen, footerButton, setIsOpen, isLoading, submit }: ModalWithFormProps) => {
    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={[
                <Button key="back" onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>,
                footerButton,
                <Button key="submit" type="primary" loading={isLoading} onClick={submit}>
                    Submit
                </Button>
            ]}>
            {children}
        </Modal>
    )
}

export default ModalWithForm

import { Form, FormInstance, Input } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { DealerDomainInfoType } from '../../../api/dealerDomainApi'

type DealerDomainFormProps = {
    form: FormInstance<DealerDomainInfoType>
    onFinish: (e: DealerDomainInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const DealerDomainForm = ({ form, refetch, setIsModalOpen, onFinish }: DealerDomainFormProps) => {
    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={240} placeholder="Name" />
            </Form.Item>
        </Form>
    )
}

export default DealerDomainForm

import React, { useEffect, useState } from 'react'
import { Button, Form, Space, Spin } from 'antd'
import PageBuilder from 'components/panel/PageBuilder'
import { useAppSelector } from 'hooks'
import { DealerInfoType, useGetDealerByIdQuery, useUpdateCurrentDealerMutation } from '../../api/dealerApi'
import DealerForm from '../users/dealer/DealerForm'
import { EditOutlined, LockOutlined } from '@ant-design/icons'
import ModalWithForm from '../users/ModalWithForm'
import AccountPasswordForm from './AccountPasswordForm'
import { useGetUserQuery, UserPasswordInfoType } from '../../api/userApi'
import { useGetCurrencyQuery } from '../../api/priceApi'

const DealerAccount = () => {
    const { id } = useAppSelector(state => state.user)

    const [formUpdate] = Form.useForm<DealerInfoType>()
    const [formUpdatePassword] = Form.useForm<UserPasswordInfoType>()

    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)
    const [isModalOpenChangePassword, setIsModalOpenChangePassword] = useState(false)

    const { data: currencies } = useGetCurrencyQuery(null)
    const { data: dealer, isSuccess, refetch } = useGetDealerByIdQuery({ id })
    const { refetch: refetchUserInfo } = useGetUserQuery(null)
    const [update, resultsUpdate] = useUpdateCurrentDealerMutation()

    useEffect(() => {
        if (dealer) {
            const { dealerInfo, ...otherFields } = dealer
            formUpdate.setFieldsValue({ ...dealerInfo, ...otherFields })
        }
    }, [dealer])

    return (
        <PageBuilder title="Account" documentTitle="Account">
            {isSuccess ? (
                <>
                    <Space style={{ marginBottom: 20 }}>
                        <Button type="primary" onClick={() => setIsModalOpenUpdate(true)}>
                            <EditOutlined /> Edit Info
                        </Button>

                        <Button onClick={() => setIsModalOpenChangePassword(true)} type="primary">
                            <LockOutlined /> Change Password
                        </Button>
                    </Space>

                    <ModalWithForm
                        title="Edit Info"
                        isOpen={isModalOpenUpdate}
                        setIsOpen={setIsModalOpenUpdate}
                        isLoading={resultsUpdate.isLoading}
                        submit={formUpdate.submit}>
                        <DealerForm
                            form={formUpdate}
                            onFinish={update}
                            countryId={dealer.dealerInfo.country_id}
                            onSubmitted={() => {
                                setIsModalOpenUpdate(false)
                                refetch()
                                refetchUserInfo()
                            }}
                            currencies={currencies ?? []}
                            mode="editing"
                        />
                    </ModalWithForm>

                    <AccountPasswordForm
                        isModalOpen={isModalOpenChangePassword}
                        setIsModalOpen={setIsModalOpenChangePassword}
                        form={formUpdatePassword}
                    />
                </>
            ) : (
                <Spin size="small" />
            )}
        </PageBuilder>
    )
}

export default DealerAccount

import React, { useEffect, useState } from 'react'
import { useGetUserQuery, UserInfoType, UserPasswordInfoType } from 'api/userApi'
import { Button, Form, Space } from 'antd'
import PageBuilder from 'components/panel/PageBuilder'
import { useAppSelector } from 'hooks'
import AccountInfoForm from './AccountInfoForm'
import AccountPasswordForm from './AccountPasswordForm'
import { EditOutlined, LockOutlined } from '@ant-design/icons'

const Account = () => {
    const user = useAppSelector(state => state.user)

    const [formChangeUser] = Form.useForm<UserInfoType>()
    const [formChangePassword] = Form.useForm<UserPasswordInfoType>()

    const [isModalOpenEditUser, setIsModalOpenEditUser] = useState(false)
    const [isModalOpenChangePassword, setIsModalOpenChangePassword] = useState(false)

    const { refetch } = useGetUserQuery(null)

    useEffect(() => {
        formChangeUser.setFieldsValue(user)
    }, [user])

    return (
        <PageBuilder title="Account" documentTitle="Account">
            <Space style={{ marginBottom: 20 }}>
                <Button onClick={() => setIsModalOpenEditUser(true)} type="primary">
                    <EditOutlined /> Edit Information
                </Button>

                <Button onClick={() => setIsModalOpenChangePassword(true)} type="primary">
                    <LockOutlined /> Change Password
                </Button>
            </Space>

            <AccountInfoForm
                isModalOpen={isModalOpenEditUser}
                setIsModalOpen={setIsModalOpenEditUser}
                refetch={refetch}
                form={formChangeUser}
            />

            <AccountPasswordForm
                isModalOpen={isModalOpenChangePassword}
                setIsModalOpen={setIsModalOpenChangePassword}
                form={formChangePassword}
            />
        </PageBuilder>
    )
}

export default Account

import TAsset from 'types/TAsset.js'
import { v4 as uuid } from 'uuid'

import { AMAChildrenScene } from '@amaspace-editor/editor-3d/lib/types/objects.types'
import toast from 'react-hot-toast'
import * as THREE from 'three'
import { isArray, isObject } from 'underscore'
import { TAssetEditorProp } from '../pages/assets/editor/assetEditorProps'
import createTagsForList from './createTagsForList.js'
import FabricApplication from '@amaspace-editor/editor-2d/dist/lib/core/application'
import { fabric } from 'fabric'

type CompressTextureFormat = 'webp' | 'jpeg' | 'png' | 'ktx2'

export default class Utils {
    public static renameFile(originalFile: File, newName: string) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        })
    }

    public static insertImageAsRectWithPattern(
        application: FabricApplication,
        image: fabric.Image,
        canvasWidthCorrected: number
    ): fabric.Rect {
        const matrix = image.calcOwnMatrix()
        const decomposed = fabric.util.qrDecompose(matrix)

        const pattern = new fabric.Pattern({
            // @ts-ignore
            source: image._element,
            repeat: 'repeat'
        })
        const rect = new fabric.Rect({
            ...decomposed,
            width: image.width,
            height: image.height,
            fill: pattern,
            left: canvasWidthCorrected / 2 - image.getScaledWidth() / 2,
            top: canvasWidthCorrected / 2 - image.getScaledHeight() / 2
        })

        // @ts-ignore
        rect.uid = uuid()
        // @ts-ignore
        rect._NAME = image.name

        application.relations.push({
            type: 'pattern',
            image,
            target: rect
        })

        application.canvas.add(rect)
        application.canvas.setActiveObject(rect)

        return rect
    }

    public static getErrorMessage(response: any) {
        if (response.data && response.data.message) {
            toast.error(response.data.message)
        }
        if (response.hasOwnProperty('error')) {
            const result = response.error.data
            const keys = Object.keys(result)

            if ('message' in result) {
                const { message } = result

                if (typeof message === 'string') {
                    toast.error(message)
                    return
                }

                if (isArray(message)) {
                    message.forEach((error: string | { message: string }) => {
                        if (typeof error === 'string') {
                            toast.error(error)
                            return
                        }

                        toast.error(error.message)
                    })

                    return
                }

                if (isObject(message)) {
                    const keys = Object.keys(message)

                    keys.forEach(key => {
                        if (typeof message[key] === 'string') {
                            toast.error(message[key])
                            return
                        }
                    })

                    return
                }
            }

            if (isArray(result)) {
                result.forEach(error => {
                    toast.error(error.message)
                })
                return
            }

            if (keys.length !== 0) {
                keys.forEach(key => {
                    if (result[key] === 'string') {
                        toast.error(result[key])
                        return
                    }

                    if (isArray(result[key])) {
                        result[key].forEach((message: string) => {
                            toast.error(message)
                        })
                        return
                    }
                })
            }
        }
    }

    public static uploadImage(file: File): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const originalName = encodeURIComponent(file.name)

            const fileNameWithUid = `${uuid()}:${file.name}`
            const fileToUpload = Utils.renameFile(file, fileNameWithUid)

            const fileName = encodeURIComponent(fileToUpload.name)
            const fileType = encodeURIComponent(fileToUpload.type)

            const presignedPostResponse = await fetch(
                `https://3.76.103.185/getUrl?file=${fileName}&fileType=${fileType}`,
                {
                    method: 'POST'
                }
            )

            // @ts-ignore
            const { url, fields } = await presignedPostResponse.json()
            const formData = new FormData()

            Object.entries({ ...fields, file: fileToUpload }).forEach(([key, value]) => {
                formData.append(key, value as string)
            })

            const upload = await fetch(url, {
                method: 'POST',
                body: formData
            })
            console.log(url, fields)
            if (!upload.ok) {
                throw new Error('Could not upload')
            }

            const urlToProcess = `${url}${fields.key}`
            const formToProcess = new FormData()

            formToProcess.append('urlToProcess', urlToProcess)
            formToProcess.append('originalName', originalName)

            const assetResponse = await fetch('/api/parseImage', {
                method: 'POST',
                body: formToProcess
            })
            const data = await assetResponse.json()
            resolve(data.body.data)
        })
    }

    public static createAssetsFromFile(
        file: File,
        parentFolderUid = '',
        options: any = { mode: 'uastc', format: 'webp', quality: 100, resize: [] }
    ): Promise<TAsset[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const originalName = encodeURIComponent(file.name)

                const fileNameWithUid = `${uuid()}:${file.name}`
                const fileToUpload = Utils.renameFile(file, fileNameWithUid)

                const fileName = encodeURIComponent(fileToUpload.name)
                const fileType = encodeURIComponent(fileToUpload.type)

                const presignedPostResponse = await fetch(
                    `https://3.76.103.185/getUrl?file=${fileName}&fileType=${fileType}`,
                    {
                        method: 'POST'
                    }
                )

                // @ts-ignore
                const { url, fields } = await presignedPostResponse.json()
                const formData = new FormData()

                Object.entries({ ...fields, file: fileToUpload }).forEach(([key, value]) => {
                    formData.append(key, value as string)
                })

                const upload = await fetch(url, {
                    method: 'POST',
                    body: formData
                })

                if (!upload.ok) {
                    throw new Error('Could not upload')
                }

                const urlToProcess = `${url}${fields.key}`
                const formToProcess = new FormData()
                console.log('@options', options)

                formToProcess.append('urlToProcess', urlToProcess)
                formToProcess.append('originalName', originalName)
                // formToProcess.append('compressFormat', compressFormat)
                formToProcess.append('options', JSON.stringify(options))
                formToProcess.append('type', file.type)

                const assetResponse = await fetch('https://3.76.103.185/parseData', {
                    method: 'POST',
                    body: formToProcess
                })

                if (!assetResponse.ok) {
                    reject()
                    return
                }
                const data = (await assetResponse.json()) as TAsset[]

                data.forEach(asset => {
                    ;(asset.parent as any) = parentFolderUid
                })

                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static generateAndUploadThumbnail(
        assetName: string,
        dataUrl: string,
        resize: boolean,
        thumbnailSize: number = 100
    ): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const base64Response = await fetch(dataUrl)
            const blob = await base64Response.blob()
            const image = new Image()
            image.src = URL.createObjectURL(blob)
            await new Promise(resolve => {
                image.onload = resolve
            })
            const resizedBlob = resize
                ? ((await Utils.resizeImageAndFill(image, thumbnailSize)) as BlobPart)
                : ((await Utils.fillImage(image)) as BlobPart)
            const fileImage = new File([resizedBlob], `${assetName}_thumbnail`, { type: 'image/png' })
            const imagePreview = await Utils.createAssetsFromFile(fileImage)
            resolve(imagePreview[0]?.preview || '')
        })
    }

    public static fillImage(image: HTMLImageElement) {
        return new Promise(resolve => {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(image, 0, 0, image.width, image.height)
            canvas.toBlob(blob => resolve(blob), 'image/png')
        })
    }

    public static resizeImageAndFill(image: HTMLImageElement, targetSize: number) {
        return new Promise(resolve => {
            const imgWidth = image.width
            const imgHeight = image.height

            let scaleX = targetSize / imgWidth
            let scaleY = targetSize / imgHeight
            let offsetX = 0
            let offsetY = 0

            if (scaleX > scaleY) {
                scaleY = scaleX
                offsetY = (targetSize - imgHeight * scaleY) / 2
            } else {
                scaleX = scaleY
                offsetX = (targetSize - imgWidth * scaleX) / 2
            }

            const canvas = document.createElement('canvas')
            canvas.width = targetSize
            canvas.height = targetSize
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(image, offsetX, offsetY, imgWidth * scaleX, imgHeight * scaleY)
            canvas.toBlob(blob => resolve(blob), 'image/png')
        })
    }
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function checkTypeValue<T extends TAssetEditorProp['value']>(value: TAssetEditorProp['value']): value is T {
    if (typeof value === 'undefined') return false
    if (typeof value === 'string') return true
    if (typeof value === 'boolean') return true
    if (typeof value === 'number') return true

    if ('isEuler' in value) return true

    return 'isVector3' in value
}

type AMAObjectUtils<T> = {
    obj: T
    value?: number
    key?: string
}
type AMAObjectUtilsReturn<T> = {
    key: keyof T
    value: THREE.Blending | THREE.Side
}

function objectUtils<T>({ obj, value, key }: AMAObjectUtils<T>): AMAObjectUtilsReturn<T> {
    const keys = Object.keys(obj as any)

    return keys
        .map(item => {
            const newItem = item as keyof T
            if (value !== undefined && value === obj[newItem]) return { key: newItem, value } as AMAObjectUtilsReturn<T>
            if (key !== undefined && key === newItem)
                return { key: newItem, value: obj[newItem] } as AMAObjectUtilsReturn<T>
        })
        .filter(el => el)[0] as AMAObjectUtilsReturn<T>
}

const isLight = (
    node: AMAChildrenScene
): node is THREE.DirectionalLight | THREE.PointLight | THREE.HemisphereLight | THREE.SpotLight => {
    if (node instanceof THREE.PointLight) return true
    if (node instanceof THREE.HemisphereLight) return true
    if (node instanceof THREE.SpotLight) return true
    return node instanceof THREE.DirectionalLight
}

async function updateProductItems<T extends { newItem?: 'new'; status: 'changed' | null }>(
    items: T[],
    removedItems: number[],
    createItems: (item: T) => FetchFunctionReturn<null>,
    updateItems: (item: T) => FetchFunctionReturn<any>,
    deleteItems: (items: number[]) => FetchFunctionReturn<null>
) {
    const uploadCreatedItems = async (items: T[]) => {
        const createdItems = items.filter(attr => attr.newItem)

        for (let i = 0; i < createdItems.length; i++) {
            const res = await createItems(createdItems[i])
            if ('error' in res) return false
            toast.success('Created!')
        }

        return true
    }

    const uploadUpdatedItems = async (items: T[]) => {
        const updatedItems = items.filter(attr => !attr.newItem && attr.status === 'changed')

        for (let i = 0; i < updatedItems.length; i++) {
            const res = await updateItems(updatedItems[i])
            if ('error' in res) return false
            toast.success('Updated!')

            if (typeof res.data === 'string') {
                toast.error(res.data)
            }
        }

        return true
    }

    const uploadDeletedItems = async (removedItems: number[]) => {
        if (removedItems.length) {
            const res = await deleteItems(removedItems)
            if ('error' in res) return false
            toast.success('Deleted!')
        }

        return true
    }

    const createdStatus = await uploadCreatedItems(items)

    const updatedStatus = await uploadUpdatedItems(items)

    const deletedStatus = await uploadDeletedItems(removedItems)

    return { createdStatus, updatedStatus, deletedStatus }
}

type OnDragEndType = <T extends { [k: string]: any }>(fromIndex: number, toIndex: number, list: T[]) => T[]
const onDragEndAttributes: OnDragEndType = (fromIndex, toIndex, list) => {
    if (toIndex < 0) return list

    const newList = [...list]

    const [removed] = newList.splice(fromIndex, 1)

    newList.splice(toIndex, 0, removed)

    return newList.map((item, index) => {
        return { ...item, sorting: index + 1, status: !item?.newItem ? 'changed' : null }
    })
}

function createChangeTrackingProxy<T extends { status?: string }>(obj: T): T {
    return new Proxy<T>(obj, {
        set(target, prop, value) {
            ;(target as Record<keyof T, any>)[prop as keyof T] = value
            target.status = 'changed'
            return true
        }
    })
}

export type FetchFunctionReturn<T> = Promise<{ data: T } | { error: any }>

export const getBreadcrumbsByPath = (path: string, splat: string) => {
    const paths = path.split('/').filter(Boolean)

    const pathsId = (splat as string).split('/')

    const folderPaths: string[] = []
    let prefix = ''

    for (let i = 0; i < pathsId.length; i++) {
        prefix += '/' + pathsId[i]
        if (paths.length > 3) {
            if (i < 1 || i >= pathsId.length - 3) {
                folderPaths.push(prefix)
            }
        } else {
            folderPaths.push(prefix)
        }
    }

    return [paths, folderPaths]
}

const formatSecondsToMMSS = (seconds: number) => {
    // Calculate the minutes and seconds
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    // Format minutes and seconds with leading zeros if needed
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).split('.')[0].padStart(2, '0')

    // Return the formatted time
    return `${formattedMinutes}:${formattedSeconds}`
}

const parentDomain = document.referrer ? new URL(document.referrer).hostname : window.location.hostname

export {
    createChangeTrackingProxy,
    onDragEndAttributes,
    updateProductItems,
    createTagsForList,
    capitalizeFirstLetter,
    checkTypeValue,
    objectUtils,
    isLight,
    formatSecondsToMMSS,
    parentDomain
}

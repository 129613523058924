import {InputNumber, Select} from 'antd'
import { ProductAttributeType } from '../../tabs/ProductAttributes'
import React, { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import {isObject} from "underscore";

type ProductAttributeProductItemProps = {
    attr: ProductAttributeType
    type?: 'default' | 'number'
}

const ProductAttributeProductItem = ({ attr, type = 'default' }: ProductAttributeProductItemProps) => {
    const { requests, setAttributes, attributes } = useContext(CatalogContext)
    const { products } = requests

    const handleChangeValue: HandleChangeAttributeValue = value => {
        console.log(attr)
      if (type === 'number') {
        setAttributes(
          attributes.map(item => ({
            ...item,
            value: (item.id === attr.id && isObject(item.value)) ? {...item.value, product_id: value ?? 0} : item.value,
            status: item.id === attr.id ? 'changed' : item.status
          }))
        )
      } else {
        setAttributes(
          attributes.map(item => ({
            ...item,
            value: item.id === attr.id ? value ?? [] : item.value,
            default_value: item.id === attr.id ? null : item.default_value,
            status: item.id === attr.id ? 'changed' : item.status
          }))
        )
      }

    }

    const handleChangeDefaultValue: HandleChangeAttributeDefaultValue = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                default_value: item.id === attr.id ? value : item.default_value,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    return (
        <>
            <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Select Products"
                value={isObject(attr.value)
                    ? (attr.value as {min: number; max: number; product_id: number})?.product_id?.toString()
                    : attr.value.toString() ? (attr.value as string | string[]) : null
                }
                onChange={handleChangeValue}>
                {products.map(item => {
                    return (
                        <Select.Option key={item.id} value={item.id.toString()}>
                            {item.name}
                        </Select.Option>
                    )
                })}
            </Select>

            {type === 'number' ? (
              <InputNumber
                placeholder="Enter default value"
                value={attr.default_value}
                onChange={(v) => handleChangeDefaultValue((v || 0).toString())}
              />
            ) : (
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Select Default Option"
                value={attr.default_value}
                onChange={handleChangeDefaultValue}>
                {products.map(item => {
                  if (item.id != attr.value) return
                  return (
                    <Select.Option key={item.id} value={item.id.toString()}>
                      {item.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )}

        </>
    )
}

export default ProductAttributeProductItem

type HandleChangeAttributeValue = (value: string | string[]) => void
type HandleChangeAttributeDefaultValue = (value: string) => void

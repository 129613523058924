import PageBuilder from '../../components/panel/PageBuilder'
import {Button} from 'antd'
import {DeleteOutlined, DollarOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import React, {useContext, useEffect, useState} from 'react'
import ModalWithForm from '../users/ModalWithForm'
import {
    LicenseFilter,
    LicenseInfoType,
    LicenseType,
    useCreateLicenseMutation, useDeleteLicenseMutation,
    useGetLicensesQuery,
    useUpdateLicenseMutation
} from '../../api/licenseApi'
import {Form} from 'antd'
import LicenseForm from './LicenseForm'
import {useAppSelector} from '../../hooks'
import {useNavigate, useSearchParams} from 'react-router-dom'
import ExtendedTable from '../../components/ExtendedTable'
import {useFilterTable} from '../../hooks/useFilterTable'
import {routesUrl} from '../../router/urls'
import toast from "react-hot-toast";
import {ModalConfirmContext} from "../../providers/ModalConfirmProvider";

const Licenses = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {showModal} = useContext(ModalConfirmContext)
    const navigate = useNavigate()
    const {role} = useAppSelector(state => state.user)

    const [formCreate] = Form.useForm<LicenseInfoType>()
    const [formUpdate] = Form.useForm()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [filter, setFilter] = useState<LicenseFilter>({
        page: 1,
        name: ''
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
        })
    }, [searchParams]);

    const [deleteItem] = useDeleteLicenseMutation()
    const [create, resultsCreate] = useCreateLicenseMutation()
    const [update, resultsUpdate] = useUpdateLicenseMutation()
    const {data, isLoading, refetch} = useGetLicensesQuery(filter)

    const [filterByText] = useFilterTable<LicenseFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval'
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, license: LicenseType) => (
                <>
                    {role === 'admin' && (<>
                        <Button
                            onClick={() => {
                                const {product} = license
                                const productIds = product.map(item => item.id)
                                formUpdate.setFieldsValue({
                                    id: license.id,
                                    name: license.name,
                                    dealer: license.dealer,
                                    interval: 4,
                                    description: license.description,
                                    brand: license.brand,
                                    is_show_all: license.is_show_all,
                                    product: productIds
                                })
                                setIsModalOpenUpdate(true)
                            }}
                            type="link"
                            size="small">
                            <EditOutlined/>
                        </Button>

                        <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const {id} = license
                                    const res = await deleteItem({id})
                                    if ('error' in res) return
                                    toast.success('License is successfully deleted')
                                    refetch()
                                }

                                showModal('delete this license', handleDelete)
                            }}
                            type="link"
                            size="small">
                            <DeleteOutlined/>
                        </Button>
                    </>)}

                    {role === 'account manager' && (
                        <Button
                            onClick={() => {
                                const {id} = license
                                navigate(`${routesUrl.licensesPrice}/${id}`)
                            }}
                            type="link"
                            size="small">
                            <DollarOutlined/>
                        </Button>
                    )}
                </>
            )
        }
    ]

    return (
        <PageBuilder title="Licenses" documentTitle="Licences">
            {role === 'admin' && (
                <div style={{marginBottom: 20}}>
                    <Button
                        type="primary"
                        onClick={() => {
                            formCreate.setFieldValue('interval', 4)
                            setIsModalOpenCreate(true)
                        }}>
                        <PlusOutlined/> Create License
                    </Button>
                </div>
            )}

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="license"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            {isModalOpenCreate && (
                <ModalWithForm
                    title="Create License"
                    isOpen={isModalOpenCreate}
                    setIsOpen={setIsModalOpenCreate}
                    isLoading={resultsCreate.isLoading}
                    submit={formCreate.submit}>
                    <LicenseForm
                        setIsModalOpen={setIsModalOpenCreate}
                        refetch={refetch}
                        form={formCreate}
                        onFinish={create}
                        mode="creating"
                    />
                </ModalWithForm>
            )}

            {isModalOpenUpdate && (
                <ModalWithForm
                    title="Update License"
                    isOpen={isModalOpenUpdate}
                    setIsOpen={setIsModalOpenUpdate}
                    isLoading={resultsUpdate.isLoading}
                    submit={formUpdate.submit}>
                    <LicenseForm
                        setIsModalOpen={setIsModalOpenUpdate}
                        refetch={refetch}
                        form={formUpdate}
                        onFinish={update}
                    />
                </ModalWithForm>
            )}
        </PageBuilder>
    )
}

export default Licenses

import { Col, InputNumber, Slider } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'underscore'
import { ActionType } from '../assetEditorProps'

export const RenderRange = ({ data, onChange }: ActionType) => {
    const [inputValue, setInputValue] = useState(data.value)

    const precision = data.misc?.precision || 0.01
    const min = data.misc?.min || 0
    const max = data.misc?.max || 100
    const enableInfinity = data.misc?.enableInfinity || false

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.stopPropagation()
    }
    const throttledChangeHandler = useCallback(
        _.debounce((value: number | null) => onChange(data.key, value), 500),
        [data.value, data.key, onChange]
    )

    const changeHandler = useCallback(
        (value: number | null) => {
            setInputValue(value)
            throttledChangeHandler(value)
        },
        [throttledChangeHandler]
    )
    useEffect(() => {
        setInputValue(data.value)
    }, [data.value])

    return (
        <>
            <PropsItem span={5}>
                <InputNumber
                    min={min}
                    max={max}
                    step={0.01}
                    value={inputValue?.toFixed(2)}
                    onKeyDown={handleKeyDown}
                    onChange={value => {
                        if (enableInfinity) {
                            if (value === min) {
                                changeHandler(-Infinity)
                                return
                            } else if (value === max) {
                                changeHandler(Infinity)
                                return
                            }
                        }
                        changeHandler(value)
                    }}
                />
            </PropsItem>
            <PropsItem span={11}>
                <Slider
                    style={{ flex: 1 }}
                    min={min}
                    max={max}
                    step={precision}
                    value={inputValue}
                    onChange={value => {
                        if (enableInfinity) {
                            if (value === min) {
                                changeHandler(-Infinity)
                                return
                            } else if (value === max) {
                                changeHandler(Infinity)
                                return
                            }
                        }
                        changeHandler(value)
                    }}
                />
            </PropsItem>
        </>
    )
}

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`

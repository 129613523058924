import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const COMPANY = 'company'

const companyApi = api.injectEndpoints({
    endpoints: build => ({
        getCompanies: build.query<GetCompaniesType, CompanyFilter>({
            query: body => {
                const { page, name, email } = body

                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    email,
                    expand: 'brands'
                })

                return `${COMPANY}?${params}`
            }
        }),

        getCompaniesList: build.mutation<GetCompaniesType, { page: number }>({
            query: body => {
                const { page } = body

                const params = new URLSearchParams({ page: page.toString() })

                return `${COMPANY}?${params}`
            }
        }),

        getCompanyById: build.query<CompanyType, { id: string }>({
            query: body => {
                return `${COMPANY}/${body.id}`
            }
        }),

        createCompany: build.mutation<null, CompanyInfoType>({
            query: body => ({
                url: COMPANY,
                method: 'POST',
                body
            })
        }),

        updateCompany: build.mutation<null, CompanyInfoType>({
            query: body => {
                const { id, ...newBody } = body
                return {
                    url: `${COMPANY}/${id}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),

        deleteCompany: build.mutation<null, Pick<CompanyType, 'id'>>({
            query: body => {
                return {
                    url: `${COMPANY}/${body.id}`,
                    method: 'DELETE'
                }
            }
        })
    }),
    overrideExisting: false
})

export type CompanyType = {
    id: number
    name: string
    email: string
    lead_email: string
    city: string
    country_id: number
    state_id: number
    street: string
    zip_code: string
    payment: { name: string; current_currency: string; current_price: string; current_debit: number; id: number }
    how_pay: 0 | 1 | 2 | 3 | 4
    brands: { id: number; name: string }[]
}

export type CompanyInfoType = Omit<CompanyType, 'brands'> & {
    brands: number[]
}

export type GetCompaniesType = {
    items: CompanyType[]
    _meta: TableMetaType
}

export type CompanyFilter = {
    page: number
    name: string
    email: string
}

export const {
    useGetCompaniesListMutation,
    useGetCompaniesQuery,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
    useCreateCompanyMutation,
    useGetCompanyByIdQuery
} = companyApi

export default companyApi

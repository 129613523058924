import { DeleteOutlined, DragOutlined } from '@ant-design/icons'
import { Button, Card, List, Spin } from 'antd'
import { useContext, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import { useParams } from 'react-router-dom'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { onDragEndAttributes, updateProductItems } from '../../../../utils'
import ProductAttributeCategory from '../attributes/ProductAttributeCategory'
import {
    ProductAttributeItemKeyType,
    ProductAttributeType,
    StyledAttribute,
    StyledAttributeTitle
} from './ProductAttributes'
import { StyledCatalogBtnWrapper } from './ProductInfo'

const { Item } = List

const ProductCategories = () => {
    const { categories, setCategories, fetchCategories, isLoadingCategories, requests } = useContext(CatalogContext)

    const { createAttributes, updateAttributes, deleteAttributes, getCategories } = requests

    const { id: productId } = useParams()

    const [isLoadingBtn, setIsLoadingBtn] = useState(false)

    const [removedCategories, setRemovedCategories] = useState<number[]>([])

    const handleCreateCategory = (type: ProductAttributeItemKeyType) => {
        let sorting = 1
        categories.forEach(item => {
            if (item.sorting > sorting) {
                sorting = item.sorting
            }
        })

        const attr: ProductAttributeType = {
            newItem: 'new',
            id: Date.now(),
            imageName: null,
            image: null,
            status: null,
            name: '',
            description: '',
            replacement_attribute_id: null,
            annotation_id: null,
            product_id: productId ?? null,
            is_hide: 0,
            is_color: 0,
            is_morph_target: 0,
            default_value: null,
            parent_id: null,
            type,
            value: '',
            sorting,
            discount_type: 0
        }

        return attr
    }

    const handleAddCategory = () => {
        const category = handleCreateCategory('category')
        setCategories([...categories, category])
    }

    const handleDeleteCategory = (category: ProductAttributeType) => {
        if (!category.newItem) setRemovedCategories([...removedCategories, category.id])
        setCategories(categories.filter(item => item.id !== category.id))
    }

    const handleUpdateCategories = async () => {
        setIsLoadingBtn(true)
        const { createdStatus, updatedStatus, deletedStatus } = await updateProductItems(
            categories,
            removedCategories,
            createAttributes,
            updateAttributes,
            deleteAttributes
        )
        setIsLoadingBtn(false)

        if (createdStatus && updatedStatus && deletedStatus) {
            setRemovedCategories([])

            await fetchCategories(getCategories)
        }
    }

    const renderItem = (category: ProductAttributeType) => {
        return (
            <Item key={category.id}>
                <StyledAttributeTitle>
                    {category.parent_id ? 'Sub CategoryWrapper' : 'Parent CategoryWrapper'}
                </StyledAttributeTitle>

                <StyledAttribute>
                    <div className="attr-dragdrop">
                        <DragOutlined />
                    </div>

                    <ProductAttributeCategory category={category} />

                    <Button
                        disabled={!!categories.find(item => Number(category.id) === Number(item.parent_id))}
                        type="link"
                        size="small"
                        onClick={() => handleDeleteCategory(category)}>
                        <DeleteOutlined />
                    </Button>
                </StyledAttribute>
            </Item>
        )
    }

    return (
        <div className="catalog">
            <Card title="Categories">
                {isLoadingCategories ? (
                    <Spin size="small" />
                ) : (
                    <ReactDragListView
                        nodeSelector=".catalog .ant-list-item"
                        handleSelector=".catalog .attr-dragdrop"
                        lineClassName="dragLine"
                        onDragEnd={(fromIndex, toIndex) =>
                            setCategories(onDragEndAttributes(fromIndex, toIndex, categories))
                        }>
                        <List
                            itemLayout="vertical"
                            dataSource={categories}
                            footer={
                                <StyledCatalogBtnWrapper>
                                    <Button loading={isLoadingBtn} onClick={handleAddCategory}>
                                        Add Category
                                    </Button>
                                    <Button loading={isLoadingBtn} onClick={handleUpdateCategories} type="primary">
                                        Update Categories
                                    </Button>
                                </StyledCatalogBtnWrapper>
                            }
                            renderItem={renderItem}
                        />
                    </ReactDragListView>
                )}
            </Card>
        </div>
    )
}

export default ProductCategories

import { Button, Dropdown, Form, Input, List, Space, Spin, Typography } from 'antd'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    AssetAnnotationType,
    AssetAttributeType,
    AssetRuleActionType,
    AssetRuleConditionType,
    AssetRuleType,
    useCreateAssetRuleMutation,
    useDeleteAssetRuleActionsMutation,
    useDeleteAssetRuleConditionsMutation,
    useGetAssetAnnotationsQuery,
    useGetAssetAttributesQuery,
    useUpdateAssetRuleMutation
} from '../../../../../api/assetApi'
import { v4 as uuid } from 'uuid'
import { ActionTypeId, ILogicEditorTypeId, LogicEditorTypeId } from '../LogicEditor'
import RulesConditionItem from './RulesConditionItem'
import {DownOutlined, DragOutlined} from '@ant-design/icons'
import RulesActionItem from './RulesActionItem'
import { actionRuleItems } from './LogicRules'
import { TAsset } from '../../../../../types'
import { utilsEditor } from '@amaspace-editor/editor-3d'
import { AssetsForAttributes } from '../../assetEditor'
import ReactDragListView from "react-drag-listview";
import {onDragEndAttributes} from "../../../../../utils";
const { Item } = List
const { Title } = Typography

type LogicRulesEditorProps = {
    ruleInfo: AssetRuleType
    assetId: number
    assets: AssetsForAttributes
    setIsOpen: Dispatch<SetStateAction<boolean>>
    modelPart: TAsset[]
}

const LogicRulesEditor = ({ assetId, modelPart, assets, ruleInfo, setIsOpen }: LogicRulesEditorProps) => {
    const [rule, setRule] = useState<AssetRuleType>(ruleInfo)
    const [deletedIdCondition, setDeletedIdCondition] = useState<number[]>([])
    const [deletedIdAction, setDeletedIdAction] = useState<number[]>([])
    const editor3D = utilsEditor()
    const [updateRule, result] = useUpdateAssetRuleMutation()
    const { isLoading } = result
    const [deleteConditions, resultDeletedConditions] = useDeleteAssetRuleConditionsMutation()
    const { isLoading: isLoadingDeleteConditions } = resultDeletedConditions
    const [deleteActions, resultDeletedActions] = useDeleteAssetRuleActionsMutation()
    const { isLoading: isLoadingDeleteActions } = resultDeletedActions

    const { data: assetAttributes, isLoading: isLoadingAttributes } = useGetAssetAttributesQuery({ id: assetId })
    const { data: annotations, isLoading: isLoadingAnnotations } = useGetAssetAnnotationsQuery({ id: assetId })

    const handleUpdateRule = async () => {
        await updateRule(rule)
        if (editor3D.config.rules) {
            editor3D.updateRuleConfig(rule)
        }
        if (deletedIdCondition.length !== 0) {
            await deleteConditions(deletedIdCondition)
        }

        if (deletedIdAction.length !== 0) {
            await deleteActions(deletedIdAction)
        }

        setIsOpen(false)
    }

    const handleAddCondition = () => {
        const newCondition: AssetRuleConditionType = {
            appId: uuid(),
            value: '',
            type_condition: 0,
            connection_id: 0,
            connection_type: 0
        }

        setRule(prevState => ({
            ...prevState,
            condition: [...prevState.condition, newCondition]
        }))
    }

    const handleDeleteCondition = (item: AssetRuleConditionType) => {
        if (item.appId) {
            const filtered = rule.condition.filter(data => data.appId !== item.appId)
            setRule(prevState => ({ ...prevState, condition: filtered }))
        } else {
            const filtered = rule.condition.filter(data => data.id !== item.id)
            setRule(prevState => ({ ...prevState, condition: filtered }))
            setDeletedIdCondition([...deletedIdCondition, item.id as number])
        }
    }

    const handleDeleteAction = (item: AssetRuleActionType) => {
        console.log(item)
        if (item.appId) {
            const filtered = rule.action.filter(data => data.appId !== item.appId)
            setRule(prevState => ({ ...prevState, action: filtered }))
        } else {
            const filtered = rule.action.filter(data => data.id !== item.id)
            setRule(prevState => ({ ...prevState, action: filtered }))
            setDeletedIdAction([...deletedIdAction, item.id as number])
        }
    }
    console.log(rule)
    //TODO Check
    const handleChangeAction = (item: AssetRuleActionType, value: string) => {
        if (item.appId) {
            // const filtered = rule.action.find(data => data.appId === item.appId)
            // const filteredAnother = rule.action.filter(data => data.appId !== item.appId)
            // const final = { ...filtered, value } as AssetRuleActionType
            const updatedActions = rule.action.map(data => {
                if (data.appId === item.appId) {
                    return { ...data, value }
                }
                return data
            })
            setRule(prevState => ({ ...prevState, action: updatedActions }))
        } else {
            // const filtered = rule.action.find(data => data.id === item.id)
            // const filteredAnother = rule.action.filter(data => data.id !== item.id)
            // const final = { ...filtered, value } as AssetRuleActionType
            const updatedActions = rule.action.map(data => {
                if (data.id === item.id) {
                    return { ...data, value }
                }
                return data
            })
            setRule(prevState => ({ ...prevState, action: updatedActions }))
        }
    }

    const handleAddAction = (type: any) => {
        const type_action = LogicEditorTypeId[type.key as keyof typeof ActionTypeId]

        let sorting = 1
        rule.action.forEach(item => {
            if (item.sorting > sorting) {
                sorting = item.sorting;
            }
        });

        const newAction: AssetRuleActionType = {
            appId: uuid(),
            value: '',
            type_action,
            sorting,
        }

        setRule(prevState => ({
            ...prevState,
            action: [...prevState.action, newAction]
        }))
    }

    function getKeyByValue(object: { [key: string]: string | number }, value: string | number) {
        return Object.keys(object).find(key => object[key] === value)
    }

    return (
        <>
            <Title level={4}>Edit Rule</Title>

            {isLoadingAnnotations || isLoadingAttributes ? (
                <Spin size="large" />
            ) : (
                <>
                    <div className="rules-actions" style={{marginBottom: 16}}>
                        <Form.Item>
                            <Input
                                maxLength={50}
                                value={rule.name}
                                onChange={e => {
                                    setRule(prevState => ({...prevState, name: e.target.value}))
                                }}
                                placeholder="Name"
                            />
                        </Form.Item>

                        <Form.Item>
                            <List
                                className="logic__list logic__list_action"
                                itemLayout="vertical"
                                size="large"
                                dataSource={rule.condition}
                                renderItem={item => (
                                    <Item key={item.appId}>
                                        <RulesConditionItem
                                            item={item}
                                            setRule={setRule}
                                            deleteCondition={handleDeleteCondition}
                                            annotations={annotations as AssetAnnotationType[]}
                                            modelPart={modelPart}
                                        />
                                    </Item>
                                )}
                                header={
                                    <Button onClick={handleAddCondition} style={{width: '100%'}}>
                                        <Space>
                                            Add Condition <DownOutlined/>
                                        </Space>
                                    </Button>
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            <ReactDragListView
                                nodeSelector=".rules-actions .ant-list-item"
                                handleSelector=".rules-actions .attr-dragdrop"
                                lineClassName="dragLine"
                                onDragEnd={(fromIndex, toIndex) => {
                                    setRule(prevState => {
                                        const action = onDragEndAttributes<AssetRuleActionType>(fromIndex, toIndex, prevState.action)
                                        return {...prevState, action}
                                    })
                                }
                                }>
                                <List
                                    className="logic__list logic__list_action"
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={rule.action}
                                    renderItem={item => (
                                        <Item key={item.appId || item.id}>
                                            <Space>
                                                <div className="attr-dragdrop">
                                                    <DragOutlined/>
                                                </div>
                                                <div>{getKeyByValue(LogicEditorTypeId, item.type_action)}</div>
                                            </Space>
                                            <RulesActionItem
                                                item={item}
                                                modelPart={modelPart}
                                                assets={assets}
                                                attributes={assetAttributes as AssetAttributeType[]}
                                                changeAction={handleChangeAction}
                                                deleteAction={handleDeleteAction}
                                            />
                                        </Item>
                                    )}
                                    header={
                                        <Dropdown menu={{items: actionRuleItems, onClick: handleAddAction}}>
                                            <Button type="primary" style={{width: '100%'}}>
                                                <Space>
                                                    Add Action <DownOutlined/>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    }
                                />
                            </ReactDragListView>
                        </Form.Item>
                    </div>

                    <Space>
                        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button
                            loading={isLoading && isLoadingDeleteConditions && isLoadingDeleteActions}
                            onClick={handleUpdateRule}
                            type="primary">
                            Edit Rule
                        </Button>
                    </Space>
                </>
            )}
        </>
    )
}

export default LogicRulesEditor

import { CodepenOutlined, FolderOutlined, PictureOutlined, RocketOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, InputRef, Space, Typography, Image, Select } from 'antd'
import { ASSET_TYPE, useCreateAssetMutation, useUpdateAssetMutation } from 'api/assetApi'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Utils from 'utils'
import { v4 as uuid } from 'uuid'
import PageBuilder from '../../components/panel/PageBuilder'
import { SendAssetType } from '../../types/Assets'
import { routesUrl } from '../../router/urls'
import { TAsset } from 'types'
import { ViewerForPreview } from '@amaspace-editor/editor-3d'
import styled from 'styled-components'

const buttonWidthStyle = {
    width: 172
}
const acceptedModelTypes = ['glb', 'gltf']
const acceptedImageTypes = ['jpg', 'jpeg', 'png', 'webp', 'ktx2']

const FILE_SELECTOR_TYPE: Record<TSelection, string> = {
    folder: '*',
    scene: '*',
    model: '.glb',
    material: '*',
    image: 'image/jpeg, image/png, image/webp',
    vector: '*',
    composite: '*'
}

export type ValueObjectType<T> = {
    [K in keyof T]: T[K]
}[keyof T]

type TSelection = 'folder' | 'scene' | 'model' | 'material' | 'image' | 'vector' | 'composite'

type ModelOptions = {
    format: 'webp' | 'jpeg' | 'png' | 'ktx2'
    enable: boolean
    mode: typeof Mode.UASTC | typeof Mode.ETC1S
    options: typeof UASTC_DEFAULTS | typeof ETC1S_DEFAULTS
}
export const Mode = {
    ETC1S: 'etc1s',
    UASTC: 'uastc'
} as const

export const ETC1S_DEFAULTS = {
    quality: { max: 255, min: 1, value: 5 },
    compression: { max: 5, min: 0, value: 1 }
}

export const UASTC_DEFAULTS = {
    level: { max: 4, min: 0, value: 2 }
}

type ImageOptions = {
    enable: boolean
    quality?: number
    format: 'webp' | 'jpeg' | 'png' | 'ktx2'
    resize: [number, number]
}

const extRegexp = new RegExp(/\.[^/\\.]+$/)

const defaultModelOptions = {
    format: 'webp',
    enable: false,
    mode: Mode.ETC1S,
    options: ETC1S_DEFAULTS
} as const

const defaultImageOptions: ImageOptions = {
    enable: false,
    format: 'webp',
    quality: 100,
    resize: [0, 0]
}

const CreateAsset: React.FC = () => {
    const [assetName, setAssetName] = useState('')
    const [assetExt, setAssetExt] = useState('')
    const fileSelector = useRef<HTMLInputElement>(null)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [savingAsset, setSavingAsset] = useState(false)
    const [selectedType, setSelectedType] = useState('')

    const [previewModel, setPreviewModel] = useState<Record<string, TAsset[]> | null>(null)
    const [modelOptions, setModelOptions] = useState<ModelOptions>(defaultModelOptions)
    const [imageOptions, setImageOptions] = useState<ImageOptions>(defaultImageOptions)
    const [uploadingAssets, setUploadingAssets] = useState<Record<string, TAsset[]> | null>(null)

    const [createAsset] = useCreateAssetMutation()
    const [updateAssetRequest] = useUpdateAssetMutation()

    const navigate = useNavigate()

    const { '*': splat } = useParams()
    const list = splat?.split('/')
    const lastId = list?.pop() || ''

    const onSuccess = (): string => {
        setSavingAsset(false)
        navigate(`${routesUrl.assets}/${splat}`, { replace: true })
        return 'Asset successfully saved!'
    }

    const saveAsset = async () => {
        setSavingAsset(true)
        const createDefaultAsset = (type: ValueObjectType<typeof ASSET_TYPE>): SendAssetType => ({
            name: assetName,
            id: uuid(),
            parent: lastId,
            type: type,
            url: '',
            preview: '',
            props: {},
            patch: {},
            tags: []
        })

        const onFail = (e?: any): string => {
            console.error(e)
            setSavingAsset(false)
            return 'Could not save asset'
        }

        if (selectedType === ASSET_TYPE.Folder) {
            const assetFolder = createDefaultAsset(ASSET_TYPE.Folder)
            createAsset({ assets: [assetFolder] })
                .then(onSuccess)
                .catch(onFail)

            return
        }
        if (selectedType === ASSET_TYPE.Scene) {
            const assetFolder = createDefaultAsset(ASSET_TYPE.Scene)
            createAsset({ assets: [assetFolder] })
                .then(onSuccess)
                .catch(onFail)

            return
        }
        if (selectedType === ASSET_TYPE.Material) {
            const assetFolder = createDefaultAsset(ASSET_TYPE.Material)
            createAsset({ assets: [assetFolder] })
                .then(onSuccess)
                .catch(onFail)

            return
        }

        const nameWithExtension = `${assetName}${assetExt}`
        const fileToUpload = Utils.renameFile(selectedFile as File, nameWithExtension)

        const convertOptions = (options: any) => {
            return Object.keys(options).reduce((acc, key) => {
                return { ...acc, [key]: String(options[key].value) }
            }, {})
        }
        const needResize = imageOptions.resize.every(i => i > 0)
        const resize = !needResize && imageOptions.format === 'ktx2' ? 0 : imageOptions.resize.filter(Boolean)

        const options = {
            mode: modelOptions.mode,
            format: imageOptions.format,
            ...(imageOptions.format === 'ktx2' ? convertOptions(modelOptions.options) : imageOptions),
            resize: resize
        }

        try {
            await toast.promise(
                Utils.createAssetsFromFile(fileToUpload, lastId, options).then(res =>
                    createAsset({ assets: res })
                        .then(() => {
                            if (selectedType === 'image') onSuccess()
                            const materials = res.filter(i => i.type === ASSET_TYPE.Material)
                            const model = res.filter(i => i.type === ASSET_TYPE.Model)

                            setUploadingAssets(prev =>
                                prev
                                    ? { ...prev, [fileToUpload.name]: [...materials, ...model] }
                                    : { [fileToUpload.name]: [...materials, ...model] }
                            )
                        })
                        .catch(onFail)
                ),
                {
                    loading: 'Saving asset...',
                    success: 'Save asset success!',
                    error: onFail
                }
            )
        } catch (e) {
            onFail(e)
        }
    }

    const uploadingPreview = () => {
        if (!previewModel || !uploadingAssets) return
        const previewKeys = Object.keys(previewModel)
        const assetKeys = Object.keys(uploadingAssets)

        Promise.all(
            assetKeys.map((item: any) => {
                const preview = previewKeys.find(key => key === item)
                if (!preview) return
                const asset = uploadingAssets[item]
                const previewModelItem = previewModel[preview]

                if (asset?.length === previewModelItem?.length) {
                    return Promise.all(
                        previewModelItem.map(async item => {
                            await updateAssetRequest({
                                id: item!.id,
                                asset: item
                            })
                        })
                    )
                }
            })
        )
            .then(res => {
                if (res.some(i => !i)) return
                setPreviewModel(null)
                setUploadingAssets(null)
                onSuccess()
            })
            .catch(e => {
                console.log(e)
            })
    }

    const processSelection = (type: TSelection) => {
        if (['scene', 'folder', 'material'].includes(type)) {
            if (!fileSelector.current) return

            setSelectedType(type)
            setSelectedFile(null)
            fileSelector.current.value = ''
            setModelOptions(prev => ({ ...prev, enable: false }))
            return
        }
        if (!fileSelector.current) return
        const mimetype = FILE_SELECTOR_TYPE[type]

        setSelectedType('')

        fileSelector.current.accept = mimetype
        fileSelector.current.click()
    }

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        e.preventDefault()

        if (!e.target.files?.length) return

        const file = e.target.files![0] as File
        const name = file.name.replace(extRegexp, '')
        const ext = file.name.match(extRegexp)!.pop() as string
        const acceptedModel = acceptedModelTypes.map(i => `.${i}`).includes(ext)
        const acceptedImage = acceptedImageTypes.map(i => `.${i}`).includes(ext)
        const type = acceptedModel ? 'model' : acceptedImage ? 'image' : ''

        setAssetExt(ext)
        setAssetName(name)
        setSelectedFile(file)
        setSelectedType(type)

        setModelOptions(prev => ({ ...prev, enable: acceptedModel || acceptedImage }))
    }

    const btn = () => (
        <Select
            value={imageOptions.format}
            onChange={value => {
                setImageOptions(prev => ({
                    ...prev,
                    format: value
                }))
            }}>
            {acceptedImageTypes
                .filter(i => i !== 'jpg')
                .map(format => (
                    <Select.Option value={format}>{format}</Select.Option>
                ))}
        </Select>
    )
    const renderModelOptions = () => {
        return (
            <div>
                <Typography.Text>Texture format</Typography.Text>

                {renderImageOptions()}

                {imageOptions.format === 'ktx2' && (
                    <div>
                        <Button
                            onClick={() =>
                                setModelOptions(prev => ({ ...prev, mode: 'etc1s', options: ETC1S_DEFAULTS }))
                            }
                            type={modelOptions.mode === 'etc1s' ? 'primary' : 'default'}>
                            etc1s
                        </Button>
                        <Button
                            onClick={() =>
                                setModelOptions(prev => ({ ...prev, mode: 'uastc', options: UASTC_DEFAULTS }))
                            }
                            type={modelOptions.mode === 'uastc' ? 'primary' : 'default'}>
                            uastc
                        </Button>
                    </div>
                )}
                {modelOptions.options &&
                    imageOptions.format === 'ktx2' &&
                    Object.keys(modelOptions.options).map((_key, index) => {
                        const key = _key as keyof typeof modelOptions.options
                        return (
                            <div key={index}>
                                <Typography.Text>{key}</Typography.Text>
                                <InputNumber
                                    value={(modelOptions.options[key] as any).value}
                                    max={(modelOptions.options[key] as any).max}
                                    min={(modelOptions.options[key] as any).min}
                                    onChange={value => {
                                        setModelOptions(prev => ({
                                            ...prev,
                                            options: {
                                                ...prev.options,
                                                [key]: { ...(prev.options[key] as any), value: value as number }
                                            }
                                        }))
                                    }}></InputNumber>
                            </div>
                        )
                    })}
            </div>
        )
    }
    const renderImageOptions = () => {
        return Object.keys(imageOptions).map((_key, index) => {
            const key = _key as keyof typeof imageOptions

            const renderOptions = () => {
                if (key === 'format') {
                    return btn()
                }

                if (key === 'resize') {
                    return (
                        <div key={index}>
                            <span> width </span>
                            <InputNumber
                                value={imageOptions[key][0]}
                                min={0}
                                max={2048}
                                onChange={value => {
                                    setImageOptions(prev => ({
                                        ...prev,
                                        [key]: [value as number, prev[key][1]]
                                    }))
                                }}
                            />
                            <span> height </span>
                            <InputNumber
                                value={imageOptions[key][1]}
                                min={0}
                                max={2048}
                                onChange={value => {
                                    setImageOptions(prev => ({
                                        ...prev,
                                        [key]: [prev[key][0], value as number]
                                    }))
                                }}
                            />
                        </div>
                    )
                }

                if (imageOptions.format === 'ktx2') return null

                if (key === 'quality') {
                    return (
                        <div key={index}>
                            <Typography.Text>{key}</Typography.Text>
                            <InputNumber
                                value={imageOptions[key]}
                                max={100}
                                min={1}
                                onChange={value => {
                                    setImageOptions(prev => ({
                                        ...prev,
                                        [key]: value as number
                                    }))
                                }}></InputNumber>
                        </div>
                    )
                }
            }

            return <div key={index}>{renderOptions()}</div>
        })
    }
    useEffect(() => {
        uploadingPreview()
    }, [previewModel])

    return (
        <PageBuilder breadcrumbs={['assets', 'create']} documentTitle="Create Asset" title="Create Asset">
            <Typography.Text>Name</Typography.Text>
            <Input
                placeholder="Asset name"
                value={assetName}
                onChange={e => {
                    setAssetName(e.target.value)
                }}></Input>
            <p></p>
            <Typography.Text>Type</Typography.Text>
            <div style={{ marginTop: 16 }}>
                <StyledBtnWrapper size={[15, 15]}>
                    <Button
                        onClick={() => processSelection('folder')}
                        style={buttonWidthStyle}
                        type={selectedType === 'folder' ? 'primary' : 'default'}
                        icon={<FolderOutlined />}>
                        Folder
                    </Button>
                    <Button
                        onClick={() => processSelection('scene')}
                        style={buttonWidthStyle}
                        type={selectedType === 'scene' ? 'primary' : 'default'}
                        icon={<CodepenOutlined />}>
                        Scene
                    </Button>
                    <Button
                        onClick={() => processSelection('model')}
                        style={buttonWidthStyle}
                        type={selectedType === 'model' ? 'primary' : 'default'}
                        icon={<RocketOutlined />}>
                        Model
                    </Button>
                    <Button
                        onClick={() => processSelection('image')}
                        style={buttonWidthStyle}
                        type={selectedType === 'image' ? 'primary' : 'default'}
                        icon={<PictureOutlined />}>
                        Image
                    </Button>
                    <Button
                        onClick={() => processSelection('material')}
                        style={buttonWidthStyle}
                        type={selectedType === 'material' ? 'primary' : 'default'}
                        icon={<SmileOutlined />}>
                        Material
                    </Button>
                </StyledBtnWrapper>
                <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column' }}>
                    {modelOptions.enable ? renderModelOptions() : null}
                </div>
            </div>
            <p></p>
            <Button type="primary" onClick={saveAsset} disabled={!selectedType} loading={savingAsset}>
                Save asset
            </Button>
            <input ref={fileSelector} type="file" style={{ display: 'none' }} onChange={selectFile}></input>
            {uploadingAssets ? (
                <div
                    style={{
                        position: 'fixed',
                        width: '100px',
                        height: '100px',
                        top: '-5000px'
                    }}>
                    {Object.keys(uploadingAssets).map(key => {
                        const allAssets = uploadingAssets[key] as any
                        return (
                            <ViewerForPreview name={key} allAssets={allAssets} updatePreview={setPreviewModel as any} />
                        )
                    })}
                </div>
            ) : null}
        </PageBuilder>
    )
}

export default CreateAsset

const StyledBtnWrapper = styled(Space)`
    flex-wrap: wrap;
`

import api from './index'
import { AuthDataType } from 'slices/authSlice'

const AUTH = 'auth'

const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<AuthDataType, LoginType>({
            query: body => ({
                url: `${AUTH}/login`,
                method: 'POST',
                body
            })
        }),

        getAccessToken: build.query<AccessTokenData, null>({
            query: () => `${AUTH}/access-token`
        })
    }),
    overrideExisting: false
})

export const { useLoginMutation, useGetAccessTokenQuery } = authApi

export default authApi

type LoginType = {
    login: string
    password: string
}

export type AccessTokenData = {
    accessToken: string
}

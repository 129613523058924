import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const STYLE_GUIDE = 'style-guides'

const styleGuideApi = api.injectEndpoints({
    endpoints: build => ({
        getStyleGuides: build.query<StyleGuidesType, StyleGuideFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${STYLE_GUIDE}?${params}`
            }
        }),

        getStyleGuideList: build.mutation<StyleGuidesType, StyleGuideFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${STYLE_GUIDE}?${params}`
            }
        }),

        createStyleGuide: build.mutation<null, StyleGuideType>({
            query: body => ({
                url: STYLE_GUIDE,
                method: 'POST',
                body
            })
        }),

        updateStyleGuide: build.mutation<null, StyleGuideInfoType>({
            query: body => {
                const { id, ...newBody } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${STYLE_GUIDE}?${params}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),

        deleteStyleGuide: build.mutation<null, Pick<StyleGuideType, 'id'>>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${STYLE_GUIDE}?${params}`,
                    method: 'DELETE'
                }
            }
        })
    }),
    overrideExisting: false
})

export type StyleGuideType = {
    id: number
    name: string
    path: string
}

export type StyleGuidesType = {
    items: StyleGuideInfoType[]
    _meta: TableMetaType
}

export type StyleGuideFilter = { page: number }

export type StyleGuideInfoType = StyleGuideType

export const {
    useGetStyleGuidesQuery,
    useGetStyleGuideListMutation,
    useCreateStyleGuideMutation,
    useUpdateStyleGuideMutation,
    useDeleteStyleGuideMutation
} = styleGuideApi

export default styleGuideApi

import Title from 'antd/lib/typography/Title'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    AssetAttributeType,
    useDeleteAssetAttributeConnectionMutation,
    useUpdateAssetAttributeMutation
} from '../../../../../api/assetApi'
import LogicAttributeBoolean from './LogicAttributeBoolean'
import { Button, Space } from 'antd'
import LogicAttributeNumber from './LogicAttributeNumber'
import LogicAttributeStringEdit from './LogicAttributeStringEdit'
import LogicAttributeAsset from './LogicAttributeAsset'
import { AssetsForAttributes } from '../../assetEditor'

type LogicAttributeEditorProps = {
    item: AssetAttributeType
    assets: AssetsForAttributes
    assetId: number
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const LogicAttributeEditor = ({ item, assetId, setIsOpen, assets }: LogicAttributeEditorProps) => {
    const [attribute, setAttribute] = useState<AssetAttributeType>({
        asset_id: assetId,
        name: item.name,
        type: item.type,
        value: item.value
    })
    const [defaultValue, setDefaultValue] = useState(item.connections.map((connection: any) => 'db_' + connection.id))
    const [options, setOptions] = useState(
        item.connections.map((connection: any) => ({
            value: 'db_' + connection.id,
            label: connection.value
        }))
    )
    const [removedConnections, setRemovedConnections] = useState<any[]>([])

    const [updateAttribute, result] = useUpdateAssetAttributeMutation()
    const [deleteAttributeConnections, resultDelete] = useDeleteAssetAttributeConnectionMutation()
    const { isLoading } = result
    const { isLoading: isLoadingDelete } = resultDelete

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setAttribute(prevState => ({ ...prevState, name: e.target.value }))
    }

    const handleChangeValue = (value: string) => {
        setAttribute(prevState => ({ ...prevState, value: value }))
    }

    const handleChangeStringValue = (value: string[]) => {
        const dbString = filteredSelectedOptions(value, 'db_')
        const newString = filteredSelectedOptions(value, 'db_', true)

        const removedString = item.connections.filter((connection: any) => !dbString.includes(connection.id.toString()))

        setRemovedConnections(removedString.map((item: any) => item.id))

        setAttribute(prevState => ({ ...prevState, value: newString }))

        setDefaultValue(value)
    }

    const handleChangeAssetValue = (value: string) => {
        setAttribute(prevState => ({ ...prevState, value }))
    }

    return (
        <>
            <Title level={4}>Edit Attribute</Title>

            {(attribute.type === '5' || attribute.type === '6' || attribute.type === '7') && (
                <LogicAttributeAsset
                    attribute={attribute}
                    assets={assets}
                    changeName={handleChangeName}
                    changeValue={handleChangeAssetValue}
                    setAttribute={setAttribute}
                />
            )}
            {attribute.type === '2' && (
                <LogicAttributeBoolean
                    attribute={attribute}
                    changeName={handleChangeName}
                    changeValue={handleChangeValue}
                />
            )}
            {attribute.type === '3' && (
                <LogicAttributeNumber
                    attribute={attribute}
                    changeName={handleChangeName}
                    changeValue={handleChangeValue}
                />
            )}
            {attribute.type === '4' && (
                <LogicAttributeStringEdit
                    attribute={attribute}
                    defaultValue={defaultValue}
                    options={options}
                    changeName={handleChangeName}
                    changeValue={handleChangeStringValue}
                />
            )}

            <Space>
                <Button
                    onClick={() => {
                        setIsOpen(false)
                    }}>
                    Cancel
                </Button>

                <Button
                    loading={isLoading && isLoadingDelete}
                    onClick={async () => {
                        await updateAttribute({ id: item.id!, attribute: attribute })

                        if (removedConnections.length !== 0) {
                            await deleteAttributeConnections(removedConnections)
                        }

                        setIsOpen(false)
                    }}
                    type="primary">
                    Update Attribute
                </Button>
            </Space>
        </>
    )
}

export default LogicAttributeEditor

const filteredSelectedOptions = (selected: string[], indicator: string, reverse?: boolean) => {
    if (!Array.isArray(selected)) return []
    const filtered = selected.filter(value => value.startsWith(indicator))
    if (reverse) return selected.filter(value => !value.startsWith(indicator))
    return filtered.map(option => option.slice(indicator.length))
}

import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from 'react'
import { Button, Form, Input, List, Modal } from 'antd'
import { CopyOutlined, DeleteOutlined, DragOutlined, EyeOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ProductRuleCondition from './ProductRuleCondition'
import ProductRuleAction from './ProductRuleAction'
import { ProductRuleType } from '../tabs/ProductRules'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { useAppSelector } from "../../../../hooks";
import toast from "react-hot-toast";
import {useDebouncedCallback} from "use-debounce";

type ProductRuleProps = {
    rule: ProductRuleType
    removedRules: number[]
    setRemovedRules: Dispatch<SetStateAction<number[]>>
}

export const ProductRule = ({ removedRules, setRemovedRules, rule }: ProductRuleProps) => {
    const { role } = useAppSelector(state => state.user)
    const {rules, setRules, fetchRules, requests: {cloneRule, getRules}} = useContext(CatalogContext)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [value, setValue] = useState(rule.name || '');

    const debounced = useDebouncedCallback(
        (name) => setRules(
            rules.map(item => {
                return {
                    ...item,
                    name: item.id === rule.id ? name : item.name,
                    status: item.id === rule.id ? 'changed' : item.status
                }
            })
        ),
        500
    );

    const handleChangeRuleName = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        debounced(e.target.value)
    }

    const handleDeleteRule = (rule: ProductRuleType) => {
        if (!rule.newItem) setRemovedRules([...removedRules, rule.id])
        setRules(rules.filter(item => item.id !== rule.id))
    }

    const handleCloneRule = async (rule_id: string) => {
        const loader = toast.loading('Progressing...')
        const res = await cloneRule({rule_id})
        toast.dismiss(loader)
        if ('error' in res) return
        toast.success('Rule is successfully copied!')
        await fetchRules(getRules)
    }

    return (
        <StyledRuleWrapper key={rule.id} isOpenModal={isOpenModal}>
            <div className="attr-dragdrop">
                <DragOutlined />
            </div>

            <Button onClick={() => setIsOpenModal(true)} type="link" size="small">
                <EyeOutlined />
                {rule.name}
            </Button>

            <div  style={{marginLeft: 'auto'}}>
                {
                    !rule.newItem && role === 'admin' &&
                    <Button onClick={() => handleCloneRule(rule.id.toString())} type="link" size="small">
                        <CopyOutlined/>
                    </Button>
                }

                <Button onClick={() => handleDeleteRule(rule)} type="link" size="small">
                    <DeleteOutlined/>
                </Button>
            </div>

            <Modal
                title="Rule Setup"
                centered
                open={isOpenModal}
                width={'800px'}
                onCancel={() => setIsOpenModal(false)}
                footer={[
                    <Button type="primary" key="save" onClick={() => setIsOpenModal(false)} htmlType="button">
                        Save
                    </Button>
                ]}>
                <Form layout="vertical">
                    <Form.Item label="Name">
                        <Input maxLength={140} placeholder="Name" value={value} onChange={handleChangeRuleName} />
                    </Form.Item>

                    <ProductRuleCondition rule={rule} />
                    <ProductRuleAction rule={rule} />
                </Form>
            </Modal>
        </StyledRuleWrapper>
    )
}

export default ProductRule

type DropdownType = { id: number; label: string; disabled: boolean }

export type ProductRuleActions = Record<string, DropdownType>

const StyledRuleWrapper = styled(List.Item)<{isOpenModal: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 1s;
    background: ${props => props.isOpenModal ? '#3E3E3E' : '#fff'};
`

import { Form, InputNumber, Spin } from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { isArray } from 'underscore'

const ProductCurrencyItem = () => {
    const { product, setProduct, isLoadingProduct } = useContext(CatalogContext)

    const minCurrencyAmount = '0'

    return (
        <Form.Item label="Currency">
            {isLoadingProduct ? (
                <Spin size="small" />
            ) : (
                <InputNumber
                    value={isArray(product.price) ? minCurrencyAmount : product.price}
                    style={{ width: '100%' }}
                    placeholder="99.99"
                    step="0.01"
                    stringMode
                    onChange={e => {
                        setProduct(prevState => ({
                            ...prevState,
                            price: e ?? minCurrencyAmount
                        }))
                    }}
                />
            )}
        </Form.Item>
    )
}

export default ProductCurrencyItem

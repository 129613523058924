import { Form, Select, Spin } from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { ASSET_TYPE, useGetAssetsAllQuery, useGetAssetsQuery } from '../../../../api/assetApi'

const ProductAssetsItem = () => {
    const { product, setProduct } = useContext(CatalogContext)

    const { data: assets, isLoading: isLoadingAssets } = useGetAssetsAllQuery(null)
    const { data: assetsData } = useGetAssetsQuery({ type: ASSET_TYPE.Texture })

    const handleAddAsset = (asset_id: number) => {
        setProduct({ ...product, asset_id })
    }

    return (
        <Form.Item required={true} label="Asset">
            {isLoadingAssets ? (
                <Spin size="small" />
            ) : (
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="Select Asset"
                    value={product.asset_id}
                    onChange={handleAddAsset}>
                    {assets?.assets &&
                        assetsData &&
                        [...assets.assets, ...assetsData].map(asset => {
                            return (
                                <Select.Option key={asset.dbId} value={asset.dbId}>
                                    {asset.name}
                                </Select.Option>
                            )
                        })}
                </Select>
            )}
        </Form.Item>
    )
}

export default ProductAssetsItem

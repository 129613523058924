import React, { Dispatch, SetStateAction } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { BrandInfoType } from '../../../api/brandApi'
import toast from 'react-hot-toast'
import { IndustryType } from '../../../api/industryApi'
import { StyleGuideType } from '../../../api/styleGuideApi'

type BrandFormProps = {
    form: FormInstance<BrandInfoType>
    onFinish: (e: BrandInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>

    industries: IndustryType[]
    styleGuides: StyleGuideType[]
}

const BrandForm = ({ form, refetch, setIsModalOpen, onFinish, industries, styleGuides }: BrandFormProps) => {
    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Name" />
            </Form.Item>

            <Form.Item label="Industry" name="industry_id">
                <Select showSearch allowClear optionFilterProp="children" placeholder="Industry">
                    {industries.map(industry => {
                        return (
                            <Select.Option key={industry.id} value={industry.id}>
                                {industry.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Style Guide" name="style_guide_id">
                <Select showSearch allowClear optionFilterProp="children" placeholder="Style Guide">
                    {styleGuides.map(styleGuide => {
                        return (
                            <Select.Option key={styleGuide.id} value={styleGuide.id}>
                                {styleGuide.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea placeholder="Description" maxLength={200} />
            </Form.Item>
        </Form>
    )
}

export default BrandForm

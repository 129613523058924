import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { parentDomain } from 'utils'
import {
    CatalogLicensePackages,
    CatalogPreviewItemType,
    CatalogShareResponse,
    useGetLicenseShareMutation,
    useGetProductShareMutation
} from '../../api/shareApi'

const IframeViewer = () => {
    const [product, setProduct] = useState<CatalogPreviewItemType | null>(null)
    const [assetData, setAssetData] = useState<CatalogShareResponse | null>(null)
    const [chosenProductID, setChosenProductID] = useState<string>(null!)
    const [importedComponent, setImportedComponent] = useState<any>(null)
    const [packages, setPackages] = useState<CatalogLicensePackages[]>([])
    const [path, setPath] = useState<string>('')

    const [getProduct, resultProduct] = useGetProductShareMutation()
    const [getLicense, resultLicense] = useGetLicenseShareMutation()

    const { id } = useParams()
    const { search } = useLocation()
    const query = useMemo(() => new URLSearchParams(search), [search])

    const importComponent = async (
        path: string,
        packages: CatalogLicensePackages[],
        product: CatalogPreviewItemType | null,
        assetData: CatalogShareResponse | null
    ) => {
        const module = await import(`../../${path}`).catch(
            async () => await import('../catalog/themes/basic/BasicTheme')
        )
        const Theme = module.default

        setImportedComponent(
            <Theme
                id={id}
                product={product}
                setChosenProductID={setChosenProductID}
                assetData={assetData}
                packages={packages}
            />
        )
    }
    useEffect(() => {
        if (path) {
            importComponent(path, packages, product, assetData)
        }
    }, [packages, product])

    useEffect(() => {
        if (!id || !chosenProductID) return
        const body = {
            domain: parentDomain,
            accessToken: id,
            product_id: chosenProductID
        }
        getProduct(body)
    }, [chosenProductID, getProduct, id])

    useEffect(() => {
        if (resultProduct.isSuccess) {
            const product = {
                ...resultProduct.data.product,
                price: Array.isArray(resultProduct.data?.product.price) ? undefined : resultProduct.data?.product.price
            }
            setAssetData(resultProduct.data)
            setProduct(product)
        }
    }, [resultProduct.data, resultProduct.isSuccess])

    useEffect(() => {
        const productId = query.get('productID')
        console.log(productId)
        if (!id || !productId) return

        getLicense({
            domain: parentDomain,
            accessToken: id,
            license_id: productId
        }).then(res => {
            if ('error' in res) return
            setPath(res?.data?.style_guides || 'pages/catalog/themes/basic/BasicTheme')
            setPackages(res.data.products)
        })
    }, [id])

    return importedComponent
}

export default IframeViewer

import { ProductAttributeType } from '../../tabs/ProductAttributes'
import React, { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { Select } from 'antd'
import { useGetAssetAttributesQuery } from '../../../../../api/assetApi'

type ProductAttributeAssetItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeAssetItem = ({ attr }: ProductAttributeAssetItemProps) => {
    const { attributes, setAttributes, product } = useContext(CatalogContext)

    const { data: assetAttributes } = useGetAssetAttributesQuery({
        id: product.asset_id!
    })

    const handleChangeAssetAttribute: HandleChangeAssetAttribute = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                replacement_attribute_id: item.id === attr.id ? value : item.replacement_attribute_id,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    return (
        <>
            {assetAttributes && (
                <Select
                    placeholder="Asset Attribute"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={attr.replacement_attribute_id}
                    onChange={handleChangeAssetAttribute}>
                    {assetAttributes.map(asset_attr => {
                        return (
                            <Select.Option key={asset_attr.id} value={asset_attr.id}>
                                {asset_attr.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            )}
        </>
    )
}

export default ProductAttributeAssetItem

type HandleChangeAssetAttribute = (value: number) => void

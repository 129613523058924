import { Button, Slider, Switch } from 'antd'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TextureFiltersConfig } from './AnnotationTexture'
import { TAnnotationParams, TPatchParams } from './AnnotationsEditor'

type Props = {
    annotationPatch: TPatchParams<TAnnotationParams>
    close: () => void
    imageSource: string
    setAnnotationPatch: Dispatch<SetStateAction<TPatchParams<TAnnotationParams>>>
}

const AnnotationTextureEditor = ({ annotationPatch, close, imageSource, setAnnotationPatch }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [image, setImage] = useState<HTMLImageElement | null>(null)

    const handleFilterChange = (value: number | boolean, filterName: string) => {
        setAnnotationPatch(current => {
            return {
                ...current,
                [filterName]: value
            }
        })
    }

    const handleSave = () => {
        const canvas = canvasRef.current
        if (!canvas) {
            return
        }
        const base64String = canvas.toDataURL('image/png')
        setAnnotationPatch(current => {
            return {
                ...current,
                textureModified: base64String
            }
        })
        close()
    }

    useEffect(() => {
        const img = new Image()
        img.crossOrigin = '*'
        img.src = imageSource
        img.onload = () => {
            setImage(img)
        }
    }, [imageSource])

    useEffect(() => {
        const canvas = canvasRef.current
        if (!image || !canvas) {
            return
        }
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.filter = `
            hue-rotate(${annotationPatch.hue}deg) 
            brightness(${annotationPatch.brightness}%) 
            contrast(${annotationPatch.contrast}%) 
            saturate(${annotationPatch.saturation}%)
            ${annotationPatch.invert ? 'invert(100%)' : ''}
        `
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        const base64String = canvas.toDataURL('image/png')
        setAnnotationPatch(current => {
            return {
                ...current,
                textureModified: base64String
            }
        })
    }, [
        annotationPatch.brightness,
        annotationPatch.contrast,
        annotationPatch.hue,
        annotationPatch.invert,
        annotationPatch.saturation,
        image,
        setAnnotationPatch
    ])

    return (
        <Component>
            <CanvasWrapper>
                <canvas ref={canvasRef} width={240} height={160}></canvas>
            </CanvasWrapper>
            <OptionsList>
                <OptionRow>
                    <OptionHeader>
                        <OptionLabel>HUE</OptionLabel>
                        <OptionValue>{annotationPatch.hue}</OptionValue>
                    </OptionHeader>
                    <Slider
                        defaultValue={Number(annotationPatch.hue)}
                        min={0}
                        max={360}
                        onChange={value => {
                            handleFilterChange(value, 'hue')
                        }}
                        value={typeof annotationPatch.hue === 'number' ? annotationPatch.hue : 0}
                    />
                </OptionRow>
                <OptionRow>
                    <OptionHeader>
                        <OptionLabel>Saturation</OptionLabel>
                        <OptionValue>{annotationPatch.saturation} %</OptionValue>
                    </OptionHeader>
                    <Slider
                        defaultValue={Number(annotationPatch.saturation)}
                        min={0}
                        max={200}
                        onChange={value => {
                            handleFilterChange(value, 'saturation')
                        }}
                        value={typeof annotationPatch.saturation === 'number' ? annotationPatch.saturation : 0}
                    />
                </OptionRow>
                <OptionRow>
                    <OptionHeader>
                        <OptionLabel>Brightness</OptionLabel>
                        <OptionValue>{annotationPatch.brightness} %</OptionValue>
                    </OptionHeader>
                    <Slider
                        defaultValue={Number(annotationPatch.brightness)}
                        min={0}
                        max={200}
                        onChange={value => {
                            handleFilterChange(value, 'brightness')
                        }}
                        value={typeof annotationPatch.brightness === 'number' ? annotationPatch.brightness : 0}
                    />
                </OptionRow>
                <OptionRow>
                    <OptionHeader>
                        <OptionLabel>Contrast</OptionLabel>
                        <OptionValue>{annotationPatch.contrast} %</OptionValue>
                    </OptionHeader>
                    <Slider
                        defaultValue={Number(annotationPatch.contrast)}
                        min={0}
                        max={200}
                        onChange={value => {
                            handleFilterChange(value, 'contrast')
                        }}
                        value={typeof annotationPatch.contrast === 'number' ? annotationPatch.contrast : 0}
                    />
                </OptionRow>
                <OptionRow>
                    <OptionHeader>
                        <OptionLabel>Invert</OptionLabel>
                        <Switch
                            checked={Boolean(annotationPatch.invert)}
                            onChange={value => {
                                handleFilterChange(value, 'invert')
                            }}
                        />
                    </OptionHeader>
                </OptionRow>
            </OptionsList>
            <Button onClick={handleSave} style={{ backgroundColor: '#1890ff' }} type="primary">
                Ok
            </Button>
        </Component>
    )
}

export default AnnotationTextureEditor

const CanvasWrapper = styled.div`
    width: 100%;
`

const Component = styled.div`
    align-items: center;
    background-color: #efefef !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 0 16px 0;
`

const OptionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    width: 100%;
`

const OptionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`

const OptionLabel = styled.div``

const OptionValue = styled.div``

const OptionRow = styled.div`
    display: flex;
    flex-direction: column;
`

import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { AssetAttributeType } from '../../../../../api/assetApi'
import { AssetsForAttributes } from '../../assetEditor'
import { v4 as uuid } from 'uuid'

const { Option } = Select

type LogicAttributeAssetProps = {
    assets: AssetsForAttributes
    attribute: AssetAttributeType
    setAttribute: Dispatch<SetStateAction<AssetAttributeType>>
    changeName: (e: ChangeEvent<HTMLInputElement>) => void
    changeValue: (e: string) => void
}

const LogicAttributeAsset = ({
    attribute,
    changeName,
    assets,
    setAttribute,
    changeValue
}: LogicAttributeAssetProps) => {
    const [options, setOptions] = useState<{ value: string; label: string }[]>([])

    const modelOptions = assets.models.map(part => ({
        value: part.id,
        label: part.name ? part.name : `${part.type} ${part.id}`
    }))
    const textureOptions = assets.textures.map(part => ({
        value: part.id,
        label: part.name ? part.name : `${part.type} ${part.id}`
    }))
    const materialOptions = assets.materials.map(part => ({
        value: part.id,
        label: part.name ? part.name : `${part.type} ${part.id}`
    }))

    useEffect(() => {
        if (attribute.type === '5') {
            setOptions(modelOptions)
        }

        if (attribute.type === '6') {
            setOptions(textureOptions)
        }

        if (attribute.type === '7') {
            setOptions(materialOptions)
        }

        setAttribute(prevState => {
            return { ...prevState, type: attribute.type }
        })
    }, [attribute.type])

    return (
        <div style={{ marginBottom: 16 }}>
            <Form.Item>
                <Input maxLength={100} value={attribute.name} onChange={e => changeName(e)} placeholder="Name" />
            </Form.Item>

            <Form.Item>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp="children"
                    value={attribute.type}
                    placeholder="Select Type"
                    onSelect={value => {
                        setAttribute(prevState => ({ ...prevState, value: '', type: value }))
                    }}>
                    {typeOptions.map(option => (
                        <Option value={option.value} key={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp="children"
                    value={attribute.value ? (attribute.value as string) : null}
                    onChange={value => {
                        changeValue(value)
                    }}
                    placeholder="Select Default Value">
                    {options.map(option => (
                        <Option value={option.value} key={uuid()}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    )
}

export default LogicAttributeAsset

const typeOptions = [
    {
        label: 'Model',
        value: '5'
    },
    {
        label: 'Texture',
        value: '6'
    },
    {
        label: 'Material',
        value: '7'
    }
]

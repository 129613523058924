import { DeleteOutlined, DownOutlined, DragOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown, List, Space, Spin } from 'antd'
import { ProductAttributeConnection } from 'api/shareApi'
import { useContext, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { onDragEndAttributes, updateProductItems } from '../../../../utils'
import ProductAttributeColor from '../attributes/ProductAttributeColor'
import ProductAttributeImage from '../attributes/ProductAttributeImage'
import ProductAttributeNumber from '../attributes/ProductAttributeNumber'
import ProductAttributePartReference from '../attributes/ProductAttributePartReference'
import ProductAttributeSingle from '../attributes/ProductAttributeSingle'
import ProductAttributeString from '../attributes/ProductAttributeString'
import { StyledCatalogBtnWrapper } from './ProductInfo'

const { Item } = List

type ProductAttributesProps = {
    isHiddenAssetAttributes: boolean
}

const ProductAttributes = ({ isHiddenAssetAttributes }: ProductAttributesProps) => {
    const { requests } = useContext(CatalogContext)

    const { getAttributes, createAttributes, updateAttributes, deleteAttributes } = requests

    const { attributes, setAttributes, isLoadingAttributes, fetchAttributes } = useContext(CatalogContext)

    const { id: productId } = useParams()

    const [isLoadingBtn, setIsLoadingBtn] = useState(false)

    const [removedAttributes, setRemovedAttributes] = useState<number[]>([])

    const handleCreateAttribute = (type: ProductAttributeItemKeyType) => {
        let sorting = 1
        attributes.forEach(item => {
            if (item.sorting > sorting) {
                sorting = item.sorting
            }
        })

        const attr: ProductAttributeType = {
            newItem: 'new',
            id: Date.now(),
            annotation_id: null,
            name: '',
            description: '',
            product_id: productId!,
            parent_id: null,
            replacement_attribute_id: null,
            is_hide: 0,
            is_color: 0,
            is_morph_target: 0,
            default_value: null,
            type,
            value: '',
            imageName: null,
            image: null,
            status: null,
            sorting,
            discount_type: 0
        }

        if (type === 'string') {
            attr.value = []
        }

        if (type === 'number') {
            attr.value = {}
        }

        return attr
    }

    const onClick = (e: { key: string }) => {
        const newAttr = handleCreateAttribute(e.key as ProductAttributeItemKeyType)
        setAttributes([...attributes, newAttr])
    }

    const handleDeleteAttribute = (attr: ProductAttributeType) => {
        if (!attr.newItem) setRemovedAttributes([...removedAttributes, attr.id])
        setAttributes(attributes.filter(item => item.id !== attr.id))
    }

    const handleUpdateAttributes = async () => {
        setIsLoadingBtn(true)
        const { createdStatus, updatedStatus, deletedStatus } = await updateProductItems(
            attributes,
            removedAttributes,
            createAttributes,
            updateAttributes,
            deleteAttributes
        )
        setIsLoadingBtn(false)

        if (createdStatus && updatedStatus && deletedStatus) {
            setRemovedAttributes([])

            await fetchAttributes(getAttributes)
        }
    }

    const renderItem = (item: ProductAttributeType) => {
        return (
            <Item key={item.id}>
                <StyledAttributeTitle>{item.type}</StyledAttributeTitle>

                <StyledAttribute>
                    <div className="attr-dragdrop">
                        <DragOutlined />
                    </div>

                    {item.type === 'number' && (
                        <ProductAttributeNumber isHiddenAssetAttributes={isHiddenAssetAttributes} attr={item} />
                    )}
                    {item.type === 'string' && <ProductAttributeString attr={item} />}
                    {item.type === 'single' && (
                        <ProductAttributeSingle isHiddenAssetAttributes={isHiddenAssetAttributes} attr={item} />
                    )}
                    {item.type === 'color' && (
                        <ProductAttributeColor isHiddenAssetAttributes={isHiddenAssetAttributes} attr={item} />
                    )}
                    {item.type === 'part_reference' && (
                        <ProductAttributePartReference isHiddenAssetAttributes={isHiddenAssetAttributes} attr={item} />
                    )}
                    {item.type === 'image' && (
                        <ProductAttributeImage isHiddenAssetAttributes={isHiddenAssetAttributes} attr={item} />
                    )}

                    <Button type="link" size="small" onClick={() => handleDeleteAttribute(item)}>
                        <DeleteOutlined />
                    </Button>
                </StyledAttribute>
            </Item>
        )
    }

    return (
        <div className="catalog">
            <Card title="Attributes">
                {isLoadingAttributes ? (
                    <Spin size="small" />
                ) : (
                    <ReactDragListView
                        nodeSelector=".catalog .ant-list-item"
                        handleSelector=".catalog .attr-dragdrop"
                        lineClassName="dragLine"
                        onDragEnd={(fromIndex, toIndex) =>
                            setAttributes(onDragEndAttributes<ProductAttributeType>(fromIndex, toIndex, attributes))
                        }>
                        <List
                            itemLayout="vertical"
                            dataSource={attributes}
                            renderItem={renderItem}
                            footer={
                                <StyledCatalogBtnWrapper>
                                    <Dropdown menu={{ items, onClick }}>
                                        <Button loading={isLoadingBtn}>
                                            <Space>
                                                Add Attribute <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                    <Button loading={isLoadingBtn} onClick={handleUpdateAttributes} type="primary">
                                        Update Attributes
                                    </Button>
                                </StyledCatalogBtnWrapper>
                            }
                        />
                    </ReactDragListView>
                )}
            </Card>
        </div>
    )
}

export default ProductAttributes

export type ProductAttributeType = {
    newItem?: 'new'
    id: number
    annotation_id: number | null
    product_id: string | null
    parent_id: number | null
    description: string | null
    name: string
    type: ProductAttributeItemKeyType
    replacement_attribute_id: number | null
    is_hide: 0 | 1
    is_color: 0 | 1
    is_morph_target: 0 | 1
    value: string | string[] | ProductsAndTagsType | { min: number; max: number; product_id: string | string[] }
    sorting: number
    imageName: string | null
    image: string | null
    status: 'changed' | null
    default_value: null | string
    connections?: ProductAttributeConnection[]
    discount_type: number
}

export type ProductsAndTagsType = { products?: number[]; tags?: string[] }

export type ProductAttributeItemKeyType =
    | 'string'
    | 'number'
    | 'part_reference'
    | 'image'
    | 'category'
    | 'single'
    | 'color'

const items: { label: string; key: ProductAttributeItemKeyType; disabled?: boolean }[] = [
    {
        label: 'String',
        key: 'string',
        disabled: false
    },
    {
        label: 'Number',
        key: 'number',
        disabled: false
    },
    {
        label: 'Single',
        key: 'single',
        disabled: false
    },
    {
        label: 'Color',
        key: 'color',
        disabled: false
    },
    {
        label: 'Image',
        key: 'image',
        disabled: false
    },
    {
        label: 'Part Reference',
        key: 'part_reference',
        disabled: false
    }
]

export const StyledAttributeTitle = styled.div`
    font-size: 11px;
    padding-left: 22px;
`

export const StyledAttribute = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`

export const StyledAttributeItem = styled.div<{ repeat: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.repeat}, 1fr);
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;

    > * {
        width: 100%;
    }
`

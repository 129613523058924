import api from './index'

const TFA = 'tfa'

const tfaApi = api.injectEndpoints({
    endpoints: build => ({
        generateTFASecret: build.mutation<GenerateSecretType, null>({
            query: () => `${TFA}/generate-secret`
        }),

        setupTFA: build.mutation<UpgradedAccessTokenType, TFACodeType & TFASecretType>({
            query: body => ({
                url: `${TFA}/setup`,
                method: 'POST',
                body
            })
        }),

        sendTFAResetEmail: build.mutation<null, null>({
            query: () => ({
                url: `${TFA}/reset-link`,
                method: 'POST'
            })
        }),

        resetTFA: build.query<null, { token: string }>({
            query: body => ({
                url: `${TFA}/reset`,
                method: 'POST',
                body
            })
        }),

        checkTFA: build.mutation<UpgradedAccessTokenType, TFACodeType>({
            query: body => ({
                url: `${TFA}/check`,
                method: 'POST',
                body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useResetTFAQuery,
    useCheckTFAMutation,
    useGenerateTFASecretMutation,
    useSetupTFAMutation,
    useSendTFAResetEmailMutation
} = tfaApi

export default tfaApi

type TFASecretType = { secret: string }
type TFACodeType = { code: number }
type UpgradedAccessTokenType = { upgradedAccessToken: string }
type GenerateSecretType = { QRImageSrc: string } & TFASecretType

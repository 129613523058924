import PageBuilder from '../../components/panel/PageBuilder'
import { Button, Form, Input, Space, Spin } from 'antd'
import ModalWithForm from '../users/ModalWithForm'
import { useForm } from 'antd/es/form/Form'
import { useState } from 'react'
import toast from 'react-hot-toast'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useAppSelector } from '../../hooks'
import { useGenerateTFASecretMutation, useSendTFAResetEmailMutation, useSetupTFAMutation } from '../../api/tfaApi'

const SetupTFA = () => {
    const { tfaIsConfigured } = useAppSelector(state => state.user)

    const [formSetup] = useForm()
    const [isModalOpenSetup, setIsModalOpenSetup] = useState(false)
    const [isModalOpenCode, setIsModalOpenCode] = useState(false)

    const [generateSecret, resultsGenerateSecret] = useGenerateTFASecretMutation()
    const [setupTFA, resultsSetup] = useSetupTFAMutation()
    const [sendEmail] = useSendTFAResetEmailMutation()

    return (
        <PageBuilder title="Two-Factor Authentication" documentTitle="Two-Factor Authentication">
            <Space>
                {tfaIsConfigured || resultsSetup.isSuccess ? (
                    <Button
                        onClick={async () => {
                            const loader = toast.loading('Progressing...')
                            const res = await sendEmail(null)
                            toast.dismiss(loader)
                            if ('error' in res) return
                            toast.success('Reset link is successfully sent to Email!')
                        }}
                        type="primary">
                        Reset Active 2FA
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            setIsModalOpenSetup(true)
                            generateSecret(null)
                        }}
                        type="primary">
                        Setup TFA
                    </Button>
                )}
            </Space>

            <ModalWithForm
                title="Setup TFA for more security"
                isLoading={!resultsGenerateSecret.isSuccess}
                isOpen={isModalOpenSetup}
                setIsOpen={setIsModalOpenSetup}
                submit={() => {
                    setIsModalOpenSetup(false)
                    setIsModalOpenCode(true)
                    resultsGenerateSecret.data && formSetup.setFieldValue('secret', resultsGenerateSecret.data.secret)
                }}>
                {resultsGenerateSecret.isSuccess ? (
                    <>
                        <p>1. Download Google Authenticator on your device</p>
                        <p>2. Click add button in your app and scan QR code below</p>
                        <p style={{ textAlign: 'center' }}>
                            <img src={resultsGenerateSecret.data.QRImageSrc} alt="" />
                        </p>
                        <p>3. Alternatively, you can enter secret key below manually.</p>
                        <Paragraph copyable>{resultsGenerateSecret.data.secret}</Paragraph>
                        <p>
                            Make sure you select <b>time based</b> authentication.
                        </p>
                        <p>4.Click Confirm to activate 2FA for your account.</p>
                        <p>5. For next time OTP code will be required when you log in admin panel.</p>
                    </>
                ) : (
                    <Spin size="large" />
                )}
            </ModalWithForm>

            <ModalWithForm
                title="TFA Code"
                isLoading={resultsSetup.isLoading}
                isOpen={isModalOpenCode}
                setIsOpen={setIsModalOpenCode}
                submit={formSetup.submit}>
                <Form
                    form={formSetup}
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    onFinish={async e => {
                        const loader = toast.loading('Progressing...')
                        const res = await setupTFA(e)
                        toast.dismiss(loader)
                        if ('error' in res) return
                        toast.success('TFA Setup is successfully completed!')
                        setIsModalOpenCode(false)
                        formSetup.resetFields()
                    }}>
                    <Form.Item name="secret" noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                        <Input showCount maxLength={6} />
                    </Form.Item>
                </Form>
            </ModalWithForm>
        </PageBuilder>
    )
}

export default SetupTFA

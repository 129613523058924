import {
    PaymentCardIDType,
    useAddCompanyCardMutation,
    useDeleteCompanyCardMutation,
    useGetCompanyCardQuery
} from '../../api/paymentApi'
import Payment from './Payment'

const CompanyPayment = () => {
    const { data, isLoading, refetch } = useGetCompanyCardQuery(null)
    const [addCard, resultsAddCard] = useAddCompanyCardMutation()
    const [deleteCard] = useDeleteCompanyCardMutation()

    const handleAddCard = async () => {
        return await addCard(null)
    }

    const handleDeleteCard = async ({ id }: PaymentCardIDType) => {
        return await deleteCard({ id })
    }

    return (
        <Payment
            addCard={handleAddCard}
            deleteCard={handleDeleteCard}
            isLoadingAddCard={resultsAddCard.isLoading}
            isLoadingCards={isLoading}
            refetchCards={refetch}
            cards={data ? data : []}
        />
    )
}

export default CompanyPayment

import { FilePondFile } from 'filepond'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../redux/store'

interface IErrorUpload {
    message: string | JSX.Element
    code: number
}

interface IUploadState {
    files: {
        [key: string]: FilePondFile[] | File
    }
    error: {
        [key: string]: IErrorUpload | null
    }
}

const initialState: IUploadState = {
    files: {},
    error: {}
}

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        addFilesToUpload: (state, action: { payload: { key: string; value: FilePondFile[] | File } }) => {
            state.files[action.payload.key] = action.payload.value
        },
        setErrorUpload: (state, action: { payload: { key: string; value: IErrorUpload | null } }) => {
            state.error[action.payload.key] = action.payload.value
        }
    }
})

export const { addFilesToUpload, setErrorUpload } = uploadSlice.actions

export const uploadFilesState = (state: RootState): IUploadState['files'] => state.upload.files
export const uploadFilesError = (state: RootState): IUploadState['error'] => state.upload.error

export default uploadSlice

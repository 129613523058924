import { Form, FormInstance, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetCountryQuery, useGetStatesByCountryMutation } from '../../../api/countryApi'
import toast from 'react-hot-toast'
import { DealerInfoType } from '../../../api/dealerApi'
import { CurrencyType } from '../../../api/priceApi'

type DealerFormProps = {
    form: FormInstance<DealerInfoType>
    onFinish: (e: DealerInfoType) => Promise<any>
    onSubmitted: () => void
    countryId?: number
    currencies?: CurrencyType[]
    mode: 'creating' | 'registration' | 'editing'
}

const DealerForm = ({ form, mode, countryId, currencies, onSubmitted, onFinish }: DealerFormProps) => {
    const { data: countries } = useGetCountryQuery()
    const [getStatesByCountry, resultsStatesByCountry] = useGetStatesByCountryMutation()
    const { data: states } = resultsStatesByCountry

    useEffect(() => {
        if (typeof countryId === 'number') {
            getStatesByCountry({ id: countryId })
        }
    }, [countryId])

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                onSubmitted()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="First Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="First Name" />
            </Form.Item>

            <Form.Item label="Last Name" name="lastname" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Last Name" />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                <Input
                    maxLength={40}
                    type="email"
                    placeholder="Email"
                />
            </Form.Item>

            {mode === 'editing' && (
                <Form.Item label="Lead Email" name="leads_email">
                    <Input maxLength={40} type="email" placeholder="Lead Email" />
                </Form.Item>
            )}

            {mode === 'editing' && (
                <Form.Item rules={[{ required: true }]} label="Currency" name="currency_id">
                    <Select showSearch allowClear optionFilterProp="children" placeholder="Currency">
                        {currencies
                            ? currencies.map(currency => {
                                  return (
                                      <Select.Option key={currency.id} value={currency.id}>
                                          {currency.name}
                                      </Select.Option>
                                  )
                              })
                            : null}
                    </Select>
                </Form.Item>
            )}

            {
                <Form.Item label="Country" name="country_id" rules={[{ required: true }]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Country"
                        onChange={(id: number) => {
                            getStatesByCountry({ id })
                            form.setFieldValue('country_id', id)
                            form.setFieldValue('state_id', null)
                        }}>
                        {countries
                            ? countries.map(country => (
                                  <Select.Option key={country.id} value={country.id}>
                                      {country.name}
                                  </Select.Option>
                              ))
                            : null}
                    </Select>
                </Form.Item>
            }

            {states && states.length !== 0 && (
                <Form.Item label="State/Province" name="state_id" rules={[{ required: true }]}>
                    <Select showSearch optionFilterProp="children" placeholder="State/Province">
                        {states.map(country => (
                            <Select.Option key={country.id} value={country.id}>
                                {country.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            <Form.Item label="City" name="city" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="City" />
            </Form.Item>

            <Form.Item label="Street Address" name="street" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Street Address" />
            </Form.Item>

            <Form.Item label="Zip/Postal Code" name="zip_code" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Zip/Postal Code" />
            </Form.Item>

            <Form.Item label="Business Name" name="business_name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Business Name" />
            </Form.Item>

            <Form.Item label="Business Phone" name="business_phone" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Business Phone" />
            </Form.Item>

            <Form.Item label="Business URL" name="business_url" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="https://example.com" />
            </Form.Item>
        </Form>
    )
}

export default DealerForm

import { useEffect, useState } from 'react'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryApi, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { TableMetaType } from '../components/ExtendedTable'
import {isArray} from "underscore";

type HookType = UseMutation<
    MutationDefinition<
        any,
        (
            args: any,
            api: BaseQueryApi,
            extraOptions: {}
        ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
        any,
        any,
        'api'
    >
>

export const useFetchByPagination = <K>(hook: HookType, params?: any): [K[], boolean] => {
    const [isPending, setIsPending] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [get] = hook()
    const [meta, setMeta] = useState<TableMetaType>({ page_count: 1, current_page: 1, page_size: 0, total_count: 0 })
    const [page, setPage] = useState(0)
    const [items, setItems] = useState<K[]>([])

    const fetchData = async (params = {}) => {
        setIsPending(true)

        const res = await get({ page: page + 1, ...params })
        if ('error' in res) return
        const meta = res.data._meta
        if (!meta) return

        setMeta(meta)

        setPage(prevState => prevState + 1)
        const key = Object.keys(res.data)[0]
        const data = res.data[key]
        setItems([...items, ...(isArray(data) ? data : [])])

        setIsPending(false)
    }

    const handleFetch = async (params: any) => {
        if (!isPending) {
            if (!isPending && meta.page_count !== 0 && meta.current_page !== 0 && meta.page_count !== page) {
                fetchData(params)
            } else {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        handleFetch(params)
    }, [isPending])

    return [items, isLoading]
}

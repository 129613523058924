import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../pages/authorization/Login'
import ForgotPassword from '../pages/authorization/ForgotPassword'
import SetPassword from '../pages/authorization/SetPassword'
import { routesUrl } from './urls'

const AuthRouter = () => {
    return (
        <Routes>
            <Route path="" element={<Navigate to={routesUrl.login} />} />

            <Route path={routesUrl.login} element={<Login />} />

            <Route path={routesUrl.forgotPassword} element={<ForgotPassword />} />

            <Route path={`${routesUrl.setPassword}/:token`} element={<SetPassword />} />

            <Route path="*" element={<Navigate to={routesUrl.login} />} />
        </Routes>
    )
}

export default AuthRouter

import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'
import ProductAttributeNameItem from './items/ProductAttributeNameItem'
import ProductAttributeParentIdItem from './items/ProductAttributeParentIdItem'
import ProductAttributeIsHideItem from './items/ProductAttributeIsHideItem'
import ProductAttributeAssetItem from './items/ProductAttributeAssetItem'
import ProductAttributeProductsTagsItem from './items/ProductAttributeProductsTagsItem'
import ProductAttributeDescriptionItem from "./items/ProductAttributeDescription";
import ProductAttributeDiscountItem from "./items/ProductAttributeDiscountItem";

type ProductAttributePartReferenceProps = {
    attr: ProductAttributeType
    isHiddenAssetAttributes: boolean
}

const ProductAttributePartReference = ({ attr, isHiddenAssetAttributes }: ProductAttributePartReferenceProps) => {
    return (
        <div style={{ width: '100%' }}>
            <StyledAttributeItem repeat={2}>
                <ProductAttributeNameItem attr={attr} />

                <ProductAttributeParentIdItem attr={attr} />

                {!isHiddenAssetAttributes && <ProductAttributeAssetItem attr={attr} />}

                <ProductAttributeProductsTagsItem attr={attr} />

                <ProductAttributeDescriptionItem attr={attr} />
                <ProductAttributeDiscountItem attr={attr} />
            </StyledAttributeItem>

            <ProductAttributeIsHideItem attr={attr} />
        </div>
    )
}

export default ProductAttributePartReference

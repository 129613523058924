import { StyledAuthBlock, StyledAuthWrapper } from '../styles'
import Title from 'antd/lib/typography/Title'
import { useSetDocumentTitle } from '../../../hooks'
import { ReactNode } from 'react'

type AuthWrapperProps = {
    title: string
    children: ReactNode
}

const AuthWrapper = ({ title, children }: AuthWrapperProps) => {
    useSetDocumentTitle({ title })

    return (
        <StyledAuthWrapper>
            <StyledAuthBlock>
                <Title level={2}>{title}</Title>

                {children}
            </StyledAuthBlock>
        </StyledAuthWrapper>
    )
}

export default AuthWrapper

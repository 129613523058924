import { useDragLayer } from 'react-dnd'
import React from 'react'
import { useAppSelector } from '../../../hooks'
import { selectSelectedRows } from '../../../selectors/assetsSliceSelectors'

interface Props {
    id: string
    rowRef: React.RefObject<HTMLTableRowElement> | null
    render: (styles: any, className: string) => React.ReactElement
}

export const CustomDragLayer = (props: Props): React.ReactNode => {
    const { id, rowRef, render } = props

    const { isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }))

    const selectedRows = useAppSelector(selectSelectedRows)
    const indexWithinSelected = selectedRows.findIndex(r => r.id === id)

    if (!isDragging || indexWithinSelected === -1) {
        return null
    }

    // @ts-ignore
    return render(...getItemStyles(initialOffset, currentOffset, indexWithinSelected, selectedRows.length, rowRef))
}

function getItemStyles(
    initialOffset: any,
    currentOffset: any,
    index: number,
    amount: number,
    rowRef: React.RefObject<HTMLTableRowElement> | null
) {
    const rangeY = 50
    const rangeX = rangeY / 2
    const shiftY = rangeY / amount
    const shiftX = rangeX / amount

    if (!initialOffset || !currentOffset) {
        return [{}, 'dnd-table__row_hidden']
    }

    let { x, y } = currentOffset || {}

    const rect = rowRef && rowRef.current ? rowRef.current.getBoundingClientRect() : null

    const transform = `translate(${x + shiftX * index}px, ${y + shiftY * index}px)`
    return [
        {
            transform,
            WebkitTransform: transform,
            width: rect ? `${rect.width}px` : '100%',
            height: rect ? `${rect.height}px` : '100%'
        },
        'dnd-table__row_floating'
    ]
}

export default CustomDragLayer

import { AMAOption, utilsEditor } from '@amaspace-editor/editor-3d'
import { ProductAttributeType } from 'api/shareApi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ColorPicker from 'react-best-gradient-color-picker'
import styles from './OptionColorPicker.module.scss'

type TOptionsGroup = {
    attribute: ProductAttributeType
    options: AMAOption[]
}

type Props = {
    optionsGroup: TOptionsGroup
}

const OptionColorPicker = ({ optionsGroup }: Props) => {
    const editor = utilsEditor()
    const [color, setColor] = useState<string | undefined>(undefined)
    const options = useMemo(() => optionsGroup.options, [optionsGroup.options])

    const checkIfMaterialIsInOptions = useCallback(
        (materialId: string) => {
            return options.map(option => option.customizer_id).includes(materialId)
        },
        [options]
    )

    useEffect(() => {
        const updatedMaterials = editor.sceneMaterials.map(material => {
            const isMaterialInOptions = checkIfMaterialIsInOptions(material.id)
            if (isMaterialInOptions) {
                return {
                    ...material,
                    properties: {
                        ...material.properties,
                        color: color
                    }
                }
            }
            return material
        })
        if (!updatedMaterials.length) {
            return
        }
        editor.setSceneMaterials(updatedMaterials as any)
    }, [checkIfMaterialIsInOptions, color])

    useEffect(() => {
        if (color) {
            return
        }
        const materialColor = editor.sceneMaterials.find(material => checkIfMaterialIsInOptions(material.id))
            ?.properties.color
        if (!materialColor) {
            return
        }
        setColor(String(materialColor))
    }, [checkIfMaterialIsInOptions, color, editor.sceneMaterials])

    return (
        <div className={styles.component}>
            <ColorPicker
                hideOpacity
                hideGradientAngle
                hidePresets
                hideEyeDrop
                hideGradientType
                hideAdvancedSliders
                hideColorGuide
                hideInputType
                hideColorTypeBtns
                value={color}
                onChange={setColor}
            />
        </div>
    )
}

export default OptionColorPicker

import { DatePicker, Table } from 'antd'
import PageBuilder from '../../components/panel/PageBuilder'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DealerAnalyticsType, DealerAnalyticsFilter, useGetDealerAnalyticsMutation } from 'api/analyticsApi'
import { v4 as uuid } from 'uuid'
import toast from 'react-hot-toast'

const { RangePicker } = DatePicker

const format = 'YYYY-MM-DD'
const currentDate = new Date().toISOString().slice(0, 10)

const columns = [
    {
        title: 'Name',
        dataIndex: 'product_name',
        key: 'product_name'
    },
    {
        title: 'Page Visit',
        dataIndex: 'page_visit',
        key: 'page_visit'
    },
    {
        title: 'Lead Generate',
        dataIndex: 'lead_generate',
        key: 'lead_generate'
    },
    {
        title: 'Lead Conversion',
        dataIndex: 'lead_conversion',
        key: 'lead_conversion',
        render: (text: string) => <>{`${text}%`}</>
    }
]

const DealerAnalytics = () => {
    const [filters, setFilters] = useState<DealerAnalyticsFilter>({
        date_start: currentDate,
        date_end: currentDate
    })

    const [data, setData] = useState<DealerAnalyticsType[]>([])

    const [getAnalytics, results] = useGetDealerAnalyticsMutation()

    useEffect(() => {
        const fetchData = async (filters: DealerAnalyticsFilter) => {
            const loader = toast.loading('Progressing...')
            const res = await getAnalytics(filters)
            toast.dismiss(loader)
            if ('error' in res) return
            setData(results.data ? results.data : [])
        }

        fetchData(filters)
    }, [filters])

    return (
        <PageBuilder documentTitle="Analytics" title="Analytics">
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                <RangePicker
                    format={format}
                    defaultValue={[moment(currentDate, format), moment(currentDate, format)]}
                    onChange={(_, dates) => {
                        setFilters(prevState => ({
                            ...prevState,
                            date_start: dates[0],
                            date_end: dates[1]
                        }))
                    }}
                />
            </div>

            <Table
                rowKey={() => uuid()}
                loading={results.isLoading}
                pagination={false}
                dataSource={data}
                columns={columns}
            />
        </PageBuilder>
    )
}

export default DealerAnalytics

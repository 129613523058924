import {useParams, useSearchParams} from 'react-router-dom'
import PageBuilder from '../../../components/panel/PageBuilder'
import { Button, Form } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import ModalWithForm from '../../users/ModalWithForm'
import {useContext, useEffect, useState} from 'react'
import {
    LicensePriceFilter,
    LicensePriceInfoType,
    LicensePriceType,
    useCreateLicensePriceMutation,
    useDeleteLicensePriceMutation,
    useGetLicensePricesQuery,
    useUpdateLicensePriceMutation
} from '../../../api/licenseApi'
import LicensePriceForm from './LicensePriceForm'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import ExtendedTable from '../../../components/ExtendedTable'
import toast from 'react-hot-toast'

const LicensePrices = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams()
    const { showModal } = useContext(ModalConfirmContext)

    const [formCreate] = Form.useForm<LicensePriceInfoType>()
    const [formUpdate] = Form.useForm<LicensePriceInfoType>()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [filter, setFilter] = useState<LicensePriceFilter>({
        page: 1,
        id: id!
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")

        const page = parseInt(pageParam ?? '1')

        setFilter(prevState => ({
            ...prevState,
            page: Number.isNaN(page) ? 1 : page,
        }))
    }, [searchParams]);

    const [create, resultsCreate] = useCreateLicensePriceMutation()
    const [update, resultsUpdate] = useUpdateLicensePriceMutation()
    const [deleteItem] = useDeleteLicensePriceMutation()

    const { data, isLoading, refetch } = useGetLicensePricesQuery(filter)

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, item: LicensePriceType) => (
                <>
                    <Button
                        onClick={() => {
                            const { amount, id } = item
                            formUpdate.setFieldsValue({ id, amount })
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDeleteUser = async () => {
                                const { id } = item
                                const loader = toast.loading('Progressing...')
                                const res = await deleteItem({ id })
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Price is successfully deleted!')
                                refetch()
                            }

                            showModal('delete this price', handleDeleteUser)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    return (
        <PageBuilder title={`License Prices: ${id}`} documentTitle={`License Prices: ${id}`}>
            <div style={{ marginBottom: 20 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setIsModalOpenCreate(true)
                        formCreate.setFieldValue('license_id', id)
                    }}>
                    <PlusOutlined /> Add Price
                </Button>
            </div>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="license_price"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Price"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <LicensePriceForm
                    refetch={refetch}
                    setIsModalOpen={setIsModalOpenCreate}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Price"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                isLoading={resultsUpdate.isLoading}
                submit={formUpdate.submit}>
                <LicensePriceForm
                    refetch={refetch}
                    setIsModalOpen={setIsModalOpenUpdate}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default LicensePrices

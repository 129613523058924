import { AMAOption } from '@amaspace-editor/editor-3d'
import { Select } from 'antd'
import { ProductAttributeType } from 'api/shareApi'
import { useMemo } from 'react'
import styles from './OptionSelect.module.scss'

type TOptionsGroup = {
    attribute: ProductAttributeType
    options: AMAOption[]
}

type Props = {
    optionsGroup: TOptionsGroup
    selectedPCOs: AMAOption[]
    selectOptionWithRules: any
}

const OptionSelect = ({ optionsGroup, selectedPCOs, selectOptionWithRules }: Props) => {
    const allPCO = useMemo(() => {
        return selectedPCOs.filter(pco => optionsGroup.options.map(option => option.id).includes(pco.id))
    }, [optionsGroup.options, selectedPCOs])
    const value = useMemo(() => {
        const isSelected = allPCO.find(pco => pco.checked)
        return isSelected ? isSelected.make || isSelected.name : null
    }, [allPCO])

    return (
        <div className={styles.component}>
            <span className={styles.component__label}>{optionsGroup.attribute.name}</span>
            <Select
                className={styles.component__select}
                options={optionsGroup.options.map(option => {
                    return {
                        label: option.make || option.name,
                        value: option.name + option.id
                    }
                })}
                placeholder="Select..."
                value={value}
                onChange={(value, option) => {
                    const chosenOption = optionsGroup.options.find(
                        optionItem => optionItem.name + optionItem.id === value
                    )
                    if (!chosenOption) {
                        return
                    }
                    selectOptionWithRules({ id: chosenOption.id, option: chosenOption })
                }}
            />
        </div>
    )
}

export default OptionSelect

import {Button, Dropdown, Form, Upload} from 'antd'
import { useContext, useState } from 'react'
import { CatalogContext, ProductType } from '../../../../providers/CatalogProvider'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'
import {UploadOutlined} from "@ant-design/icons";

type ProductInputItemProps = {
    itemKey: keyof Pick<ProductType, 'name' | 'make' | 'year' | 'model' | 'description' | 'color'>
    required?: boolean
}

const ProductColorItem = ({ itemKey, required }: ProductInputItemProps) => {
    const [pickerShown, setPickerShown] = useState(false)

    const { product, setProduct } = useContext(CatalogContext)

    const handleChangeValue = (key: keyof ProductType, value: string) => {
        setProduct({ ...product, [key]: value })
    }

    const items = [
        {
            key: 'color',
            label: (
                <ChromePicker
                    color={product[itemKey] || ''}
                    onChange={color => {
                        const {
                            rgb: { r, g, b }
                        } = color
                        handleChangeValue(itemKey, `rgb(${r}, ${g}, ${b})`)
                    }}
                />
            )
        }
    ]

    return (
        <Form.Item label="Color">
            <Dropdown
                open={pickerShown}
                menu={{
                    items,
                    onMouseLeave: () => setPickerShown(false)
                }}>
                <ColorIcon backgroundColor={product[itemKey]} onClick={e => setPickerShown(!pickerShown)} />
            </Dropdown>
        </Form.Item>
    )
}

export default ProductColorItem

const DefaultIcon = styled.div`
    width: 60px;
    height: 30px;
    border: 1px solid #000;
`

const ColorIcon = styled(DefaultIcon)<{ backgroundColor: string }>`
    background: ${props => `${props?.backgroundColor} !important`};
`

import React, { createContext, useState } from 'react'
import { Modal } from 'antd'

type ModalConfirmProviderProps = { children: React.ReactNode }

const ModalConfirmProvider = ({ children }: ModalConfirmProviderProps) => {
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState('')
    const [callback, setCallback] = useState<() => void>(() => {})

    const showModal = (text: string, cb: () => void) => {
        setOpen(true)
        setContent(text)
        setCallback(() => cb)
    }

    const okModal = () => {
        callback()
        hideModal()
    }

    const hideModal = () => {
        setOpen(false)
        setContent('')
        setCallback(() => {})
    }

    const context = {
        showModal
    }

    return (
        <ModalConfirmContext.Provider value={context}>
            {children}

            <Modal
                title={`Confirm Action`}
                open={open}
                onOk={okModal}
                onCancel={hideModal}
                okText="Yes"
                cancelText="Cancel">
                <span>{`Are you sure you want to ${content} ?`}</span>
            </Modal>
        </ModalConfirmContext.Provider>
    )
}

export default ModalConfirmProvider

export const ModalConfirmContext = createContext({} as ModalConfirmType)

type ModalConfirmType = {
    showModal: (text: string, cb: () => void) => void
}

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Switch } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AssetAttributeType, AssetRuleActionType } from '../../../../../../api/assetApi'
import { AMAMeshType, utilsEditor, CombinedObject } from '@amaspace-editor/editor-3d'

const { Option } = Select

type ActionActivateAnimationProps = {
    item: AssetRuleActionType
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
    attributes: AssetAttributeType[]
}

type CameraActionType = {
    camera: CameraData | null
    attribute: AssetAttributeType | null
}
type CameraData = {
    value: string
    label: string
}
const ActionSetActiveCamera = ({ item, deleteAction, changeAction, attributes }: ActionActivateAnimationProps) => {
    const editor3D = utilsEditor()
    const [action, setAction] = useState<CameraActionType>(
        item.value
            ? JSON.parse(item.value)
            : {
                  camera: null,
                  attribute: null
              }
    )

    const camerasData = React.useMemo((): CameraData[] | undefined => {
        if (editor3D?.config?.cameras) {
            return editor3D?.config?.cameras.map(item => ({ value: item.id, label: item.name }))
        }
    }, [editor3D])

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    return (
        <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
            <Col span={18}>
                <Select
                    value={action.camera?.value}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Camera"
                    onChange={value => {
                        const foundModel = camerasData && camerasData.find(item => item.value === value)
                        setAction({ ...action, camera: foundModel ? foundModel : null })
                    }}>
                    {camerasData &&
                        camerasData.map(item => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                </Select>
            </Col>
            <Select
                value={action.attribute?.id}
                showSearch
                optionFilterProp="children"
                placeholder="Select Attribute"
                onChange={value => {
                    const item = attributes.filter(attr => attr.id === value)
                    setAction({ ...action, attribute: item[0] })
                }}>
                {attributes.map(item => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <Col span={3}>
                <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
            </Col>
        </Row>
    )
}

export default ActionSetActiveCamera

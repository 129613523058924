import { Form, FormInstance, Input } from 'antd'
import { IndustryInfoType } from '../../../api/industryApi'
import React, { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'

type IndustryFormProps = {
    form: FormInstance<IndustryInfoType>
    onFinish: (e: IndustryInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const IndustryForm = ({ form, setIsModalOpen, refetch, onFinish }: IndustryFormProps) => {
    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Name" />
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea maxLength={200} placeholder="Description" />
            </Form.Item>
        </Form>
    )
}

export default IndustryForm

import React, { useEffect, useState } from 'react'
import PageBuilder from '../../../components/panel/PageBuilder'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { DealerLicenseProductFilter, useGetDealerLicensesQuery } from '../../../api/dealerCatalogApi'
import { Button, Space } from 'antd'
import { FolderFilled, PlusOutlined } from '@ant-design/icons'
import { ProductType } from '../../../providers/CatalogProvider'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { routesUrl } from '../../../router/urls'

const DealerLicensesProducts = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate()
    const [filter, setFilter] = useState<DealerLicenseProductFilter>({
        page: 1,
        name: '',
        name_sort: ''
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const name_sort = searchParams.get("name_sort")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            name_sort: name_sort ?? '',
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetDealerLicensesQuery(filter)

    const [filterByText] = useFilterTable<DealerLicenseProductFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name', 'name_sort'),
            render: (name: string, product: ProductType) => (
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        navigate(`${routesUrl.dealerCatalog}?license_id=${product.id}`)
                    }}>
                    <FolderFilled style={{ marginRight: '10px' }} className={'table__name-icon'} />
                    {name}
                </Button>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder breadcrumbs={['products']} title="Catalog: Products" documentTitle="Catalog: Products">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => navigate(routesUrl.dealerCatalogCreate)}>
                    <PlusOutlined /> Create Product
                </Button>

                <Button type={'primary'} onClick={() => navigate(routesUrl.dealerCatalogArchive)}>
                    Archived Products
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="license"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />
        </PageBuilder>
    )
}

export default DealerLicensesProducts

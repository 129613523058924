import PageBuilder from '../../../components/panel/PageBuilder'
import { Button, Form, Space } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import ModalWithForm from '../../users/ModalWithForm'
import {
    StyleGuideInfoType,
    useCreateStyleGuideMutation,
    useDeleteStyleGuideMutation,
    useGetStyleGuidesQuery,
    useUpdateStyleGuideMutation
} from '../../../api/styleGuideApi'
import StyleGuideForm from './StyleGuideForm'
import ExtendedTable from '../../../components/ExtendedTable'
import toast from 'react-hot-toast'
import {useSearchParams} from "react-router-dom";

const StyleGuides = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showModal } = useContext(ModalConfirmContext)

    const [formCreate] = Form.useForm<StyleGuideInfoType>()
    const [formUpdate] = Form.useForm<StyleGuideInfoType>()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [filter, setFilter] = useState({ page: 1 })

    useEffect(() => {
        const pageParam = searchParams.get("page")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetStyleGuidesQuery(filter)
    const [create, resultsCreate] = useCreateStyleGuideMutation()
    const [update, resultsUpdate] = useUpdateStyleGuideMutation()
    const [deleteItem] = useDeleteStyleGuideMutation()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Path',
            dataIndex: 'path',
            key: 'path'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, styleGuide: StyleGuideInfoType) => (
                <>
                    <Button
                        onClick={() => {
                            formUpdate.setFieldsValue(styleGuide)
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const { id } = styleGuide
                                const loader = toast.loading('Progressing...')
                                const res = await deleteItem({ id })
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Style Guide is successfully deleted')
                                refetch()
                            }

                            showModal('delete this style guide', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Style Guides" documentTitle="Style Guides">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <PlusOutlined /> Create Style Guide
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Style Guide"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                submit={formCreate.submit}
                isLoading={resultsCreate.isLoading}>
                <StyleGuideForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Style Guide"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                submit={formUpdate.submit}
                isLoading={resultsUpdate.isLoading}>
                <StyleGuideForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default StyleGuides

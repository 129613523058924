import { ReactNode } from 'react'
import {
    useCreateDealerProductAttributeMutation,
    useCreateDealerProductMutation,
    useCreateDealerProductRuleMutation,
    useDeleteDealerProductAttributesMutation,
    useDeleteDealerProductRulesMutation,
    useGetDealerProductAndPCOListMutation,
    useGetDealerProductAttributesMutation,
    useGetDealerProductCategoriesMutation,
    useGetDealerProductMutation,
    useGetDealerProductRulesMutation,
    useGetDealerProductTagsAllQuery,
    useUpdateDealerProductAttributeMutation,
    useUpdateDealerProductMutation,
    useUpdateDealerProductRuleMutation
} from '../api/dealerCatalogApi'
import CatalogProvider, { CatalogRequestsContextType, ProductType } from './CatalogProvider'
import { useFetchByPagination } from '../hooks'
import {useCloneCompanyRuleMutation} from "../api/companyCatalogApi";

const CatalogDealerProvider = ({ children }: { children: ReactNode }) => {
    const [getProduct] = useGetDealerProductMutation()
    const [createProduct] = useCreateDealerProductMutation()
    const [updateProduct] = useUpdateDealerProductMutation()
    const {
        data: productTags,
        refetch: fetchProductTags,
        isLoading: isLoadingProductTags
    } = useGetDealerProductTagsAllQuery(null)

    const [getProductsByTags] = useGetDealerProductAndPCOListMutation()
    const [products] = useFetchByPagination<ProductType>(useGetDealerProductAndPCOListMutation)

    const [getCategories] = useGetDealerProductCategoriesMutation()
    const [getAttributes] = useGetDealerProductAttributesMutation()
    const [createAttributes] = useCreateDealerProductAttributeMutation()
    const [updateAttributes] = useUpdateDealerProductAttributeMutation()
    const [deleteAttributes] = useDeleteDealerProductAttributesMutation()

    const [getRules] = useGetDealerProductRulesMutation()
    const [updateRules] = useUpdateDealerProductRuleMutation()
    const [createRules] = useCreateDealerProductRuleMutation()
    const [deleteRules] = useDeleteDealerProductRulesMutation()
    // need to be changed to dealer method
    const [cloneRule] = useCloneCompanyRuleMutation()

    const fetchInfoItems = () => {
        fetchProductTags()
    }

    const value: CatalogRequestsContextType = {
        getProduct,
        createProduct,
        updateProduct,

        getCategories,
        getAttributes,
        createAttributes,
        updateAttributes,
        deleteAttributes,

        getRules,
        createRules,
        updateRules,
        deleteRules,
        cloneRule,

        products,

        getProductsByTags,

        productTags: productTags ?? [],
        isLoadingProductTags,

        fetchInfoItems
    }

    return <CatalogProvider requests={value}>{children}</CatalogProvider>
}

export default CatalogDealerProvider

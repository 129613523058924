import { Dispatch, SetStateAction } from 'react'
import { Form, FormInstance, Input } from 'antd'
import ModalWithForm from './ModalWithForm'
import toast from 'react-hot-toast'

type UserChangeEmailFormProps = {
    form: FormInstance
    changeEmail: (form: { id: number | string; email: string }) => Promise<any>
    isModalOpen: boolean
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    isLoading: boolean
    refetch: () => void
}

const UserChangeEmailModal = ({
    form,
    changeEmail,
    isModalOpen,
    setIsModalOpen,
    isLoading,
    refetch
}: UserChangeEmailFormProps) => {
    return (
        <ModalWithForm
            title={'Change Email'}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isLoading={isLoading}
            submit={form.submit}>
            <Form
                validateMessages={{ required: 'Field is required!' }}
                layout="vertical"
                form={form}
                onFinish={async e => {
                    const loader = toast.loading('Progressing...')
                    const res = await changeEmail(e)
                    toast.dismiss(loader)
                    if ('error' in res) return
                    toast.success('Email is successfully changed!')
                    setIsModalOpen(false)
                    refetch()
                    form.resetFields()
                }}>
                <Form.Item name="id" noStyle>
                    <Input type="hidden" />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label="Email" name="email">
                    <Input maxLength={40} type="email" placeholder="Email" />
                </Form.Item>
            </Form>
        </ModalWithForm>
    )
}

export default UserChangeEmailModal

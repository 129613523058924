import { Select } from 'antd'
import { ProductConditionType } from '../../tabs/ProductRules'

type RuleTypeConditionItemProps = {
    condition: ProductConditionType
    handleChange: (item: ProductConditionType) => void
}

const RuleTypeConditionItem = ({ condition, handleChange }: RuleTypeConditionItemProps) => {
    return (
        <Select
            showSearch
            optionFilterProp="children"
            placeholder="Select Operator"
            value={condition.type_condition ? +condition.type_condition : null}
            onChange={type_condition => handleChange({ ...condition, type_condition })}>
            {logicalOperatorOptions.map(item => (
                <Select.Option key={item.value} value={+item.value}>
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default RuleTypeConditionItem

const logicalOperatorOptions = [
    { label: '=', value: 11 },
    { label: '!=', value: 12 }
]

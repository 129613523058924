import React, {Suspense, useEffect} from 'react'
import {Layout, Spin} from 'antd'
import Nav from 'components/panel/Nav'
import PanelRouter from 'router/PanelRouter'
import Sider from 'antd/lib/layout/Sider'
import {useGetUserQuery} from '../../api/userApi'
import {resetStore} from '../../redux/store'
import ModalConfirmProvider from '../../providers/ModalConfirmProvider'
import WebDisruptors from '../../assets/images/disruptors.webp'
import {
    StyledLogoAmaspace,
    StyledLogoWebDisruptors,
    StyledPanel,
    StyledPanelContent,
    StyledPanelHeader,
    StyledPanelLoader,
    StyledPanelWrapper
} from './styles'
import {useLocation, useNavigate} from 'react-router-dom'
import {routesUrl} from '../../router/urls'

const Panel = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {data: user, isError, isSuccess} = useGetUserQuery(null)

    useEffect(() => {
        switch (location.pathname) {
            case routesUrl.login:
                navigate('/')
        }
    }, [])

    useEffect(() => {
        isError && signOut()
    }, [isError])

    return (
        <ModalConfirmProvider>
            <StyledPanel>
                {isSuccess && user ? (
                    <StyledPanelWrapper>
                        <Sider width={210}>
                            {user.company === 'Web Disruptors Test Company' ? (
                                <StyledLogoWebDisruptors>
                                    <span>WEB</span>
                                    <img width="100%" src={WebDisruptors}/>
                                </StyledLogoWebDisruptors>
                            ) : (
                                <StyledLogoAmaspace>Amaspace</StyledLogoAmaspace>
                            )}
                            <Nav/>
                        </Sider>

                        <Layout>
                            <StyledPanelHeader>
                                {`${user.name} ${user.lastname} (${user.email}) | Role: ${user.role}`}
                            </StyledPanelHeader>

                            <StyledPanelContent>
                                <PanelRouter/>
                            </StyledPanelContent>
                        </Layout>
                    </StyledPanelWrapper>
                ) : (
                    <StyledPanelLoader>
                        <Spin size="large"/>
                    </StyledPanelLoader>
                )}
            </StyledPanel>
        </ModalConfirmProvider>
    )
}

export default Panel

export const signOut = async () => {
    await resetStore()
    window.location.reload()
}

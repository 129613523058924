import { Button, Col, Dropdown, MenuProps, Space } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { customLights } from '@amaspace-editor/editor-3d'

export type TAssetEditorAction = {
    label: string
    icon?: React.ReactNode
    key: string
    folder?: string
}

export type TAssetEditorActions = {
    actions: TAssetEditorAction[]
    handler: (key: string, value: any) => void
}

const AssetEditorActions: React.FC<TAssetEditorActions> = ({ actions, handler }) => {
    const folderItems = useCallback(
        (type: string) => {
            const items = actions.filter(action => action.folder === type)
            return items.map(action => {
                return {
                    label: action.label,
                    key: action.key
                }
            })
        },
        [actions]
    )
    const onClick = (data?: any) => {
        handler(data.key, null)
    }

    return (
        <Col>
            <Space size={[10, 10]}>
                <Dropdown menu={{ items: folderItems('shapes'), onClick }}>
                    <Button type={'primary'}>Shapes</Button>
                </Dropdown>
                <Dropdown menu={{ items: folderItems('lights'), onClick }}>
                    <Button type={'primary'}>Lights</Button>
                </Dropdown>
                <Dropdown menu={{ items: folderItems('mode'), onClick }}>
                    <Button type={'primary'}>Mode</Button>
                </Dropdown>
                <Dropdown menu={{ items: folderItems('settings'), onClick }}>
                    <Button type={'primary'}>Settings</Button>
                </Dropdown>
                <Dropdown menu={{ items: folderItems('cameras'), onClick }}>
                    <Button type={'primary'}>Cameras</Button>
                </Dropdown>
            </Space>
        </Col>
    )
}

export default AssetEditorActions

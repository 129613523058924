import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Modal, Select, Space } from 'antd'
import { TAssetsTagList, useGetAssetsTagsQuery } from 'api/assetApi'
import * as React from 'react'
import { TAsset } from 'types'
import { TAssetTag } from 'types/TAsset'

const { Option } = Select

type TTagsSelector = {
    assets: TAsset[]
    onClose: () => void
    onApply: (options: {
        assets: TAsset[]
        tagsToApply: string[]
        tagsToRemove: string[]
        tagsList: TAssetsTagList
    }) => void
}

type TTags = {
    label: string
    list: string[]
    onChange: (tags: string[]) => void
    mode: 'tags' | 'multiple'
}

const Tags: React.FC<TTags> = ({ label, list, onChange, mode }) => {
    return (
        <Space
            direction="vertical"
            style={{
                width: '100%'
            }}
            size={[0, 4]}>
            <div>{label}</div>
            <Select
                mode={mode}
                style={{
                    width: '100%'
                }}
                placeholder="Select tags"
                optionLabelProp="label"
                onChange={onChange}>
                {list.map(tag => {
                    return (
                        <Option key={tag} value={tag} label={tag}>
                            {tag}
                        </Option>
                    )
                })}
            </Select>
        </Space>
    )
}

const TagsSelector: React.FC<TTagsSelector> = ({ assets, onClose, onApply }) => {
    const getAssetsTags = useGetAssetsTagsQuery()

    const [tagsForApplyList, setTagsForApplyList] = React.useState<string[]>([])
    const [tagsForRemoveList, setTagsForRemoveList] = React.useState<string[]>([])

    const [tagsToApply, setTagsToApply] = React.useState<string[]>([])
    const [tagsToRemove, setTagsToRemove] = React.useState<string[]>([])

    const title = `Ediit tags for ${assets.length} ${assets.length <= 1 ? 'item' : 'items'}`

    React.useEffect(() => {
        const tagsToRemoveStack: string[] = []

        assets.forEach(asset => {
            asset.tags.forEach(({ name }) => {
                tagsToRemoveStack.push(name)
            })
        })

        const unique = new Set(tagsToRemoveStack)

        const values = Array.from(unique.values())

        setTagsForRemoveList(values)
    }, [])

    React.useEffect(() => {
        const assetsTags = getAssetsTags.data

        if (assetsTags) {
            const allAssetsTags = tagsForRemoveList.slice()
            const tagNamesToIgonre: string[] = []

            allAssetsTags.forEach(tagName => {
                const assetsWithThisTag = assets.filter(asset => {
                    const assetTagsNames = asset.tags.map(({ name }) => {
                        return name
                    })

                    return assetTagsNames.includes(tagName)
                })

                const shouldIgnoreThisTag = assetsWithThisTag.length === assets.length

                if (shouldIgnoreThisTag) {
                    tagNamesToIgonre.push(tagName)
                }
            })

            const tagsForList = assetsTags
                .filter(({ name }) => {
                    return !tagNamesToIgonre.includes(name)
                })
                .map(({ name }) => {
                    return name
                })

            setTagsForApplyList(tagsForList)
        }
    }, [getAssetsTags.data, tagsForRemoveList])

    const __onClose = () => {
        onClose()
    }

    const __onApply = () => {
        onApply({
            assets,
            tagsToApply,
            tagsToRemove,
            tagsList: getAssetsTags.data || []
        })
    }

    return (
        <Modal
            // extra={
            //   <Button
            //     onClick={__onClose}
            //     icon={<CloseOutlined />}
            //   ></Button>
            // }
            open={true}
            title={title}
            centered
            onOk={__onApply}
            onCancel={__onClose}
            width={440}>
            <Tags mode="tags" label="Add existing or new tags" list={tagsForApplyList} onChange={setTagsToApply} />
            <p></p>
            <Tags mode="multiple" label="Remove tags" list={tagsForRemoveList} onChange={setTagsToRemove} />
            {/* <p></p>
      <div>
        <Space 
          style={{
            width: '100%',
            textAlign: 'right'
          }}
        >
          <Button
            onClick={__onClose}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={__onApply}
          >
            Apply
          </Button>
        </Space>
      </div> */}
        </Modal>
    )
}

export default TagsSelector

import { Form, FormInstance, Input } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'
import { UserInfoType, useUpdateUserMutation } from '../../api/userApi'
import toast from 'react-hot-toast'
import ModalWithForm from '../users/ModalWithForm'
import { useAppDispatch } from '../../hooks'
import { changeAccessToken } from '../../slices/authSlice'

type AccountFormProps = {
    form: FormInstance<UserInfoType>
    isModalOpen: boolean
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    refetch: () => void
}

const AccountInfoForm = ({ form, isModalOpen, setIsModalOpen, refetch }: AccountFormProps) => {
    const dispatch = useAppDispatch()

    const [updateUser, resultUpdateUser] = useUpdateUserMutation()

    return (
        <ModalWithForm
            title={'Update Information'}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isLoading={resultUpdateUser.isLoading}
            submit={form.submit}>
            <Form
                validateMessages={{ required: 'Field is required!' }}
                layout="vertical"
                form={form}
                onFinish={async e => {
                    const loader = toast.loading('Progressing...')
                    const res = await updateUser(e)
                    toast.dismiss(loader)
                    if ('error' in res) return
                    toast.success('User info is successfully changed!')
                    dispatch(changeAccessToken(res.data.accessToken))
                    setIsModalOpen(false)
                    refetch()
                }}>
                <Form.Item label="First Name" name="name" rules={[{ required: true }]}>
                    <Input maxLength={40} placeholder="First Name" />
                </Form.Item>

                <Form.Item label="Last Name" name="lastname" rules={[{ required: true }]}>
                    <Input maxLength={40} placeholder="Last Name" />
                </Form.Item>

                <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                    <Input maxLength={40} type="email" placeholder="Email" />
                </Form.Item>

                <Form.Item label="Current Password" name="password_recovery" rules={[{ required: true }]}>
                    <Input.Password maxLength={40} placeholder="Current Password" />
                </Form.Item>
            </Form>
        </ModalWithForm>
    )
}

export default AccountInfoForm

import { Pagination, Table } from 'antd'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

type ExtendedTableData = {
    items?: object[]
    products?: object[]
    domain?: object[]
    invoice?: object[]
    license_price?: object[]
    license?: object[]
    _meta: TableMetaType
}

type ExtendedTableProps = {
    isLoading: boolean
    data?: ExtendedTableData
    dataKey: 'items' | 'products' | 'domain' | 'invoice' | 'license' | 'license_price'
    columns?: object[]
    onChange: (e: number) => void
}

const ExtendedTable = ({ data, dataKey, isLoading, columns, onChange }: ExtendedTableProps) => {
    const [dataSource, setDataSource] = useState<object[]>([])
    const [pagination, setPagination] = useState<TableMetaType | null>(null)

    useEffect(() => {
        if (data) {
            setDataSource(dataKey in data ? data[dataKey]! : [])
            setPagination(data._meta)
        }
    }, [data])

    return (
        <>
            <Table
                rowKey={() => uuid()}
                loading={isLoading}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
            />

            {pagination && dataSource.length !== 0 && (
                <Pagination
                    style={{ marginTop: 16 }}
                    onChange={onChange}
                    disabled={isLoading}
                    showSizeChanger={false}
                    defaultCurrent={pagination.current_page}
                    pageSize={pagination.page_size}
                    total={pagination.total_count}
                />
            )}
        </>
    )
}

export default ExtendedTable

export type TableMetaType = {
    total_count: number
    page_size: number
    page_count: number
    current_page: number
}

import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import toast from 'react-hot-toast'
import { useGetCurrencyQuery } from '../../api/priceApi'
import { PaymentInvoiceInfoType } from '../../api/invoiceApi'

type InvoiceFormProps = {
    type: 'dealer' | 'company'
    form: FormInstance<PaymentInvoiceInfoType>
    onFinish: (e: PaymentInvoiceInfoType) => Promise<any>
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    refetch: () => void
}

const InvoiceForm = ({ form, onFinish, setIsModalOpen, refetch, type }: InvoiceFormProps) => {
    const { data: currencies } = useGetCurrencyQuery(null)

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} placeholder="99.99" step="0.01" min={1} stringMode />
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea placeholder="Description" maxLength={200} />
            </Form.Item>

            {type === 'company' && (
                <Form.Item rules={[{ required: true }]} label="Currency" name="currency_id">
                    <Select showSearch allowClear optionFilterProp="children" placeholder="Currency">
                        {currencies
                            ? currencies.map(currency => {
                                  return (
                                      <Select.Option key={currency.id} value={currency.id}>
                                          {currency.name}
                                      </Select.Option>
                                  )
                              })
                            : null}
                    </Select>
                </Form.Item>
            )}
        </Form>
    )
}

export default InvoiceForm

import React, { Dispatch, SetStateAction } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { AMAProductUserType, APOUpdateType } from '../../../api/amaProductOwnershipApi'
import toast from 'react-hot-toast'

type ApoEditFormProps = {
    form: FormInstance<APOUpdateType>
    onFinish: (e: APOUpdateType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    userType: AMAProductUserType[]
}

const ApoEditForm = ({ form, refetch, setIsModalOpen, userType, onFinish }: ApoEditFormProps) => {
    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('APO is successfully updated!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="apo_id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item rules={[{ required: true }]} label="Product Type" name="product_id">
                <Select showSearch optionFilterProp="children" placeholder="Product Type">
                    {userType.map(type => {
                        return (
                            <Select.Option key={type.id} value={type.id}>
                                {type.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
        </Form>
    )
}

export default ApoEditForm

import React, { useEffect, useState } from 'react'
// @ts-ignore
import { Editor2DComponent as Editor2D } from '@amaspace-editor/editor-2d/dist/editor-2d.esm'
import { Button, Col, Divider, Row } from 'antd'
import { useCreateAssetMutation, useUpdateAssetMutation } from 'api/assetApi'
import Utils from 'utils'
import { TAsset } from '../../../types'
import AssetEditorActions, { TAssetEditorAction } from './assetEditorActions'
import AssetEditorProps, { TAssetEditorProp, TAssetEditorSection } from './assetEditorProps'
import AssetEditorTree from './assetEditorTree'

type TAssetEditor2D = {
    asset: TAsset
    leftMenu: 'nodes' | JSX.Element
    rightMenu: 'properties' | JSX.Element
}

const AssetEditor2D: React.FC<TAssetEditor2D> = ({ asset, rightMenu, leftMenu }) => {
    const [editorAPI, setEditorAPI] = useState<any>()
    const [filtersConfig, setFiltersConfig] = useState([])
    const [patternConfig, setPatternConfig] = useState([])
    const [selectedUid, setSelectedUid] = useState(null)
    const [canvasConfig, setCanvasConfig] = useState([])
    const [propsConfig, setPropsConfig] = useState([])
    const [tree, setTree] = useState([])
    const [cropMode, setCropMode] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [repeatState, setRepeatState] = useState(asset?.patch?.repeat || 'repeat')

    const [updateAssetRequest] = useUpdateAssetMutation()
    const [createAsset] = useCreateAssetMutation()

    const EDITOR_CONFIG = extractEditorConfig(asset)
    const loadData = EDITOR_CONFIG
        ? EDITOR_CONFIG
        : {
              src: asset.url,
              name: asset.name
          }
    const loadType = EDITOR_CONFIG ? 'JSON' : 'IMAGE_LINK'

    useEffect(() => {
        if (editorAPI) {
            editorAPI.objects.on.patternConfigCreated((config: any) => {
                setPatternConfig(config)
            })

            editorAPI.objects.on.filtersConfigCreated((config: any) => {
                setFiltersConfig(config)
            })

            editorAPI.objects.on.propsConfigCreated((config: any) => {
                setPropsConfig(config)
            })

            editorAPI.canvas.on.configCreated((config: any) => {
                setCanvasConfig(config)
            })

            editorAPI.objects.on.selected((data: any) => {
                setSelectedUid(data[0].uid)
            })

            editorAPI.objects.on.deselected(() => {
                setSelectedUid(null)
            })

            editorAPI.objects.on.treeUpdated((data: any) => {
                // const uids = data.map((object: any) => {
                //   return object.uid;
                // });

                setTree(mapTree(data) as any)
            })

            editorAPI.system.onLoad(() => {
                console.log('system.onLoad')

                setCanvasConfig(editorAPI.application.requestCanvasConfig())
                setTree(mapTree(editorAPI.application.requestTreeConfig()) as any)

                if (loadType === 'IMAGE_LINK') {
                    setFiltersConfig(editorAPI.application.interaction.filtersController.requestFiltersConfig())
                    setPatternConfig(editorAPI.application.patternController.requestPatternConfig())
                    setSelectedUid(editorAPI.application.canvas.getActiveObject().uid)

                    // hack to trigger props config update
                    const obj = editorAPI.application.canvas.getActiveObject()
                    editorAPI.application.canvas.discardActiveObject()
                    editorAPI.application.canvas.setActiveObject(obj)
                }
            })
        }
    }, [editorAPI])
    const createProps = () => {
        const sections: TAssetEditorSection[] = []

        if (cropMode) return []

        const filtersSection = {
            name: 'Filters',
            props: []
        } as TAssetEditorSection

        const patternSection = {
            name: 'Pattern',
            props: []
        } as TAssetEditorSection

        const canvasSection = {
            name: 'Canvas',
            props: []
        } as TAssetEditorSection

        const propsSection = {
            name: 'Object Props',
            props: []
        } as TAssetEditorSection

        filtersConfig &&
            filtersConfig.forEach(segment => {
                const { name, dataType, value, misc, min, max, precision } = segment
                const { constructor } = misc

                if (dataType === 'number') {
                    filtersSection.props.push({
                        label: name,
                        type: 'range',
                        key: `filters:${constructor}`,
                        value,
                        misc: {
                            min,
                            max,
                            precision
                        }
                    })
                }

                if (dataType === 'boolean') {
                    filtersSection.props.push({
                        label: name,
                        type: 'switch',
                        key: `filters:${constructor}`,
                        value
                    })
                }
            })

        patternConfig &&
            patternConfig.forEach(segment => {
                const { min, max, precision, value, key, list, dataType, name } = segment

                if (dataType === 'number') {
                    patternSection.props.push({
                        label: name,
                        type: 'range',
                        key: `pattern:${key}`,
                        value,
                        misc: {
                            min,
                            max,
                            precision
                        }
                    })
                }

                if (dataType === 'list') {
                    patternSection.props.push({
                        label: name,
                        type: 'list',
                        key: `pattern:${key}`,
                        value,
                        misc: {
                            list: (list as any[]).map(option => {
                                return {
                                    key: option.key as string,
                                    value: option.value as string,
                                    label: option.key as string
                                }
                            })
                        }
                    })
                }
            })

        canvasConfig &&
            canvasConfig.forEach(segment => {
                const { min, max, precision, value, key, list, dataType, name } = segment

                if (dataType === 'number') {
                    canvasSection.props.push({
                        label: name,
                        type: 'range',
                        key: `canvas:${key}`,
                        value,
                        misc: {
                            min,
                            max,
                            precision
                        }
                    })
                }

                if (dataType === 'color') {
                    canvasSection.props.push({
                        label: 'Background',
                        type: 'color',
                        key: `canvas:${key}`,
                        value
                    })
                }
            })

        propsConfig &&
            propsConfig.forEach(segment => {
                const { min, max, precision, value, key, list, dataType, name } = segment

                if (dataType === 'number') {
                    propsSection.props.push({
                        label: name,
                        type: 'range',
                        key: `object_props:${key}`,
                        value,
                        misc: {
                            min,
                            max,
                            precision
                        }
                    })
                }
            })
        const repeatList = [
            { key: 'repeat', value: 'repeat' },
            { key: 'no-repeat', value: 'no-repeat' }
        ]
        canvasSection.props.push({
            label: 'Repeat',
            type: 'list',
            key: `canvas:repeat`,
            value: repeatState,
            misc: {
                list: repeatList.map(option => {
                    return {
                        key: option.key,
                        value: option.value,
                        label: option.key
                    }
                })
            }
        })

        canvasSection.props.length && sections.push(canvasSection)
        propsSection.props.length && sections.push(propsSection)
        patternSection.props.length && sections.push(patternSection)
        filtersSection.props.length && sections.push(filtersSection)

        return sections
    }

    const CANVAS_API_CALLS = {
        CANVAS_WIDTH: (value: number) => {
            editorAPI.canvas.update.viewSizeWidth(value)
        },
        CANVAS_HEIGHT: (value: number) => {
            editorAPI.canvas.update.viewSizeHeight(value)
        },
        CANVAS_COLOR: (value: string) => {
            editorAPI.canvas.update.backgroundColor(value)
        },
        repeat: (value: string) => {
            setRepeatState(value)
        }
    }

    const OBJECT_PROPS_API_CALLS = {
        left: (value: number) => {
            editorAPI.objects.update.position(value, null)
        },
        top: (value: number) => {
            editorAPI.objects.update.position(null, value)
        },
        angle: (value: number) => {
            editorAPI.objects.update.angle(value)
        },
        width: (value: number) => {
            editorAPI.objects.update.width(value)
        },
        height: (value: number) => {
            editorAPI.objects.update.height(value)
        },
        scaleX: (value: number) => {
            editorAPI.objects.update.scaleX(value)
        },
        scaleY: (value: number) => {
            editorAPI.objects.update.scaleY(value)
        }
    }

    const processPropChange = (key: string, value: TAssetEditorProp['value']) => {
        const APIRequest = key.split(':')[0]
        const actualKey = key.split(':')[1]

        if (APIRequest === 'canvas') {
            const APICall = CANVAS_API_CALLS[actualKey as keyof typeof CANVAS_API_CALLS]

            if (APICall) {
                APICall(value as never)
            }

            return
        }

        if (APIRequest === 'object_props') {
            const APICall = OBJECT_PROPS_API_CALLS[actualKey as keyof typeof OBJECT_PROPS_API_CALLS]

            if (APICall) {
                APICall(value as never)
            }

            return
        }

        if (APIRequest) {
            const APICall = editorAPI.objects.update[APIRequest]

            if (APICall) {
                APICall(actualKey, value)
            } else {
                console.log('Api call', `editorAPI.objects.${APIRequest} is not implemented`, {
                    key,
                    value,
                    APIRequest,
                    actualKey
                })
            }
        } else {
            console.log('Wrong prop key', { key, value })
        }
    }

    const processTreeSelection = (selectedKeys: React.Key[], info: any) => {
        if (!selectedKeys.length) {
            // TODO: deselect API call
            editorAPI.application.canvas.discardActiveObject()
            editorAPI.application.renderFrame()
            return
        }

        const targetUid = tree.find((data: any) => {
            return data.key === selectedKeys[0]
        }) as any

        editorAPI.objects.select(targetUid?.uid)
    }

    const ACTIONS_HANDLERS = {
        presetText: () => {
            editorAPI.objects.preset.text()
        },
        presetImage: () => {
            editorAPI.objects.preset.image()
        },
        cropMode: () => {
            editorAPI.crop.toggle()
            setCropMode(!cropMode)
        },
        setPatternWithinCanvasBounds: () => {
            editorAPI.objects.setPatternWithinCanvasBounds()
        },
        undo: () => {
            editorAPI.history.undo()
        },
        redo: () => {
            editorAPI.history.redo()
        },
        remove: () => {
            editorAPI.objects.remove()
        },
        generateImage: () => {
            editorAPI.system.generateImage()
        }
    }
    function getImageDimensions(base64: string): Promise<{ width: number; height: number }> {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => {
                resolve({ width: img.width, height: img.height })
            }
            img.onerror = error => {
                reject(error)
            }
            img.src = base64
        })
    }
    const updateAsset = async () => {
        setIsSaving(true)

        let options
        const base64 = editorAPI.system.generateImage()
        const response = await fetch(base64)

        const blob = await response.blob()

        const file = new File([blob], asset.name, { type: 'image/png' })

        if (asset?.url?.endsWith('.ktx2')) {
            const dimensions = await getImageDimensions(base64)

            options = {
                ...(asset?.patch?.converted_options || {}),
                disable_compress: true,
                resize: [dimensions?.width, dimensions?.height]
            }
        }
        try {
            const generatedAssets = await Utils.createAssetsFromFile(file, undefined, options)
            const generatedAsset = generatedAssets[0]

            const editorConfig = editorAPI.system.requestEditorLoadConfig()
            const assetToSave = JSON.parse(JSON.stringify(asset))

            assetToSave.preview = generatedAsset.preview
            assetToSave.url = generatedAsset.url

            Object.assign(assetToSave.patch, {
                EDITOR_CONFIG: editorConfig,
                repeat: repeatState
            })

            await updateAssetRequest({
                id: asset!.id,
                asset: assetToSave
            })

            setIsSaving(false)
        } catch (error) {
            setIsSaving(false)
        }
    }

    const actions = [
        { label: 'Text', icon: null, key: 'presetText', folder: 'shapes' },
        { label: 'Image', icon: null, key: 'presetImage', folder: 'shapes' },
        { label: 'Crop mode', icon: null, key: 'cropMode' },
        { label: 'Fit to canvas bounds', icon: null, key: 'setPatternWithinCanvasBounds', folder: 'shapes' },
        { label: 'Remove', icon: null, key: 'remove', folder: 'shapes' },
        { label: 'Generate image', icon: null, key: 'generateImage', folder: 'shapes' },
        { label: 'Undo', icon: null, key: 'undo', folder: 'shapes' },
        { label: 'Redo', icon: null, key: 'redo', folder: 'shapes' }
    ] as TAssetEditorAction[]

    const processAction = (key: string, value: any) => {
        const actionCall = ACTIONS_HANDLERS[key as keyof typeof ACTIONS_HANDLERS]

        if (actionCall) {
            actionCall()
        } else {
            console.log('Action', key, 'is not implemented')
        }
    }

    return (
        <>
            <Row>
                <AssetEditorActions actions={actions} handler={processAction} />

                <Button type="primary" onClick={updateAsset} loading={isSaving}>
                    UPDATE ASSET
                </Button>

                <Divider />
            </Row>

            <Row gutter={[24, 0]}>
                <Col span={8}>
                    {leftMenu === 'nodes' && tree.length ? (
                        <AssetEditorTree
                            nodes={tree}
                            onSelect={processTreeSelection}
                            selectedKeys={
                                !selectedUid
                                    ? []
                                    : tree
                                          .filter((element: any) => {
                                              return element.uid === selectedUid
                                          })
                                          .map((element: any) => {
                                              return element.key
                                          })
                            }
                        />
                    ) : (
                        leftMenu
                    )}
                </Col>
                <Col span={8}>
                    <div style={{ height: '60vh', background: 'rgb(248, 248, 248)' }}>
                        <Editor2D
                            config={{
                                loadType: loadType,
                                data: loadData,
                                mode: 'EDIT_MODE',
                                applyViewBoundsToCanvas: true
                            }}
                            setEditorAPI={(API: any) => {
                                console.log('setEditorApi')
                                setEditorAPI(API)
                            }}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    {rightMenu === 'properties' ? (
                        <AssetEditorProps sectionsList={createProps()} onPropChange={processPropChange} />
                    ) : (
                        rightMenu
                    )}
                </Col>
            </Row>
        </>
    )
}

export default AssetEditor2D

const mapTree = (objects: any[]) => {
    return objects.map((object, index) => {
        return {
            uid: object.uid,
            title: object.uid,
            key: `${index}-0`
        }
    })
}

export const extractEditorConfig = (asset: any) => {
    if (asset.patch.EDITOR_CONFIG) {
        return JSON.parse(JSON.stringify(asset.patch.EDITOR_CONFIG))
    }

    return null

    // if (!asset.patch.length) {
    //   return null;
    // }

    // return JSON.parse(JSON.stringify(asset.patch[0].EDITOR_CONFIG));
}

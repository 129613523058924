import {
    AMAMeshType,
    AMAObject,
    BackgroundType,
    BlendFunction,
    isCamera,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import { TAssetEditorProp, TAssetEditorSection } from '../../assetEditorProps'
import { ENV_LIST } from '../assetEditor3D'

const sceneProps = (editor3D: ReturnType<typeof utilsEditor>): TAssetEditorSection[] => {
    if (!editor3D.activeCamera) return []

    const cameraConfig = editor3D.getCurrentConfigObj(editor3D.activeCamera)
    const customCameras = () => {
        const cameras: {
            label: string
            key: string
            value: string | number
        }[] = []
        editor3D.config.cameras.forEach(obj => {
            if (isCamera(obj)) {
                if (obj.type === AMAMeshType.PerspectiveCamera) {
                    cameras.push({ label: obj.name, value: obj.id, key: `${obj.userData.id}_camera` })
                }
            }
        })

        return cameras
    }
    const customTargets = () => {
        const defaultValue = { name: 'None', id: '' }
        const targets: AMAObject[] = []

        if (editor3D.config.customObjects) {
            editor3D.config.customObjects.forEach(obj => {
                if (obj.type === AMAMeshType.Target) {
                    targets.push(obj)
                }
            })
        }
        if (editor3D.uploadedModelsConfig.length) {
            editor3D.uploadedModelsConfig.forEach(config => {
                config.customObjects.forEach(obj => {
                    if (obj.type === AMAMeshType.Target) {
                        targets.push(obj)
                    }
                })
            })
        }
        return [defaultValue, ...targets].map(target => {
            return {
                label: target.name,
                value: target.id,
                key: `${target.id}_target`
            }
        })
    }
    // const controlsProps = () => {
    //     const cameraConfig = editor3D.getCurrentConfigObj(editor3D.activeCamera)
    //     if(!cameraConfig) return []
    //     if(!isCamera(cameraConfig)) return []
    //     const keys = cameraConfig.updatedControls && Object.keys(cameraConfig.updatedControls) || []

    //     return keys.map(key => {
    //         if(cameraConfig.updatedControls) {
    //             const value = cameraConfig.updatedControls[key as keyof typeof cameraConfig.updatedControls]
    //             return {
    //                 type: 'range',
    //                 label: key,
    //                 value: value,
    //                 key: `${key}_controls`
    //             }
    //         }

    //     }).filter(Boolean) as TAssetEditorProp[]
    // }
    const envs = () => {
        return Object.keys(ENV_LIST).map(i => {
            const key = i as keyof typeof ENV_LIST
            return { label: key, value: ENV_LIST[key], key: `${ENV_LIST[key]}` }
        })
    }

    const postProcessingList = () => {
        return editor3D.postProcessingList.filter(el => el.enabled)
    }
    const validType = (value: any): TAssetEditorProp['type'] => {
        if (typeof value === 'boolean') {
            return 'switch'
        }
        if (typeof value === 'object' && 'x' in value && !('z' in value)) {
            return 'vec2'
        }

        return 'range'
    }
    const blendFunctionList = () => {
        return Object.keys(BlendFunction).map(i => {
            const key = i as keyof typeof BlendFunction
            return {
                label: key,
                value: BlendFunction[key],
                key: BlendFunction[key]
            }
        })
    }

    const contactShadowsProps = () => {
        const list: TAssetEditorProp[] = [
            {
                type: 'switch',
                label: 'Enabled',
                value: editor3D.contactShadow.enabled,
                key: 'contactShadow_enabled'
            }
        ]

        if (editor3D.contactShadow.enabled) {
            const params = editor3D.contactShadow.params

            const restProps: TAssetEditorProp[] = [
                {
                    type: 'range',
                    label: 'Opacity',
                    key: 'contactShadow_opacity',
                    misc: {
                        precision: 0.01,
                        min: 0,
                        max: 1
                    },
                    value: params.opacity
                },
                {
                    type: 'range',
                    label: 'Far',
                    key: 'contactShadow_far',
                    misc: {
                        precision: 0.1,
                        min: 0,
                        max: 1000
                    },
                    value: params.far
                },
                {
                    type: 'range',
                    label: 'Near',
                    key: 'contactShadow_near',
                    misc: {
                        precision: 0.1,
                        min: 0,
                        max: 1000
                    },
                    value: params.near
                },
                {
                    type: 'range',
                    label: 'Scale',
                    key: 'contactShadow_scale',
                    misc: {
                        precision: 0.1,
                        min: 0,
                        max: 50
                    },
                    value: params.scale
                },
                {
                    type: 'range',
                    label: 'Frames',
                    key: 'contactShadow_frames',
                    misc: {
                        precision: 1,
                        min: 1,
                        max: 20
                    },
                    value: params.frames
                },
                {
                    type: 'range',
                    label: 'Blur',
                    key: 'contactShadow_blur',
                    misc: {
                        precision: 0.05,
                        min: 0,
                        max: 50
                    },
                    value: params.blur
                },
                {
                    type: 'select',
                    label: 'Resolution',
                    key: 'contactShadow_resolution',
                    value: {
                        options: ['512', '1024', '2048'].map(el => ({
                            key: el.toString(),
                            value: el as unknown as string,
                            label: el.toString()
                        })),
                        value: (params.resolution || '512').toString()
                    }
                },
                { type: 'input', label: 'Color', value: params.color, key: 'contactShadow_color' }
            ]

            list.push(...restProps)
        }

        return list
    }

    const fogProps = () => {
        const settings = editor3D.fog

        const list: TAssetEditorProp[] = [
            {
                type: 'switch',
                label: 'Enabled',
                value: !!settings,
                key: 'fog_enabled'
            }
        ]

        if (!!settings) {
            list.push(
                {
                    misc: {
                        min: 0,
                        max: 100,
                        precision: 0.1
                    },
                    type: 'range',
                    label: 'Near',
                    key: 'fog_near',
                    value: settings?.near || 0
                },
                {
                    misc: {
                        min: 0,
                        max: 100,
                        precision: 0.1
                    },
                    type: 'range',
                    label: 'Far',
                    key: 'fog_far',
                    value: settings?.far || 100
                },
                {
                    type: 'color',
                    label: 'Color',
                    value: settings?.color || '#000000',
                    key: 'fog_color'
                }
            )
        }

        return list
    }

    const backgroundProps = () => {
        const settings = editor3D.backgroundSettings
        const envSettings = editor3D.activeEnvironment

        const list: TAssetEditorProp[] = [
            {
                type: 'select',
                label: 'Type',
                value: {
                    options: Object.values(BackgroundType).map(el => ({
                        key: el,
                        value: el,
                        label: el
                    })),
                    value: settings.type || 'empty'
                },
                key: 'background_type'
            }
        ]

        switch (settings.type) {
            case 'solid':
                list.push({
                    type: 'color',
                    label: 'Color',
                    value: settings.settings.value || '#000000',
                    key: 'background_color'
                })
                break
            case 'texture':
                list.push(
                    {
                        type: 'attachImage',
                        label: 'Image',
                        key: 'background_image',
                        value: settings.settings.value
                    },
                    {
                        type: 'range',
                        label: 'Hue',
                        key: 'background_hue',
                        misc: {
                            min: -180,
                            max: 180
                        },
                        value: settings.settings.hue
                    },
                    {
                        type: 'range',
                        label: 'Saturation',
                        key: 'background_saturation',
                        misc: {
                            min: -100,
                            max: 100
                        },
                        value: settings.settings.saturation
                    },
                    {
                        type: 'range',
                        label: 'Brightness',
                        key: 'background_brightness',
                        misc: {
                            min: -100,
                            max: 100
                        },
                        value: settings.settings.brightness
                    },
                    {
                        type: 'range',
                        label: 'Contrast',
                        key: 'background_contrast',
                        misc: {
                            min: -100,
                            max: 100
                        },
                        value: settings.settings.contrast
                    },
                    {
                        type: 'switch',
                        label: 'Invert',
                        key: 'background_invert',
                        value: settings.settings.invert
                    },
                    {
                        type: 'vec2',
                        label: 'Tiling',
                        key: 'background_tiling',
                        value: {
                            x: settings.transform.tiling[0],
                            y: settings.transform.tiling[1]
                        }
                    },
                    {
                        type: 'vec2',
                        label: 'Offset',
                        key: 'background_offset',
                        value: {
                            x: settings.transform.offset[0],
                            y: settings.transform.offset[1]
                        }
                    },
                    {
                        type: 'range',
                        label: 'Rotation',
                        key: 'background_rotation',
                        misc: {
                            min: -180,
                            max: 180
                        },
                        value: settings.transform.rotation
                    },
                    {
                        type: 'range',
                        label: 'Resolution scale',
                        key: 'background_resolutionScale',
                        misc: {
                            min: 0.1,
                            max: 1
                        },
                        value: settings.transform.resolutionScale
                    }
                )
                break

            case 'linear':
            case 'radial':
                list.push(
                    {
                        type: 'gradient',
                        label: 'Gradient',
                        key: 'background_gradient',
                        value: settings.settings.value
                    },
                    // {
                    //     type: 'vec2',
                    //     label: 'Tiling',
                    //     key: 'background_tiling',
                    //     value: {
                    //         x: settings.transform.tiling[0],
                    //         y: settings.transform.tiling[1]
                    //     }
                    // },
                    {
                        misc: {
                            min: 1,
                            max: 10,
                            precision: 1
                        },
                        type: 'range',
                        label: 'Tiling x',
                        key: 'background_tilingX',
                        value: settings.transform.tiling[0]
                    },
                    {
                        type: 'range',
                        misc: {
                            min: 1,
                            max: 10,
                            precision: 1
                        },
                        label: 'Tiling y',
                        key: 'background_tilingY',
                        value: settings.transform.tiling[1]
                    },
                    {
                        misc: {
                            min: 1,
                            max: 10,
                            precision: 1
                        },
                        type: 'range',
                        label: 'Offset x',
                        key: 'background_offsetX',
                        value: settings.transform.offset[0]
                    },
                    {
                        type: 'range',
                        misc: {
                            min: 1,
                            max: 10,
                            precision: 1
                        },
                        label: 'Offset y',
                        key: 'background_offsetY',
                        value: settings.transform.offset[1]
                    },
                    {
                        type: 'range',
                        label: 'Rotation',
                        key: 'background_rotation',
                        misc: {
                            precision: 0.01,
                            min: -Math.PI,
                            max: Math.PI
                        },
                        value: settings.transform.rotation
                    }
                )
                break

            default:
                break
        }
        // if (softShadow.enabled) {
        // const params = softShadow.params

        // const restProps: TAssetEditorProp[] = [
        //     {
        //         type: 'range',
        //         label: 'Size',
        //         key: 'softShadow_size',
        //         misc: {
        //             min: 0,
        //             max: 100
        //         },
        //         value: params.size
        //     },
        //     {
        //         type: 'range',
        //         label: 'Focus',
        //         key: 'softShadow_focus',
        //         misc: {
        //             precision: 0.1,
        //             min: 0,
        //             max: 2
        //         },
        //         value: params.focus
        //     },
        //     {
        //         type: 'range',
        //         label: 'Samples',
        //         key: 'softShadow_samples',
        //         misc: {
        //             precision: 1,
        //             min: 1,
        //             max: 20
        //         },
        //         value: params.samples
        //     }
        // ]

        // list.push(...restProps)
        // }

        return list
    }
    const softShadowProps = () => {
        const softShadow = editor3D.softShadow

        const list: TAssetEditorProp[] = [
            {
                type: 'switch',
                label: 'Enabled',
                value: softShadow.enabled,
                key: 'softShadow_enabled'
            }
        ]

        if (softShadow.enabled) {
            const params = softShadow.params

            const restProps: TAssetEditorProp[] = [
                {
                    type: 'range',
                    label: 'Size',
                    key: 'softShadow_size',
                    misc: {
                        min: 0,
                        max: 100
                    },
                    value: params.size
                },
                {
                    type: 'range',
                    label: 'Focus',
                    key: 'softShadow_focus',
                    misc: {
                        precision: 0.1,
                        min: 0,
                        max: 2
                    },
                    value: params.focus
                },
                {
                    type: 'range',
                    label: 'Samples',
                    key: 'softShadow_samples',
                    misc: {
                        precision: 1,
                        min: 1,
                        max: 20
                    },
                    value: params.samples
                },
                {
                    type: 'range',
                    label: 'Opacity',
                    key: 'softShadow_opacity',
                    misc: {
                        precision: 0.01,
                        min: 0,
                        max: 1
                    },
                    value: params.opacity
                }
            ]

            list.push(...restProps)
        }

        return list
    }
    const envGroundSettings = () => {
        const list: TAssetEditorProp[] = [
            {
                type: 'attachEnv',
                label: 'Environment',
                value: editor3D.activeEnvironment.url || 'none',
                key: 'url_env'
            }
        ]
        if (editor3D.activeEnvironment.url) {
            const restParams: TAssetEditorProp[] = [
                {
                    type: 'switch',
                    label: 'Env ground',
                    value: !!editor3D.activeEnvironment.ground,
                    key: 'ground_envGround'
                },
                {
                    type: 'range',
                    label: 'Env Intensity',
                    value: editor3D.activeEnvironment?.intensity || 1,
                    key: 'background_envIntensity',
                    misc: { min: 0, precision: 0.1, max: 15 }
                }
            ]

            list.push(...restParams)

            if (editor3D.activeEnvironment.ground) {
                const restParams: TAssetEditorProp[] = [
                    {
                        type: 'range',
                        label: 'Scale',
                        value: editor3D.activeEnvironment?.ground?.scale,
                        key: 'scale_envGround',
                        misc: { max: Infinity }
                    },
                    {
                        type: 'range',
                        label: 'Radius',
                        value: editor3D.activeEnvironment?.ground?.radius,
                        key: 'radius_envGround',
                        misc: { max: Infinity }
                    },
                    {
                        type: 'range',
                        label: 'Height',
                        value: editor3D.activeEnvironment?.ground?.height,
                        key: 'height_envGround',
                        misc: { max: Infinity }
                    }
                ]
                list.push(...restParams)
            }
        }
        return list
    }
    const camConfig = editor3D.getCurrentConfigObj(editor3D.activeCamera)

    const activeCameraData = camConfig && isCamera(camConfig) ? camConfig : null

    const defaultCamera = editor3D.config.customObjects.find(x => x.type === AMAMeshType.Default_Camera)!
    const viewportCamera = editor3D.config.customObjects.find(x => x.type === AMAMeshType.ViewportCamera)!
    const configurationCamera = editor3D.config.customObjects.find(x => x.type === AMAMeshType.ConfigurationCamera)!

    return [
        {
            name: 'Scene',
            props: [
                { type: 'switch', label: 'Grid', value: editor3D.showGrid, key: 'grid' },
                { type: 'switch', label: 'Animate Camera', value: editor3D.animateCamera, key: 'animateCamera' },
                { type: 'tuple', label: 'Sidebar offset', value: editor3D.focalOffset, key: 'focalOffset' },
                // { type: 'color', label: 'Background', value: editor3D.backgroundColor, key: 'background' },

                ...envGroundSettings(),
                {
                    type: 'switch',
                    label: 'Showroom environment',
                    value: editor3D.sceneRoom === 'showroom',
                    key: 'scene_showroom'
                }
            ]
        },
        {
            name: 'PostProcessing',
            props: [
                {
                    type: 'postProcessing',
                    label: 'PostProcessing',
                    value: '',
                    key: 'post_processing',
                    misc: {
                        list: [...(postProcessingList() as any)]
                    }
                }
                // ...activePostProcessingProps()
            ]
        },
        {
            name: 'Camera',
            props: [
                {
                    type: 'list',
                    label: 'Cameras',
                    value: editor3D.activeCamera.name,
                    key: 'change',
                    misc: {
                        list: [
                            { label: 'default', value: defaultCamera?.id, key: `${defaultCamera?.id}_change` },
                            { label: 'viewport', value: viewportCamera?.id, key: `${viewportCamera?.id}_change` },
                            {
                                label: 'configurator',
                                value: configurationCamera?.id,
                                key: `${configurationCamera?.id}_change`
                            },
                            ...customCameras()
                        ]
                    }
                },
                { type: 'range', label: 'Fov', value: activeCameraData?.updatedCamera?.fov, key: 'fov_camera' },
                { type: 'range', label: 'Near', value: activeCameraData?.updatedCamera?.near, key: 'near_camera' },
                { type: 'range', label: 'Far', value: activeCameraData?.updatedCamera?.far, key: 'far_camera' },
                { type: 'tuple', label: 'Scale', value: activeCameraData?.updatedCamera?.scale, key: 'scale_camera' },
                {
                    type: 'select',
                    label: 'Target',
                    value: { options: customTargets(), value: isCamera(cameraConfig) && cameraConfig?.target?.object },
                    key: 'camera_target'
                },
                // { type: 'button', label: 'Camera position', value: 'Save', key: 'camera_position' },

                { type: 'switch', label: 'Depth Of Field', value: activeCameraData?.DOFEnabled, key: 'dof_camera' },
                //TODO igor
                ...(activeCameraData?.DOFEnabled
                    ? [
                          {
                              type: 'range' as const,
                              label: 'Focus Distance',
                              misc: { min: 0, max: 1, precision: 0.01 },
                              value: activeCameraData.DOFParameters!.focusDistance,
                              key: 'focusDistance_camera'
                          },
                          {
                              type: 'button' as const,
                              label: 'Set focus by mouse',
                              value: 'true',
                              key: 'focusDistanceMouse_camera'
                          },
                          {
                              type: 'range' as const,
                              label: 'Bokeh',
                              misc: { min: 0, max: 20, precision: 1 },
                              value: activeCameraData.DOFParameters!.bokehScale,
                              key: 'bokehScale_camera'
                          },
                          {
                              type: 'range' as const,
                              label: 'Focal Length',
                              misc: { min: 0, max: 2, precision: 0.1 },
                              value: activeCameraData.DOFParameters!.focalLength,
                              key: 'focalLength_camera'
                          }
                      ]
                    : [])
            ]
        },
        {
            name: 'Controls',
            props: [
                {
                    type: 'range',
                    label: 'maxDistance',
                    value: activeCameraData?.updatedControls?.maxDistance,
                    key: 'maxDistance_controls'
                },
                {
                    type: 'range',
                    label: 'minDistance',
                    value: activeCameraData?.updatedControls?.minDistance,
                    key: 'minDistance_controls'
                },
                {
                    type: 'range',
                    label: 'maxPolarAngle',
                    value: activeCameraData?.updatedControls?.maxPolarAngle,
                    key: 'maxPolarAngle_controls',
                    misc: { max: Math.PI * 2, enableInfinity: true }
                },
                {
                    type: 'range',
                    label: 'minPolarAngle',
                    value: activeCameraData?.updatedControls?.minPolarAngle,
                    key: 'minPolarAngle_controls',
                    misc: { max: Math.PI * 2, enableInfinity: true }
                },
                {
                    type: 'range',
                    label: 'maxAzimuthAngle',
                    value: activeCameraData?.updatedControls?.maxAzimuthAngle,
                    key: 'maxAzimuthAngle_controls',
                    misc: { max: Math.PI * 2, min: -Math.PI * 2, enableInfinity: true }
                },
                {
                    type: 'range',
                    label: 'minAzimuthAngle',
                    value: activeCameraData?.updatedControls?.minAzimuthAngle,
                    key: 'minAzimuthAngle_controls',
                    misc: { max: Math.PI * 2, min: -Math.PI * 2, enableInfinity: true }
                },
                {
                    type: 'switch',
                    label: 'autoRotate',
                    value: activeCameraData?.updatedControls?.autoRotate,
                    key: 'autoRotate_controls'
                },
                {
                    type: 'range',
                    label: 'autoRotate speed',
                    value: activeCameraData?.updatedControls?.autoRotateSpeed,
                    key: 'autoRotateSpeed_controls',
                    misc: { max: 10 }
                },
                {
                    type: 'range',
                    label: 'rotate speed',
                    value: activeCameraData?.updatedControls?.rotateSpeed,
                    key: 'rotateSpeed_controls',
                    misc: { max: 10 }
                },
                {
                    type: 'range',
                    label: 'zoom speed',
                    value: activeCameraData?.updatedControls?.zoomSpeed,
                    key: 'zoomSpeed_controls',
                    misc: { max: 10 }
                }
            ]
        },
        {
            name: 'Background',
            props: backgroundProps()
        },
        {
            name: 'Fog',
            props: fogProps()
        },
        {
            name: 'Contact shadows',
            props: contactShadowsProps()
        },
        {
            name: 'Soft shadows',
            props: softShadowProps()
        }
    ]
}
export { sceneProps }

import React, { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Switch } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AssetAttributeType, AssetRuleActionType, AssetType, useGetAssetsQuery } from '../../../../../../api/assetApi'
import { TAsset } from '../../../../../../types'
import { AssetsForAttributes } from '../../../assetEditor'
import { AMAMeshType, utilsEditor } from '@amaspace-editor/editor-3d'

const { Option } = Select

type ActionSetMaterialProps = {
    item: AssetRuleActionType
    modelPart: TAsset[]
    attributes: AssetAttributeType[]
    assets: AssetsForAttributes
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
}

type MaterialActionType = {
    modelPart: TAsset | null
    attribute: AssetAttributeType | null
    value: AssetType | null
    switcher: boolean
}

const ActionSetModel = ({
    item,
    deleteAction,
    attributes,
    assets,
    modelPart,
    changeAction
}: ActionSetMaterialProps) => {
    const editor = utilsEditor()
    const [action, setAction] = useState<MaterialActionType>(
        item.value
            ? JSON.parse(item.value)
            : {
                  modelPart: null,
                  value: null,
                  attribute: null,
                  switcher: true
              }
    )
    const customTargets = useMemo(() => {
        return editor.config.customObjects.filter(obj => obj.type === AMAMeshType.Target)
    }, [editor])

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    return (
        <Row gutter={[0, 16]}>
            <Col span={24}>
                <Select
                    value={action.modelPart?.id}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select  Part"
                    onChange={value => {
                        const foundModel = [...customTargets, ...modelPart].find(item => item.id === value) as TAsset

                        if (!foundModel) return
                        setAction({ ...action, modelPart: foundModel })
                    }}>
                    {[...customTargets, ...modelPart].map(item => {
                        let name = ''
                        if ('created_at' in item) name = item.patch.name
                        else name = item.name

                        return (
                            <Option key={item.id} value={item.id}>
                                {name}
                            </Option>
                        )
                    })}
                </Select>
            </Col>

            <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Switch
                    checkedChildren="Model"
                    onChange={() =>
                        setAction({
                            ...action,
                            value: null,
                            attribute: null,
                            switcher: !action.switcher
                        })
                    }
                    unCheckedChildren="Attribute"
                    defaultChecked={action.switcher}
                />
            </Col>

            <Col span={24}>
                <Row gutter={[8, 0]}>
                    {action.switcher ? (
                        <Col span={20}>
                            <Select
                                value={action.value?.id}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select Model"
                                onChange={value => {
                                    const foundMaterial = assets.allModels.find(item => item.id === value)
                                    if (!foundMaterial) return
                                    const final = {
                                        ...foundMaterial,
                                        patch: JSON.parse(foundMaterial.patch)
                                    } as any
                                    setAction({ ...action, value: final })
                                }}>
                                {assets.allModels.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    ) : (
                        <Col span={20}>
                            <Select
                                value={action.attribute?.id}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select Attribute"
                                onChange={value => {
                                    const item = attributes.filter(attr => attr.id === value)
                                    setAction({ ...action, attribute: item[0] })
                                }}>
                                {attributes.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}

                    <Col span={3}>
                        <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ActionSetModel

import React, { useEffect, useState } from 'react'
import { AssetsForAttributes } from './assetEditor'
import styled, { keyframes } from 'styled-components'
import { pxToEm } from '../../../theme/stylesMixins'
import assetApi, { AssetType } from '../../../api/assetApi'
import { useAppDispatch } from '../../../hooks'
import { AMAModelConfig, AMAUpdateConfig, customObjects, utilsEditor } from '@amaspace-editor/editor-3d'
import { UploadOutlined } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'

interface IAssetEditorAssetsProps {
    allAssets?: AssetsForAttributes
}

const AssetEditorAssets = ({ allAssets }: IAssetEditorAssetsProps) => {
    const dispatch = useAppDispatch()
    const editor = utilsEditor()
    const customModel = customObjects()

    const [filteredAssets, setFilteredAssets] = useState<AssetsForAttributes['allModels']>([])

    const removeUploadedModels = (modelID: string) => {
        editor.unselect()
        const dataInstance = [...editor.uploadedModelsConfig]
        const updatedUploadedModels = dataInstance.filter(model => model.id !== modelID)
        editor.setUploadedModelsConfig(updatedUploadedModels)
    }

    const uploadAssetToScene = async (asset: AssetType, isLazy: boolean, handleLoaded: () => void) => {
        const { data } = (await dispatch(assetApi.endpoints.getAssetById.initiate({ id: asset.id }))) as any

        customModel.create
            .model({
                name: asset.name,
                modelId: data.assets[0].id,
                lazyLoad: isLazy,
                modelData: structuredClone(data) as AMAModelConfig
            })
            .then(() => handleLoaded())
    }
    const filterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase().trim()
        if (allAssets) {
            setFilteredAssets(allAssets.allModels)
            setFilteredAssets(prev => [...prev.filter(i => i.name.toLowerCase().includes(value))])
        }
    }
    useEffect(() => {
        if (allAssets) {
            setFilteredAssets(allAssets.allModels)
        }
    }, [allAssets])

    return (
        <StyledComponent>
            {editor.uploadedModelsConfig.length ? (
                <StyledSidebar>
                    <StyledUploadedModelsList>
                        <StyledSidebarTitle>Uploaded models</StyledSidebarTitle>
                        {editor.uploadedModelsConfig.map((uploadedModelConfig, index, array) => {
                            const model = allAssets
                                ? allAssets.allModels.find(
                                      model => model.id === uploadedModelConfig.additionalOptions.modelId
                                  )
                                : null

                            if (!model) {
                                return null
                            }

                            return (
                                <>
                                    <StyledUploadedModelCard
                                        key={uploadedModelConfig.id}
                                        onClick={event => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            editor.setActiveById(model.id)
                                        }}>
                                        <StyledUploadedModelCardImage src={model.preview} alt="" />
                                        <StyledUploadedModelCardTitle>{model.name}</StyledUploadedModelCardTitle>
                                        <StyledUploadedModelCardButton
                                            type="button"
                                            onClick={event => {
                                                event.preventDefault()
                                                event.stopPropagation()
                                                removeUploadedModels(uploadedModelConfig.id)
                                            }}>
                                            <StyledUploadedModelCardButtonIcon
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14 11V17M10 11V17M6 7V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7M4 7H20M7 7L9 3H15L17 7"
                                                    stroke="#950E0E"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </StyledUploadedModelCardButtonIcon>
                                        </StyledUploadedModelCardButton>
                                    </StyledUploadedModelCard>
                                    {index < array.length - 1 ? <StyledUploadedModelCardDivider /> : null}
                                </>
                            )
                        })}
                    </StyledUploadedModelsList>
                </StyledSidebar>
            ) : null}
            <StyledWrapperAssets>
                <StyledSearch placeholder="Search..." onChange={filterHandler} />
                <StyledModelsList>
                    {filteredAssets.length
                        ? filteredAssets.map(model => {
                              return <ModelCard model={model} uploadAssetToScene={uploadAssetToScene} />
                          })
                        : 'No assets'}
                </StyledModelsList>
            </StyledWrapperAssets>
        </StyledComponent>
    )
}

interface IModelCard {
    model: AssetType
    uploadAssetToScene: (a: AssetType, b: boolean, c: () => void) => Promise<void>
}

const ModelCard = ({ model, uploadAssetToScene }: IModelCard) => {
    const [isLazy, setIsLazy] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleLoaded = () => {
        setLoading(false)
    }

    const handleUpload = () => {
        setLoading(true)
        uploadAssetToScene(model, isLazy, handleLoaded)
    }

    return (
        <StyledModelCard>
            <StyledModelCardImage src={model.preview} alt="" />
            <StyledModelCardTitle title={model.name}>{model.name}</StyledModelCardTitle>
            {loading && (
                <LoadingCardContainer>
                    <LoadingCard />
                </LoadingCardContainer>
            )}
            <StyledModelCardChoose>
                <StyledModelCardChooseContainer>
                    <Tooltip placement="top" title="If true, don't preload on preview page">
                        <StyledModelCardChooseLabel htmlFor="lazyLoad">
                            Is Lazy load?
                            <StyledModelCardChooseInput
                                name="lazyLoad"
                                type="checkbox"
                                checked={isLazy}
                                onChange={e => setIsLazy(e.target.checked)}
                            />
                        </StyledModelCardChooseLabel>
                    </Tooltip>
                </StyledModelCardChooseContainer>
                <StyledModelCardUpload onClick={handleUpload}>
                    <UploadOutlined />
                </StyledModelCardUpload>
            </StyledModelCardChoose>
        </StyledModelCard>
    )
}

export default AssetEditorAssets

const spinAnimation = keyframes`
 0% { -webkit-transform: rotate(0deg); }
 100% { -webkit-transform: rotate(360deg); }
`
const StyledSearch = styled(Input)`
    width: 95%;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
`
const StyledWrapperAssets = styled.div`
    width: 100%;
    text-align: center;
`
const LoadingCardContainer = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - 22px);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: brightness(0.5);
`

const LoadingCard = styled.div`
    -webkit-animation: ${spinAnimation} 2s linear infinite;
    animation: ${spinAnimation} 2s linear infinite;
    border: solid 6px rgba(0 0 0 / 0.25);
    border-right: solid 6px #08347c;
    border-top: solid 6px #08347c;
    border-radius: 50%;
    height: 100px;
    width: 100px;
`

const StyledModelCardChooseInput = styled.input`
    height: 1rem;
    width: 1rem;
    cursor: pointer;
`

const StyledModelCardChooseLabel = styled.label`
    display: flex;
    font-weight: 600;
    font-size: 16px;
    gap: 0.5rem;
    align-items: center;
`

const StyledModelCardChooseContainer = styled.div`
    display: flex;
    background: #ffffff5c;
    box-shadow: 0 0 20px 20px #00000052;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
`

const StyledModelCardUpload = styled.div`
    padding: 0.25rem 0.5rem;
    background: #bebebe;
    border-radius: 0.25rem;
    transition: background-color 0.25s;
    cursor: pointer;
    &:hover {
        transition: background-color 0.25s;
        background: #e5e5e5;
    }
`

const StyledModelCardChoose = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 22px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    opacity: 0;
    transition: all 0.5s;
    display: flex;
    &:hover {
        z-index: 1;
        opacity: 1;
        backdrop-filter: brightness(0.5);
    }
`

const StyledComponent = styled.div`
    display: flex;
    height: 100%;
`

const StyledSidebar = styled.div`
    border-right: 1px solid #fff;
    flex: 1 1 25%;
    overflow: auto;
    padding: ${pxToEm(20)};
`

const StyledSidebarTitle = styled.span`
    font-size: 16px;
    font-weight: 600;
`

const StyledUploadedModelsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const StyledUploadedModelCard = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;
`

const StyledUploadedModelCardDivider = styled.div`
    background-color: #efefef;
    border-radius: 4px;
    height: 2px;
`

const StyledUploadedModelCardButton = styled.button`
    align-items: center;
    background-color: #efefef;
    border: 1px solid #950e0e;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    padding: 6px;
    width: 40px;
`

const StyledUploadedModelCardButtonIcon = styled.svg`
    height: 100%;
    object-fit: contain;
    width: 100%;
`

const StyledUploadedModelCardImage = styled.img`
    border-radius: 4px;
    height: 64px;
    width: 64px;
`

const StyledUploadedModelCardTitle = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-break: anywhere;
`

const StyledModelsList = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    padding: ${pxToEm(20)};

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${pxToEm(150)}, 1fr));
    overflow: auto;
    gap: 20px;
    flex: 1 1 75%;
`
const StyledModelCard = styled.div`
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: relative;
    &:hover ${StyledModelCardChoose} {
    }
`
const StyledModelCardTitle = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
const StyledModelCardImage = styled.img`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #c6c6c6;
    border-radius: 5px;
`

import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'
import ProductAttributeNameItem from './items/ProductAttributeNameItem'
import ProductAttributeValueItem from './items/ProductAttributeValueItem'
import ProductAttributeDescriptionItem from "./items/ProductAttributeDescription";

type ProductStringItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeString = ({ attr }: ProductStringItemProps) => {
    return (
        <StyledAttributeItem repeat={3}>
            <ProductAttributeNameItem attr={attr} />
            <ProductAttributeValueItem type="select" attr={attr} />
            <ProductAttributeDescriptionItem attr={attr} />
        </StyledAttributeItem>
    )
}

export default ProductAttributeString

import { Button, Card, List, Spin } from 'antd'
import ProductRule from '../rules/ProductRule'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { StyledCatalogBtnWrapper } from './ProductInfo'
import {onDragEndAttributes, updateProductItems} from '../../../../utils'
import ReactDragListView from "react-drag-listview";

const ProductRules = () => {
    const { fetchRules, rules, setRules, isLoadingRules, requests } = useContext(CatalogContext)

    const { getRules, createRules, updateRules, deleteRules } = requests

    const { id: productId } = useParams()

    const [isLoadingBtn, setIsLoadingBtn] = useState(false)

    const [removedRules, setRemovedRules] = useState<number[]>([])

    const handleCreateRule = () => {
        let sorting = 1
        rules.forEach(item => {
            if (item.sorting > sorting) {
                sorting = item.sorting;
            }
        });

        const newRuleData: ProductRuleType = {
            newItem: 'new',
            id: Date.now(),
            product_id: productId!,
            name: `New Rule #${rules.length + 1}`,
            action: [],
            condition: [],
            status: null,
            sorting,
        }

        setRules(prevState => [...prevState, newRuleData])
    }

    const handleUpdateRules = async () => {
        setIsLoadingBtn(true)
        const { createdStatus, updatedStatus, deletedStatus } = await updateProductItems(
            rules,
            removedRules,
            createRules,
            updateRules,
            deleteRules
        )
        setIsLoadingBtn(false)

        if (createdStatus && updatedStatus && deletedStatus) {
            setRemovedRules([])

            await fetchRules(getRules)
        }
    }

    return (
        <Card className='catalog' title="Rules">
            {isLoadingRules ? (
                <Spin size="small" />
            ) : (
                <ReactDragListView
                    nodeSelector=".catalog .ant-list-item"
                    handleSelector=".catalog .attr-dragdrop"
                    lineClassName="dragLine"
                    onDragEnd={(fromIndex, toIndex) =>
                        setRules(onDragEndAttributes<ProductRuleType>(fromIndex, toIndex, rules))
                    }>
                    <List
                        itemLayout="horizontal"
                        dataSource={rules}
                        footer={
                            <StyledCatalogBtnWrapper>
                                <Button type={'primary'} loading={isLoadingBtn} onClick={handleCreateRule}>
                                    Add Rule
                                </Button>
                                <Button loading={isLoadingBtn} onClick={handleUpdateRules} type="primary">
                                    Update Rules
                                </Button>
                            </StyledCatalogBtnWrapper>
                        }
                        renderItem={item => (
                            <ProductRule rule={item} removedRules={removedRules} setRemovedRules={setRemovedRules} />
                        )}
                    />
                </ReactDragListView>

            )}
        </Card>
    )
}

export default ProductRules

export type ProductRuleType = {
    newItem?: 'new'
    id: number
    product_id: string | null
    name: string
    sorting: number
    condition: ProductConditionType[]
    action: ProductActionType[]
    status: 'changed' | null
}

export type ProductConditionType = {
    id: number
    newCondition?: 'new'
    dealer_attribute_id?: number | null
    attribute_id: number | null
    type_condition: number | null
    value: string
}

export type ProductActionType = {
    id: number
    newAction?: 'new'
    dealer_attribute_id?: number | null
    attribute_id: number | null
    type_action: number | null
    value: string
    option_id: string
}

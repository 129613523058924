import styled from 'styled-components'
import { Alert, Button, Checkbox } from 'antd'
import { Link } from 'react-router-dom'

export const StyledAuthWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 100vh;
    background: #f0f2f5;
`

export const StyledAuthBlock = styled.div`
    padding: 30px;
    width: 100%;
    max-width: 460px;
    background: #ffffff;
`

export const StyledAuthButton = styled(Button)`
    width: 100%;
    margin: 0 0 15px;
`

export const StyledAuthHelperLink = styled(Link)`
    display: flex;
    margin-left: auto;
`

export const StyledAuthAlert = styled(Alert)`
    margin: 0 0 20px;
`

export const StyledAuthAgreement = styled(Checkbox)`
    margin: 0 0 15px;
`

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AssetAttributeType, AssetRuleActionType } from '../../../../../../api/assetApi'
import { TAsset } from '../../../../../../types'
import { CombinedObject, utilsEditor } from '@amaspace-editor/editor-3d'
const { Option } = Select

type ActionActivateAnimationProps = {
    item: AssetRuleActionType
    modelPart: TAsset[]
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
    attributes: AssetAttributeType[]
}

type VisibilityActionType = {
    modelPart: CameraData | null
    value: boolean
    attribute: AssetAttributeType | null
}
type CameraData = {
    value: string
    label: string
}
const ActionSetVisibility = ({
    item,
    deleteAction,
    modelPart,
    changeAction,
    attributes
}: ActionActivateAnimationProps) => {
    const editor3D = utilsEditor()

    const [action, setAction] = useState<VisibilityActionType>(
        item.value
            ? JSON.parse(item.value)
            : {
                  modelPart: null,
                  value: false,
                  attribute: null
              }
    )

    const nodesData = React.useMemo((): CameraData[] | undefined => {
        if (editor3D?.config?.objects) {
            if (editor3D.uploadedModelsConfig.length) {
                const objects = editor3D.uploadedModelsConfig.map(config => {
                    return config?.objects.map(item => ({ value: item.userData.id, label: item.name }))
                })
                const innerConfig = editor3D?.config?.objects.map(item => ({
                    value: item.userData.id,
                    label: item.name
                }))
                return [...objects.flat(), ...innerConfig]
            }
            return editor3D?.config?.objects.map(item => ({ value: item.userData.id, label: item.name }))
        }
    }, [editor3D])

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    return (
        <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
            <Col span={18}>
                <Select
                    value={action.modelPart?.value}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Model Part"
                    onChange={value => {
                        const foundModel = nodesData && nodesData.find(item => item.value === value)
                        setAction({ ...action, modelPart: foundModel ? foundModel : null })
                    }}>
                    {nodesData &&
                        nodesData.map(item => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                </Select>
            </Col>

            <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox checked={action.value} onChange={() => setAction({ ...action, value: !action.value })} />
            </Col>
            <Select
                value={action.attribute?.id}
                showSearch
                optionFilterProp="children"
                placeholder="Select Attribute"
                onChange={value => {
                    const item = attributes.filter(attr => attr.id === value)
                    setAction({ ...action, attribute: item[0] })
                }}>
                {attributes.map(item => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <Col span={3}>
                <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
            </Col>
        </Row>
    )
}

export default ActionSetVisibility

import { createServer } from 'miragejs'
// import {config} from "@/config";
import { baseUrl } from '../api/url'

mockServer.Speed = {
    FAST: 200,
    NORMAL: 1500,
    SLOW: 60_000
}

function mockServer(features, speed = mockServer.Speed.NORMAL) {
    createServer({
        routes() {
            this.urlPrefix = baseUrl
            // this.namespace = `api`;
            this.timing = speed

            features.forEach(applyFeature => applyFeature(this))

            this.passthrough()
            this.passthrough(baseUrl + '**')
        }
    })
}

export default mockServer

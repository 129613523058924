import { createSlice } from '@reduxjs/toolkit'
import authApi from 'api/authApi'
import tfaApi from '../api/tfaApi'

const initialState: AuthDataType = {
    isAuth: false,
    accessToken: '',
    tfaConfigured: false,
    tfaRequired: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeAccessToken: (state, action: { payload: string }) => {
            state.accessToken = action.payload
        }
    },
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
            const { accessToken, tfaConfigured, tfaRequired } = action.payload

            state.accessToken = accessToken
            state.tfaConfigured = tfaConfigured
            state.tfaRequired = tfaRequired

            if (!tfaConfigured || !tfaRequired) {
                state.isAuth = true
            }
        })

        builder.addMatcher(tfaApi.endpoints.setupTFA.matchFulfilled, function (state, action) {
            state.accessToken = action.payload.upgradedAccessToken
        })

        builder.addMatcher(
            tfaApi.endpoints.checkTFA.matchFulfilled,

            function (state, action) {
                state.accessToken = action.payload.upgradedAccessToken
                state.isAuth = true
            }
        )
    }
})

export const { changeAccessToken } = authSlice.actions

export default authSlice

export type AuthDataType = {
    isAuth: boolean
    accessToken: string
    tfaRequired: boolean
    tfaConfigured: boolean
}

import React, { Dispatch } from 'react'
import classNames from 'classnames'
import styles from './ConfirmationMessage.module.scss'

type Props = {
    optionToAdd: {
        name: string
    }
    optionsToRemove: string[]
    priceChange: string
    onConfirm: () => void
    setConfirmationMessage: Dispatch<any>
}

const ConfirmationMessage = ({
    optionToAdd,
    optionsToRemove,
    priceChange,
    onConfirm,
    setConfirmationMessage
}: Props) => {
    return (
        <div className={styles.component}>
            <div className={styles.component__row}>Adding “{optionToAdd.name}” also requires the following changes</div>
            <div className={styles.component__divider}></div>
            <div className={styles.component__row}>
                <div className={styles.component__label}>Add</div>
                <div className={styles.component__value}>{optionToAdd.name}</div>
            </div>
            <div className={styles.component__divider}></div>
            <div className={styles.component__row}>
                <div className={styles.component__label}>Remove</div>
                <div className={styles.component__value}>
                    {optionsToRemove.map(optionsToRemove => {
                        return <div key={optionsToRemove}>{optionsToRemove}</div>
                    })}
                </div>
            </div>
            <div className={styles.component__divider}></div>
            <div className={styles.component__buttons}>
                <span className={styles.component__buttonsQuestion}>Do you want to continue?</span>
                <button
                    className={classNames(styles.component__button, styles.component__button_cancel)}
                    type="button"
                    onClick={() => {
                        setConfirmationMessage({
                            visible: false
                        })
                    }}>
                    Cancel
                </button>
                <button
                    className={classNames(styles.component__button, styles.component__button_confirm)}
                    type="button"
                    onClick={() => {
                        onConfirm()
                        setConfirmationMessage({
                            visible: false
                        })
                    }}>
                    Confirm
                </button>
            </div>
        </div>
    )
}

export default ConfirmationMessage

import { TableMetaType } from '../components/ExtendedTable'
import { ProductAttributeType } from '../pages/products/components/tabs/ProductAttributes'
import { ProductRuleType } from '../pages/products/components/tabs/ProductRules'
import { ProductType } from '../providers/CatalogProvider'
import { ProductTagType } from './dealerCatalogApi'
import api from './index'

const COMPANY_PRODUCT = 'product'
const COMPANY_PRODUCT_TAGS = 'tags'
const COMPANY_PRODUCT_KEYWORDS = 'keywords'
const COMPANY_PRODUCT_RULE = 'product-rule'
const COMPANY_PRODUCT_ATTRIBUTE = 'product-attribute'

export const companyCatalogApi = api.injectEndpoints({
    endpoints: build => ({
        getCompanyProducts: build.query<GetCompanyProductsType, CompanyProductFilter>({
            query: body => {
                const {
                    page,
                    name,
                    name_sort,
                    date_created_range_start,
                    date_created_range_end,
                    date_range_created_sort,
                    date_updated_range_start,
                    date_updated_range_end,
                    date_range_updated_sort,
                    created_by,
                    created_by_sort,
                    product_group_id,
                    tags,
                    assets,
                    license_id
                } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    date_created_range_start,
                    date_created_range_end,
                    date_updated_range_start,
                    date_updated_range_end,
                    created_by,
                    product_group_id: product_group_id.toString(),
                    license_id: license_id.toString()
                })

                if (name_sort) {
                    params.set('name_sort', name_sort)
                }
                params.set('date_range_created_sort', date_range_created_sort.toString())
                params.set('date_range_updated_sort', date_range_updated_sort.toString())
                params.set('created_by_sort', created_by_sort.toString())

                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''
                const assetsValue = assets ? assets.map(asset => `&assets[]=${asset}`).join('') : ''

                return `${COMPANY_PRODUCT}?${params}${tagsValue}${assetsValue}`
            }
        }),
        getCompanyProductList: build.mutation<GetCompanyProductsType, Pick<CompanyProductFilter, 'page' | 'tags'>>({
            query: body => {
                const { tags, page } = body
                const params = new URLSearchParams({ page: page.toString() })
                const tagsValue = tags ? tags.map(tag => `&tagsValues[]=${tag}`).join('') : ''

                return `${COMPANY_PRODUCT}/select?${params}${tagsValue}`
            }
        }),

        cloneCompanyRule: build.mutation<null, { rule_id: string }>({
            query: body => {
                const params = new URLSearchParams(body)
                return `${COMPANY_PRODUCT_RULE}/clone?${params}`
            }
        }),

        getCompanyProductByLicense: build.mutation<
            GetCompanyProductsType,
            Pick<CompanyProductFilter, 'page' | 'license_id'>
        >({
            query: body => {
                const { license_id, page } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    license_id: license_id.toString()
                })

                return `${COMPANY_PRODUCT}?${params}`
            }
        }),
        createCompanyProduct: build.mutation<{ id: number }, ProductType>({
            query: body => ({
                url: COMPANY_PRODUCT,
                method: 'POST',
                body
            })
        }),
        deleteCompanyProduct: build.mutation<null, string[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${COMPANY_PRODUCT}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),
        cloneCompanyProduct: build.mutation<null, string[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${COMPANY_PRODUCT}/clone?${ids}`,
                    method: 'PUT'
                }
            }
        }),
        updateCompanyProduct: build.mutation<boolean | string, ProductType>({
            query: body => {
                const { id, ...newBody } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${COMPANY_PRODUCT}?${params}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),
        getCompanyProduct: build.mutation<ProductType, { id: string }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id })
                return `${COMPANY_PRODUCT}/view-one?${params}`
            }
        }),

        getCompanyProductCategories: build.mutation<ProductAttributeType[], { id: string }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ product_id: id, show_category: '1' })

                return `${COMPANY_PRODUCT_ATTRIBUTE}?${params}`
            }
        }),

        getCompanyProductAttributes: build.mutation<ProductAttributeType[], { id: string }>({
            query: body => {
                const { id } = body
                const params = new URLSearchParams({ product_id: id })

                return `${COMPANY_PRODUCT_ATTRIBUTE}?${params}`
            }
        }),
        createCompanyProductAttribute: build.mutation<null, ProductAttributeType>({
            query: body => {
                const { id, ...newBody } = body

                return {
                    url: `${COMPANY_PRODUCT_ATTRIBUTE}`,
                    method: 'POST',
                    body: newBody
                }
            }
        }),
        updateCompanyProductAttribute: build.mutation<boolean | string, ProductAttributeType>({
            query: body => {
                const { id, ...attribute } = body
                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${COMPANY_PRODUCT_ATTRIBUTE}?${params}`,
                    method: 'PUT',
                    body: attribute
                }
            }
        }),
        deleteCompanyProductAttributes: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${COMPANY_PRODUCT_ATTRIBUTE}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getCompanyProductKeywords: build.query<ProductTagType[], null>({
            query: () => COMPANY_PRODUCT_KEYWORDS
        }),
        getCompanyProductTags: build.query<ProductTagType[], null>({
            query: () => COMPANY_PRODUCT_TAGS
        }),

        getCompanyProductRules: build.mutation<ProductRuleType[], { id: string }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id })

                return `${COMPANY_PRODUCT_RULE}?${params}`
            }
        }),
        createCompanyProductRule: build.mutation<null, ProductRuleType>({
            query: body => {
                const { action, condition } = body

                const filteredAction = action.map(({ newAction, id, ...item }) => (newAction ? item : { id, ...item }))
                const filteredCondition = condition.map(({ newCondition, id, ...item }) =>
                    newCondition ? item : { id, ...item }
                )

                const newBody = { ...body, action: filteredAction, condition: filteredCondition }

                return {
                    url: COMPANY_PRODUCT_RULE,
                    method: 'POST',
                    body: newBody
                }
            }
        }),
        updateCompanyProductRule: build.mutation<null, ProductRuleType>({
            query: body => {
                const { id, action, condition } = body

                const filteredAction = action.map(({ newAction, id, ...item }) => (newAction ? item : { id, ...item }))
                const filteredCondition = condition.map(({ newCondition, id, ...item }) =>
                    newCondition ? item : { id, ...item }
                )

                const newBody = { ...body, action: filteredAction, condition: filteredCondition }

                const params = new URLSearchParams({ id: id.toString() })

                return {
                    url: `${COMPANY_PRODUCT_RULE}?${params}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),
        deleteCompanyProductRules: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => `ids[]=${id}`).join('&')

                return {
                    url: `${COMPANY_PRODUCT_RULE}?${ids}`,
                    method: 'DELETE'
                }
            }
        }),
        getShareCompanyProduct: build.mutation<ProductType, { id: string }>({
            query: body => {
                const params = new URLSearchParams({ id: body.id })
                return `${COMPANY_PRODUCT}/share?${params}`
            },
            extraOptions: {
                ignoreErrors: true
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetCompanyProductsQuery,
    useGetCompanyProductListMutation,
    useDeleteCompanyProductMutation,
    useCreateCompanyProductMutation,
    useUpdateCompanyProductMutation,
    useGetCompanyProductMutation,
    useCloneCompanyProductMutation,
    useGetCompanyProductByLicenseMutation,

    useCloneCompanyRuleMutation,

    useGetCompanyProductRulesMutation,
    useCreateCompanyProductRuleMutation,
    useDeleteCompanyProductRulesMutation,
    useUpdateCompanyProductRuleMutation,

    useGetCompanyProductKeywordsQuery,
    useGetCompanyProductTagsQuery,

    useCreateCompanyProductAttributeMutation,
    useDeleteCompanyProductAttributesMutation,
    useGetCompanyProductAttributesMutation,
    useGetCompanyProductCategoriesMutation,
    useUpdateCompanyProductAttributeMutation,
    useGetShareCompanyProductMutation
} = companyCatalogApi

export type CompanyProductFilter = {
    page: number
    name: string
    license_id: number | string
    product_group_id: number | string
    name_sort: string

    date_created_range_start: string
    date_created_range_end: string
    date_range_created_sort: string

    date_updated_range_start: string
    date_updated_range_end: string
    date_range_updated_sort: string

    created_by: string
    created_by_sort: string

    assets: string[]
    tags: string[]
}

export type GetCompanyProductsType = {
    products: ProductType[]
    _meta: TableMetaType
}

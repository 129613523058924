import React, { useEffect, useState } from 'react'
import { TAsset } from '../../../types'
import AssetEditor2D from './assetEditor2D'
import AnnotationsList from './annotations/AnnotationsList'
import AnnotationsEditor from './annotations/AnnotationsEditor'
import {
    ASSET_TYPE,
    AssetAnnotationType,
    useCreateAssetAnnotationMutation,
    useGetAssetAnnotationsQuery,
    useUpdateAssetAnnotationMutation
} from 'api/assetApi'
import Title from 'antd/lib/typography/Title'
import AssetEditor3D from './editor3D/assetEditor3D'
import { AMAMode } from '@amaspace-editor/editor-3d'

type AssetAnnotationsProps = {
    asset: TAsset
}

const AssetAnnotations = ({ asset }: AssetAnnotationsProps) => {
    const is2D = asset?.type === ASSET_TYPE.Texture
    const is3D = asset?.type === ASSET_TYPE.Scene || asset?.type === ASSET_TYPE.Model

    const [dataSource, setDataSource] = useState<AssetAnnotationType[]>([])
    const [editorAnnotationId, setEditorAnnotationId] = useState<CurrentAnnotationIdType>(null)
    const [showEditor, setShowEditor] = useState(true)
    const { data, isLoading, refetch } = useGetAssetAnnotationsQuery({ id: asset.dbId })

    const [createAnnotation, resultCreator] = useCreateAssetAnnotationMutation()
    const [updateAnnotation, resultUpdater] = useUpdateAssetAnnotationMutation()
    const { isLoading: isLoadingCreator, isSuccess: isSuccessCreator } = resultCreator
    const { isLoading: isLoadingUpdater, isSuccess: isSuccessUpdater } = resultUpdater

    useEffect(() => {
        if (showEditor) setShowEditor(false)
    }, [showEditor])

    useEffect(() => {
        if (data) setDataSource(data)
    }, [data])

    useEffect(() => {
        if (isSuccessCreator || isSuccessUpdater) {
            setEditorAnnotationId(null)
            refetch()
        }
    }, [isSuccessCreator, isSuccessUpdater])

    return (
        <>
            {is2D ? (
                <AssetEditor2D
                    leftMenu={
                        <AnnotationsList
                            editorAnnotationId={editorAnnotationId}
                            loading={isLoading}
                            setShowEditor={setShowEditor}
                            setEditorAnnotationId={setEditorAnnotationId}
                            assetId={asset.dbId}
                            data={dataSource}
                            setData={setDataSource}
                        />
                    }
                    rightMenu={
                        <>
                            <Title level={3} children="Annotation Editor" />
                            {!showEditor && editorAnnotationId && (
                                <AnnotationsEditor
                                    createAnnotation={createAnnotation}
                                    updateAnnotation={updateAnnotation}
                                    isLoading={isLoadingCreator || isLoadingUpdater}
                                    data={dataSource}
                                    setData={setDataSource}
                                    id={editorAnnotationId}
                                />
                            )}
                        </>
                    }
                    asset={asset}
                />
            ) : null}
            {is3D ? (
                <AssetEditor3D
                    leftMenu={
                        <AnnotationsList
                            editorAnnotationId={editorAnnotationId}
                            loading={isLoading}
                            setShowEditor={setShowEditor}
                            setEditorAnnotationId={setEditorAnnotationId}
                            assetId={asset.dbId}
                            data={dataSource}
                            setData={setDataSource}
                        />
                    }
                    rightMenu={
                        <>
                            <Title level={3} children="Annotation Editor" />
                            {!showEditor && editorAnnotationId && (
                                <AnnotationsEditor
                                    createAnnotation={createAnnotation}
                                    updateAnnotation={updateAnnotation}
                                    isLoading={isLoadingCreator || isLoadingUpdater}
                                    data={dataSource}
                                    setData={setDataSource}
                                    id={editorAnnotationId}
                                />
                            )}
                        </>
                    }
                    asset={asset}
                    mode={AMAMode.VIEWER}
                    propsAreaOpened={Boolean(!showEditor && editorAnnotationId)}
                />
            ) : null}
        </>
    )
}

export default AssetAnnotations

export type CurrentAnnotationIdType = number | string | null

import { createSlice } from '@reduxjs/toolkit'
import userApi, { UserType } from 'api/userApi'

const initialState: UserType = {
    id: 0,
    name: '',
    lastname: '',
    role: 'super admin',
    email: '',
    company: '',
    tfaIsConfigured: false,
    tfaIsRequired: false,
    is_only_company: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
            state.id = action.payload.id
            state.role = action.payload.role
            state.name = action.payload.name
            state.lastname = action.payload.lastname
            state.email = action.payload.email
            state.company = action.payload.company
            state.tfaIsConfigured = action.payload.tfaIsConfigured
            state.tfaIsRequired = action.payload.tfaIsRequired
            state.is_only_company = action.payload.is_only_company
        })
    }
})

export default userSlice

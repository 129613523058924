import React, { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { Select } from 'antd'
import { ProductActionType } from '../../tabs/ProductRules'

type RuleAttributeToValueItemProps = {
    item: ProductActionType
    handleChange: (item: ProductActionType) => void
}

const RuleAttributeToValueItem = ({ item, handleChange }: RuleAttributeToValueItemProps) => {
    const { attributes } = useContext(CatalogContext)

    const handleChangeAttribute = (value: string) => {
        handleChange({ ...item, value: value.toString() })
    }

    return (
        <Select
            showSearch
            optionFilterProp="children"
            placeholder="Select Attribute"
            value={item.value ? item.value : null}
            onChange={handleChangeAttribute}>
            {Array.from(new Set(attributes.map(item => item.type))).map(type => (
                <Select.OptGroup key={type} label={type}>
                    {attributes
                        .filter(item => item.type === type)
                        .map(item => (
                            <Select.Option key={item.id} value={item.id.toString()}>
                                {item.name ? item.name : 'Empty Name'}
                            </Select.Option>
                        ))}
                </Select.OptGroup>
            ))}
        </Select>
    )
}

export default RuleAttributeToValueItem

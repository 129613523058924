import features from 'server-mock/features'
import mockServer from 'server-mock/createServer'

function initServerMocks(envValue, speed) {
    if (!envValue || !envValue.trim()) return

    const enabledFeatures = envValue
        .trim()
        .split(' ')
        .map(name => {
            const feature = features[name]

            if (feature) {
                return feature
            } else {
                console.warn(`Server-mock: feature "${name}" not found`)
                return null
            }
        })
        .filter(feature => feature)

    mockServer(enabledFeatures, speed)
}

export default initServerMocks

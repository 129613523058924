import api from './index'
import { UserStatus, UserStatusInfo } from './accountManagerApi'
import { TableMetaType } from '../components/ExtendedTable'

const DEALER = 'dealer'

const dealerApi = api.injectEndpoints({
    endpoints: build => ({
        getDealers: build.query<GetDealersType, DealerFilter>({
            query: body => {
                const { page, name, lastname, email, status } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    lastname,
                    email,
                    status: status ?? ''
                })

                return `${DEALER}?${params}`
            }
        }),

        getDealersList: build.mutation<GetDealersType, DealerFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${DEALER}?${params}`
            }
        }),

        getDealerById: build.query<DealerType, Pick<DealerType, 'id'>>({
            query: body => `${DEALER}/${body.id}`
        }),

        changeDealerStatus: build.mutation<null, UserStatusInfo>({
            query: body => {
                const { status, id } = body

                return {
                    url: `${DEALER}/${id}/change-status`,
                    method: 'PUT',
                    body: { status }
                }
            }
        }),

        changeDealerPay: build.mutation<null, DealerPayInfo>({
            query: body => {
                const { type_pay, id } = body

                return {
                    url: `${DEALER}/${id}/change-pay`,
                    method: 'PUT',
                    body: { type: type_pay }
                }
            }
        }),

        approveDealer: build.mutation<null, Pick<DealerType, 'id'>>({
            query: body => ({
                url: `${DEALER}/${body.id}/approve`,
                method: 'POST'
            })
        }),

        updateCurrentDealer: build.mutation<null, DealerInfoType>({
            query: body => ({
                url: `${DEALER}/me`,
                method: 'PUT',
                body
            })
        }),

        createDealer: build.mutation<null, DealerInfoType>({
            query: body => ({
                url: DEALER,
                method: 'POST',
                body
            })
        }),

        registerDealer: build.mutation<null, DealerRegistrationType>({
            query: body => {
                const { dealer, companyId } = body
                const params = new URLSearchParams({ company: companyId })

                return {
                    url: `${DEALER}/sign-up?${params}`,
                    method: 'POST',
                    body: dealer
                }
            }
        }),

        deleteDealer: build.mutation<null, {id: DealerType['id']}>({
            query: body => ({
                url: `${DEALER}/${body.id}`,
                method: 'DELETE'
            })
        }),


        changeDealerEmail: build.mutation<null, { id: DealerType['id'], email: string }>({
            query: body => {
                const {id, email} = body
                return {
                    url: `${DEALER}/${id}/email-change`,
                    method: 'POST',
                    body: {email}
                }
            }
        }),

        resendDealerEmailConfirmation: build.mutation<null, { id: DealerType['id'] }>({
            query: body => {
                const {id} = body
                return {
                    url: `${DEALER}/${id}/confirmation-resend`,
                    method: 'POST',
                }
            }
        }),
    }),
    overrideExisting: false
})

export type DealerFilter = {
    page: number
    email: string
    name: string
    lastname: string
    status: UserStatus | null
}

export type DealerType = {
    id: number | string
    email: string
    name: string
    lastname: string
    status: UserStatus
    dealerInfo: DealerAdditionalInfoType
}

export type DealerInfoType = Omit<DealerType, 'status, dealerInfo'> & Omit<DealerAdditionalInfoType, 'currency_id'>

export type DealerPayInfo = { id: DealerType['id']; type_pay: DealerAdditionalInfoType['type_pay'] }

export type DealerAdditionalInfoType = {
    currency_id: number
    country_id: number
    state_id: number
    business_name: string
    business_url: string
    business_phone: string
    leads_email: string
    city: string
    street: string
    payment: { name: string; current_currency: string; current_price: string; current_debit: number; id: number }
    player_access_token: string
    zip_code: string
    type_pay: 0 | 1
}

type DealerRegistrationType = {
    companyId: string
    dealer: DealerInfoType
}

export type GetDealersType = {
    items: DealerType[]
    _meta: TableMetaType
}

export const {
    useGetDealersQuery,
    useGetDealerByIdQuery,
    useUpdateCurrentDealerMutation,
    useChangeDealerStatusMutation,
    useApproveDealerMutation,
    useGetDealersListMutation,
    useCreateDealerMutation,
    useRegisterDealerMutation,
    useChangeDealerPayMutation,
    useDeleteDealerMutation,
    useChangeDealerEmailMutation,
    useResendDealerEmailConfirmationMutation,
} = dealerApi

export default dealerApi

import { useState } from 'react'
import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import AuthWrapper from './components/AuthWrapper'
import AuthSuccessAlert from './components/AuthSuccessAlert'
import { StyledAuthButton } from './styles'
import RegistrationAgreement from './components/RegistrationAgreement'
import { DealerInfoType, useRegisterDealerMutation } from '../../api/dealerApi'
import DealerForm from '../users/dealer/DealerForm'
import { routesUrl } from '../../router/urls'

const messages = [
    'You have completed your account registration.',
    'Please allow our team up to 24 hours to review your details. You will then receive a follow-up email notifying you that your account is now activated and accessible upon sign in.'
]

const DealerRegistration = () => {
    const [isCompleted, setIsCompleted] = useState(false)
    const [isAgreement, setIsAgreement] = useState(false)

    const { companyId } = useParams()
    const [form] = Form.useForm<DealerInfoType>()

    const [register, resultRegister] = useRegisterDealerMutation()

    const onFinish = async (form: DealerInfoType) => {
        return await register({ dealer: form, companyId: companyId as string })
    }

    return (
        <AuthWrapper title="Sign up">
            {isCompleted ? (
                <AuthSuccessAlert link={routesUrl.login} messages={messages} />
            ) : (
                <>
                    <DealerForm
                        form={form}
                        onFinish={onFinish}
                        onSubmitted={() => {
                            setIsCompleted(true)
                            form.resetFields()
                        }}
                        mode="registration"
                    />

                    <RegistrationAgreement isAgreement={isAgreement} setIsAgreement={setIsAgreement} />

                    <StyledAuthButton
                        disabled={!isAgreement}
                        type="primary"
                        loading={resultRegister.isLoading}
                        onClick={form.submit}>
                        Submit
                    </StyledAuthButton>
                </>
            )}
        </AuthWrapper>
    )
}

export default DealerRegistration

import { Col, Input } from 'antd'
import React, { useState, useCallback, useEffect } from 'react'
import _ from 'underscore'
import { ActionType } from '../assetEditorProps'
import styled from 'styled-components'

export const RenderInput = React.memo(({ data, onChange }: ActionType) => {
    const [inputValue, setInputValue] = useState(data.value)
    const maxLength = data.misc?.maxLength || 100
    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.stopPropagation()
    }
    const throttledChangeHandler = useCallback(
        _.debounce((value: string) => onChange(data.key, value), 500),
        [data.value, data.key, onChange]
    )

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value)
            throttledChangeHandler(event.target.value)
        },
        [throttledChangeHandler]
    )

    useEffect(() => {
        setInputValue(data.value)
    }, [data.value])

    return (
        <PropsItem span={16}>
            <Input maxLength={maxLength} value={inputValue} onChange={changeHandler} onKeyDown={handleKeyDown} />
        </PropsItem>
    )
})

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`

import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Form, Input, Select, Space, Spin } from 'antd'
import {
    BankOutlined,
    CheckCircleOutlined,
    CreditCardOutlined,
    FileProtectOutlined,
    ProjectOutlined,
    UserSwitchOutlined
} from '@ant-design/icons'
import toast from 'react-hot-toast'
import PageBuilder from '../../../components/panel/PageBuilder'
import {
    DealerPayInfo,
    useApproveDealerMutation,
    useChangeDealerPayMutation,
    useChangeDealerStatusMutation,
    useGetDealerByIdQuery
} from '../../../api/dealerApi'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { useAppSelector } from '../../../hooks'
import UserChangeStatusModal from '../UserChangeStatusModal'
import { useNavigate, useParams } from 'react-router-dom'
import ModalWithForm from '../ModalWithForm'
import { UserStatusInfo } from '../../../api/accountManagerApi'
import { useGetCountryQuery, useGetStatesByCountryMutation } from '../../../api/countryApi'
import { useGetCurrencyQuery } from '../../../api/priceApi'
import { routesUrl } from '../../../router/urls'

const Dealer = () => {
    const { id } = useParams()
    const { role } = useAppSelector(state => state.user)
    const { showModal } = useContext(ModalConfirmContext)
    const navigate = useNavigate()

    const [formChangeStatus] = Form.useForm<UserStatusInfo>()
    const [formChangePay] = Form.useForm<DealerPayInfo>()

    const [isModalOpenChangeStatus, setIsModalOpenChangeStatus] = useState(false)
    const [isModalOpenChangePay, setIsModalOpenChangePay] = useState(false)

    const { data: currencies } = useGetCurrencyQuery(null)
    const { data: countries } = useGetCountryQuery()
    const [getStatesByCountry, resultsStatesByCountry] = useGetStatesByCountryMutation()

    const [changeStatus, resultsChangeStatus] = useChangeDealerStatusMutation()
    const [changePay, resultsChangePay] = useChangeDealerPayMutation()
    const [approve] = useApproveDealerMutation()

    const { data: user, isSuccess, isLoading, refetch } = useGetDealerByIdQuery({ id: id! })

    useEffect(() => {
        if (isSuccess && user) {
            getStatesByCountry({ id: user.dealerInfo.country_id })
        }
    }, [isSuccess])

    return (
        <PageBuilder
            breadcrumbs={['dealers', 'dealer']}
            title={`Dealer ${user?.dealerInfo?.business_name ?? ''}`}
            documentTitle={`Dealer ${user?.dealerInfo?.business_name ?? ''}`}>
            {user && isSuccess && (
                <Space size={'small'}>
                    {role === 'admin' && (
                        <>
                            <Button
                                onClick={() => {
                                    formChangeStatus.setFieldsValue({ id })
                                    setIsModalOpenChangeStatus(true)
                                }}
                                type="primary">
                                <UserSwitchOutlined />
                                Change Status
                            </Button>

                            {user.status === 'Wait approving' && (
                                <Button
                                    onClick={() => {
                                        const handleApprove = async () => {
                                            const loader = toast.loading('Progressing...')
                                            const res = await approve({ id: id! })
                                            toast.dismiss(loader)
                                            if ('error' in res) return
                                            toast.success('Dealer is successfully approved!')
                                            refetch()
                                        }

                                        showModal('approve the dealer', handleApprove)
                                    }}
                                    type="primary">
                                    <CheckCircleOutlined />
                                    Approve
                                </Button>
                            )}
                        </>
                    )}

                    {user.status === 'Active' && (
                        <>
                            {(role === 'admin' || role === 'account manager') && (
                                <Button
                                    onClick={() => {
                                        navigate(`${routesUrl.licensesDealer}/${id}`)
                                    }}
                                    type="primary">
                                    <FileProtectOutlined />
                                    Licenses
                                </Button>
                            )}

                            {role === 'account manager' && (
                                <>
                                    {user.dealerInfo.type_pay !== 0 && (
                                        <Button
                                            onClick={() => {
                                                navigate(`${routesUrl.apoDealer}/${id}`)
                                            }}
                                            type="primary">
                                            <ProjectOutlined />
                                            Ama Product Ownership
                                        </Button>
                                    )}

                                    <Button
                                        onClick={() => {
                                            const { dealerInfo } = user
                                            formChangePay.setFieldsValue({ type_pay: dealerInfo?.type_pay, id })
                                            setIsModalOpenChangePay(true)
                                        }}
                                        type="primary">
                                        <BankOutlined />
                                        Change Pay
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            navigate(`${routesUrl.invoicesDealer}/${id}`)
                                        }}
                                        type="primary">
                                        <CreditCardOutlined />
                                        Invoices
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Space>
            )}

            <Card title="Details" style={{ marginTop: 16 }}>
                {isLoading ? (
                    <Spin size={'small'} />
                ) : (
                    <>
                        {user && (
                            <div>
                                <p>
                                    <b>Name:</b> {user.name ?? '-'}
                                </p>
                                <p>
                                    <b>Last Name:</b> {user.lastname ?? '-'}
                                </p>
                                <p>
                                    <b>Email:</b> {user.email ?? '-'}
                                </p>
                                <p>
                                    <b>Status:</b> {user.status ?? '-'}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </Card>

            <Card title="Bussiness" style={{ marginTop: 16 }}>
                {isLoading ? (
                    <Spin size={'small'} />
                ) : (
                    <>
                        {user && (
                            <div>
                                <p>
                                    <b>Business Name:</b> {user.dealerInfo.business_name ?? '-'}
                                </p>
                                <p>
                                    <b>business Phone:</b> {user.dealerInfo.business_phone ?? '-'}
                                </p>
                                <p>
                                    <b>business Url:</b> {user.dealerInfo.business_url ?? '-'}
                                </p>
                                <p>
                                    <b>Leads Email:</b>{' '}
                                    {user.dealerInfo.leads_email ? user.dealerInfo.leads_email : '-'}
                                </p>
                                <p>
                                    <b>Currency:</b>{' '}
                                    {(currencies &&
                                        currencies.find(price => price.id === user.dealerInfo.currency_id)?.name) ??
                                        '-'}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </Card>

            <Card title="Address" style={{ marginTop: 16 }}>
                {isLoading ? (
                    <Spin size={'small'} />
                ) : (
                    <>
                        {user && (
                            <div>
                                <p>
                                    <b>Country:</b>{' '}
                                    {(countries &&
                                        countries.find(country => country.id === user.dealerInfo.country_id)?.name) ??
                                        '-'}
                                </p>
                                <p>
                                    <b>State:</b>{' '}
                                    {(resultsStatesByCountry.data &&
                                        resultsStatesByCountry.data.find(state => state.id === user.dealerInfo.state_id)
                                            ?.name) ??
                                        '-'}
                                </p>
                                <p>
                                    <b>City:</b> {user.dealerInfo.city ?? '-'}
                                </p>
                                <p>
                                    <b>Street:</b> {user.dealerInfo.street ?? '-'}
                                </p>
                                <p>
                                    <b>Zip Code:</b> {user.dealerInfo.zip_code ?? '-'}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </Card>

            <UserChangeStatusModal
                form={formChangeStatus}
                changeStatus={changeStatus}
                isModalOpen={isModalOpenChangeStatus}
                setIsModalOpen={setIsModalOpenChangeStatus}
                isLoading={resultsChangeStatus.isLoading}
                refetch={refetch}
            />

            <ModalWithForm
                title={'Change Pay type'}
                isOpen={isModalOpenChangePay}
                setIsOpen={setIsModalOpenChangePay}
                isLoading={resultsChangePay.isLoading}
                submit={formChangePay.submit}>
                <Form
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    form={formChangePay}
                    onFinish={async e => {
                        const loader = toast.loading('Progressing...')
                        const res = await changePay(e)
                        toast.dismiss(loader)
                        if ('error' in res) return
                        toast.success('The Pay type is successfully changed!')
                        setIsModalOpenChangePay(false)
                        refetch()
                        formChangePay.resetFields()
                    }}>
                    <Form.Item name="id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} label="Type" name="type_pay">
                        <Select placeholder="Pay type" options={initOptionsPayType} />
                    </Form.Item>
                </Form>
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Dealer

const initOptionsPayType: { value: number; label: string }[] = [
    { value: 0, label: 'Pay for licenses' },
    { value: 1, label: 'Pay for subscription' }
]

import PageBuilder from '../../../components/panel/PageBuilder'
import CatalogCompanyProvider from '../../../providers/CatalogCompanyProvider'
import ProductTabs from '../components/tabs/ProductTabs'

const CompanyProductCreate = () => {
    return (
        <PageBuilder breadcrumbs={['catalog', 'create']} title="Create Product" documentTitle="Create Product">
            <CatalogCompanyProvider>
                <ProductTabs type="company" isCreating={true} />
            </CatalogCompanyProvider>
        </PageBuilder>
    )
}

export default CompanyProductCreate

import Title from 'antd/lib/typography/Title'
import Breadcrumbs, { BreadcrumbsType } from './Breadcrumbs'
import { useSetDocumentTitle } from '../../hooks'
import { ReactNode } from 'react'

type PageBuilderProps = {
    documentTitle: string
    title?: string
    breadcrumbs?: BreadcrumbsType
    children: ReactNode
}

const PageBuilder = ({ title, children, breadcrumbs, documentTitle }: PageBuilderProps) => {
    useSetDocumentTitle({ title: documentTitle })

    return (
        <div style={{ height: '100%' }}>
            <Breadcrumbs breadcrumbs={breadcrumbs ? breadcrumbs : null} />
            {title && <Title level={2}>{title}</Title>}
            {children}
        </div>
    )
}

export default PageBuilder

import React, { useContext, useEffect, useState } from 'react'
import {Button, Dropdown, Space, Table} from 'antd'
import {CopyOutlined, DeleteOutlined, DownOutlined, DragOutlined, EditOutlined} from '@ant-design/icons'
import {
    AssetAttributeType,
    AssetRuleType, useCloneAssetAttributeMutation, useCloneAssetRuleMutation,
    useDeleteAssetRulesMutation,
    useGetAssetAttributesQuery,
    useGetAssetRulesQuery, useUpdateAssetRuleMutation
} from '../../../../../api/assetApi'
import { ActionTypeId } from '../LogicEditor'
import { utilsEditor } from '@amaspace-editor/editor-3d'
import { ModalConfirmContext } from '../../../../../providers/ModalConfirmProvider'
import toast from "react-hot-toast";
import ReactDragListView from "react-drag-listview";
import {onDragEndAttributes} from "../../../../../utils";

type LogicRulesProps = {
    id: number
    addRule: () => void
    editRule: (rule: AssetRuleType) => void
}

const LogicRules = ({ addRule, editRule, id }: LogicRulesProps) => {
    const { showModal } = useContext(ModalConfirmContext)

    const editor3D = utilsEditor()

    const [dataSource, setDataSource] = useState<AssetRuleType[]>([])

    const [isUpdating, setIsUpdating] = useState(false)
    const { data: assetRules, isLoading, refetch: refetchAssetRules } = useGetAssetRulesQuery({ id })
    const [deleteRules, result] = useDeleteAssetRulesMutation()
    const { isLoading: isLoadingDeleted, isSuccess: isSuccessDeleted } = result
    const [cloneItems] = useCloneAssetRuleMutation()
    const [updateRule] = useUpdateAssetRuleMutation()

    useEffect(() => {
        if (assetRules) setDataSource(assetRules)
    }, [assetRules])

    useEffect(() => {
        if (isSuccessDeleted) refetchAssetRules()
    }, [isSuccessDeleted])

    const uploadUpdatedItems = async (items: AssetRuleType[]) => {
        const updatedItems = items.filter(item => item.status === 'changed')
        setIsUpdating (true)

        for (let i = 0; i < updatedItems.length; i++) {
            const res = await updateRule(updatedItems[i])
            if ('error' in res) {
                setIsUpdating(false)
                return false
            }
            toast.success('Updated!')

            if (typeof res.data === 'string') {
                toast.error(res.data)
            }
        }

        setIsUpdating(false)
        refetchAssetRules()

        return true
    }

    const columns = [
        {
            dataIndex: 'DragAndDrop',
            key: 'DragAndDrop',
            render: () => (
                <span className="attr-dragdrop">
                    <DragOutlined/>
                </span>
            )
        },
        {
            dataIndex: 'name',
            key: 'name'
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, rule: AssetRuleType) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="link" onClick={() => editRule(rule)} size="small">
                        <EditOutlined />
                    </Button>
                    <Button
                        onClick={() => {
                            const handleDuplicate = async () => {
                                const loader = toast.loading('Progressing...')
                                const res = await cloneItems({id: rule.id as number})
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Rule is successfully duplicated!')
                                refetchAssetRules()
                            }

                            showModal('duplicate rule', handleDuplicate)
                        }}
                        type="link"
                        size="small">
                        <CopyOutlined/>
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            const callback = () => {
                                deleteRules([rule.id as number])
                                if (editor3D.config.rules && rule.id) {
                                    editor3D.deleteRuleConfig(rule.id)
                                }
                            }

                            showModal('delete this rule', callback)
                        }}
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </div>
            )
        }
    ]

    return (
        <>
            <div className='logic-wrapper' style={{marginBottom: 16}}>
                <ReactDragListView
                    nodeSelector=".logic-wrapper .ant-table-row"
                    handleSelector=".logic-wrapper .attr-dragdrop"
                    lineClassName="dragLine"
                    onDragEnd={(fromIndex, toIndex) => {
                        setDataSource(onDragEndAttributes<AssetRuleType>(fromIndex, toIndex, dataSource))
                    }
                    }>
                    <Table
                        loading={isLoading || isLoadingDeleted}
                        pagination={false}
                        showHeader={false}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </ReactDragListView>
            </div>

            <Space>
                {
                    dataSource.filter(item => item.status === 'changed').length != 0 &&
                    <Button loading={isUpdating} type="primary" onClick={() => uploadUpdatedItems(dataSource)}>Update Rules</Button>
                }

                <Button type="primary" onClick={addRule}>
                    Add Rule
                </Button>
            </Space>
        </>
    )
}

export default LogicRules

export const actionRuleItems: { label: string; key: keyof typeof ActionTypeId; disabled: boolean }[] = [
    {
        label: 'Activate Animation',
        key: 'ActivateAnimation',
        disabled: false
    },
    {
        label: 'Set Visibility',
        key: 'SetVisibility',
        disabled: false
    },
    {
        label: 'Set Visibility One',
        key: 'SetVisibilityOne',
        disabled: false
    },
    {
        label: 'Set Material',
        key: 'SetMaterial',
        disabled: false
    },
    {
        label: 'Set Active Camera',
        key: 'SetActiveCamera',
        disabled: false
    },
    {
        label: 'Set Model',
        key: 'SetModel',
        disabled: false
    },
    {
        label: 'Change Object Property',
        key: 'ChangeObjectProperty',
        disabled: false
    },
    {
        label: 'Set Default Camera',
        key: 'SetDefaultCamera',
        disabled: false
    },
    {
        label: 'Set Attribute Visible',
        key: 'SetAttributeVisible',
        disabled: true
    },
    {
        label: 'Set Attribute Enabled',
        key: 'SetAttributeEnabled',
        disabled: true
    },
    {
        label: 'Set Attribute Value',
        key: 'SetAttributeValue',
        disabled: true
    },
    {
        label: 'Set Attribute Value Visibility',
        key: 'SetAttributeValueVisibility',
        disabled: true
    },
    {
        label: 'Set Attribute Value Enabled',
        key: 'SetAttributeValueEnabled',
        disabled: true
    },
    {
        label: 'Custom Script',
        key: 'CustomScript',
        disabled: true
    }
]

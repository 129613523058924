import React from 'react'
import {Button, DatePicker, Input, Select, Space, Tooltip} from 'antd'
import {CalendarOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons'
import {ColumnType} from 'antd/es/table'
import moment from 'moment'
import {useLocation, useSearchParams} from "react-router-dom";

const {RangePicker} = DatePicker

type SearchType<T> = (textField: keyof T, sortField?: keyof T) => ColumnType<T>
type DatePickerType<T> = (dataStart: keyof T, dataEnd: keyof T, sortField: keyof T) => ColumnType<T>
type SelectType<T> = (
    field: keyof T,
    items: { id: number | string; name: string }[],
    valueField?: 'id' | 'name',
    isMultiple?: boolean
) => ColumnType<T>

export const useFilterTable = <T, >(): [filterByText: SearchType<T>, filterByDate: DatePickerType<T>, filterBySelect: SelectType<T>] => {
    const status = (sort: string) => {
        switch (sort) {
            case '':
                return "1"
            case "1":
                return "0"
            default:
                return ''
        }
    }

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const sortButton = (sortField: keyof T, sort: string) => (
        <Button
            type={'primary'}
            style={{width: 50}}
            size="small"
            onClick={() => {
                const newSearchParams = new URLSearchParams(location.search);
                newSearchParams.set(sortField as string, status(sort));
                newSearchParams.set('page', '1');
                setSearchParams(newSearchParams.toString())
            }}>
            <Tooltip title={
                sort === '' ? 'Click to sort ascending' : sort === '1' ? 'Click to sort descending' : 'Click to cancel sort'
            }>
                {sort === '' ? 'Sort' : sort === '1' ? <SortAscendingOutlined/> : <SortDescendingOutlined/>}
            </Tooltip>
        </Button>
    )

    const filterByText: SearchType<T> = (textField, sortField) => {
        const value = searchParams.get(textField as string)
        const sort = searchParams.get(sortField as string)

        return {
            filteredValue: value ? [value] : null,
            filterDropdown: ({selectedKeys, setSelectedKeys, confirm}) => {
                return <div style={{padding: 8}}>
                    <Input
                        maxLength={40}
                        placeholder="Search..."
                        value={selectedKeys[0] as string}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        style={{marginBottom: 8, display: 'block'}}
                    />
                    <Space>
                        <Button
                            type="primary"
                            icon={<SearchOutlined/>}
                            style={{width: 150}}
                            size="small"
                            onClick={() => {
                                const newSearchParams = new URLSearchParams(location.search);
                                newSearchParams.set('page', '1');
                                newSearchParams.set(textField as string, selectedKeys[0] as string ?? '');
                                setSearchParams(newSearchParams.toString())
                                confirm()
                            }}>
                            Search
                        </Button>

                        {sortField && sortButton(sortField, sort ?? '')}

                        <Button
                            type={'primary'}
                            size="small"
                            style={{width: 90}}
                            onClick={() => {
                                setSelectedKeys([])
                                const newSearchParams = new URLSearchParams(location.search);
                                newSearchParams.set('page', '1');
                                newSearchParams.set(textField as string, '');
                                if (sortField) {
                                    newSearchParams.set(sortField as string, '');
                                }
                                setSearchParams(newSearchParams.toString())
                                confirm()
                            }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            },
            filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        }
    }

    const filterByDate: DatePickerType<T> = (dataStart, dataEnd, sortField) => {
        const valueStart = searchParams.get(dataStart as string)
        const valueEnd = searchParams.get(dataEnd as string)
        const sort = searchParams.get(sortField as string)

        return {
            filteredValue: valueStart && valueEnd ? [valueStart, valueEnd] : null,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
                <div style={{padding: 8}}>
                    <div style={{marginBottom: 8, display: 'block'}}>
                        <RangePicker
                            style={{width: '100%'}}
                            format="YYYY-MM-DD"
                            value={[
                                selectedKeys[0] ? moment(selectedKeys[0] as string) : null,
                                selectedKeys[1] ? moment(selectedKeys[1] as string) : null
                            ]}
                            onChange={e => {
                                if (!e) {
                                    setSelectedKeys([])
                                    return
                                }

                                const start = moment(e[0]).format('YYYY-MM-DD')
                                const end = moment(e[1]).format('YYYY-MM-DD')
                                setSelectedKeys([start, end])
                            }}
                            allowClear={false}
                        />
                    </div>

                    <Space>
                        <Button
                            type="primary"
                            size="small"
                            style={{width: 150}}
                            icon={<SearchOutlined/>}
                            onClick={() => {
                                const start = selectedKeys[0] ? selectedKeys[0] as string : ''
                                const end = selectedKeys[1] ? selectedKeys[1] as string : ''

                                const newSearchParams = new URLSearchParams(location.search);
                                newSearchParams.set('page', '1');
                                newSearchParams.set(dataStart as string, start);
                                newSearchParams.set(dataEnd as string, end);
                                setSearchParams(newSearchParams.toString())

                                confirm()
                            }}>
                            Search
                        </Button>

                        {sortField && sortButton(sortField, sort ?? '')}

                        <Button
                            type={'primary'}
                            style={{width: 90}}
                            size="small"
                            onClick={() => {
                                setSelectedKeys([])
                                const newSearchParams = new URLSearchParams(location.search);
                                newSearchParams.set('page', '1');
                                newSearchParams.set(dataStart as string, '');
                                newSearchParams.set(dataEnd as string, '');
                                if (sortField) {
                                    newSearchParams.set(sortField as string, '');
                                }
                                setSearchParams(newSearchParams.toString())
                                confirm()
                            }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <CalendarOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        }
    }

    const filterBySelect: SelectType<T> = (field, items, valueField = 'id', isMultiple = true) => {
        const value = searchParams.get(field as string)

        return {
            filteredValue: value ? value.split(',') : null,
            filterDropdown: ({selectedKeys, setSelectedKeys, confirm}) => {
                const selectProps =
                    isMultiple &&
                    ({
                        maxTagCount: 'responsive',
                        mode: 'multiple'
                    } as const)

                return (
                    <div style={{padding: 8}}>
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Value"
                            optionFilterProp="children"
                            value={selectedKeys as string[]}
                            {...selectProps}
                            onChange={e => {
                                setSelectedKeys(e)
                                const newSearchParams = new URLSearchParams(location.search);
                                newSearchParams.set('page', '1');
                                newSearchParams.set(field as string, e ? e.toString(): '');
                                setSearchParams(newSearchParams.toString())
                                confirm()
                            }}
                            style={{minWidth: 220, display: 'block'}}>
                            {items &&
                                items.map(item => (
                                    <Select.Option key={item.id} value={item[valueField].toString()}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                )
            },
            filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        }
    }

    return [filterByText, filterByDate, filterBySelect]
}

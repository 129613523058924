import api from './index'

const USER = 'user'

const userApi = api.injectEndpoints({
    endpoints: build => ({
        getUser: build.query<UserType, null>({
            query: () => `${USER}/view`
        }),

        updateUser: build.mutation<{ accessToken: string }, UserInfoType>({
            query: body => ({
                url: `${USER}/update`,
                method: 'PUT',
                body
            })
        }),

        updatePassword: build.mutation<{ accessToken: string }, UserPasswordInfoType>({
            query: body => ({
                url: `${USER}/update`,
                method: 'PUT',
                body
            })
        }),

        verifyEmail: build.mutation<null, { email_access_token: string; password: string }>({
            query: body => ({
                url: `${USER}/verify-email`,
                method: 'PUT',
                body
            })
        }),

        resetPassword: build.mutation<null, { email: string }>({
            query: body => ({
                url: `${USER}/reset-password`,
                method: 'PUT',
                body
            })
        }),

        deleteUser: build.mutation<null, { id: number | string }>({
            query: body => ({
                url: `${USER}?id=${body.id}`,
                method: 'DELETE',
            })
        }),

        setPassword: build.mutation<null, { restore_token: string; new_password: string }>({
            query: body => ({
                url: `${USER}/set-password`,
                method: 'PUT',
                body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useGetUserQuery,
    useVerifyEmailMutation,
    useResetPasswordMutation,
    useSetPasswordMutation,
    useDeleteUserMutation,
} = userApi

export type UserInfoType = Pick<UserType, 'name' | 'lastname' | 'email'> &
    Pick<UserPasswordInfoType, 'password_recovery'>

export type UserPasswordInfoType = {
    password_recovery: string
    new_password: string
}

export type UserType = {
    id: number
    name: string
    lastname: string
    company: string
    email: string
    role: UserRole
    tfaIsRequired: boolean
    tfaIsConfigured: boolean
    is_only_company: boolean,
}

export type UserRole = 'admin' | 'super admin' | 'account manager' | 'dealer' | 'sale member' | 'company_admin'

export default userApi

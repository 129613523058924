import Invoices from './Invoices'
import { useCreateCompanyInvoiceMutation, useGetCompanyInvoicesQuery } from '../../api/invoiceApi'

const CompanyInvoices = () => {
    return (
        <Invoices
            type="company"
            title="Company Invoices"
            getInvoices={useGetCompanyInvoicesQuery}
            createInvoice={useCreateCompanyInvoiceMutation}
        />
    )
}

export default CompanyInvoices

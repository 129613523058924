import PageBuilder from '../../../components/panel/PageBuilder'
import ActivatedLicensesTable from './ActivatedLicensesTable'
import { useAppSelector } from '../../../hooks'

const ActivatedLicenses = () => {
    const { id } = useAppSelector(state => state.user)

    return (
        <PageBuilder title="Activated Licenses" documentTitle="Activated Licenses">
            <ActivatedLicensesTable dealerId={id.toString()} />
        </PageBuilder>
    )
}

export default ActivatedLicenses

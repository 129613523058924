import React, { useContext, useEffect, useState } from 'react'
import PageBuilder from 'components/panel/PageBuilder'
import { Button, Form } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { ModalConfirmContext } from 'providers/ModalConfirmProvider'
import ExtendedTable from '../../../components/ExtendedTable'
import {
    DealerDomainFilter,
    DealerDomainType,
    useCreateDealerDomainMutation,
    useDeleteDealerDomainMutation,
    useGetDealerDomainsQuery,
    useUpdateDealerDomainMutation
} from '../../../api/dealerDomainApi'
import ModalWithForm from '../../users/ModalWithForm'
import DealerDomainForm from './DealerDomainForm'
import toast from 'react-hot-toast'
import {useSearchParams} from "react-router-dom";

const DealerDomains = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showModal } = useContext(ModalConfirmContext)

    const [formCreate] = Form.useForm()
    const [formUpdate] = Form.useForm()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [create, resultsCreate] = useCreateDealerDomainMutation()
    const [update, resultsUpdate] = useUpdateDealerDomainMutation()
    const [deleteItem] = useDeleteDealerDomainMutation()

    const [filter, setFilter] = useState<DealerDomainFilter>({ page: 1 })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetDealerDomainsQuery(filter)

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, domain: DealerDomainType) => (
                <>
                    <Button
                        onClick={() => {
                            formUpdate.setFieldsValue(domain)
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const { id } = domain
                                const loader = toast.loading('Progressing...')
                                const res = await deleteItem({ id })
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Domain is successfully deleted')
                                refetch()
                            }

                            showModal('delete this domain', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Domains" documentTitle="Domains">
            <div style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <PlusOutlined /> Create Domain
                </Button>
            </div>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="domain"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Domain"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                submit={formCreate.submit}
                isLoading={resultsCreate.isLoading}>
                <DealerDomainForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Domain"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                submit={formUpdate.submit}
                isLoading={resultsUpdate.isLoading}>
                <DealerDomainForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default DealerDomains

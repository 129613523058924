import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { capitalizeFirstLetter } from '../../utils'

export type BreadcrumbsType = (string | { id: string; name: string })[]

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbsType | null }) => {
    return (
        <>
            {breadcrumbs && (
                <div style={{ marginBottom: 24 }}>
                    <Link to="/">Home</Link>
                    <span style={{ margin: '0 8px' }}>/</span>

                    {breadcrumbs.map((breadcrumb, index) => {
                        if (typeof breadcrumb === 'string') {
                            if (index === breadcrumbs.length - 1) {
                                return <span key={uuid()}>{capitalizeFirstLetter(breadcrumb)}</span>
                            }

                            return (
                                <Fragment key={uuid()}>
                                    <Link to={`/${breadcrumb}`}>{capitalizeFirstLetter(breadcrumb)}</Link>
                                    <span style={{ margin: '0 8px' }}>/</span>
                                </Fragment>
                            )
                        } else {
                            if (index === breadcrumbs.length - 1) {
                                return <span key={uuid()}>{capitalizeFirstLetter(breadcrumb.name)}</span>
                            }

                            return (
                                <Fragment key={uuid()}>
                                    <Link to={`/${breadcrumb.id}`}>{capitalizeFirstLetter(breadcrumb.name)}</Link>
                                    <span style={{ margin: '0 8px' }}>/</span>
                                </Fragment>
                            )
                        }
                    })}
                </div>
            )}
        </>
    )
}

export default Breadcrumbs

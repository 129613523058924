import { Dispatch, SetStateAction } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import ModalWithForm from './ModalWithForm'
import toast from 'react-hot-toast'
import { UserStatusInfo } from '../../api/accountManagerApi'

type UserChangeStatusFormProps = {
    form: FormInstance
    changeStatus: (form: UserStatusInfo) => Promise<any>
    isModalOpen: boolean
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    isLoading: boolean
    refetch: () => void
}

const initOptionsStatus: { value: string; label: string; disabled: boolean }[] = [
    { value: 'active', label: 'Active', disabled: false },
    { value: 'inactive', label: 'Inactive', disabled: false },
    { value: 'deleted', label: 'Deleted', disabled: true },
    { value: 'sign_up_email_confirmation', label: 'Sign up email confirmation', disabled: true }
]

const UserChangeStatusModal = ({
    form,
    changeStatus,
    isModalOpen,
    setIsModalOpen,
    isLoading,
    refetch
}: UserChangeStatusFormProps) => {
    return (
        <ModalWithForm
            title={'Change Status'}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isLoading={isLoading}
            submit={form.submit}>
            <Form
                validateMessages={{ required: 'Field is required!' }}
                layout="vertical"
                form={form}
                onFinish={async e => {
                    const loader = toast.loading('Progressing...')
                    const res = await changeStatus(e)
                    toast.dismiss(loader)
                    if ('error' in res) return
                    toast.success('Status is successfully changed!')
                    setIsModalOpen(false)
                    refetch()
                    form.resetFields()
                }}>
                <Form.Item name="id" noStyle>
                    <Input type="hidden" />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label="Status" name="status">
                    <Select placeholder="Status" options={initOptionsStatus} />
                </Form.Item>
            </Form>
        </ModalWithForm>
    )
}

export default UserChangeStatusModal

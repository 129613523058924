const authRoutes = {
    login: '/login',
    forgotPassword: '/forgot-password',
    setPassword: '/set-password'
} as const

const publicRoutes = {
    register: '/sign-up',
    verifyEmail: '/verify-email',
    reset2fa: '/reset-2fa'
} as const

const protectedRoutes = {
    assets: '/assets',
    assetsView: '/assets/view',
    assetsEdit: '/assets/edit',
    assetsCreate: '/assets/create',

    industries: '/industries',
    brands: '/brands',
    domains: '/domains',
    companies: '/companies',
    styleGuides: '/style-guides',

    dealers: '/dealers',
    admins: '/admins',
    accountManagers: '/account-managers',
    saleManagers: '/sale-managers',



    dealerAccount: '/dealer/account',
    dealerInvoices: '/dealer/invoices',
    dealerAnalytics: '/dealer/analytics',
    dealerPayment: '/dealer/payment',
    dealerCatalog: '/products/pco',
    dealerCatalogProducts: '/products',
    dealerCatalogArchive: '/products/archive',
    dealerCatalogView: '/products/view',
    dealerCatalogCreate: '/products/create',
    dealerCatalogEdit: '/products/edit',

    companyInvoices: '/company/invoices',
    companyAnalytics: '/company/analytics',
    companyPayment: '/company/payment',
    payment: '/payment',

    companyCatalog: '/catalog',
    companyCatalogView: '/catalog/view',
    companyCatalogCreate: '/catalog/create',
    companyCatalogEdit: '/catalog/edit',

    invoicesDealer: '/invoices/dealer',
    invoicesCompany: '/invoices/company',

    apoDealer: '/apo/dealer',
    apoCompany: '/apo/company',

    licenses: '/licenses',
    licenseActivation: '/license/activation',
    licensesDealer: '/licenses/dealer',
    licensesActivated: '/licenses/activated',
    licensesBilling: '/licenses/billing',
    licensesAvailable: '/licenses/available',
    licensesPrice: '/licenses/price',

    account: '/account',
    tfa: '/tfa'
} as const

export const routesUrl = {
    ...authRoutes,
    ...publicRoutes,
    ...protectedRoutes
} as const

type RoutesUrl = typeof routesUrl

export type RoutesUrlType = RoutesUrl[keyof RoutesUrl]

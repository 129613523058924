import React, { useEffect, useState } from 'react'
import { LicenseBillingFilter, useGetLicensesBillingQuery } from '../../../api/licenseApi'
import { useAppSelector } from '../../../hooks'
import ExtendedTable from '../../../components/ExtendedTable'
import PageBuilder from '../../../components/panel/PageBuilder'
import Title from 'antd/lib/typography/Title'
import {useSearchParams} from "react-router-dom";

const LicensesBilling = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useAppSelector(state => state.user)

    const [filter, setFilter] = useState<LicenseBillingFilter>({
        id: id!,
        page: 1
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const page = parseInt(pageParam ?? '1')

        setFilter(prevState => ({
            ...prevState,
            page: Number.isNaN(page) ? 1 : page,
        }))
    }, [searchParams]);

    const { data, isSuccess, isLoading, refetch } = useGetLicensesBillingQuery(filter)

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Licenses Billing" documentTitle="Licenses Billing">
            {isSuccess && (
                <div>
                    <Title level={4}>Subtotal: {data.price.subtotal}</Title>
                    <Title level={5}>Total: {data.price.total}</Title>
                </div>
            )}

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="license"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />
        </PageBuilder>
    )
}

export default LicensesBilling

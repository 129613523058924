import { Form, Select, Spin } from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'

const ProductTypeItem = () => {
    const { product, isLoadingProduct, setProduct } = useContext(CatalogContext)

    const handleChangeType = (type: 1 | 2) => {
        setProduct({ ...product, type })
    }

    return (
        <Form.Item label="Type">
            {isLoadingProduct ? (
                <Spin size="small" />
            ) : (
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="Type"
                    value={product.type}
                    onChange={handleChangeType}>
                    {typeOptions.map(opt => {
                        return (
                            <Select.Option key={opt.value} value={opt.value}>
                                {opt.label}
                            </Select.Option>
                        )
                    })}
                </Select>
            )}
        </Form.Item>
    )
}

export default ProductTypeItem

const typeOptions = [
    { value: 1, label: 'Product' },
    { value: 2, label: 'PCO' }
]

import React, {useContext} from 'react';
import {CatalogContext} from "../../../../../providers/CatalogProvider";
import {ProductAttributeType} from "../../tabs/ProductAttributes";
import {Select} from "antd";

type ProductAttributeDiscountItemProps = {
  attr: ProductAttributeType
}

type handleChangeDiscountType = (value: number) => void

const ProductAttributeDiscountItem = ({attr}: ProductAttributeDiscountItemProps) => {


  const { attributes, setAttributes } = useContext(CatalogContext)

  const handleChangeDiscountType: handleChangeDiscountType = value => {
    setAttributes(
      attributes.map(item => ({
        ...item,
        discount_type: item.id === attr.id ? value : (item.discount_type || 0),
        status: item.id === attr.id ? 'changed' : item.status
      }))
    )
  }

  const discountsArr = [
    {
      id: 0,
      label: 'No discount'
    },
    {
      id: 1,
      label: 'Parent discount'
    },
    {
      id: 2,
      label: 'Discount for default_value'
    },
    {
      id: 3,
      label: 'Own discount'
    },
  ]

  return (
    <Select
      placeholder="Discount type"
      showSearch
      allowClear
      optionFilterProp="children"
      value={attr.discount_type || 0}
      onChange={handleChangeDiscountType}>
        {discountsArr
          .map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.label}
            </Select.Option>
          ))}
    </Select>
  );
};

export default ProductAttributeDiscountItem;
import { AMAMode } from '@amaspace-editor/editor-3d'
import { Typography } from 'antd'
import { ASSET_TYPE } from 'api/assetApi'
import { TAsset } from '../../../types'
import { AssetsForAttributes } from './assetEditor'
import AssetEditor2D from './assetEditor2D'
import { ConfiguratorViewer } from './ConfiguratorViewer'
import AssetEditor3D from './editor3D/assetEditor3D'
import LogicEditor from './logic/LogicEditor'

const { Title } = Typography

type AssetLogicProps = {
    asset: TAsset
    assets: AssetsForAttributes
    modelPart: TAsset[]
}

const AssetLogic = ({ asset, assets, modelPart }: AssetLogicProps) => {
    const is2D = asset?.type === ASSET_TYPE.Texture
    const is3D = asset?.type === ASSET_TYPE.Scene || asset?.type === ASSET_TYPE.Model
    return (
        <>
            {is2D ? (
                <AssetEditor2D
                    leftMenu={<LogicEditor modelPart={modelPart} assets={assets} asset={asset} />}
                    rightMenu={<Title level={3} children="Configurator Viewer" />}
                    asset={asset}
                />
            ) : null}
            {is3D ? (
                <AssetEditor3D
                    leftMenu={<LogicEditor modelPart={modelPart} assets={assets} asset={asset} />}
                    rightMenu={<ConfiguratorViewer asset={asset} />}
                    asset={asset}
                    mode={AMAMode.VIEWER}
                />
            ) : null}
        </>
    )
}

export default AssetLogic

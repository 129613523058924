import api from './index'
import { PAYMENT } from './paymentApi'
import { TableMetaType } from '../components/ExtendedTable'

const INVOICE = 'invoice'

const invoiceApi = api.injectEndpoints({
    endpoints: build => ({
        getDealerInvoices: build.query<GetInvoiceType, InvoiceFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ page: page.toString(), id })

                return {
                    url: `${INVOICE}/dealer?${params}`,
                    method: 'GET'
                }
            }
        }),
        createDealerInvoice: build.mutation<null, PaymentInvoiceInfoType>({
            query: body => {
                const { id, ...otherFields } = body

                return {
                    url: `${PAYMENT}/invoice-dealer`,
                    method: 'POST',
                    body: { ...otherFields, dealer_id: id }
                }
            }
        }),

        getCompanyInvoices: build.query<GetInvoiceType, InvoiceFilter>({
            query: body => {
                const { page, id } = body
                const params = new URLSearchParams({ page: page.toString(), id })

                return {
                    url: `${INVOICE}/company?${params}`,
                    method: 'GET'
                }
            }
        }),

        createCompanyInvoice: build.mutation<null, PaymentInvoiceInfoType>({
            query: body => {
                const { id, ...otherFields } = body

                return {
                    url: `${PAYMENT}/invoice-company`,
                    method: 'POST',
                    body: { ...otherFields, company_id: id }
                }
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetCompanyInvoicesQuery,
    useCreateCompanyInvoiceMutation,

    useGetDealerInvoicesQuery,
    useCreateDealerInvoiceMutation
} = invoiceApi

export default invoiceApi

export type InvoiceType = {
    id: string
    name: string
    invoice_url: string
    status: string
}

export type GetInvoiceType = {
    invoice: InvoiceType[]
    _meta: TableMetaType
}

export type InvoiceFilter = {
    id: string
    page: number
}

export type PaymentInvoiceInfoType = {
    id: number
    amount: number
    description: string
}

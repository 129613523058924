import React, { useEffect, useState } from 'react'
import { CompanyAnalyticsType, CompanyAnalyticsFilter, useGetCompanyAnalyticsMutation } from '../../api/analyticsApi'
import PageBuilder from '../../components/panel/PageBuilder'
import { Button, Input, Select, Space, Spin } from 'antd'
import moment from 'moment/moment'
import { DatePicker } from 'antd'
import { useGetCountryQuery, useGetStatesByCountryMutation } from '../../api/countryApi'
import { DealerType, useGetDealersListMutation } from '../../api/dealerApi'
import { useFetchByPagination } from '../../hooks'
import toast from 'react-hot-toast'

const { RangePicker } = DatePicker

const format = 'YYYY-MM-DD'
const currentDate = new Date().toISOString().slice(0, 10)

const CompanyAnalytics = () => {
    const [filters, setFilters] = useState<CompanyAnalyticsFilter>({
        date_start: currentDate,
        date_end: currentDate,
        state_id: '',
        country_id: '',
        dealer_id: '',
        city: '',
        product_make: '',
        product_model: '',
        product_name: '',
        product_year: ''
    })

    const [data, setData] = useState<CompanyAnalyticsType | null>(null)

    const { data: countries } = useGetCountryQuery()
    const [getStatesByCountry, resultsStatesByCountry] = useGetStatesByCountryMutation()
    const [getAnalytics, results] = useGetCompanyAnalyticsMutation()

    const [dealers] = useFetchByPagination<DealerType>(useGetDealersListMutation)

    const initAnalytics = async () => {
        const loader = toast.loading('Progressing...')
        const res = await getAnalytics(filters)
        toast.dismiss(loader)
        if ('error' in res) return
        setData(results.data ? results.data : null)
    }

    useEffect(() => {
        initAnalytics()
    }, [])

    return (
        <PageBuilder documentTitle="Analytics" title="Analytics">
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 16 }}>
                <Space>
                    <RangePicker
                        format={format}
                        defaultValue={[moment(currentDate, format), moment(currentDate, format)]}
                        onChange={(_, dates) => {
                            setFilters(prevState => ({
                                ...prevState,
                                date_start: dates[0],
                                date_end: dates[1]
                            }))
                        }}
                    />

                    <Select
                        style={{ width: 180 }}
                        placeholder="Country"
                        allowClear
                        onChange={id => {
                            setFilters({ ...filters, country_id: id ? id : '', state_id: '' })

                            if (id) getStatesByCountry({ id: +id })
                        }}>
                        {countries &&
                            countries.map(country => (
                                <Select.Option key={country.id} value={country.id.toString()}>
                                    {country.name}
                                </Select.Option>
                            ))}
                    </Select>

                    <Select
                        style={{ width: 180 }}
                        placeholder="State/Province"
                        allowClear
                        onChange={id => {
                            setFilters({ ...filters, state_id: id ? id.toString() : '' })
                        }}>
                        {resultsStatesByCountry.data?.map(country => (
                            <Select.Option key={country.id} value={country.id}>
                                {country.name}
                            </Select.Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: 180 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Dealer"
                        onChange={e => {
                            setFilters({ ...filters, dealer_id: e ? e.toString() : '' })
                        }}>
                        {dealers.map(dealer => {
                            return (
                                <Select.Option key={dealer.id} value={dealer.id}>
                                    {dealer.dealerInfo.business_name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Space>

                <Space>
                    <Input
                        maxLength={40}
                        value={filters.city}
                        placeholder="City"
                        onChange={e => setFilters({ ...filters, city: e.target.value })}
                    />

                    <Input
                        maxLength={40}
                        value={filters.product_name}
                        placeholder="Product Name"
                        onChange={e => setFilters({ ...filters, product_name: e.target.value })}
                    />

                    <Input
                        maxLength={40}
                        value={filters.product_model}
                        placeholder="Product Model"
                        onChange={e => setFilters({ ...filters, product_model: e.target.value })}
                    />

                    <Input
                        maxLength={40}
                        value={filters.product_make}
                        placeholder="Product Make"
                        onChange={e => setFilters({ ...filters, product_make: e.target.value })}
                    />

                    <Input
                        maxLength={40}
                        value={filters.product_year}
                        placeholder="Product Year"
                        onChange={e => setFilters({ ...filters, product_year: e.target.value })}
                    />

                    <Button
                        type="primary"
                        loading={results.isLoading}
                        onClick={async () => {
                            const loader = toast.loading('Progressing...')
                            const res = await getAnalytics(filters)
                            toast.dismiss(loader)
                            if ('error' in res) return
                            setData(results.data ? results.data : null)
                        }}>
                        Search
                    </Button>
                </Space>
            </div>

            <div style={{ width: 300, margin: '16px auto 0' }}>
                <ul className="product-card__list">
                    <li>
                        <span>page visits:</span>
                        <span className="product-card__tag black">{data?.page_visit ?? 0}</span>
                    </li>

                    <li>
                        <span>leads generated:</span>
                        <span className="product-card__tag light">{data?.lead_generate ?? 0}</span>
                    </li>

                    <li>
                        <span>leads conversion rate:</span>
                        <span className="product-card__tag light">{`${data?.lead_conversion ?? 0}%`}</span>
                    </li>
                </ul>
            </div>
        </PageBuilder>
    )
}

export default CompanyAnalytics

import React, { useContext } from 'react'
import { Select } from 'antd'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { ProductActionType, ProductConditionType } from '../../tabs/ProductRules'

type RuleAttributeItemProps<T> = {
    item: T
    clearField: 'value' | 'option_id'
    handleChange: (item: T) => void
}

const RuleAttributeItem = <T extends ProductConditionType | ProductActionType>({
    item,
    clearField,
    handleChange
}: RuleAttributeItemProps<T>) => {
    const { attributes } = useContext(CatalogContext)

    const handleChangeAttribute = (value: number) => {
        handleChange({ ...item, attribute_id: value, [clearField]: '' })
    }

    return (
        <Select
            showSearch
            optionFilterProp="children"
            placeholder="Select Attribute"
            value={item.attribute_id ?? null}
            onChange={handleChangeAttribute}>
            {Array.from(new Set(attributes.map(item => item.type))).map(type => (
                <Select.OptGroup key={type} label={type}>
                    {attributes
                        .filter(item => item.type === type)
                        .map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name ? item.name : 'Empty Name'}
                            </Select.Option>
                        ))}
                </Select.OptGroup>
            ))}
        </Select>
    )
}

export default RuleAttributeItem

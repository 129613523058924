import { Button, Form, List, Spin } from 'antd'
import { useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import ProductMetadataItem from './ProductMetadataItem'

export type MetadataType = {
    id: string | number
    type: 1 | 2
    keyValue: string | null
    value: string
    valueState: string
}

const ProductMetadata = () => {
    const { product, isLoadingProduct, setProduct } = useContext(CatalogContext)

    const renderItem = (item: MetadataType) => <ProductMetadataItem key={item.id} item={item} setProduct={setProduct} />

    const handleAddMetadata = () => {
        setProduct(prevState => ({
            ...prevState,
            meta_data: [
                ...(prevState.meta_data ?? []),
                {
                    id: uuid(),
                    type: 1,
                    keyValue: null,
                    value: '',
                    valueState: ''
                }
            ]
        }))
    }

    return (
        <Form.Item label="Metadata">
            {isLoadingProduct ? (
                <Spin size="small" />
            ) : (
                <List
                    itemLayout="vertical"
                    dataSource={product.meta_data ?? ([] as MetadataType[])}
                    renderItem={renderItem}
                    footer={
                        <Button onClick={handleAddMetadata} type="default">
                            Add Metadata
                        </Button>
                    }
                />
            )}
        </Form.Item>
    )
}

export default ProductMetadata

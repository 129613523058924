import { Card, Form } from 'antd'
import ProductInputItem from '../components/items/ProductInputItem'
import ProductTagsItem from '../components/items/ProductTagsItem'
import ProductDescriptionItem from '../components/items/ProductDescriptionItem'
import ProductInfo, { StyledCatalog, StyledCatalogList } from '../components/tabs/ProductInfo'
import ProductCurrencyItem from '../components/items/ProductCurrencyItem'
import ProductImageItem from '../components/items/ProductImageItem'
import ProductMetadata from "../components/metadata/ProductMetadata";

const DealerProductInfo = () => {
    return (
        <ProductInfo url="dealer">
            <Form layout="vertical">
                <StyledCatalog>
                    <StyledCatalogList>
                        <Card style={{ width: '100%' }}>
                            <ProductInputItem itemKey="name" maxLength={40} />
                            <ProductDescriptionItem />
                        </Card>

                        <Card style={{ width: '100%' }}>
                            <ProductTagsItem />
                            <ProductCurrencyItem />
                            <ProductImageItem />
                        </Card>
                    </StyledCatalogList>

                    <Card>
                        <ProductMetadata/>
                    </Card>
                </StyledCatalog>
            </Form>
        </ProductInfo>
    )
}

export default DealerProductInfo

import { Button, Col, Form, List, Row, Space } from 'antd'
import React, { useContext } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { StyledWrapperFlexCol } from './ProductRuleAction'
import { ProductConditionType, ProductRuleType } from '../tabs/ProductRules'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import RuleValueItem from './items/RuleValueItem'
import RuleAttributeItem from './items/RuleAttributeItem'
import Item = List.Item
import RuleTypeConditionItem from './items/RuleTypeConditionItem'

type ProductRuleConditionProps = {
    rule: ProductRuleType
}

const ProductRuleCondition = ({ rule }: ProductRuleConditionProps) => {
    const { rules, setRules } = useContext(CatalogContext)

    const handleCreateCondition = (rule: ProductRuleType) => {
        const newCondition: ProductConditionType = {
            id: Date.now(),
            newCondition: 'new',
            attribute_id: null,
            type_condition: null,
            value: ''
        }

        setRules(
            rules.map(state => {
                return {
                    ...state,
                    condition: state.id === rule.id ? [...state.condition, newCondition] : state.condition,
                    status: state.id === rule.id ? 'changed' : state.status
                }
            })
        )
    }

    const handleDeleteCondition = (condition: ProductConditionType) => {
        setRules(
            rules.map(item => {
                return {
                    ...item,
                    condition:
                        item.id === rule.id ? item.condition.filter(item => item.id !== condition.id) : item.condition,
                    status: item.id === rule.id ? 'changed' : item.status
                }
            })
        )
    }

    const handleChangeCondition = (newCondition: ProductConditionType) => {
        setRules(
            rules.map(prevRule => {
                return {
                    ...prevRule,
                    condition:
                        prevRule.id === rule.id
                            ? prevRule.condition.map(prevCondition => {
                                  return prevCondition.id === newCondition.id ? newCondition : prevCondition
                              })
                            : prevRule.condition,
                    status: prevRule.id === rule.id ? 'changed' : prevRule.status
                }
            })
        )
    }

    return (
        <Form.Item label="Conditions">
            <List
                className="logic__list"
                itemLayout="vertical"
                dataSource={rule.condition}
                renderItem={condition => (
                    <Item key={condition.id}>
                        <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
                            <Col span={8}>
                                <RuleAttributeItem
                                    item={condition}
                                    clearField="value"
                                    handleChange={handleChangeCondition}
                                />
                            </Col>

                            <Col span={5}>
                                <RuleTypeConditionItem condition={condition} handleChange={handleChangeCondition} />
                            </Col>

                            <Col span={8}>
                                <RuleValueItem item={condition} itemKey="value" handleChange={handleChangeCondition} />
                            </Col>

                            <StyledWrapperFlexCol span={3}>
                                <Button type="link" size="small" onClick={() => handleDeleteCondition(condition)}>
                                    <DeleteOutlined />
                                </Button>
                            </StyledWrapperFlexCol>
                        </Row>
                    </Item>
                )}
                header={
                    <Button style={{ width: '100%' }} onClick={() => handleCreateCondition(rule)}>
                        <Space>Add Condition</Space>
                    </Button>
                }
            />
        </Form.Item>
    )
}

export default ProductRuleCondition

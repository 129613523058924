import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import {
    AppstoreOutlined,
    BankOutlined,
    BarChartOutlined,
    BranchesOutlined,
    BuildOutlined,
    CarOutlined,
    CreditCardOutlined,
    CrownOutlined,
    DesktopOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    FileProtectOutlined,
    FileSyncOutlined,
    FireOutlined,
    GlobalOutlined,
    HistoryOutlined,
    LogoutOutlined,
    PictureOutlined,
    ProfileOutlined,
    SecurityScanOutlined,
    TeamOutlined,
} from '@ant-design/icons'
import { NavLink, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { signOut } from './Panel'
import { routesUrl, RoutesUrlType } from '../../router/urls'
import {rolePermissions} from '../../router/ProtectedRoute'

const Nav = () => {
    const user = useAppSelector(state => state.user)
    const role = user.role === 'admin' && user.is_only_company ? 'company_admin' : user.role

    const [current, setCurrent] = useState<RoutesUrlType | string>('')
    const location = useLocation()

    useEffect(() => {
        const pathName = location.pathname as RoutesUrlType
        setCurrent(rolePermissions[role].includes(pathName) ? pathName : '')
    }, [location])

    const businessChildren: NavItemType[] = [
        {
            label: <NavLink to={routesUrl.industries}>Industries</NavLink>,
            key: routesUrl.industries,
            icon: <BuildOutlined />
        },
        { label: <NavLink to={routesUrl.brands}>Brands</NavLink>, key: routesUrl.brands, icon: <FireOutlined /> },
        {
            label: <NavLink to={routesUrl.domains}>Domains</NavLink>,
            key: routesUrl.domains,
            icon: <BranchesOutlined />
        },
        {
            label: <NavLink to={routesUrl.companies}>Companies</NavLink>,
            key: routesUrl.companies,
            icon: <GlobalOutlined />
        },
        {
            label: <NavLink to={routesUrl.styleGuides}>Style Guides</NavLink>,
            key: routesUrl.styleGuides,
            icon: <PictureOutlined />
        }
    ]

    const usersChildren: NavItemType[] = [
        { label: <NavLink to={routesUrl.dealers}>Dealers</NavLink>, key: routesUrl.dealers, icon: <TeamOutlined /> },
        { label: <NavLink to={routesUrl.admins}>Admins</NavLink>, key: routesUrl.admins, icon: <TeamOutlined /> },
        {
            label: <NavLink to={routesUrl.accountManagers}>Account Managers</NavLink>,
            key: routesUrl.accountManagers,
            icon: <TeamOutlined />
        }
        // {label: <NavLink to={routesUrl.saleManagers}>Sale Managers</NavLink>, key: routesUrl.saleManagers, icon: <TeamOutlined/>}
    ]

    const profileChildren: NavItemType[] = [
        { label: <NavLink to={routesUrl.account}>Account</NavLink>, key: routesUrl.account, icon: <CrownOutlined /> },
        {
            label: <NavLink to={routesUrl.dealerAccount}>Account</NavLink>,
            key: routesUrl.dealerAccount,
            icon: <CrownOutlined />
        },
        { label: <NavLink to={routesUrl.tfa}>2FA</NavLink>, key: routesUrl.tfa, icon: <SecurityScanOutlined /> }
    ]

    const licensesChildren: NavItemType[] = [
        {
            label: <NavLink to={routesUrl.licensesActivated}>Activated</NavLink>,
            key: routesUrl.licensesActivated,
            icon: <FileDoneOutlined />
        },
        {
            label: <NavLink to={routesUrl.licensesAvailable}>Available</NavLink>,
            key: routesUrl.licensesAvailable,
            icon: <FileAddOutlined />
        },
        {
            label: <NavLink to={routesUrl.licensesBilling}>Billing</NavLink>,
            key: routesUrl.licensesBilling,
            icon: <HistoryOutlined />
        }
    ]

    const dealerItems: NavItemType[] = [
        {
            label: <NavLink to={routesUrl.licenses}>Licenses</NavLink>,
            key: routesUrl.licenses,
            icon: <FileSyncOutlined />
        },
        {
            label: <NavLink to={routesUrl.dealerInvoices}>Invoices</NavLink>,
            key: routesUrl.dealerInvoices,
            icon: <BankOutlined />
        },
        {
            label: <NavLink to={routesUrl.dealerAnalytics}>Analytics</NavLink>,
            key: routesUrl.dealerAnalytics,
            icon: <BarChartOutlined />
        },
        {
            label: <NavLink to={routesUrl.payment}>Payment</NavLink>,
            key: routesUrl.dealerPayment,
            icon: <CreditCardOutlined />
        }
    ]

    const dealerCatalogItems: NavItemType[] = [
        {
            label: <NavLink to={routesUrl.dealerCatalog}>PCO</NavLink>,
            key: routesUrl.dealerCatalog,
            icon: <CarOutlined />
        },
        {
            label: <NavLink to={routesUrl.dealerCatalogProducts}>Products</NavLink>,
            key: routesUrl.dealerCatalogProducts,
            icon: <CarOutlined />
        }
    ]

    const adminItems: NavItemType[] = [
        {
            label: <NavLink to={routesUrl.companyCatalog}>Catalog</NavLink>,
            key: routesUrl.companyCatalog,
            icon: <CarOutlined />
        },
        {
            label: <NavLink to={routesUrl.companyInvoices}>Invoices</NavLink>,
            key: routesUrl.companyInvoices,
            icon: <BankOutlined />
        },
        {
            label: <NavLink to={routesUrl.companyAnalytics}>Analytics</NavLink>,
            key: routesUrl.companyAnalytics,
            icon: <BarChartOutlined />
        },
        {
            label: <NavLink to={routesUrl.payment}>Payment</NavLink>,
            key: routesUrl.companyPayment,
            icon: <CreditCardOutlined />
        }
    ]

    const items: NavItemsType = [
        { label: <NavLink to={routesUrl.assets}>Assets</NavLink>, key: routesUrl.assets, icon: <AppstoreOutlined /> },

        { label: 'Licenses', key: 'licenses-dropdown', icon: <FileProtectOutlined />, children: licensesChildren },
        { label: 'Business', key: 'business-dropdown', icon: <DesktopOutlined />, children: businessChildren },
        { label: 'Users', key: 'users-dropdown', icon: <TeamOutlined />, children: usersChildren },
        { label: 'Catalog', key: 'catalog-dropdown', icon: <CarOutlined />, children: dealerCatalogItems },

        ...dealerItems,
        ...adminItems,

        { label: 'Profile', key: 'profile-dropdown', icon: <ProfileOutlined />, children: profileChildren }
    ]

    const filteredItems = items.filter(item => {
        if ('children' in item) {
            item.children = item.children.filter(child => rolePermissions[role].includes(child.key))

            return item.children.length !== 0 && item
        }

        // return rolePermissions[role].includes(item.key) || item.key === 'logout'
        return rolePermissions[role].includes(item.key)
    })

    const newItems = filteredItems.map(item => {
        if ('children' in item) {
            if (item.children.length === 1) {
                item = item.children[0]
            }
        }

        return item
    })

    const defaultItems = [{ label: <div onClick={signOut}>Logout</div>, key: 'logout', icon: <LogoutOutlined /> }]

    return <Menu theme="dark" mode="inline" selectedKeys={[current]} items={[...newItems, ...defaultItems]} />
}

export default Nav

type NavItemType = { label: React.JSX.Element | string; key: RoutesUrlType; icon: React.JSX.Element }
type NavDropdownItemType = { label: React.JSX.Element | string; key: string; icon: React.JSX.Element; children: NavItemType[] }

type NavItemsType = (NavItemType | NavDropdownItemType)[]

import PageBuilder from '../../../components/panel/PageBuilder'
import { Button, Form, Space } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
    IndustryFilter,
    IndustryInfoType,
    IndustryType,
    useCreateIndustryMutation,
    useDeleteIndustryMutation,
    useGetIndustriesQuery,
    useUpdateIndustryMutation
} from '../../../api/industryApi'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import IndustryForm from './IndustryForm'
import ModalWithForm from '../../users/ModalWithForm'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import toast from 'react-hot-toast'
import {useSearchParams} from "react-router-dom";

const Industries = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showModal } = useContext(ModalConfirmContext)

    const [formCreate] = Form.useForm<IndustryInfoType>()
    const [formUpdate] = Form.useForm<IndustryInfoType>()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [filter, setFilter] = useState<IndustryFilter>({
        page: 1,
        name: ''
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetIndustriesQuery(filter)
    const [create, resultsCreate] = useCreateIndustryMutation()
    const [update, resultsUpdate] = useUpdateIndustryMutation()
    const [deleteItem] = useDeleteIndustryMutation()

    const [filterByText] = useFilterTable<IndustryFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, industry: IndustryType) => (
                <>
                    <Button
                        onClick={() => {
                            formUpdate.setFieldsValue(industry)
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const { id } = industry
                                const loader = toast.loading('Progressing...')
                                const res = await deleteItem({ id })
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Industry is successfully deleted')
                                refetch()
                            }

                            showModal('delete this industry', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Industries" documentTitle="Industries">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <PlusOutlined /> Create Industry
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Industry"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                submit={formCreate.submit}
                isLoading={resultsCreate.isLoading}>
                <IndustryForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Industry"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                submit={formUpdate.submit}
                isLoading={resultsUpdate.isLoading}>
                <IndustryForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Industries

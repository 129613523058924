import { TAssetEditorProp } from '../../assetEditorProps'
import { isMeshAMA, utilsEditor } from '@amaspace-editor/editor-3d'
import { isLight } from '../../../../../utils'
import * as THREE from 'three'

const currentGeometryMesh = (editor3D: ReturnType<typeof utilsEditor>): TAssetEditorProp[] => {
    if (!editor3D.activeMesh) return [] as TAssetEditorProp[]

    if (isMeshAMA(editor3D.activeMesh)) {
        const geometry = editor3D.activeMesh.geometry
        const innerMesh = editor3D.activeMesh.children[0]

        if (isLight(innerMesh)) return [] as TAssetEditorProp[]
        if (innerMesh instanceof THREE.PerspectiveCamera) return [] as TAssetEditorProp[]

        if (geometry instanceof THREE.SphereGeometry) {
            return [
                {
                    type: 'range',
                    label: 'Radius',
                    value: geometry.parameters.radius,
                    key: 'radius_geom',
                    misc: { max: 20 }
                }
            ]
        }
        if (geometry instanceof THREE.BoxGeometry) {
            return [
                {
                    type: 'range',
                    label: 'Width',
                    value: geometry.parameters.width,
                    key: 'width_geom',
                    misc: { max: 20 }
                },
                {
                    type: 'range',
                    label: 'Height',
                    value: geometry.parameters.height,
                    key: 'height_geom',
                    misc: { max: 20 }
                },
                {
                    type: 'range',
                    label: 'Depth',
                    value: geometry.parameters.depth,
                    key: 'depth_geom',
                    misc: { max: 20 }
                }
            ]
        }
        if (geometry instanceof THREE.PlaneGeometry) {
            return [
                {
                    type: 'range',
                    label: 'Width',
                    value: geometry.parameters.width,
                    key: 'width_geom',
                    misc: { max: 20 }
                },
                {
                    type: 'range',
                    label: 'Height',
                    value: geometry.parameters.height,
                    key: 'height_geom',
                    misc: { max: 20 }
                }
            ]
        }
    }
    return [] as TAssetEditorProp[]
}
export { currentGeometryMesh }

import { Col, Row, InputNumber } from 'antd'
import { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import * as THREE from 'three'
import _ from 'underscore'
import { checkTypeValue } from 'utils'
import { ActionType } from '../assetEditorProps'

export const RenderTuple = ({ data, onChange }: ActionType) => {
    const [innerValue, setInnerValue] = useState(data.value)

    const throttledChangeHandler = useCallback(
        _.debounce((value: THREE.Vector3 | THREE.Euler) => onChange(data.key, value), 300),
        [data.value, data.key, onChange]
    )

    const changeHandler = useCallback(
        (value: THREE.Vector3 | THREE.Euler) => {
            setInnerValue(value)
            throttledChangeHandler(value)
        },
        [throttledChangeHandler]
    )

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.stopPropagation()
    }

    const checkType = (value: THREE.Vector3 | THREE.Euler): value is THREE.Vector3 | THREE.Euler => {
        return 'isEuler' in value
    }

    const correctValue = (value: number, type: 'euler' | 'vec3') => {
        switch (type) {
            case 'euler':
                var correctKey = THREE.MathUtils.radToDeg(value)
                return Number(correctKey.toFixed(3))

            default:
                return Number(value.toFixed(3))
        }
    }
    const type = useMemo(() => {
        return checkType(data.value) ? 'euler' : 'vec3'
    }, [data.value])

    useEffect(() => {
        setInnerValue(data.value)
    }, [data.value])

    return (
        <Col span={16}>
            <Row>
                <PropsItem span={8}>
                    <InputNumber
                        value={correctValue(innerValue.x, type)}
                        step={0.001}
                        onKeyDown={handleKeyDown}
                        onChange={value => {
                            if (value === null) return
                            if (!checkTypeValue<THREE.Vector3 | THREE.Euler>(data.value)) return
                            if ('isEuler' in innerValue) {
                                var rad = THREE.MathUtils.degToRad(value)
                                changeHandler(new THREE.Euler(rad, innerValue.y, innerValue.z))
                                return
                            }
                            changeHandler(new THREE.Vector3(value, innerValue.y, innerValue.z))
                        }}
                    />
                </PropsItem>
                <PropsItem span={8}>
                    <InputNumber
                        value={correctValue(innerValue.y, type)}
                        step={0.001}
                        onKeyDown={handleKeyDown}
                        onChange={value => {
                            if (value === null) return
                            if (!checkTypeValue<THREE.Vector3 | THREE.Euler>(data.value)) return
                            if ('isEuler' in innerValue) {
                                var rad = THREE.MathUtils.degToRad(value)

                                changeHandler(new THREE.Euler(innerValue.x, rad, innerValue.z))
                                return
                            }
                            changeHandler(new THREE.Vector3(innerValue.x, value, innerValue.z))
                        }}
                    />
                </PropsItem>
                <PropsItem span={8}>
                    <InputNumber
                        value={correctValue(innerValue.z, type)}
                        step={0.001}
                        onKeyDown={handleKeyDown}
                        onChange={value => {
                            if (value === null) return

                            if (!checkTypeValue<THREE.Vector3 | THREE.Euler>(data.value)) return
                            if ('isEuler' in innerValue) {
                                var rad = THREE.MathUtils.degToRad(value)

                                changeHandler(new THREE.Euler(innerValue.x, innerValue.y, rad))
                                return
                            }
                            changeHandler(new THREE.Vector3(innerValue.x, innerValue.y, value))
                        }}
                    />
                </PropsItem>
            </Row>
        </Col>
    )
}

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`

import React from 'react'
import { Checkbox } from 'antd'
import { StyledWrapperFlexCol } from '../ProductRuleAction'
import { ProductActionType } from '../../tabs/ProductRules'

type RuleCheckboxItemProps = {
    action: ProductActionType
    handleChangeAction: (newAction: ProductActionType) => void
}

const RuleCheckboxItem = ({ handleChangeAction, action }: RuleCheckboxItemProps) => {
    const handleChangeActionValue = (value: string) => {
        handleChangeAction({ ...action, value })
    }

    return (
        <StyledWrapperFlexCol span={3}>
            <Checkbox
                checked={!!(action.value && action.value === '1')}
                onChange={e => handleChangeActionValue(e.target.checked ? '1' : '0')}
            />
        </StyledWrapperFlexCol>
    )
}

export default RuleCheckboxItem

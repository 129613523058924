import { AMAOption, useCheckOptionActions, utilsEditor } from '@amaspace-editor/editor-3d'
import { CatalogPreviewItemType } from 'api/shareApi'
import { Fragment, useEffect, useMemo, useState } from 'react'
import ConfirmationMessage from './ConfirmationMessage/ConfirmationMessage'
import OptionColorPicker from './OptionColorPicker/OptionColorPicker'
import { OptionImage } from './OptionImage/OptionImage'
import OptionNumber from './OptionNumber/OptionNumber'
import OptionSelect from './OptionSelect/OptionSelect'
import OptionSingle from './OptionSingle/OptionSingle'
import styles from './ProductAttributesSelect.module.scss'

type Props = {
    product: CatalogPreviewItemType
}

const ProductAttributesSelect = ({ product }: Props) => {
    const editor = utilsEditor()
    const { excludeItemsWithMessage, selectedPCOs, selectOptionWithRules } = useCheckOptionActions()

    const [allUpdatedPCOs, setAllUpdatedPCOs] = useState<AMAOption[]>([])
    const optionsList = useMemo(() => {
        const groupOptions = (options: AMAOption[]) => {
            const grouped = product.attributes.map(attribute => {
                const attributeOptions = options.filter(option => option.parent === attribute.id)
                return {
                    attribute: attribute,
                    options: attributeOptions,
                    visible: attributeOptions.find(option => option.category !== null)
                }
            })
            return grouped
        }
        return groupOptions(editor.optionsAMA)
    }, [editor.optionsAMA, product.attributes])
    const [confirmationMessage, setConfirmationMessage] = useState<any>({})

    useEffect(() => {
        setAllUpdatedPCOs(current => {
            const newUpdatedOptionsIDs = editor.optionsAMAUpdated.map(optionAMAUpdated => optionAMAUpdated.id)
            const optionsToKeep = current.filter(currentOption => !newUpdatedOptionsIDs.includes(currentOption.id))
            return [...optionsToKeep, ...editor.optionsAMAUpdated]
        })
    }, [editor.optionsAMAUpdated])

    const renderOption = (optionsGroup: any) => {
        const ids = [3728, 3755]
        const mat_ids = [3756]
        if (ids.includes(optionsGroup.attribute.id)) {
            return <OptionImage optionsGroup={optionsGroup} />
        }
        if (mat_ids.includes(optionsGroup.attribute.id)) {
            return <OptionColorPicker optionsGroup={optionsGroup} />
        }
        switch (optionsGroup.attribute.type) {
            case 'color':
                return (
                    <OptionSelect
                        optionsGroup={optionsGroup}
                        selectedPCOs={editor.optionsAMAUpdated}
                        selectOptionWithRules={selectOptionWithRules}
                    />
                )
            case 'part_reference':
                return (
                    <OptionSelect
                        optionsGroup={optionsGroup}
                        selectedPCOs={editor.optionsAMAUpdated}
                        selectOptionWithRules={selectOptionWithRules}
                    />
                )
            case 'number':
                return (
                    <OptionNumber
                        allUpdatedPCOs={allUpdatedPCOs}
                        excludeItemsWithMessage={excludeItemsWithMessage}
                        maxValue={optionsGroup.attribute.value.max}
                        minValue={optionsGroup.attribute.value.min}
                        optionsGroup={optionsGroup}
                        selectedPCOs={editor.optionsAMAUpdated}
                        selectOptionWithRules={selectOptionWithRules}
                    />
                )
            case 'single':
                return (
                    <OptionSingle
                        allUpdatedPCOs={allUpdatedPCOs}
                        excludeItemsWithMessage={excludeItemsWithMessage}
                        optionsGroup={optionsGroup}
                        selectedPCOs={editor.optionsAMAUpdated}
                        selectOptionWithRules={selectOptionWithRules}
                        setConfirmationMessage={setConfirmationMessage}
                    />
                )
            default:
                return null
        }
    }
    return (
        <div className={styles.component}>
            <div className={styles.component__pcoList}>
                {optionsList?.map((optionsGroup, index, array) => {
                    if (
                        !optionsGroup.visible ||
                        !optionsGroup.options.find(option => {
                            const updatedData = {
                                visible: true,
                                ...(allUpdatedPCOs.find(updatedPCO => updatedPCO.id === option.id)?.updated || {})
                            }
                            return updatedData.visible
                        })
                    ) {
                        return null
                    }
                    return <Fragment key={index}>{renderOption(optionsGroup)}</Fragment>
                })}
            </div>
            {confirmationMessage.visible === true ? (
                <div className={styles.component__popup}>
                    <ConfirmationMessage
                        optionToAdd={confirmationMessage.optionToAdd}
                        optionsToRemove={confirmationMessage.optionsToRemove}
                        onConfirm={confirmationMessage.onConfirm}
                        priceChange={confirmationMessage.priceChange}
                        setConfirmationMessage={setConfirmationMessage}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default ProductAttributesSelect

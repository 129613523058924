import React, { Dispatch, SetStateAction } from 'react'
import { Button, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { routesUrl } from '../../router/urls'

type ModalAlertDeleteAssetProps = ModalStatus & {
    assets: string[]
    connections: string[]
    deleteAssets: (ids: string[]) => void
}

const ModalAlertDeleteAsset = ({
    isOpen,
    setIsOpen,
    assets,
    connections,
    deleteAssets
}: ModalAlertDeleteAssetProps) => {
    const navigate = useNavigate()
    const onCancel = () => setIsOpen(false)

    const handleDelete = () => {
        onCancel()
        deleteAssets(assets)
    }

    const handleShowProducts = () => {
        localStorage.setItem('assets-deleting', JSON.stringify(connections))
        navigate(routesUrl.companyCatalog)
    }

    return (
        <Modal
            title="Delete Assets Alert"
            centered
            open={isOpen}
            footer={[
                <Button onClick={onCancel} key="cancel">
                    Cancel
                </Button>,
                connections.length === 0 ? (
                    <Button type="primary" onClick={handleDelete} key="delete">
                        Delete
                    </Button>
                ) : (
                    <Button type="primary" onClick={handleShowProducts} key="links">
                        Show Products
                    </Button>
                )
            ]}
            onCancel={onCancel}>
            {connections.length === 0 ? (
                <>Are you sure you want to delete this assets? This operation cannot be undone.</>
            ) : (
                <>You first need to delete or disconnect all of the products that are connected to this assets.</>
            )}
        </Modal>
    )
}

export default ModalAlertDeleteAsset

type ModalStatus = {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

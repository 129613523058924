import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import toast from 'react-hot-toast'
import {Form, FormInstance, Input, Select, Spin, Tooltip} from 'antd'
import {LicenseInfoType} from '../../api/licenseApi'
import {BrandType, useGetBrandListMutation} from '../../api/brandApi'
import {DealerType, useGetDealersListMutation} from '../../api/dealerApi'
import {useFetchByPagination} from '../../hooks'
import {useGetCompanyProductListMutation} from '../../api/companyCatalogApi'
import {ProductType} from '../../providers/CatalogProvider'
import {InfoCircleOutlined} from '@ant-design/icons'

type LicenseFormProps = {
    form: FormInstance<LicenseInfoType>
    onFinish: (e: LicenseInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    mode?: 'creating'
}

const LicenseForm = ({form, refetch, setIsModalOpen, onFinish, mode}: LicenseFormProps) => {
    const [licenseAccess, setLicenseAccess] = useState<LicenseInfoType['is_show_all']>(
        form.getFieldValue('is_show_all') ?? 0
    )

    const [dealers, isLoadingDealers] = useFetchByPagination<DealerType>(useGetDealersListMutation)
    const [brands, isLoadingBrands] = useFetchByPagination<BrandType>(useGetBrandListMutation)
    const [products, isLoadingProducts] = useFetchByPagination<ProductType>(useGetCompanyProductListMutation)

    return (
        <Form
            validateMessages={{required: 'Field is required!'}}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden"/>
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{required: true}]}>
                <Input maxLength={40} placeholder="Name"/>
            </Form.Item>

            <Form.Item
                rules={[{required: true}]}
                label={
                    <div style={{display: 'flex', gap: 4}}>
                        Interval
                        <Tooltip
                            placement="right"
                            title="The license is for 1 year, but payment will be withdrawn every month">
                            <InfoCircleOutlined/>
                        </Tooltip>
                    </div>
                }
                name="interval">
                <Select disabled={true} showSearch allowClear optionFilterProp="children" placeholder="Interval">
                    {intervalOptions.map(opt => {
                        return (
                            <Select.Option key={opt.value} value={opt.value}>
                                {opt.label}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            <Form.Item rules={[{required: true}]} label="License Access Options" name="is_show_all">
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="License Access Options"
                    onChange={(e: 0 | 1) => {
                        setLicenseAccess(e)
                        form.setFieldValue('dealer', [])
                    }}>
                    {statusOptions.map(opt => {
                        return (
                            <Select.Option key={opt.value} value={opt.value}>
                                {opt.label}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            {licenseAccess === 0 && (
                <Form.Item rules={[{required: true}]} label="Dealers" name="dealer">
                    {
                        isLoadingDealers ? <Spin size='small'/> :
                            <Select showSearch allowClear optionFilterProp="children" placeholder="Dealers" mode="multiple">
                                {dealers
                                    .filter(dealer => dealer.status === 'Active')
                                    .map(dealer => {
                                        return (
                                            <Select.Option key={dealer.id} value={dealer.id}>
                                                {dealer.dealerInfo.business_name}
                                            </Select.Option>
                                        )
                                    })}
                            </Select>
                    }
                </Form.Item>
            )}

            {mode === 'creating' && (
                <Form.Item required label="Brand" name="brand_id">
                    {
                        isLoadingBrands ? <Spin size='small'/> :
                            <Select showSearch allowClear optionFilterProp="children" placeholder="Brand">
                                {brands.map(brand => {
                                    return (
                                        <Select.Option key={brand.id} value={brand.id}>
                                            {brand.name}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                    }
                </Form.Item>
            )}

            {mode !== 'creating' && (
                <Form.Item required label="Brand" name="brand">
                    <Input disabled={true} maxLength={40} placeholder="Brand"/>
                </Form.Item>
            )}

            <Form.Item rules={[{required: true}]} label="Products" name="product">
                {
                    isLoadingProducts ? <Spin size='small'/> :
                        <Select showSearch allowClear optionFilterProp="children" placeholder="Products"
                                mode="multiple">
                            {products.map(product => {
                                return (
                                    <Select.Option key={product.id} value={product.id}>
                                        {product.name}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                }
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{required: true}]}>
                <Input.TextArea placeholder="Description" maxLength={200}/>
            </Form.Item>
        </Form>
    )
}

export default LicenseForm

const statusOptions = [
    {value: 1, label: 'All Dealers'},
    {value: 0, label: 'Selected Dealers'}
]

const intervalOptions = [
    {value: 1, label: 'Day'},
    {value: 2, label: 'Week'},
    {value: 3, label: 'Month'},
    {value: 4, label: 'Year'}
]

import api from './index'

const CURRENCY = 'currency'

const priceApi = api.injectEndpoints({
    endpoints: build => ({
        getCurrency: build.query<CurrencyType[], null>({
            query: () => CURRENCY
        })
    }),
    overrideExisting: false
})

export const { useGetCurrencyQuery } = priceApi
export default priceApi

export type CurrencyType = {
    id: number
    name: string
    code: string
}

function logObjectFields(name, fields, object) {
    console.group(name)

    if (!object) {
        console.error('data not found')
        return
    }

    fields.forEach(field => {
        if (field in object) {
            console.log(field, object[field])
        } else {
            console.warn(field, 'not found')
        }
    })

    Object.keys(object).forEach(key => {
        if (fields.includes(key)) return
        console.error(key, 'is redundant')
    })

    console.groupEnd()
}

export default logObjectFields

import {Button, Checkbox, Dropdown, Form, InputNumber, MenuProps, Space} from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {CatalogContext} from "../../../../providers/CatalogProvider";
import {DownOutlined} from "@ant-design/icons";

const discountTypes = {
  'No discount': 0,
  'Percent discount': 1,
  'Fixed discount': 2,
}



const ProductDiscountItem = () => {

  const { product, setProduct } = useContext(CatalogContext)
  const [selectedDiscount, setSelectedDiscount] = useState<number>(0)

  const items = Object.entries(discountTypes).map(([key, value]) => (
    {
      label: key,
      key: value,
      disabled: selectedDiscount === value,
    }
  ))

  useEffect(() => {
    setProduct({
      ...product,
      discount: {
        type: selectedDiscount,
        is_child_use_discount: false,
        percentage: 0
      }
    })
  }, [selectedDiscount])

  const onClick: MenuProps['onClick'] = e => {
    setSelectedDiscount(parseInt(e.key))
  }

  const title = useMemo(() => {
    let f = Object.entries(discountTypes).find(([key, value]) => value === selectedDiscount)
    if (f) {
      const [key, value] = f
      return key
    }
    return ''
  }, [selectedDiscount])

  return (
    <Form.Item label="Select Discount">
      <Dropdown menu={{ items, onClick }}>
        <Button style={{ width: '100%' }}>
          <Space>
            {title} <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {product.discount.type === discountTypes['Percent discount'] &&
        <div style={{ display: 'flex', gap: 5, alignItems: 'center', marginTop: '1rem' }}>
          <span>Percentage: </span>
          <InputNumber
            value={product.discount.percentage}
            style={{ width: '100%' }}
            placeholder="99.99"
            step="0.01"
            min={0}
            max={100}
            stringMode
            onChange={e => {
              setProduct({
                ...product,
                discount: {
                  ...product.discount,
                  percentage: e || 0
                }
              })
            }}
          />
        </div>
      }
      {product.discount.type !== discountTypes['No discount'] &&
        <Checkbox
          checked={product.discount.is_child_use_discount}
          onChange={() => {
            setProduct({
              ...product,
              discount: {
                ...product.discount,
                is_child_use_discount: !product.discount.is_child_use_discount
              }
            })
          }}
        >
          Is child PCOs use discount?
        </Checkbox>
      }
    </Form.Item>
  );
};

export default ProductDiscountItem;
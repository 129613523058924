import React, { Dispatch, SetStateAction } from 'react'
import { ColumnType } from 'antd/es/table'
import { Button, DatePicker, Input, Select, Space } from 'antd'
import { CalendarOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import moment from 'moment/moment'

export type SortStatusType = 0 | 1 | ''

const { Option } = Select
const { RangePicker } = DatePicker

export const useTableFilter = <T, K>(
    filter: T,
    setFilter: Dispatch<SetStateAction<T>>,
    sortHidden?: boolean,
    selectOne?: boolean
): [
    (field: keyof T, fieldSort: keyof T) => ColumnType<K>,
    (fieldStart: keyof T, fieldEnd: keyof T, fieldSort: keyof T) => ColumnType<K>,
    (field: keyof T, items: { id: number | string; name: string }[]) => ColumnType<K>
] => {
    type SearchType = (field: keyof T, fieldSort: keyof T) => ColumnType<K>
    type DatePickerType = (fieldStart: keyof T, fieldEnd: keyof T, fieldSort: keyof T) => ColumnType<K>
    type SelectType = (field: keyof T, items: { id: number | string; name: string }[]) => ColumnType<K>

    const status = (sort: SortStatusType): SortStatusType => {
        if (sort === '') return 1
        if (sort === 1) return 0
        if (sort === 0) return ''
        return ''
    }

    const selectProps =
        !selectOne &&
        ({
            maxTagCount: 'responsive',
            mode: 'multiple'
        } as const)

    const search: SearchType = (field, fieldSort) => ({
        filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search text"
                    value={selectedKeys[0] as string}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        disabled={!selectedKeys[0]}
                        icon={<SearchOutlined />}
                        style={{ width: 90 }}
                        size="small"
                        onClick={() => setFilter(prevState => ({ ...prevState, [field]: selectedKeys[0] }))}>
                        Search
                    </Button>

                    <Button
                        type={'primary'}
                        size="small"
                        style={{ width: 90 }}
                        onClick={() => {
                            setSelectedKeys([])
                            setFilter(prevState => ({ ...prevState, [field]: '', [fieldSort]: '' }))
                            confirm()
                        }}>
                        Reset
                    </Button>

                    {!sortHidden && (
                        <Button
                            type={'primary'}
                            size="small"
                            style={{ width: 120 }}
                            onClick={() => {
                                setFilter(prevState => ({
                                    ...prevState,
                                    [fieldSort]: status(prevState[fieldSort] as SortStatusType)
                                }))
                            }}>
                            {filter[fieldSort] === '' && 'Default Sort'}
                            {filter[fieldSort] === 1 && (
                                <>
                                    <SortAscendingOutlined /> Sort
                                </>
                            )}
                            {filter[fieldSort] === 0 && (
                                <>
                                    <SortDescendingOutlined /> Sort
                                </>
                            )}
                        </Button>
                    )}
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    })

    const dataPicker: DatePickerType = (fieldStart, fieldEnd, fieldSort) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <div style={{ marginBottom: 8, display: 'block' }}>
                    <RangePicker
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        value={[
                            selectedKeys[0] ? moment(selectedKeys[0] as string) : null,
                            selectedKeys[1] ? moment(selectedKeys[1] as string) : null
                        ]}
                        onChange={(e: any) => {
                            if (!e) {
                                setSelectedKeys([])
                                return
                            }

                            const start = moment(e[0]).format('YYYY-MM-DD')
                            const end = moment(e[1]).format('YYYY-MM-DD')
                            setSelectedKeys([start, end, e])
                        }}
                        allowClear={false}
                    />
                </div>

                <Space>
                    <Button
                        type="primary"
                        size="small"
                        style={{ width: 90 }}
                        icon={<SearchOutlined />}
                        onClick={() => {
                            const start = selectedKeys[0] ? selectedKeys[0] : ''
                            const end = selectedKeys[1] ? selectedKeys[1] : ''
                            setFilter(prevState => ({ ...prevState, [fieldStart]: start, [fieldEnd]: end }))
                        }}>
                        Search
                    </Button>

                    <Button
                        type={'primary'}
                        style={{ width: 90 }}
                        size="small"
                        onClick={() => {
                            setSelectedKeys([])
                            setFilter(prevState => ({
                                ...prevState,
                                [fieldStart]: '',
                                [fieldEnd]: '',
                                [fieldSort]: ''
                            }))
                            confirm()
                        }}>
                        Reset
                    </Button>

                    <Button
                        type={'primary'}
                        style={{ width: 120 }}
                        size="small"
                        onClick={() => {
                            setFilter(prevState => ({
                                ...prevState,
                                [fieldSort]: status(prevState[fieldSort] as SortStatusType)
                            }))
                        }}>
                        {filter[fieldSort] === '' && 'Default Sort'}
                        {filter[fieldSort] === 1 && (
                            <>
                                <SortAscendingOutlined /> Sort
                            </>
                        )}
                        {filter[fieldSort] === 0 && (
                            <>
                                <SortDescendingOutlined /> Sort
                            </>
                        )}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    })

    const select: SelectType = (field, items) => ({
        filterDropdown: ({ selectedKeys, setSelectedKeys }) => (
            <div style={{ padding: 8 }}>
                <Select
                    value={selectedKeys as string[]}
                    placeholder={`Select ${field as string}`}
                    {...selectProps}
                    showSearch
                    optionFilterProp="children"
                    onChange={e => {
                        setSelectedKeys(e)
                        setFilter(prevState => ({ ...prevState, [field]: e }))
                    }}
                    allowClear
                    style={{ minWidth: 220, display: 'block' }}>
                    {items &&
                        items.map(item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                </Select>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    })

    return [search, dataPicker, select]
}

import {
    AMAObject,
    isDirectionalLight,
    isHemisphereLight,
    isLight,
    isMeshAMA,
    isPointLight,
    isSpotLight,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import * as THREE from 'three'
import { isLight as isLightNode } from '../../../../../utils'
import { TAssetEditorProp } from '../../assetEditorProps'

const currentObjectsData = (editor3D: ReturnType<typeof utilsEditor>): TAssetEditorProp[] => {
    if (!editor3D.activeMesh) return [] as TAssetEditorProp[]

    const light = editor3D.activeMesh.children[0]
    const camera = editor3D.activeMesh.children[0]
    const activeNodeConfig = editor3D.getCurrentConfigObj(editor3D.activeMesh)

    const nodeType = activeNodeConfig?.type?.split('_').join(' ')

    const allNodes = () => {
        const defaultValue = { name: 'None', id: '', userData: { id: '' } }
        const nodes: AMAObject[] = editor3D.config.objects

        return [defaultValue, ...nodes].map(node => {
            return {
                label: node.name,
                value: node.userData?.id,
                key: `${node?.userData?.id}_target`
            }
        })
    }
    if (isMeshAMA(editor3D.activeMesh)) {
        const defaultCameraProps: TAssetEditorProp[] = [
            { type: 'text', label: 'Type', value: nodeType, key: 'type' },
            { type: 'input', label: 'Name', value: editor3D?.activeMesh?.name, key: 'name_prop' },
            { type: 'tuple', label: 'Position', value: editor3D?.activeMesh?.position, key: 'position_prop' },
            { type: 'tuple', label: 'Rotation', value: editor3D?.activeMesh?.rotation, key: 'rotation_prop' }
        ]

        const defaultProps: TAssetEditorProp[] = [
            { type: 'text', label: 'Type', value: nodeType, key: 'type' },
            { type: 'input', label: 'Name', value: editor3D?.activeMesh?.name, key: 'name_prop' },
            { type: 'tuple', label: 'Position', value: editor3D?.activeMesh?.position, key: 'position_prop' },
            { type: 'tuple', label: 'Rotation', value: editor3D?.activeMesh?.rotation, key: 'rotation_prop' },
            { type: 'tuple', label: 'Scale', value: editor3D?.activeMesh?.scale, key: 'scale_prop' },
            { type: 'switch', label: 'Visible', value: editor3D?.activeMesh?.visible, key: 'visible_prop' },
            {
                type: 'switch',
                label: 'Visible in AR',
                value: editor3D?.getCurrentConfigObj(editor3D?.activeMesh)?.userData?.visibleInAR,
                key: 'visibleInAR_ar'
            },
            {
                type: 'switch',
                label: 'In bloom selection',
                value: editor3D?.getCurrentConfigObj(editor3D?.activeMesh)?.userData?.selectByBloom,
                key: 'selectByBloom_bloom'
            },
            { type: 'switch', label: 'Shadow cast', value: editor3D?.activeMesh?.castShadow, key: 'castShadow_prop' },
            {
                type: 'switch',
                label: 'Shadow recieve',
                value: editor3D?.activeMesh?.receiveShadow,
                key: 'receiveShadow_prop'
            }
        ]

        if (camera instanceof THREE.PerspectiveCamera) return defaultCameraProps
        if (!isLightNode(light)) return defaultProps
        const defaultLightProps: TAssetEditorProp[] = [
            { type: 'text', label: 'Type', value: nodeType, key: 'type' },
            { type: 'input', label: 'Name', value: editor3D.activeMesh?.name, key: 'name_prop' },
            { type: 'tuple', label: 'Position', value: editor3D.activeMesh?.position, key: 'position_prop' },
            {
                type: 'range',
                label: 'Intensive',
                value: light.intensity,
                key: 'intensity_light'
            },
            { type: 'switch', label: 'Visible', value: editor3D.activeMesh.visible, key: 'visible_prop' },
            { type: 'switch', label: 'Shadow cast', value: light.castShadow, key: 'castShadow_light' },
            { type: 'color', label: 'Color', value: light.color, key: 'color_light' }
        ]

        if (light.castShadow) {
            const obj = editor3D.getCurrentConfigObj(editor3D.activeMesh)

            if (!isLight(obj)) return defaultLightProps
            defaultLightProps.push(
                // { type: 'range', misc: { min: 0, max: 10 }, label: 'Radius', value: obj.shadow, key: 'radius_lightShadow' },
                {
                    type: 'range',
                    misc: { min: 0, max: 10, precision: 0.1 },
                    label: 'Bias',
                    value: obj.shadow.bias,
                    key: 'bias_lightShadow'
                },
                {
                    type: 'range',
                    misc: { min: 0, max: 2, precision: 0.1 },
                    label: 'Normal Bias',
                    value: obj.shadow.normalBias,
                    key: 'normalBias_lightShadow'
                },
                {
                    type: 'range',
                    misc: { min: 0, max: 1000 },
                    label: 'Near',
                    value: obj.shadow.cameraNear,
                    key: 'cameraNear_lightShadow'
                },
                {
                    type: 'range',
                    misc: { min: 0, max: 2000 },
                    label: 'Far',
                    value: obj.shadow.cameraFar,
                    key: 'cameraFar_lightShadow'
                },
                {
                    type: 'range',
                    misc: { min: 0, max: 30, precision: 0.1 },
                    label: 'Cam Size',
                    value: obj.shadow.cameraSize,
                    key: 'cameraSize_lightShadow'
                },
                {
                    type: 'select',
                    label: 'Resolution',
                    value: {
                        options: ['512', '1024', '2048'].map(el => ({
                            key: el,
                            value: el,
                            label: el
                        })),
                        value: (obj.shadow.mapSize || '512').toString()
                    },
                    key: 'mapSize_lightShadow'
                }
            )
        }

        if (isDirectionalLight(light)) return defaultLightProps
        if (isPointLight(light)) return defaultLightProps
        if (isHemisphereLight(light))
            return defaultLightProps.concat([
                { type: 'color', label: 'Ground color', value: light?.groundColor, key: 'groundColor_light' }
            ])
        if (isSpotLight(light)) {
            return defaultLightProps.concat([
                { type: 'range', label: 'Angle', value: light.angle, key: 'angle_light', misc: { max: 2 } }
            ])
        }
    }
    return [
        { type: 'text', label: 'Type', value: nodeType, key: 'type' },
        { type: 'input', label: 'Name', value: editor3D?.activeMesh?.name, key: 'name_prop' },
        { type: 'tuple', label: 'Position', value: editor3D?.activeMesh?.position, key: 'position_prop' },
        { type: 'tuple', label: 'Scale', value: editor3D?.activeMesh?.scale, key: 'scale_prop' },
        { type: 'switch', label: 'Visible', value: editor3D?.activeMesh?.visible, key: 'visible_prop' },
        {
            type: 'switch',
            label: 'Visible in AR',
            value: editor3D?.getCurrentConfigObj(editor3D?.activeMesh)?.userData?.visibleInAR,
            key: 'visibleInAR_ar'
        },
        {
            type: 'select',
            label: 'Parent',
            value: { options: allNodes(), value: editor3D.activeMesh?.userData?.parent },
            key: 'node_parent'
        }
    ]
    // return [] as TAssetEditorProp[]
}
export { currentObjectsData }

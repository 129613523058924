import {useContext, useEffect, useState} from 'react'
import {Button, Form, Space, Tag} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    MailOutlined,
    SendOutlined,
    UserAddOutlined,
    UserSwitchOutlined
} from '@ant-design/icons'
import PageBuilder from '../../../components/panel/PageBuilder'
import UserChangeStatusModal from '../UserChangeStatusModal'
import ModalWithForm from '../ModalWithForm'
import {
    AdminType,
    AdminFilter,
    useChangeAdminStatusMutation,
    useCreateAdminMutation,
    useGetAdminsQuery,
    useUpdateAdminMutation,
    AdminInfoType,
    useDeleteAdminMutation, useChangeAdminEmailMutation, useResendAdminEmailConfirmationMutation,
} from '../../../api/adminApi'
import AdminForm from './AdminForm'
import {UserStatus, UserStatusInfo} from '../../../api/accountManagerApi'
import ExtendedTable from '../../../components/ExtendedTable'
import {statusFilterOptions} from '../accountManager/AccountManagers'
import {useFilterTable} from '../../../hooks/useFilterTable'
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import {ModalConfirmContext} from "../../../providers/ModalConfirmProvider";
import UserChangeEmailModal from "../UserChangeEmailModal";
import {useDeleteUserMutation} from "../../../api/userApi";

const Admins = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {showModal} = useContext(ModalConfirmContext)

    const [formChangeEmail] = Form.useForm<Pick<AdminType, 'email' | 'id'>>()
    const [formCreate] = Form.useForm<AdminInfoType>()
    const [formUpdate] = Form.useForm<AdminInfoType>()
    const [formChangeStatus] = Form.useForm<UserStatusInfo>()

    const [isModalOpenChangeEmail, setIsModalOpenChangeEmail] = useState(false)
    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)
    const [isModalOpenChangeStatus, setIsModalOpenChangeStatus] = useState(false)

    const [create, resultsCreate] = useCreateAdminMutation()
    const [changeEmail, resultsChangeEmail] = useChangeAdminEmailMutation()
    const [resendConfirmationEmail] = useResendAdminEmailConfirmationMutation()
    const [deleteItem] = useDeleteUserMutation()
    const [update, resultsUpdate] = useUpdateAdminMutation()
    const [changeStatus, resultsChangeStatus] = useChangeAdminStatusMutation()

    const [filter, setFilter] = useState<AdminFilter>({
        page: 1,
        name: '',
        lastname: '',
        email: '',
        status: null
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const lastname = searchParams.get("lastname")
        const email = searchParams.get("email")
        const status = searchParams.get("status") as UserStatus

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            email: email ?? '',
            lastname: lastname ?? '',
            status: status ?? null,
        })
    }, [searchParams]);

    const {data, isLoading, refetch} = useGetAdminsQuery(filter)

    const [filterByText, _, filterBySelect] = useFilterTable<AdminFilter>()

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...filterByText('email')
        },
        {
            title: 'First Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
            ...filterByText('lastname')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...filterBySelect('status', statusFilterOptions, 'id', false)
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            render: (company: AdminType['company']) => (
                <Tag key={company.id} color="processing">
                    {company.name}
                </Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, user: AdminType) => (
                <>
                    <Button
                        onClick={() => {
                            const {id} = user
                            formChangeStatus.setFieldsValue({id})
                            setIsModalOpenChangeStatus(true)
                        }}
                        type="link"
                        size="small">
                        <UserSwitchOutlined/>
                    </Button>

                    {
                        user.status === 'Sign up email confirmation' && <>
                            <Button
                                onClick={() => {
                                    const handleResend = async () => {
                                        const {id} = user
                                        const res = await resendConfirmationEmail({id})
                                        if ('error' in res) return
                                        toast.success('Email Confirmation is successfully delivered')
                                        refetch()
                                    }

                                    showModal('resend email confirmation', handleResend)
                                }}
                                type="link"
                                size="small">
                                <SendOutlined />
                            </Button>

                            <Button
                                onClick={() => {
                                    const {id, email} = user
                                    formChangeEmail.setFieldsValue({id, email})
                                    setIsModalOpenChangeEmail(true)
                                }}
                                type="link"
                                size="small">
                                <MailOutlined />
                            </Button>
                        </>
                    }

                    <Button
                        onClick={() => {
                            const {
                                company: {id: company_id},
                                ...otherFields
                            } = user
                            formUpdate.setFieldsValue({...otherFields, company_id})
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined/>
                    </Button>

                    {
                        user.status !== 'Deleted' && <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const {id} = user
                                    const res = await deleteItem({id})
                                    if ('error' in res) return
                                    toast.success('Admin is successfully deleted')
                                    refetch()
                                }

                                showModal('delete this admin', handleDelete)
                            }}
                            type="link"
                            size="small"
                        >
                            <DeleteOutlined/>
                        </Button>
                    }
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Admins" documentTitle="Admins">
            <Space style={{marginBottom: 20}}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <UserAddOutlined/> Create Admin
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title={'Create Admin'}
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <AdminForm
                    refetch={refetch}
                    setIsModalOpen={setIsModalOpenCreate}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title={'Update Admin'}
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                isLoading={resultsUpdate.isLoading}
                submit={formUpdate.submit}>
                <AdminForm
                    refetch={refetch}
                    setIsModalOpen={setIsModalOpenUpdate}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>

            <UserChangeStatusModal
                form={formChangeStatus}
                changeStatus={changeStatus}
                isModalOpen={isModalOpenChangeStatus}
                setIsModalOpen={setIsModalOpenChangeStatus}
                isLoading={resultsChangeStatus.isLoading}
                refetch={refetch}
            />

            <UserChangeEmailModal
                form={formChangeEmail}
                changeEmail={changeEmail}
                isModalOpen={isModalOpenChangeEmail}
                setIsModalOpen={setIsModalOpenChangeEmail}
                isLoading={resultsChangeEmail.isLoading}
                refetch={refetch}
            />
        </PageBuilder>
    )
}

export default Admins

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Collapse, Dropdown, Input, Menu, Row, Select, Switch, Typography } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import React, { KeyboardEvent, useEffect, useState } from 'react'
import * as THREE from 'three'

import ColorPicker from 'react-best-gradient-color-picker'
import styled from 'styled-components'
import Utils, { checkTypeValue } from '../../../utils'
import { PostProcessingController } from './editor3D/postProcessingController'
import RenderAttachAsset from './editor3D/renderAttachAsset'
import RenderAttachTexture from './editor3D/renderAttachTexture'
import { RenderColorPicker } from './propsComponents/ColorPicker.props'
import { RenderInput } from './propsComponents/Input.props'
import { RenderRange } from './propsComponents/Range.props'
import { RenderTuple } from './propsComponents/RenderTuple'

export type TAssetEditorProp = {
    key: string
    //TODO checks
    value: any
    label: string
    type:
        | 'postProcessing'
        | 'input'
        | 'switch'
        | 'range'
        | 'list'
        | 'color'
        | 'tuple'
        | 'button'
        | 'attach'
        | 'attachTexture'
        | 'select'
        | 'vec2'
        | 'attachEnv'
        | 'attachImage'
        | 'gradient'
        | 'text'
    misc?: {
        maxLength?: number
        precision?: number
        min?: number
        max?: number
        enableInfinity?: boolean
        list?: Array<{
            label: string
            key: string
            value: string | number
        }>
    }
}

export type TAssetEditorSection = {
    name: string
    props: TAssetEditorProp[]
}

export type TAssetEditorProps = {
    sectionsList: TAssetEditorSection[]
    onPropChange: (key: string, value: TAssetEditorProp['value']) => void
    asset?: any
}

export type ActionType = {
    data: TAssetEditorProp
    onChange: TAssetEditorProps['onPropChange']
}

const AssetEditorProps: React.FC<TAssetEditorProps> = ({ sectionsList, onPropChange, asset }) => {
    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation()
    }

    const renderSwitch = (prop: TAssetEditorProp) => {
        return (
            <PropsItem span={16}>
                <Switch
                    checked={prop.value}
                    onChange={value => {
                        onPropChange(prop.key, value)
                    }}
                />
            </PropsItem>
        )
    }
    const renderButton = (prop: TAssetEditorProp) => {
        // if (!checkTypeValue<string>(prop.value)) return

        return (
            <PropsItem span={10}>
                <Button
                    type={'primary'}
                    onClick={() => {
                        onPropChange(prop.key, '')
                    }}>
                    {prop.value}
                </Button>
            </PropsItem>
        )
    }
    const renderText = (prop: TAssetEditorProp) => {
        if (!checkTypeValue<string>(prop.value)) return

        return <PropsItem span={16}>{prop.value}</PropsItem>
    }
    const RenderAttachImage: React.FC<{ data: TAssetEditorProp }> = ({ data }) => {
        const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0]
                Utils.createAssetsFromFile(file).then(res => {
                    onPropChange(data.key, res[0].url)
                })
            }
        }

        return (
            <div style={{ position: 'relative', display: 'flex', flex: 1, gap: '40px' }}>
                <PropsItem span={6}>
                    <div style={{ position: 'relative', height: 40 }}>
                        {data.value ? <img style={{ height: '100%' }} alt="uploaded" src={data.value} /> : null}
                        <input
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            onChange={onUpload}
                            style={{ position: 'absolute', opacity: 0, left: 0, top: 0, width: '100%', height: '100%' }}
                        />
                    </div>
                </PropsItem>
            </div>
        )
    }

    const RenderGradient: React.FC<{ data: TAssetEditorProp }> = React.memo(({ data }) => {
        const [color, setColor] = useState(data.value)

        const handleApplyGradient = () => {
            onPropChange(data.key, color)
        }

        useEffect(() => {
            setColor(data.value)
        }, [data.value])

        return (
            <div style={{ position: 'relative', display: 'flex', flex: 1, gap: '40px' }}>
                <PropsItem style={{ flexDirection: 'column', alignItems: 'unset', justifyContent: 'unset' }} span={6}>
                    <ColorPicker
                        value={color}
                        onChange={value => {
                            setColor(value)
                        }}
                        hideOpacity
                        hideGradientAngle
                        hidePresets
                        hideEyeDrop
                        hideGradientType
                        hideAdvancedSliders
                        hideColorGuide
                        hideInputType
                        hideColorTypeBtns
                        width={200}
                        height={150}
                    />

                    <Button type="primary" style={{ width: 200, marginBottom: 20 }} onClick={handleApplyGradient}>
                        Apply gradient
                    </Button>
                </PropsItem>
            </div>
        )
    })

    const renderList = (prop: TAssetEditorProp) => {
        const list = prop.misc!.list!

        const MenuItems = () => {
            return (
                <Menu>
                    {list.map(item => {
                        return (
                            <Menu.Item
                                key={item.key}
                                onClick={e => {
                                    onPropChange(prop.key, item.key)
                                }}>
                                {item.label}
                            </Menu.Item>
                        )
                    })}
                </Menu>
            )
        }

        return (
            <Dropdown overlay={MenuItems}>
                <PropsItem>
                    <a href={'#'}>{prop.value}</a>
                </PropsItem>
            </Dropdown>
        )
    }

    const renderSelect = (prop: TAssetEditorProp) => {
        return (
            <PropsItem span={16}>
                <SelectItem
                    options={prop.value.options}
                    onChange={event => {
                        onPropChange(prop.key, { object: event })
                    }}
                    placeholder="Select..."
                    value={prop.value.value}
                />
            </PropsItem>
        )
    }

    const renderVec2Value = (prop: TAssetEditorProp) => {
        const maxLength = prop.misc?.maxLength || 100

        return (
            <Col span={16}>
                <Row>
                    <PropsItem span={8}>
                        <Input
                            maxLength={maxLength}
                            value={prop.value.x.toFixed(2)}
                            onChange={e => {
                                onPropChange(prop.key, new THREE.Vector2(+e.target.value, prop.value.y))
                            }}
                            onKeyDown={handleKeyDown}
                        />
                    </PropsItem>
                    <PropsItem span={8}>
                        <Input
                            maxLength={maxLength}
                            value={prop.value.y.toFixed(2)}
                            onChange={e => {
                                onPropChange(prop.key, new THREE.Vector2(prop.value.x, +e.target.value))
                            }}
                            onKeyDown={handleKeyDown}
                        />
                    </PropsItem>
                </Row>
            </Col>
        )
    }

    return (
        <WrapperProps className={'viewer_props'}>
            <Typography.Title level={3}>Properties</Typography.Title>
            {sectionsList.length ? (
                <Collapse defaultActiveKey={sectionsList[0].name}>
                    {sectionsList.map(section => {
                        const { name, props } = section
                        return (
                            <CollapsePanel style={{ fontSize: '12px' }} header={name || ''} key={section.name}>
                                {props.map(prop => {
                                    if (prop.type === 'postProcessing') {
                                        return <PostProcessingController />
                                    }
                                    if (prop.type === 'gradient') {
                                        return <RenderGradient data={prop} key={'gradient'} />
                                    }

                                    return (
                                        <Row key={prop.key} style={{ height: 44, justifyContent: 'space-between' }}>
                                            <PropsItem span={8}>
                                                <span>{prop.label}</span>
                                            </PropsItem>
                                            {(() => {
                                                if (prop.type === 'input') {
                                                    return <RenderInput data={prop} onChange={onPropChange} />
                                                }
                                                if (prop.type === 'color') {
                                                    return <RenderColorPicker data={prop} onChange={onPropChange} />
                                                }
                                                if (prop.type === 'switch') {
                                                    return renderSwitch(prop)
                                                }

                                                if (prop.type === 'range') {
                                                    return <RenderRange data={prop} onChange={onPropChange} />
                                                }

                                                if (prop.type === 'list') {
                                                    return renderList(prop)
                                                }
                                                if (prop.type === 'attachImage') {
                                                    return <RenderAttachImage data={prop} />
                                                }
                                                if (prop.type === 'tuple') {
                                                    return <RenderTuple data={prop} onChange={onPropChange} />
                                                }
                                                if (prop.type === 'vec2') {
                                                    return renderVec2Value(prop)
                                                }
                                                if (prop.type === 'button') {
                                                    return renderButton(prop)
                                                }
                                                if (prop.type === 'text') {
                                                    return renderText(prop)
                                                }
                                                if (prop.type === 'attach') {
                                                    return (
                                                        <RenderAttachAsset
                                                            asset={asset}
                                                            keyMap={prop.key}
                                                            onPropChange={onPropChange}
                                                            value={prop.value}
                                                        />
                                                    )
                                                }
                                                if (prop.type === 'attachTexture') {
                                                    return (
                                                        <RenderAttachTexture
                                                            asset={asset}
                                                            keyMap={prop.key}
                                                            onPropChange={onPropChange}
                                                            value={prop.value}
                                                        />
                                                    )
                                                }
                                                if (prop.type === 'attachEnv') {
                                                    return (
                                                        <RenderAttachTexture
                                                            asset={asset}
                                                            keyMap={prop.key}
                                                            onPropChange={onPropChange}
                                                            value={prop.value}
                                                            isEnv={true}
                                                        />
                                                    )
                                                }
                                                if (prop.type === 'select') {
                                                    return renderSelect(prop)
                                                }
                                                return null
                                            })()}
                                        </Row>
                                    )
                                })}
                            </CollapsePanel>
                        )
                    })}
                </Collapse>
            ) : null}
        </WrapperProps>
    )
}

export default AssetEditorProps

const WrapperProps = styled.div`
    height: 100%;
    overflow: auto;
    padding: 10px;
`

const PropsItem = styled(Col)`
    display: flex;
    align-items: center;
    gap: 20px;
`

const DefaultIcon = styled.div`
    width: 60px;
    height: 30px;
    border: 1px solid #000;
`

const ColorIcon = styled(DefaultIcon)<{ backgroundColor: string }>`
    background: ${props => `${props?.backgroundColor} !important`};
`

const SelectItem = styled(Select)`
    width: 100%;
`

import { AMAOption } from '@amaspace-editor/editor-3d'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ProductAttributeType } from 'api/shareApi'
import { Dispatch } from 'react'
import styles from './OptionSingle.module.scss'

type TOptionsGroup = {
    attribute: ProductAttributeType
    options: AMAOption[]
}

type Props = {
    allUpdatedPCOs: AMAOption[]
    excludeItemsWithMessage: AMAOption[]
    optionsGroup: TOptionsGroup
    selectedPCOs: AMAOption[]
    selectOptionWithRules: any
    setConfirmationMessage: Dispatch<any>
}

type TExcludeItem =
    | { attribute?: AMAOption | undefined; exclude?: AMAOption | undefined; id?: string | number | undefined }[]
    | undefined

const OptionSingle = ({
    allUpdatedPCOs,
    excludeItemsWithMessage,
    optionsGroup,
    selectedPCOs,
    selectOptionWithRules,
    setConfirmationMessage
}: Props) => {
    const handleOptionClick = (option: AMAOption, isSelected: boolean, exclude: TExcludeItem) => {
        if (exclude && exclude.length && !isSelected) {
            setConfirmationMessage({
                visible: true,
                optionToAdd: {
                    name: option.name
                },
                optionsToRemove: exclude.map(product => {
                    return product?.attribute?.name
                }),
                onConfirm: () => {
                    selectOptionWithRules({
                        id: option.id,
                        option: option
                    })
                },
                priceChange:
                    Number(option.price[0].amount) -
                    exclude?.reduce((accumulator, currentValue) => {
                        const currencies = currentValue?.attribute?.price && currentValue.attribute.price
                        return accumulator + (currencies ? Number(currencies) : 0)
                    }, 0)
            })
            return
        }
        selectOptionWithRules({ id: option.id, option: option })
    }

    return (
        <div className={styles.component}>
            {optionsGroup.options.map(option => {
                const isSelected = !!selectedPCOs.find(selectedPCO => selectedPCO.id === option.id)?.checked
                const updatedData = {
                    visible: true,
                    enabled: true,
                    value: '',
                    options: [],
                    color: null,
                    remove: isSelected,
                    ...(allUpdatedPCOs.find(updatedPCO => updatedPCO.id === option.id)?.updated || {})
                }
                const exclude = excludeItemsWithMessage.find(excludeItem => excludeItem.id === option.id)?.exclude

                return updatedData.visible === true ? (
                    <Button
                        className={styles.component__option}
                        key={option.name + option.id}
                        icon={isSelected ? <CheckCircleOutlined /> : null}
                        onClick={() => {
                            handleOptionClick(option, isSelected, exclude)
                        }}
                        type="default">
                        {option.name}
                    </Button>
                ) : null
            })}
        </div>
    )
}

export default OptionSingle

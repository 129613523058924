import { Route, Routes } from 'react-router-dom'
import { useAppSelector } from 'hooks'
import Panel from 'components/panel/Panel'
import IframeViewer from '../pages/iframeViewer/IframeViewer'
import AuthRouter from './AuthRouter'
import ResetTFA from 'pages/authorization/ResetTFA'
import VerifyEmail from '../pages/authorization/VerifyEmail'
import DealerRegistration from '../pages/authorization/DealerRegistration'
import { routesUrl } from './urls'

const Router = () => {
    const isAuth = useAppSelector(state => state.auth.isAuth)

    return (
        <Routes>
            {isAuth ? <Route path="*" element={<Panel />} /> : <Route path="*" element={<AuthRouter />} />}

            <Route path="iframe/:id" element={<IframeViewer />} />

            <Route path={`${routesUrl.register}/:companyId`} element={<DealerRegistration />} />
            <Route path={`${routesUrl.verifyEmail}/:token`} element={<VerifyEmail />} />
            <Route path={`${routesUrl.reset2fa}/:token`} element={<ResetTFA />} />
        </Routes>
    )
}

export default Router

import { useEffect, useState } from 'react'
import { Button, Form, Space } from 'antd'
import {
    InvoiceFilter,
    InvoiceType,
    PaymentInvoiceInfoType,
    useCreateCompanyInvoiceMutation,
    useGetCompanyInvoicesQuery
} from '../../api/invoiceApi'
import PageBuilder from '../../components/panel/PageBuilder'
import { PlusOutlined } from '@ant-design/icons'
import ExtendedTable from '../../components/ExtendedTable'
import ModalWithForm from '../users/ModalWithForm'
import InvoiceForm from './InvoiceForm'
import { useParams } from 'react-router'
import { useAppSelector } from '../../hooks'
import {useSearchParams} from "react-router-dom";

type InvoicesProps = {
    type: 'dealer' | 'company'
    title: string
    getInvoices: typeof useGetCompanyInvoicesQuery
    createInvoice: typeof useCreateCompanyInvoiceMutation
}

const Invoices = ({ title, createInvoice, getInvoices, type }: InvoicesProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { role } = useAppSelector(state => state.user)
    const { id } = useParams()

    const [formCreate] = Form.useForm<PaymentInvoiceInfoType>()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)

    const [filter, setFilter] = useState<InvoiceFilter>({ id: id!, page: 1 })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const page = parseInt(pageParam ?? '1')

        setFilter(prevState => ({
            ...prevState,
            page: Number.isNaN(page) ? 1 : page,
        }))
    }, [searchParams]);

    const { data, isLoading, refetch } = getInvoices(filter)
    const [create, resultsCreate] = createInvoice()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, invoice: InvoiceType) => (
                <a href={invoice.invoice_url} download>
                    Download PDF
                </a>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title={title} documentTitle={title}>
            {role === 'account manager' && (
                <Space style={{ marginBottom: 20 }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsModalOpenCreate(true)
                            formCreate.setFieldValue('id', id)
                        }}>
                        <PlusOutlined /> Create Invoice
                    </Button>
                </Space>
            )}

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="invoice"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Invoice"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <InvoiceForm
                    type={type}
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Invoices

import {
    AMAMaterialConfig,
    AMAMaterialType,
    AMAMode,
    DEFAULT_PHYSICAL_MATERIAL_PROPS,
    DEFAULT_REFLECTIVE_MATERIAL_PROPS,
    DEFAULT_REFRACTION_MATERIAL_PROPS,
    EditorConstructor,
    MaterialProps,
    useMaterialState,
    utilsEditor,
    utilsMaterial
} from '@amaspace-editor/editor-3d'
import { useAppDispatch } from 'hooks'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    changeDeleteMaterialConnections,
    changeNewMaterialConnections,
    materialDeleteConnections,
    materialNewConnections
} from 'slices/modelSlice'
import styled from 'styled-components'
import * as THREE from 'three'
import { useGetAssetByIdQuery, useUpdateAssetMutation } from '../../../../api/assetApi'
import { TAsset } from '../../../../types'
import Utils, { objectUtils } from '../../../../utils'
import AssetEditorProps, { TAssetEditorProp, TAssetEditorSection } from '../assetEditorProps'

export const MATERIAL_SIDES = {
    front_side: 0,
    back_side: 1,
    double_side: 2
} as const

export const BLENDING_TYPES = {
    no_blending: 0,
    normal_blending: 1,
    additive_blending: 2,
    subtractive_blending: 3,
    multiply_blending: 4,
    custom_blending: 5
} as const

interface AssetEditorMaterialProps {
    asset?: TAsset
}

const AssetEditorMaterial = ({ asset }: AssetEditorMaterialProps) => {
    const { config } = useMaterialState()
    const editorUtils3D = utilsEditor()
    const materialEdit = utilsMaterial()
    const { '*': splat } = useParams()
    const list = splat?.split('/')
    const id = list?.pop()
    const dispatch = useAppDispatch()

    const [updateAssetRequest] = useUpdateAssetMutation()
    const fetchAssetByIdResponse = useGetAssetByIdQuery({ id: id as string })
    const newConnection = useSelector(materialNewConnections)
    const oldConnection = useSelector(materialDeleteConnections)

    const materialProps = (): TAssetEditorSection[] => {
        if (!config) return []
        const props = config.properties as AMAMaterialConfig<typeof AMAMaterialType.MeshStandardMaterial>['properties']

        return [
            {
                name: 'Material',
                props: [
                    {
                        type: 'select',
                        label: 'Type',
                        key: 'material_type',
                        value: {
                            options: Object.keys(AMAMaterialType).map(el => ({
                                key: el,
                                value: el,
                                label: el
                            })),
                            value: config.type
                        }
                    },
                    { type: 'input', label: 'Name', value: props?.name, key: 'name_prop' },
                    ...(MaterialProps.getProperties(config, false) as any),

                    { type: 'button', label: 'Save', value: 'Save', key: 'update' }
                ]
            }
        ]
    }

    const changeProps = async (key: string, value: TAssetEditorProp['value']) => {
        const keyProp = key.split('_').slice(-1)[0]
        const keyValue = key.split('_')[0]

        if (keyValue === 'material') {
            if (keyProp === 'type') {
                let props: any = null

                switch (value.object) {
                    case AMAMaterialType.MeshReflectorMaterial:
                        props = { ...DEFAULT_REFLECTIVE_MATERIAL_PROPS, ...materialEdit.config.properties }
                        break
                    case AMAMaterialType.MeshRefractionMaterial:
                        props = { ...DEFAULT_REFRACTION_MATERIAL_PROPS, ...materialEdit.config.properties }
                        break
                    case AMAMaterialType.MeshPhysicalMaterial:
                        props = { ...DEFAULT_PHYSICAL_MATERIAL_PROPS, ...materialEdit.config.properties }
                        break
                    default:
                        props = materialEdit.config.properties
                        break
                }
                materialEdit.setConfig({
                    ...materialEdit.getCurrent().config,
                    type: value.object,
                    properties: props
                })
                return
            }

            materialEdit.updateMaterialProps({ [keyProp]: value })
            if (keyProp === 'transparent') {
                setTimeout(() => {
                    if (materialEdit.mainSphere) {
                        if (Array.isArray(materialEdit.mainSphere.material)) return
                        materialEdit.mainSphere.material.needsUpdate = true
                    }
                }, 0)
            }
        }

        if (keyProp === 'map') {
            materialEdit.updateMaterialTextures(value)
        }
        if (keyProp === 'side') {
            const sideValue = objectUtils<typeof MATERIAL_SIDES>({ obj: MATERIAL_SIDES, key: value })
                .value as THREE.Side
            materialEdit.updateMaterialProps({ side: sideValue, doubleside: false })
        }
        if (keyProp === 'blending') {
            const blendingValue = objectUtils({ obj: BLENDING_TYPES, key: value }).value as THREE.Blending
            materialEdit.updateMaterialProps({ blending: blendingValue })
        }

        if (key === 'update') {
            const name = asset?.name.replace(/[^a-zA-Z_]/g, '')
            const preview = Utils.generateAndUploadThumbnail(name || '', editorUtils3D.getScreenShot() || '', true)
            await toast.promise(preview, {
                loading: 'Updating preview...',
                success: 'Preview updated!',
                error: 'Error when updating preview!'
            })

            const textures = config.textures.reduce((accumulator, currentValue) => {
                if (currentValue?.type) {
                    accumulator[currentValue.type] = currentValue.id
                }
                return accumulator
            }, {} as any)

            const assetData = {
                id: asset!.id,
                asset: {
                    ...asset,
                    connections: newConnection,
                    connections_delete: oldConnection,
                    patch: {
                        ...asset?.patch,
                        textures,
                        updatedProps: {
                            ...config.properties,
                            type: config.type
                        },

                        updatedTextures: [...config.textures]
                    },
                    preview: await preview
                }
            }
            toast.promise(updateAssetRequest(assetData as any), {
                loading: 'Updating...',
                success: 'Update complete!',
                error: 'Update failed!'
            })
            dispatch(changeNewMaterialConnections([]))
            dispatch(changeDeleteMaterialConnections([]))
        }
        if (key === 'reset') {
            const assetData = {
                id: asset!.id,
                asset: {
                    ...asset,
                    patch: {
                        ...asset?.patch,
                        updatedProps: {},
                        updatedTextures: []
                    }
                }
            }
            toast.promise(updateAssetRequest(assetData as any), {
                loading: 'Updating...',
                success: 'Update complete!',
                error: 'Update failed!'
            })
        }
    }
    const renderMaterial = () => {
        const data = structuredClone(fetchAssetByIdResponse.data)
        return (
            <MaterialWrapper>
                <EditorConstructor
                    type={asset!.type as 'model' | 'material'}
                    data={data}
                    presetConfig={{ mode: AMAMode.EDITOR }}
                />
            </MaterialWrapper>
        )
    }

    return (
        <>
            <Wrapper>
                {renderMaterial()}
                <PropsWrapper>
                    <AssetEditorProps asset={asset} sectionsList={materialProps()} onPropChange={changeProps} />
                </PropsWrapper>
            </Wrapper>
        </>
    )
}

export default AssetEditorMaterial

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px;
    height: calc(100vh - 300px);
    width: 100%;
`

const MaterialWrapper = styled.div`
    height: 100%;
    flex: 1;
`
const PropsWrapper = styled.div`
    flex: 0.5;
`

import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Input, Select, Space } from 'antd'
import {
    BankOutlined,
    CheckCircleOutlined,
    CreditCardOutlined,
    DeleteOutlined,
    FileProtectOutlined, MailOutlined,
    ProjectOutlined, SendOutlined,
    UserAddOutlined,
    UserSwitchOutlined
} from '@ant-design/icons'
import toast from 'react-hot-toast'
import PageBuilder from '../../../components/panel/PageBuilder'
import {
    DealerFilter,
    DealerInfoType,
    DealerPayInfo,
    DealerType,
    useApproveDealerMutation, useChangeDealerEmailMutation,
    useChangeDealerPayMutation,
    useChangeDealerStatusMutation,
    useCreateDealerMutation,
    useDeleteDealerMutation,
    useGetDealersQuery, useResendDealerEmailConfirmationMutation
} from '../../../api/dealerApi'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { useAppSelector } from '../../../hooks'
import UserChangeStatusModal from '../UserChangeStatusModal'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import ExtendedTable from '../../../components/ExtendedTable'
import ModalWithForm from '../ModalWithForm'
import DealerForm from './DealerForm'
import {UserStatus, UserStatusInfo} from '../../../api/accountManagerApi'
import { statusFilterOptions } from '../accountManager/AccountManagers'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { routesUrl } from '../../../router/urls'
import {AdminType, useChangeAdminEmailMutation, useResendAdminEmailConfirmationMutation} from "../../../api/adminApi";
import UserChangeEmailModal from "../UserChangeEmailModal";
import {useDeleteUserMutation} from "../../../api/userApi";

const Dealers = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { role } = useAppSelector(state => state.user)
    const { showModal } = useContext(ModalConfirmContext)
    const navigate = useNavigate()

    const [formChangeEmail] = Form.useForm<Pick<DealerType, 'email' | 'id'>>()
    const [formCreate] = Form.useForm<DealerInfoType>()
    const [formChangeStatus] = Form.useForm<UserStatusInfo>()
    const [formChangePay] = Form.useForm<DealerPayInfo>()

    const [isModalOpenChangeEmail, setIsModalOpenChangeEmail] = useState(false)
    const [isModalOpenChangeStatus, setIsModalOpenChangeStatus] = useState(false)
    const [isModalOpenChangePay, setIsModalOpenChangePay] = useState(false)
    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)

    const [deleteItem] = useDeleteUserMutation()
    const [changeEmail, resultsChangeEmail] = useChangeDealerEmailMutation()
    const [resendConfirmationEmail] = useResendDealerEmailConfirmationMutation()
    const [create, resultsCreate] = useCreateDealerMutation()
    const [changeStatus, resultsChangeStatus] = useChangeDealerStatusMutation()
    const [changePay, resultsChangePay] = useChangeDealerPayMutation()
    const [approve] = useApproveDealerMutation()

    const [filter, setFilter] = useState<DealerFilter>({
        page: 1,
        name: '',
        lastname: '',
        email: '',
        status: null
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const lastname = searchParams.get("lastname")
        const email = searchParams.get("email")
        const status = searchParams.get("status") as UserStatus
        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            lastname: lastname ?? '',
            email: email ?? '',
            status: status ?? null,
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetDealersQuery(filter)

    const [filterByText, _, filterBySelect] = useFilterTable<DealerFilter>()

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            key: 'business_name',
            render: (_: void, user: DealerType) => {
                return <Link to={`${routesUrl.dealers}/${user.id}`}>{user.dealerInfo.business_name}</Link>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...filterByText('email')
        },
        {
            title: 'First Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
            ...filterByText('lastname')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...filterBySelect('status', statusFilterOptions, 'id', false)
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, user: DealerType) => (
                <>
                    {role === 'admin' && (
                        <>
                            <Button
                                onClick={() => {
                                    const {id } = user
                                    formChangeStatus.setFieldsValue({ id })
                                    setIsModalOpenChangeStatus(true)
                                }}
                                type="link"
                                size="small">
                                <UserSwitchOutlined />
                            </Button>

                            {
                                (user.status === 'Sign up email confirmation' || user.status === 'Wait approving') && <>
                                    <Button
                                        onClick={() => {
                                            const handleResend = async () => {
                                                const {id} = user
                                                const res = await resendConfirmationEmail({id})
                                                if ('error' in res) return
                                                toast.success('Email Confirmation is successfully delivered')
                                                refetch()
                                            }

                                            showModal('resend email confirmation', handleResend)
                                        }}
                                        type="link"
                                        size="small">
                                        <SendOutlined />
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const {id, email} = user
                                            formChangeEmail.setFieldsValue({id, email})
                                            setIsModalOpenChangeEmail(true)
                                        }}
                                        type="link"
                                        size="small">
                                        <MailOutlined />
                                    </Button>
                                </>
                            }

                            {user.status === 'Wait approving' && (
                                <Button
                                    onClick={() => {
                                        const handleApprove = async () => {
                                            const { id } = user
                                            const loader = toast.loading('Progressing...')
                                            const res = await approve({ id })
                                            toast.dismiss(loader)
                                            if ('error' in res) return
                                            toast.success('Dealer is successfully approved!')
                                            refetch()
                                        }

                                        showModal('approve the dealer', handleApprove)
                                    }}
                                    type="link"
                                    size="small">
                                    <CheckCircleOutlined />
                                </Button>
                            )}

                            {
                                user.status !== 'Deleted' && <Button
                                    onClick={() => {
                                        const handleDelete = async () => {
                                            const { id } = user
                                            const res = await deleteItem({ id })
                                            if ('error' in res) return
                                            toast.success('Dealer is successfully deleted')
                                            refetch()
                                        }

                                        showModal('delete this dealer', handleDelete)
                                    }}
                                    type="link"
                                    size="small"
                                >
                                    <DeleteOutlined />
                                </Button>
                            }
                        </>
                    )}

                    {user.status === 'Active' && (
                        <>
                            {(role === 'admin' || role === 'account manager') && (
                                <Button
                                    onClick={() => {
                                        const { id } = user
                                        navigate(`${routesUrl.licensesDealer}/${id}`)
                                    }}
                                    type="link"
                                    size="small">
                                    <FileProtectOutlined />
                                </Button>
                            )}

                            {role === 'account manager' && (
                                <>
                                    {user.dealerInfo.type_pay !== 0 && (
                                        <Button
                                            onClick={() => {
                                                const { id } = user
                                                navigate(`${routesUrl.apoDealer}/${id}`)
                                            }}
                                            type="link"
                                            size="small">
                                            <ProjectOutlined />
                                        </Button>
                                    )}

                                    <Button
                                        onClick={() => {
                                            const { dealerInfo, id } = user
                                            formChangePay.setFieldsValue({ type_pay: dealerInfo?.type_pay, id })
                                            setIsModalOpenChangePay(true)
                                        }}
                                        type="link"
                                        size="small">
                                        <BankOutlined />
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const { id } = user
                                            navigate(`${routesUrl.invoicesDealer}/${id}`)
                                        }}
                                        type="link"
                                        size="small">
                                        <CreditCardOutlined />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Dealers" documentTitle="Dealers">
            {role === 'admin' && (
                <Space style={{ marginBottom: 20 }}>
                    <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                        <UserAddOutlined /> Create Dealer
                    </Button>
                </Space>
            )}

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title={'Create Dealer'}
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <DealerForm
                    form={formCreate}
                    onFinish={create}
                    onSubmitted={() => {
                        setIsModalOpenCreate(false)
                        refetch()
                        formCreate.resetFields()
                    }}
                    mode="creating"
                />
            </ModalWithForm>

            <UserChangeStatusModal
                form={formChangeStatus}
                changeStatus={changeStatus}
                isModalOpen={isModalOpenChangeStatus}
                setIsModalOpen={setIsModalOpenChangeStatus}
                isLoading={resultsChangeStatus.isLoading}
                refetch={refetch}
            />

            <UserChangeEmailModal
                form={formChangeEmail}
                changeEmail={changeEmail}
                isModalOpen={isModalOpenChangeEmail}
                setIsModalOpen={setIsModalOpenChangeEmail}
                isLoading={resultsChangeEmail.isLoading}
                refetch={refetch}
            />

            <ModalWithForm
                title={'Change Pay type'}
                isOpen={isModalOpenChangePay}
                setIsOpen={setIsModalOpenChangePay}
                isLoading={resultsChangePay.isLoading}
                submit={formChangePay.submit}>
                <Form
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    form={formChangePay}
                    onFinish={async e => {
                        const loader = toast.loading('Progressing...')
                        const res = await changePay(e)
                        toast.dismiss(loader)
                        if ('error' in res) return
                        toast.success('The Pay type is successfully changed!')
                        setIsModalOpenChangePay(false)
                        refetch()
                        formChangePay.resetFields()
                    }}>
                    <Form.Item name="id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} label="Type" name="type_pay">
                        <Select placeholder="Pay type" options={initOptionsPayType} />
                    </Form.Item>
                </Form>
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Dealers

const initOptionsPayType: { value: number; label: string }[] = [
    { value: 0, label: 'Pay for licenses' },
    { value: 1, label: 'Pay for subscription' }
]

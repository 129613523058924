import React, {useContext, useEffect, useState} from 'react'
import {ModalConfirmContext} from '../../../providers/ModalConfirmProvider'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {Button, Space, Tag} from 'antd'
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FolderFilled, PlusOutlined} from '@ant-design/icons'
import toast from 'react-hot-toast'
import PageBuilder from '../../../components/panel/PageBuilder'
import ExtendedTable from '../../../components/ExtendedTable'
import {
    CompanyProductFilter,
    useCloneCompanyProductMutation,
    useDeleteCompanyProductMutation,
    useGetCompanyProductsQuery,
    useGetCompanyProductTagsQuery
} from '../../../api/companyCatalogApi'
import {useFilterTable} from '../../../hooks/useFilterTable'
import {useGetAssetsAllQuery} from '../../../api/assetApi'
import {v4 as uuid} from 'uuid'
import {ProductType} from '../../../providers/CatalogProvider'
import {routesUrl} from '../../../router/urls'

const CompanyProducts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {showModal} = useContext(ModalConfirmContext)
    const navigate = useNavigate()

    const storage = localStorage.getItem('assets-deleting')
    const assetsDeleting: string[] = storage ? JSON.parse(storage) : []

    useEffect(() => {
        localStorage.setItem('assets-deleting', '[]')
    }, [])

    // get tags for filter by tags name
    const {data: tagsData} = useGetCompanyProductTagsQuery(null)
    // get assets for filter bu assets name
    const {data: assetsData} = useGetAssetsAllQuery(null)

    const [filter, setFilter] = useState<CompanyProductFilter>({
        page: 1,
        name: '',
        name_sort: '',
        license_id: '',
        tags: [],
        assets: assetsDeleting,

        date_created_range_start: '',
        date_created_range_end: '',
        date_range_created_sort: '',

        date_updated_range_start: '',
        date_updated_range_end: '',
        date_range_updated_sort: '',
        product_group_id: '',

        created_by: '',
        created_by_sort: ''
    })

    useEffect(() => {
        const license_id = searchParams.get("license_id")
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const name_sort = searchParams.get("name_sort")
        const date_created_range_start = searchParams.get("date_created_range_start")
        const date_created_range_end = searchParams.get("date_created_range_end")
        const date_range_created_sort = searchParams.get("date_range_created_sort")

        const date_updated_range_start = searchParams.get("date_updated_range_start")
        const date_updated_range_end = searchParams.get("date_updated_range_end")
        const date_range_updated_sort = searchParams.get("date_range_updated_sort")

        const created_by = searchParams.get("created_by")
        const created_by_sort = searchParams.get("created_by_sort")
        const product_group_id = searchParams.get("product_group_id")

        const tags = searchParams.get("tags")
        const assets = searchParams.get("assets")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            license_id: license_id ?? '',
            name: name ?? '',
            name_sort: name_sort ?? '',
            product_group_id: product_group_id ?? '',

            date_created_range_start: date_created_range_start ?? '',
            date_created_range_end: date_created_range_end ?? '',
            date_range_created_sort: date_range_created_sort ?? '',

            date_updated_range_start: date_updated_range_start ?? '',
            date_updated_range_end: date_updated_range_end ?? '',
            date_range_updated_sort: date_range_updated_sort ?? '',

            assets: assets ? assets.split(',') : [],
            tags: tags ? tags.split(',') : [],

            created_by: created_by ?? '',
            created_by_sort: created_by_sort ?? ''
        })
    }, [searchParams]);

    const [deleteItems] = useDeleteCompanyProductMutation()
    const [cloneItems] = useCloneCompanyProductMutation()
    const {data, isFetching, refetch} = useGetCompanyProductsQuery(filter)

    const [filterByText, filterByDate, filterBySelect] = useFilterTable<CompanyProductFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            ...filterByText('name', 'name_sort'),
            render: (name: string, product: ProductType) => {
                if (product.type_object === 'license') {
                    return (
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                navigate(`${routesUrl.companyCatalog}?license_id=${product.id}`)
                            }}>
                            <FolderFilled style={{marginRight: '10px'}} className={'table__name-icon'}/>
                            {name}
                        </Button>
                    )
                }

                if (product.type_object === 'lproduct_group') {
                    return (
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                navigate(`${routesUrl.companyCatalog}?product_group_id=${product.id}`)
                            }}>
                            <FolderFilled style={{marginRight: '10px'}} className={'table__name-icon'}/>
                            {name}
                        </Button>
                    )
                }

                return name
            }
        },
        {
            title: 'Date created',
            dataIndex: 'date_created',
            ...filterByDate('date_created_range_start', 'date_created_range_end', 'date_range_created_sort')
        },
        {
            title: 'Date updated',
            dataIndex: 'date_updated',
            ...filterByDate('date_updated_range_start', 'date_updated_range_end', 'date_range_updated_sort')
        },
        {
            title: 'Created by',
            dataIndex: 'created_by',
            ...filterByText('created_by', 'created_by_sort')
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            ...filterBySelect('tags', tagsData!, 'name'),
            render: (tags: string[]) => (
                <Space size={[0, 'small']} wrap>
                    {tags.map(tag => (
                        <Tag key={uuid()} color="processing">
                            {tag}
                        </Tag>
                    ))}
                </Space>
            )
        },
        {
            title: 'Asset',
            dataIndex: 'asset_name',
            ...filterBySelect('assets', assetsData?.assets!),
            render: (asset: string) => asset && <Tag color="magenta">{asset}</Tag>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, product: ProductType) => {
                if (product.type_object === 'product' || product.type_object === 'lproduct_group') {
                    return (
                        <>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    navigate(`${routesUrl.companyCatalogView}/${product.id}`)
                                }}>
                                <EyeOutlined/>
                            </Button>

                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    navigate(`${routesUrl.companyCatalogEdit}/${product.id}`)
                                }}>
                                <EditOutlined/>
                            </Button>

                            <Button
                                onClick={() => {
                                    const handleDuplicate = async () => {
                                        const loader = toast.loading('Progressing...')
                                        const res = await cloneItems([product.id!.toString()])
                                        toast.dismiss(loader)
                                        if ('error' in res) return
                                        toast.success('Product is successfully duplicated!')
                                        refetch()
                                    }

                                    showModal('duplicate product', handleDuplicate)
                                }}
                                type="link"
                                size="small">
                                <CopyOutlined/>
                            </Button>

                            <Button
                                onClick={() => {
                                    const handleDelete = async () => {
                                        const loader = toast.loading('Progressing...')
                                        const res = await deleteItems([product.id!.toString()])
                                        toast.dismiss(loader)
                                        if ('error' in res) return
                                        toast.success('Product is successfully deleted!')
                                        refetch()
                                    }

                                    showModal('delete product', handleDelete)
                                }}
                                type="link"
                                size="small">
                                <DeleteOutlined/>
                            </Button>
                        </>
                    )
                }
                return null
            }
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder breadcrumbs={['catalog', searchParams.get("license_id") ? 'license': ''].filter(item => item)} title="Catalog" documentTitle="Catalog">
            <Space style={{marginBottom: 20}}>
                <Button type="primary" onClick={() => navigate(routesUrl.companyCatalogCreate)}>
                    <PlusOutlined/> Create Product
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isFetching}
                dataKey="products"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />
        </PageBuilder>
    )
}

export default CompanyProducts

import { Button, Form, Input } from 'antd'
import { useLoginMutation } from 'api/authApi'
import { StyledAuthButton, StyledAuthHelperLink } from './styles'
import AuthWrapper from './components/AuthWrapper'
import { useState } from 'react'
import ModalWithForm from '../users/ModalWithForm'
import { useCheckTFAMutation, useSendTFAResetEmailMutation } from 'api/tfaApi'
import { useForm } from 'antd/es/form/Form'
import toast from 'react-hot-toast'
import { routesUrl } from '../../router/urls'

const Login = () => {
    const [formCode] = useForm()
    const [isModalOpenCheck, setIsModalOpenCheck] = useState(false)

    const [login, resultLogin] = useLoginMutation()
    const [checkTFA, resultCheckTFA] = useCheckTFAMutation()
    const [sendEmail] = useSendTFAResetEmailMutation()

    return (
        <AuthWrapper title="Login">
            <Form
                validateMessages={{ required: 'Field is required!' }}
                layout="vertical"
                style={{ display: 'flex', flexDirection: 'column' }}
                onFinish={async e => {
                    const loader = toast.loading('Progressing...')
                    const res = await login(e)
                    toast.dismiss(loader)
                    if ('error' in res) return
                    if (res.data && res.data.tfaConfigured && res.data.tfaRequired) {
                        setIsModalOpenCheck(true)
                    }
                }}>
                <Form.Item name="login" rules={[{ required: true }]}>
                    <Input maxLength={40} placeholder="Email" />
                </Form.Item>

                <StyledAuthHelperLink to={routesUrl.forgotPassword}>Forgot password?</StyledAuthHelperLink>

                <Form.Item name="password" rules={[{ required: true }]}>
                    <Input.Password maxLength={40} placeholder="Password" />
                </Form.Item>

                <StyledAuthButton type="primary" htmlType="submit" loading={resultLogin.isLoading}>
                    Submit
                </StyledAuthButton>
            </Form>

            <ModalWithForm
                title="TFA Code"
                isLoading={resultCheckTFA.isLoading}
                isOpen={isModalOpenCheck}
                setIsOpen={setIsModalOpenCheck}
                submit={formCode.submit}
                footerButton={[
                    <Button
                        onClick={async () => {
                            const loader = toast.loading('Progressing...')
                            const res = await sendEmail(null)
                            toast.dismiss(loader)
                            if ('error' in res) return
                            toast.success('Reset link is successfully sent to Email!')
                            setIsModalOpenCheck(false)
                        }}
                        type="primary">
                        Reset Active 2FA
                    </Button>
                ]}>
                <Form
                    form={formCode}
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    onFinish={async e => {
                        const loader = toast.loading('Progressing...')
                        const res = await checkTFA(e)
                        toast.dismiss(loader)
                        if ('error' in res) return
                    }}>
                    <Form.Item name="code" rules={[{ required: true }]}>
                        <Input showCount maxLength={6} />
                    </Form.Item>
                </Form>
            </ModalWithForm>
        </AuthWrapper>
    )
}

export default Login

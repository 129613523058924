import { TAssetTag } from 'types/TAsset'
import { TableMetaType } from '../components/ExtendedTable'
import { MoveToArg, TAsset } from '../types'
import ApiTags from '../types/ApiTags'
import { SendAssetType } from '../types/Assets'
import { createTagsForList } from '../utils'
import api from './index'

const BASE = '/asset'

type TGetAssetArg = {
    page: string | number
    parentId?: string
    name?: string
    name_sort?: string
    created_at_sort?: string
    created_at?: string
    type?: string[]
    tags?: string[]
}

type TGetAssetsArg = {
    type: (typeof ASSET_TYPE)[keyof typeof ASSET_TYPE]
}

type TDeleteAssetArg = {
    id: string
}

type TDeleteAssetsArg = {
    ids: string[]
}

type TCreateAssetArg = {
    assets: SendAssetType[]
}

type TUpdateAssetArg = {
    id: string
    asset: TAsset
}

type TCloneAssetsArg = {
    ids: string[]
}

type TAddTagToAssetsArg = {
    ids: string[]
    tagsToAdd?: number[]
    tagsToCreate?: string[]
    tagsToRemove?: number[]
}

export type TGetAssetByIdArg = {
    id?: string
}

export type TGetAssetResponse = {
    path?: string
    root: TAsset[]
    _meta: TableMetaType
}

export type AssetsAllType = {
    id: string
    dbId: number
    name: string
}

export type TAssetsTagList = TAssetTag[]

export const ASSET_TYPE = {
    Texture: 'texture',
    Model: 'model',
    Material: 'material',
    Scene: 'scene',
    Folder: 'folder',
    Composite: 'Composite',
    Custom: 'Composite_custom'
} as const

export const ACCEPTED_TYPE_FILE = {
    image: ['image/webp', 'image/jpeg', 'image/png'],
    model: ['glb']
}

export const assetApi = api.injectEndpoints({
    endpoints: build => ({
        getAssets: build.query<AssetsType, TGetAssetsArg | null>({
            query: arg => {
                if (!arg) return '/assets'
                return `/assets?type=${arg.type}`
            }
        }),
        getAsset: build.query({
            queryFn(arg: TGetAssetArg, store, extraOptions, baseQuery) {
                const name = arg.name ? `&name=${arg.name}` : ''
                const type = arg.type?.length !== 0 ? `&type=${arg.type?.join('&type=')}` : ''
                const createdAt = arg.created_at ? `&created_at=${arg.created_at}` : ''
                const nameSort = arg.name_sort ? `&name_sort=${arg.name_sort}` : ''
                const createdAtSort = arg.created_at_sort ? `&created_at_sort=${arg.created_at_sort}` : ''
                const tags = arg.tags ? '&' + arg.tags.map(id => 'tags' + '[' + ']' + '=' + id).join('&') : ''

                return baseQuery({
                    url: `${BASE}?page=${arg.page}&group_id=${arg.parentId}${tags}${type}${createdAt}${name}${nameSort}${createdAtSort}`,
                    method: 'GET'
                })
            },
            providesTags(result) {
                return createTagsForList(result, ApiTags.Asset)
            }
        }),

        getAssetsAll: build.query<{ assets: AssetsAllType[] }, null>({
            query: () => {
                return `/asset/get-asset-select`
            }
        }),

        createAsset: build.mutation({
            queryFn(arg: TCreateAssetArg, store, extraOptions, baseQuery) {
                return baseQuery({
                    url: BASE,
                    method: 'POST',
                    body: {
                        root: arg.assets
                    }
                })
            }
        }),
        deleteAsset: build.mutation({
            queryFn(arg: TDeleteAssetArg, store, extraOptions, baseQuery) {
                return baseQuery({
                    url: `${BASE}?ids=${arg.id}`,
                    method: 'DELETE'
                })
            }
        }),
        createAssetsProducts: build.mutation<null, { type_product: number; asset_id: number[] }>({
            query: body => {
                return {
                    url: `${BASE}/product`,
                    method: 'POST',
                    body
                }
            }
        }),
        deleteAssets: build.mutation({
            queryFn(arg: TDeleteAssetsArg, store, extraOptions, baseQuery) {
                const ids = arg.ids.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return baseQuery({
                    url: `${BASE}?${ids}`,
                    method: 'DELETE'
                })
            }
        }),
        updateAsset: build.mutation({
            queryFn(arg: TUpdateAssetArg, store, extraOptions, baseQuery) {
                return baseQuery({
                    url: `${BASE}?id=${arg.id}`,
                    method: 'PUT',
                    body: {
                        ...arg.asset
                    }
                })
            }
        }),
        getAssetById: build.query({
            queryFn(arg: TGetAssetByIdArg, store, extraOptions, baseQuery) {
                if (!arg.id) {
                    return Promise.reject('Id is required')
                }
                return baseQuery({
                    url: `get-asset?id=${arg.id}`,
                    method: 'GET'
                })
            }
        }),
        moveAssets: build.mutation({
            queryFn(arg: MoveToArg, store, extraOptions, baseQuery) {
                const params = {
                    id: arg.parentId
                }

                const body = {
                    assets: arg.assets
                }

                return baseQuery({
                    url: `${BASE}/move-to`,
                    method: 'PUT',
                    params: params,
                    body: body
                })
            },
            invalidatesTags(result, error, arg) {
                if (error) {
                    return []
                }
                return [
                    ...arg.assets.map(a => ({
                        type: ApiTags.Asset,
                        id: a.id
                    })),
                    {
                        type: ApiTags.Asset,
                        id: arg.parentId
                    }
                ]
            }
        }),
        cloneAssets: build.mutation({
            queryFn(arg: TCloneAssetsArg, store, extraOptions, baseQuery) {
                const ids = arg.ids.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return baseQuery({
                    url: `${BASE}/clone?${ids}`,
                    method: 'PUT'
                })
            }
        }),
        addTagsToAssets: build.mutation({
            queryFn(arg: TAddTagToAssetsArg, store, extraOptions, baseQuery) {
                return baseQuery({
                    url: `${BASE}/add-tag`,
                    method: 'PUT',
                    body: {
                        assets: arg.ids,
                        tags_add: arg.tagsToAdd || [],
                        tags_new: arg.tagsToCreate || [],
                        tags_remove: arg.tagsToRemove || []
                    }
                })
            }
        }),
        getAssetsTags: build.query<TAssetsTagList, void>({
            query: () => ({
                url: `asset-tags`,
                method: 'GET'
            })
        }),
        checkDeleteAssets: build.mutation<any, any>({
            query: body => {
                const ids = body.map((id: any) => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `/asset/delete-check?${ids}`,
                    method: 'DELETE',
                    body
                }
            }
        }),

        getAssetAttributes: build.query<AssetAttributeType[], { id: number | string | null }>({
            query: body => ({
                url: `asset-attribute?asset_id=${body.id}`,
                method: 'GET'
            })
        }),
        createAssetAttribute: build.mutation<null, AssetAttributeType>({
            query: body => {
                return {
                    url: `asset-attribute`,
                    method: 'POST',
                    body
                }
            }
        }),
        cloneAssetAttribute: build.mutation<null, { id: number }>({
            query: body => {
                return {
                    url: `asset-attribute/clone?attr_id=${body.id}`,
                    method: 'GET'
                }
            }
        }),
        updateAssetAttribute: build.mutation<null, { id: number; attribute: AssetAttributeType }>({
            query: body => {
                return {
                    url: `asset-attribute?id=${body.id}`,
                    method: 'PUT',
                    body: body.attribute
                }
            }
        }),
        deleteAssetAttributes: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-attribute?${ids}`,
                    method: 'DELETE'
                }
            }
        }),
        deleteAssetAttributeConnection: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-attribute-connection?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getAssetAnnotations: build.query<AssetAnnotationType[], { id: number }>({
            query: body => ({
                url: `asset-annotation?asset_id=${body.id}`,
                method: 'GET'
            })
        }),
        createAssetAnnotation: build.mutation<null, AssetAnnotationType>({
            query: body => {
                const { asset_id, name, value, position, patch } = body

                return {
                    url: `asset-annotation`,
                    method: 'POST',
                    body: { asset_id, name, value, position, patch }
                }
            }
        }),
        updateAssetAnnotation: build.mutation<null, AssetAnnotationType>({
            query: body => {
                const { id, asset_id, name, value, position, patch } = body

                return {
                    url: `asset-annotation?id=${id}`,
                    method: 'PUT',
                    body: { asset_id, name, value, position, patch }
                }
            }
        }),
        deleteAssetAnnotations: build.mutation<null, string[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-annotation?${ids}`,
                    method: 'DELETE'
                }
            }
        }),

        getAssetRules: build.query<AssetRuleType[], { id: number }>({
            query: body => ({
                url: `asset-rule?asset_id=${body.id}`,
                method: 'GET'
            })
        }),
        updateAssetRule: build.mutation<null, AssetRuleType>({
            query: body => {
                const { id } = body
                return {
                    url: `asset-rule?id=${id}`,
                    method: 'PUT',
                    body
                }
            }
        }),
        cloneAssetRule: build.mutation<null, { id: number }>({
            query: body => {
                return {
                    url: `asset-rule/clone?rule_id=${body.id}`,
                    method: 'GET'
                }
            }
        }),
        createAssetRule: build.mutation<null, AssetRuleType>({
            query: body => {
                return {
                    url: `asset-rule`,
                    method: 'POST',
                    body
                }
            }
        }),
        deleteAssetRules: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-rule?${ids}`,
                    method: 'DELETE'
                }
            }
        }),
        deleteAssetRuleConditions: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-rule/condition?${ids}`,
                    method: 'DELETE'
                }
            }
        }),
        deleteAssetRuleActions: build.mutation<null, number[]>({
            query: body => {
                const ids = body.map(id => 'ids' + '[' + ']' + '=' + id).join('&')

                return {
                    url: `asset-rule/action?${ids}`,
                    method: 'DELETE'
                }
            }
        })
    }),
    overrideExisting: false
})

export const {
    useGetAssetsAllQuery,
    useGetAssetQuery,
    useCreateAssetMutation,
    useDeleteAssetMutation,
    useDeleteAssetsMutation,
    useUpdateAssetMutation,
    useGetAssetByIdQuery,
    useMoveAssetsMutation,
    useGetAssetsQuery,
    useCloneAssetsMutation,
    useAddTagsToAssetsMutation,
    useGetAssetsTagsQuery,
    useCheckDeleteAssetsMutation,

    useGetAssetAttributesQuery,
    useCreateAssetAttributeMutation,
    useUpdateAssetAttributeMutation,
    useDeleteAssetAttributesMutation,
    useDeleteAssetAttributeConnectionMutation,
    useCloneAssetAttributeMutation,

    useGetAssetAnnotationsQuery,
    useDeleteAssetAnnotationsMutation,
    useCreateAssetAnnotationMutation,
    useUpdateAssetAnnotationMutation,
    useCreateAssetsProductsMutation,

    useGetAssetRulesQuery,
    useDeleteAssetRulesMutation,
    useCreateAssetRuleMutation,
    useUpdateAssetRuleMutation,
    useDeleteAssetRuleActionsMutation,
    useDeleteAssetRuleConditionsMutation,
    useCloneAssetRuleMutation
} = assetApi

export default assetApi

export type AssetRuleType = {
    id?: number
    asset_id: number
    name: string
    status?: 'changed'
    sorting?: 0
    condition: AssetRuleConditionType[]
    action: AssetRuleActionType[]
}

export type AssetRuleConditionType = {
    id?: number
    value: string
    appId?: string
    type_condition: number | 0
    connection_id: number | 0
    connection_type: number | 0
}

export type AssetRuleActionType = {
    id?: number
    appId?: string
    type_action: number
    value: string
    sorting: number
}

export type AssetAnnotationType = {
    id: number | string
    asset_id: number
    name: string
    position: string
    patch: any
    value: string
    type?: 'new'
}

export type AssetAttributeType = {
    id?: number
    asset_id: number
    name: string
    type: string
    status?: 'changed'
    value: string | string[]
    sorting?: 0
    connections?: any
}

export type AssetType = {
    id: string
    dbId: number
    name: string
    type?: string
    preview?: string
    patch: string
    url: string
    url_original?: string
}

export type AssetsType = AssetType[]

import PageBuilder from '../../../components/panel/PageBuilder'
import { Button, Space, Spin } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { useDeleteDealerProductMutation, useGetDealerProductMutation } from '../../../api/dealerCatalogApi'
import { useGetDealerByIdQuery } from '../../../api/dealerApi'
import { useAppSelector } from '../../../hooks'
import ShareProductCode from '../components/share/ShareProductCode'
import ShareProductSelect, { ShareProductType } from '../components/share/ShareProductSelect'
import toast from 'react-hot-toast'
import { routesUrl } from '../../../router/urls'

const DealerProductView = () => {
    const { id } = useParams()
    const { id: dealerId } = useAppSelector(state => state.user)
    const navigate = useNavigate()

    const [shareType, setShareType] = useState<ShareProductType>(null)

    const { showModal } = useContext(ModalConfirmContext)

    const [getProduct, resultsProduct] = useGetDealerProductMutation()
    const [deleteItems, resultsDelete] = useDeleteDealerProductMutation()
    const { data: dealer, isSuccess } = useGetDealerByIdQuery({ id: dealerId })

    useEffect(() => {
        if (resultsDelete.isSuccess) navigate(routesUrl.dealerCatalog)
    }, [resultsDelete.isSuccess])

    useEffect(() => {
        getProduct({ id: id! })
    }, [])

    if (resultsProduct.isLoading) {
        return <Spin size="large" />
    }

    return (
        <PageBuilder breadcrumbs={['products', `view: ${id}`]} title={`Catalog Product View: ${id}`} documentTitle={`Catalog Product View: ${id}`}>
            <Space style={{ marginBottom: 20 }}>
                <Button
                    onClick={() => {
                        navigate(`${routesUrl.dealerCatalogEdit}/${id}`)
                    }}
                    type="primary">
                    <EditOutlined /> Edit Product
                </Button>

                <Button
                    onClick={() => {
                        const handleDelete = async () => {
                            const loader = toast.loading('Progressing...')
                            const res = await deleteItems([id!])
                            toast.dismiss(loader)
                            if ('error' in res) return
                            toast.success('Product is successfully deleted!')
                            navigate(routesUrl.dealerCatalog)
                        }

                        showModal('delete product', handleDelete)
                    }}
                    type="primary">
                    <DeleteOutlined /> Delete Product
                </Button>

                {isSuccess && resultsProduct.isSuccess && resultsProduct.data.license_id && (
                    <ShareProductSelect shareType={shareType} setShareType={setShareType} />
                )}
            </Space>

            {shareType && isSuccess && resultsProduct.isSuccess && resultsProduct.data.license_id && (
                <ShareProductCode
                    token={dealer.dealerInfo.player_access_token}
                    share={shareType}
                    type="model"
                    productName={resultsProduct.data.name}
                    licenseId={resultsProduct.data.license_id.toString()}
                />
            )}
        </PageBuilder>
    )
}

export default DealerProductView

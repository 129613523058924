import {
    AMAGeometry,
    AMAMaterialConfig,
    AMAMaterialType,
    AMAMeshType,
    AMAUpdatedLights,
    DEFAULT_PHYSICAL_MATERIAL_PROPS,
    DEFAULT_REFLECTIVE_MATERIAL_PROPS,
    DEFAULT_REFRACTION_MATERIAL_PROPS,
    HistoryController,
    isCamera,
    isCustomShapes,
    isLight,
    isMeshAMA,
    isSpecificObject,
    SetValueCommand,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import * as THREE from 'three'
import { TAssetEditorProp } from '../../assetEditorProps'

const meshActions = (key: string, value: TAssetEditorProp['value'], editor3D: ReturnType<typeof utilsEditor>) => {
    if (!editor3D.activeMesh) return
    const keyProp = key.split('_').slice(-1)[0]
    const keyValue = key.split('_')[0]
    const History = new HistoryController()

    if (keyProp === 'prop') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (!current) return
        // History.execute(new SetValueCommand(editor3D, current, value, [keyValue]))
        editor3D.updateObjectData(current, {
            [keyValue]: value
        })
    }
    if (keyProp === 'camera') {
        const camera = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (!isCamera(camera)) return

        switch (keyValue) {
            //TODO Igor
            case 'focalLength':
            case 'bokehScale':
            case 'focusDistance':
                History.execute(new SetValueCommand(editor3D, camera, value, ['DOFParameters', keyValue]))

                break

            case 'dof':
                History.execute(new SetValueCommand(editor3D, camera, value, ['DOFEnabled']))
                break

            default:
                editor3D.updateObjectData(editor3D.activeMesh, {
                    [keyValue]: {
                        ...camera?.target,
                        object: value.object
                    }
                })
        }
    }
    if (keyProp === 'ar') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (!current) return
        History.execute(new SetValueCommand(editor3D, current, value, ['userData', keyValue]))
    }
    if (keyProp === 'bloom') {
        editor3D.updateObjectData(editor3D.activeMesh, {
            selectByBloom: value,
            userData: {
                ...editor3D.activeMesh.userData,
                [keyValue]: value
            }
        })
    }

    if (keyProp === 'mat') {
        if ('isMesh' in editor3D.activeMesh) {
            const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
            if (current) {
                if (!isSpecificObject(current, AMAMeshType.Mesh) && !isCustomShapes(current)) return
                const keyMaterial = keyValue as keyof AMAMaterialConfig['properties']
                const material = editor3D.activeMesh.material as any
                const defaultValue =
                    material[keyMaterial] instanceof THREE.Color
                        ? material[keyMaterial].getHex()
                        : material[keyMaterial]

                const oldValue = (current.updatedMaterial && current.updatedMaterial[keyMaterial]) || defaultValue

                History.execute(
                    new SetValueCommand(editor3D, current, value, ['updatedMaterial', keyMaterial], oldValue)
                )
            }
        }
    }
    if (keyProp === 'geom') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (current) {
            if (!isCustomShapes(current)) return
            if (!isMeshAMA(editor3D.activeMesh)) return
            const keyGeom = keyValue as keyof AMAGeometry
            const geom = editor3D.activeMesh.geometry as AMAGeometry
            const updatedGeom = current.updatedGeometry as AMAGeometry
            const defaultValue = geom[keyGeom]

            const oldValue = (current.updatedGeometry && updatedGeom[keyGeom]) || defaultValue

            History.execute(new SetValueCommand(editor3D, current, +value, ['updatedGeometry', keyValue], oldValue))
        }
    }

    if (keyProp === 'parent') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (!current) return
        History.execute(new SetValueCommand(editor3D, current, value.object, ['userData', keyProp]))
    }

    if (keyProp === 'light') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)

        if (!isLight(current)) return
        const keyLight = keyValue as keyof AMAUpdatedLights
        const light = editor3D.activeMesh.children[0] as any
        const defaultValue = light[keyLight] instanceof THREE.Color ? light[keyLight].getHex() : light[keyLight]

        const oldValue = (current.updatedLight && current.updatedLight[keyLight]) || defaultValue

        History.execute(new SetValueCommand(editor3D, current, value, ['updatedLight', keyValue], oldValue))
    }

    if (keyProp === 'lightShadow') {
        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        const newValue = value?.object ? value.object : value
        if (!isLight(current)) return

        History.execute(new SetValueCommand(editor3D, current, newValue, ['shadow', keyValue]))
    }

    if (keyValue === 'material') {
        const obj = editor3D.getCurrentConfigObj(editor3D.activeMesh)
        if (!isCustomShapes(obj) && !isSpecificObject(obj, AMAMeshType.Mesh)) return []
        const material = editor3D.getCurrent().sceneMaterials.find(i => i.id === obj.material?.id)

        if (keyProp === 'type') {
            let props: any = null

            switch (value.object) {
                case AMAMaterialType.MeshReflectorMaterial:
                    props = { ...DEFAULT_REFLECTIVE_MATERIAL_PROPS, ...material?.properties }
                    break
                case AMAMaterialType.MeshRefractionMaterial:
                    props = { ...DEFAULT_REFRACTION_MATERIAL_PROPS, ...material?.properties }
                    break
                case AMAMaterialType.MeshPhysicalMaterial:
                    props = { ...DEFAULT_PHYSICAL_MATERIAL_PROPS, ...material?.properties }
                    break
                default:
                    props = material?.properties
                    break
            }
            editor3D.updateMaterialProps(obj, value.object, props)
            // editor3D.updateObjectData(editor3D.activeMesh, {
            //     updatedMaterial: {
            //         ...props,
            //         type: value.object
            //     }
            // })
            return
        }

        if (material?.type === AMAMaterialType.MeshReflectorMaterial) {
            const nextState: Record<string, any> = { ...material.properties }

            switch (keyProp) {
                case 'mirror':
                    nextState[keyProp] = value ? 1 : 0
                    break

                case 'blurX':
                    nextState.blur = [value, nextState.blur[1]]
                    break

                case 'blurY':
                    nextState.blur = [nextState.blur[0], value]
                    break

                case 'resolution':
                    nextState[keyProp] = +value.object
                    break

                default:
                    nextState[keyProp] = value
                    break
            }
            editor3D.updateMaterialProps(obj, material?.type, nextState)
            // History.execute(new SetValueCommand(editor3D, obj, nextState, ['material']))
            return
        }
        const nextState: Record<string, any> = { ...obj.updatedMaterial }
        nextState[keyProp] = value
        editor3D.updateMaterialProps(obj, material?.type, nextState)
        // History.execute(new SetValueCommand(editor3D, obj, nextState, ['material']))
    }
}

export { meshActions }

import { Button, Tooltip } from 'antd'
import {
    AvailableLicenseFilter,
    AvailableLicenseType,
    useCreateActivatedLicenseMutation,
    useDeleteAvailableLicenseMutation,
    useGetAvailableLicensesQuery
} from '../../../api/licenseApi'
import React, {useContext, useEffect, useState} from 'react'
import { DeleteOutlined, FileProtectOutlined } from '@ant-design/icons'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { useAppSelector } from '../../../hooks'
import ExtendedTable from '../../../components/ExtendedTable'
import toast from 'react-hot-toast'
import {useSearchParams} from "react-router-dom";

const AvailableLicensesTable = ({ dealerId }: { dealerId: string }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { role } = useAppSelector(state => state.user)
    const { showModal } = useContext(ModalConfirmContext)

    const [deleteItem] = useDeleteAvailableLicenseMutation()
    const [create] = useCreateActivatedLicenseMutation()

    const [filter, setFilter] = useState<AvailableLicenseFilter>({
        id: dealerId,
        page: 1
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const page = parseInt(pageParam ?? '1')

        setFilter(prevState => ({
            ...prevState,
            page: Number.isNaN(page) ? 1 : page,
        }))
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetAvailableLicensesQuery(filter)

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, license: AvailableLicenseType) => (
                <>
                    {role === 'dealer' && (
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                const handleCreate = async () => {
                                    const { id: license_id } = license
                                    const loader = toast.loading('Progressing...')
                                    const res = await create({ license_id })
                                    toast.dismiss(loader)
                                    if ('error' in res) return
                                    if (res?.data?.url) {
                                        window.open(res.data.url, '_blank')
                                    } else {
                                        toast.success('License is successfully activated')
                                        refetch()
                                    }
                                }

                                showModal('active this license', handleCreate)
                            }}>
                            <FileProtectOutlined />
                        </Button>
                    )}

                    {role === 'admin' && <>
                        {
                            license.is_show_all === 0 ? (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        const handleDelete = async () => {
                                            const { id: license_id } = license
                                            const loader = toast.loading('Progressing...')
                                            const res = await deleteItem({ license_id: license_id, dealer_id: dealerId })
                                            toast.dismiss(loader)
                                            if ('error' in res) return
                                            toast.success('License is successfully removed')
                                            refetch()
                                        }

                                        showModal('delete this license for this dealer', handleDelete)
                                    }}>
                                    <DeleteOutlined />
                                </Button>
                            ) : (
                                <Tooltip
                                    title="Licenses that apply to all dealers cannot be deleted from individual dealers. Edit License Access Options on Selected Dealers"
                                    color="red">
                                    <Button disabled={true} type="link" size="small">
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            )
                        }
                    </>}
                </>
            )
        }
    ]

    return (
        <ExtendedTable
            data={data}
            isLoading={isLoading}
            dataKey="license"
            columns={columns}
            onChange={page => setSearchParams(`page=${page}`)}
        />
    )
}

export default AvailableLicensesTable

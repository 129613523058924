import {Spin} from "antd";
import {useUpdatePaymentSessionMutation} from "../../api/paymentApi";
import {useEffect} from "react";
import toast from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import {routesUrl} from "../../router/urls";

const LicenseActivation = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const type = searchParams.get('type')
    const status = searchParams.get('status')

    const [updateSession] = useUpdatePaymentSessionMutation()

    useEffect(() => {
        if (type && status) {
            if (status === '2') {
                toast.error('Something went wrong, try to activate license again!')
            }

            const timer = setTimeout(async () => {
                await updateSession({type, status})

                if (status === '1') {
                    toast.success('License is successfully activated!')
                }

                navigate(routesUrl.licensesActivated)
            }, 5000)

            return () => clearTimeout(timer)
        } else {
            navigate(routesUrl.licensesActivated)
        }
    }, []);

    return (
        <Spin size="large" />
    );
};

export default LicenseActivation;

import { CopyOutlined, DeleteOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Divider, Space, Typography } from 'antd'
import assetApi, {
    ASSET_TYPE,
    useDeleteAssetMutation,
    useGetAssetByIdQuery,
    useUpdateAssetMutation
} from 'api/assetApi'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import { Editor2DComponent as Editor2D } from '@amaspace-editor/editor-2d/dist/editor-2d.esm'
import { EditorConstructor } from '@amaspace-editor/editor-3d'
import { useAppDispatch } from 'hooks'
import { BreadcrumbsType } from '../../../components/panel/Breadcrumbs'
import PageBuilder from '../../../components/panel/PageBuilder'
import { routesUrl } from '../../../router/urls'
import { TAsset } from '../../../types'
import { getBreadcrumbsByPath } from '../../../utils'
import { extractEditorConfig } from '../editor/assetEditor2D'

const AssetViewer: React.FC<any> = () => {
    const extRegexp = new RegExp(/\.[^/\\.]+$/)

    const { '*': splat } = useParams()
    const list = splat?.split('/')
    const id = list?.pop()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    if (!id) {
        navigate(routesUrl.assets)
    }

    const [asset, setAsset] = useState<TAsset | null>(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const [updateAssetRequest] = useUpdateAssetMutation()
    const [deleteAssetRequest] = useDeleteAssetMutation()
    const fetchAssetByIdResponse = useGetAssetByIdQuery({ id: id as string }, { refetchOnMountOrArgChange: true })
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>(['assets'])

    const [editorAPI2D, setEditorAPI2D] = useState(null)

    useEffect(() => {
        const { data } = fetchAssetByIdResponse

        if (!data) {
            return
        }
        const { assets, path } = data as { assets: TAsset[]; path: string }

        const targetAsset = assets[0]

        if (path) {
            const [paths, folderPaths] = getBreadcrumbsByPath(path, splat as string)

            setBreadcrumbs(() => {
                const newState = paths.map((path, index) => {
                    return { id: `assets${folderPaths[index]}`, name: path }
                })

                return ['assets', ...newState]
            })
        } else {
            setBreadcrumbs(['assets'])
        }

        if (targetAsset) {
            setAsset(targetAsset)
        }
    }, [fetchAssetByIdResponse])

    const updateAssetName = (newName: string) => {
        toast('Updating asset name...')

        const updatedAssetData = {
            ...(asset as TAsset),
            name: newName
        }

        updateAssetRequest({
            id: asset!.id,
            asset: updatedAssetData
        })
            .then((response: any) => {
                if (response.error) {
                    toast.error(`Could not update asset name. Reason: ${response.error.data.message}`)
                } else {
                    fetchAssetByIdResponse.refetch()
                    setAsset(updatedAssetData)
                    toast.success('Asset name successfully updated!')
                }
            })
            .catch(() => {
                toast.error('Could not update asset name')
            })
    }

    const launchEditor = () => {
        navigate(`${routesUrl.assetsEdit}/${splat}`)
    }

    const cloneAsset = () => {}

    const deleteAsset = () => {
        setIsDeleting(true)

        deleteAssetRequest({ id: asset!.id })
            .then((e: any) => {
                setIsDeleting(false)

                if (e.error) {
                    toast.error(`Could not delete asset. Reason: ${e.error.data.message}`)
                } else {
                    navigate(routesUrl.assets)
                }
            })
            .catch(e => {
                console.error(e)

                toast.error('Could not delete asset')

                setIsDeleting(false)
            })
    }

    const render2DEditor = () => {
        const EDITOR_CONFIG = extractEditorConfig(asset)
        const loadData = EDITOR_CONFIG
            ? EDITOR_CONFIG
            : {
                  src: asset!.url,
                  name: asset!.name
              }
        const loadType = EDITOR_CONFIG ? 'JSON' : 'IMAGE_LINK'

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '65vh',
                    overflow: 'hidden'
                }}>
                <Editor2D
                    config={{
                        loadType,
                        data: loadData,
                        mode: 'VIEW_MODE',
                        applyViewBoundsToCanvas: true
                    }}
                    setEditorAPI={(API: any) => {
                        setEditorAPI2D(API)
                    }}
                />
            </div>
        )
    }
    const fetchCb = async (id: string | number) => {
        if (typeof id === 'number') id = id.toString()
        const { data } = await dispatch(assetApi.endpoints.getAssetById.initiate({ id: id }))
        return structuredClone(data)
    }
    const render3DEditor = () => {
        if (!asset) return

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 350px)',
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '600px',
                        height: '100%',
                        width: '100%'
                    }}>
                    <EditorConstructor
                        type={asset!.type as 'model' | 'material'}
                        assetId={new String(asset.id) as string}
                        // fetchCb={fetchCb}
                        customRequests={{
                            fetchDataCb: fetchCb
                        }}
                        // data={data}
                        presetConfig={{
                            startAnimation: false,
                            applyProductRules: false,
                            asynchronousPackages: false,
                            customDomain: 'localhost',
                            isProd: true
                        }}
                    />
                </div>
            </div>
        )
    }
    const is2D = asset?.type === ASSET_TYPE.Texture
    const is3D =
        asset?.type === ASSET_TYPE.Scene || asset?.type === ASSET_TYPE.Model || asset?.type === ASSET_TYPE.Material

    const assetName = asset ? asset.name : ''

    return (
        <PageBuilder breadcrumbs={breadcrumbs} documentTitle="Asset View">
            {!asset ? (
                'loading...'
            ) : (
                <>
                    <Typography.Title
                        level={1}
                        editable={{
                            onChange: newName => {
                                const assetExt = asset.name.match(extRegexp)?.pop() || ''
                                updateAssetName(`${newName}${assetExt}`)
                            }
                        }}>
                        {asset.name.replace(extRegexp, '')}
                    </Typography.Title>
                    <Space size={[16, 0]}>
                        <Button type={'primary'} onClick={launchEditor} icon={<SmileOutlined />}>
                            Launch editor
                        </Button>
                        <Button type={'primary'} disabled onClick={cloneAsset} icon={<CopyOutlined />}>
                            Clone asset
                        </Button>
                        <Button type={'primary'} loading={isDeleting} onClick={deleteAsset} icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Space>
                    <Divider></Divider>

                    {is2D ? render2DEditor() : null}
                    {is3D ? render3DEditor() : null}
                </>
            )}
        </PageBuilder>
    )
}

export default AssetViewer

import { Form, Spin } from 'antd'
import { useContext } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { CatalogContext } from '../../../../providers/CatalogProvider'

const ProductDescriptionItem = () => {
    const { product, isLoadingProduct, setProduct } = useContext(CatalogContext)

    return (
        <Form.Item label="Description">
            {isLoadingProduct ? (
                <Spin size="small" />
            ) : (
                <ReactQuill
                    id={'quill'}
                    onChange={description => {
                        setProduct({ ...product, description })
                    }}
                    placeholder="Description"
                    value={product.description}
                />
            )}
        </Form.Item>
    )
}

export default ProductDescriptionItem

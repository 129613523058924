import { ReactNode } from 'react'
import CatalogProvider, { CatalogRequestsContextType, ProductType } from './CatalogProvider'
import {
    useCloneCompanyRuleMutation,
    useCreateCompanyProductAttributeMutation,
    useCreateCompanyProductMutation,
    useCreateCompanyProductRuleMutation,
    useDeleteCompanyProductAttributesMutation,
    useDeleteCompanyProductRulesMutation,
    useGetCompanyProductAttributesMutation,
    useGetCompanyProductCategoriesMutation,
    useGetCompanyProductKeywordsQuery,
    useGetCompanyProductListMutation,
    useGetCompanyProductMutation,
    useGetCompanyProductRulesMutation,
    useGetCompanyProductTagsQuery,
    useUpdateCompanyProductAttributeMutation,
    useUpdateCompanyProductMutation,
    useUpdateCompanyProductRuleMutation
} from '../api/companyCatalogApi'
import { useFetchByPagination } from '../hooks'

const CatalogCompanyProvider = ({ children }: { children: ReactNode }) => {
    const [getProduct] = useGetCompanyProductMutation()
    const [updateProduct] = useUpdateCompanyProductMutation()
    const [createProduct] = useCreateCompanyProductMutation()

    const {
        data: productKeywords,
        refetch: fetchProductKeywords,
        isLoading: isLoadingProductKeywords
    } = useGetCompanyProductKeywordsQuery(null)
    const {
        data: productTags,
        refetch: fetchProductTags,
        isLoading: isLoadingProductTags
    } = useGetCompanyProductTagsQuery(null)

    const [getCategories] = useGetCompanyProductCategoriesMutation()
    const [getAttributes] = useGetCompanyProductAttributesMutation()
    const [createAttributes] = useCreateCompanyProductAttributeMutation()
    const [updateAttributes] = useUpdateCompanyProductAttributeMutation()
    const [deleteAttributes] = useDeleteCompanyProductAttributesMutation()

    const [getRules] = useGetCompanyProductRulesMutation()
    const [updateRules] = useUpdateCompanyProductRuleMutation()
    const [createRules] = useCreateCompanyProductRuleMutation()
    const [deleteRules] = useDeleteCompanyProductRulesMutation()
    const [cloneRule] = useCloneCompanyRuleMutation()

    const [getProductsByTags] = useGetCompanyProductListMutation()
    const [products] = useFetchByPagination<ProductType>(useGetCompanyProductListMutation)

    const fetchInfoItems = () => {
        fetchProductTags()
        fetchProductKeywords()
    }

    const value: CatalogRequestsContextType = {
        getProduct,
        createProduct,
        updateProduct,
        cloneRule,

        getCategories,
        getAttributes,
        createAttributes,
        updateAttributes,
        deleteAttributes,

        getRules,
        createRules,
        updateRules,
        deleteRules,

        products,

        getProductsByTags,

        productKeywords: productKeywords ?? [],
        isLoadingProductKeywords,

        productTags: productTags ?? [],
        isLoadingProductTags,

        fetchInfoItems
    }

    return <CatalogProvider requests={value}>{children}</CatalogProvider>
}

export default CatalogCompanyProvider

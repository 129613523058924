import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Space, Typography } from 'antd'
import { capitalizeFirstLetter } from '../../../../../utils'
import LogicAttributeAsset from './LogicAttributeAsset'
import LogicAttributeBoolean from './LogicAttributeBoolean'
import LogicAttributeNumber from './LogicAttributeNumber'
import LogicAttributeString from './LogicAttributeString'
import { AssetAttributeType, useCreateAssetAttributeMutation } from 'api/assetApi'
import { AssetsForAttributes } from '../../assetEditor'

const { Title } = Typography

type LogicAttributesEditorProps = {
    assetId: number
    assets: AssetsForAttributes
    isOpen: string
    setIsOpen: Dispatch<SetStateAction<string | null>>
}

const LogicAttributesCreator = ({ assetId, isOpen, assets, setIsOpen }: LogicAttributesEditorProps) => {
    const [attribute, setAttribute] = useState<AssetAttributeType>({
        name: '',
        asset_id: assetId,
        type: isOpen,
        value: '',
        sorting: 0
    })

    const [createAttribute, result] = useCreateAssetAttributeMutation()
    const { isLoading } = result

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setAttribute(prevState => ({ ...prevState, name: e.target.value }))
    }

    const handleChangeValue = (value: string) => {
        setAttribute(prevState => ({ ...prevState, value: value }))
    }

    const handleChangeStringValue = (value: string[]) => {
        setAttribute(prevState => ({ ...prevState, value }))
    }

    const handleChangeAssetValue = (value: string) => {
        setAttribute(prevState => ({ ...prevState, value }))
    }

    return (
        <>
            <Title level={4}>Create Attribute</Title>

            {(isOpen === '5' || isOpen === '6' || isOpen === '7') && (
                <LogicAttributeAsset
                    attribute={attribute}
                    assets={assets}
                    changeName={handleChangeName}
                    changeValue={handleChangeAssetValue}
                    setAttribute={setAttribute}
                />
            )}
            {isOpen === '2' && (
                <LogicAttributeBoolean
                    attribute={attribute}
                    changeName={handleChangeName}
                    changeValue={handleChangeValue}
                />
            )}
            {isOpen === '3' && (
                <LogicAttributeNumber
                    attribute={attribute}
                    changeName={handleChangeName}
                    changeValue={handleChangeValue}
                />
            )}
            {isOpen === '4' && (
                <LogicAttributeString
                    attribute={attribute}
                    changeName={handleChangeName}
                    changeValue={handleChangeStringValue}
                />
            )}

            <Space>
                <Button
                    onClick={() => {
                        setIsOpen(null)
                    }}>
                    Cancel
                </Button>

                <Button
                    loading={isLoading}
                    onClick={async () => {
                        await createAttribute(attribute)
                        setIsOpen(null)
                    }}
                    type="primary">
                    Create Attribute
                </Button>
            </Space>
        </>
    )
}

export default LogicAttributesCreator

import React from 'react'
import { AMAMode } from '@amaspace-editor/editor-3d'

import { TAsset } from '../../../../types'
import { ASSET_TYPE } from '../../../../api/assetApi'
import AssetEditor3D from './assetEditor3D'
import AssetEditorMaterial from './assetEditorMaterial'
import { AssetsForAttributes } from '../assetEditor'

interface IAssetConstructor3DProps {
    asset?: TAsset
    allAssets: AssetsForAttributes
}
const AssetConstructor3D = ({ asset, allAssets }: IAssetConstructor3DProps) => {
    const renderEditor = () => {
        if (!asset) return <></>

        switch (asset.type) {
            case ASSET_TYPE.Material:
                return <AssetEditorMaterial asset={asset} />
            case ASSET_TYPE.Model:
                return (
                    <AssetEditor3D
                        leftMenu="nodes"
                        rightMenu="properties"
                        asset={asset}
                        allAssets={allAssets}
                        mode={AMAMode.EDITOR}
                    />
                )
            case ASSET_TYPE.Scene:
                return (
                    <AssetEditor3D
                        leftMenu="nodes"
                        rightMenu="properties"
                        asset={asset}
                        allAssets={allAssets}
                        mode={AMAMode.EDITOR}
                    />
                )
            default:
                return <></>
        }
    }

    return renderEditor()
}

export default AssetConstructor3D

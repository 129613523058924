import { ProductAttributeType } from '../../tabs/ProductAttributes'
import React, { useContext } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { Select } from 'antd'

type ProductAttributeParentIdItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeParentIdItem = ({ attr }: ProductAttributeParentIdItemProps) => {
    const { attributes, setAttributes, categories } = useContext(CatalogContext)

    const handleChangeParentId: HandleChangeAttributeParentId = value => {
        setAttributes(
            attributes.map(item => ({
                ...item,
                parent_id: item.id === attr.id ? value : item.parent_id,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        )
    }

    return (
        <Select
            placeholder="Category"
            showSearch
            allowClear
            optionFilterProp="children"
            value={attr.parent_id}
            onChange={handleChangeParentId}>
            <Select.OptGroup key={'parent'} label={'Parent Categories'}>
                {categories
                    .filter(item => !item.parent_id && !item.newItem)
                    .map(item => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select.OptGroup>

            <Select.OptGroup key={'child'} label={'Sub Categories'}>
                {categories
                    .filter(item => item.parent_id && !item.newItem)
                    .map(item => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select.OptGroup>
        </Select>
    )
}

export default ProductAttributeParentIdItem

type HandleChangeAttributeParentId = (value: number) => void

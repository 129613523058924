import { useNavigate } from 'react-router-dom'
import { StyledAuthAlert, StyledAuthButton } from '../styles'
import { v4 as uuid } from 'uuid'

type AuthSuccessAlertProps = {
    link?: string
    messages: string[]
}

const AuthSuccessAlert = ({ link, messages }: AuthSuccessAlertProps) => {
    const navigate = useNavigate()

    const description = (
        <>
            {messages.map(msg => (
                <p key={uuid()}>{msg}</p>
            ))}
        </>
    )

    return (
        <>
            <StyledAuthAlert message="Success" type="success" showIcon description={description} />

            {link && (
                <StyledAuthButton onClick={() => navigate(link)} type="primary">
                    Continue
                </StyledAuthButton>
            )}
        </>
    )
}

export default AuthSuccessAlert

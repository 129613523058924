import { createSlice } from '@reduxjs/toolkit'
import { ModelConnections } from '../pages/assets/editor/editor3D/renderAttachAsset'
import { RootState } from '../redux/store'

interface IModelState {
    newConnections: Record<string, ModelConnections[]>
    deleteConnections: Record<string, ModelConnections[]>
    newMaterialConnections: ModelConnections[]
    deleteMaterialConnections: ModelConnections[]
}

const initialState: IModelState = {
    newConnections: {},
    deleteConnections: {},
    newMaterialConnections: [],
    deleteMaterialConnections: []
}

const modelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        changeNewConnections: (state, action: { payload: IModelState['newConnections'] }) => {
            state.newConnections = action.payload
        },
        changeDeleteConnections: (state, action: { payload: IModelState['deleteConnections'] }) => {
            state.deleteConnections = action.payload
        },
        changeNewMaterialConnections: (state, action: { payload: IModelState['newMaterialConnections'] }) => {
            state.newMaterialConnections = action.payload
        },
        changeDeleteMaterialConnections: (state, action: { payload: IModelState['deleteMaterialConnections'] }) => {
            state.deleteMaterialConnections = action.payload
        }
    }
})

export const {
    changeNewConnections,
    changeDeleteConnections,
    changeDeleteMaterialConnections,
    changeNewMaterialConnections
} = modelSlice.actions

export const modelNewConnections = (state: RootState): IModelState['newConnections'] => state.model.newConnections

export const modelDeleteConnections = (state: RootState): IModelState['deleteConnections'] =>
    state.model.deleteConnections
export const materialNewConnections = (state: RootState): IModelState['newMaterialConnections'] =>
    state.model.newMaterialConnections
export const materialDeleteConnections = (state: RootState): IModelState['deleteMaterialConnections'] =>
    state.model.deleteMaterialConnections
export default modelSlice

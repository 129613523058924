import React, { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { LicensePriceInfoType } from '../../../api/licenseApi'
import { useGetCurrencyQuery } from '../../../api/priceApi'

type LicensePriceFormProps = {
    form: FormInstance<LicensePriceInfoType>
    onFinish: (e: LicensePriceInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const LicensePriceForm = ({ form, refetch, setIsModalOpen, onFinish }: LicensePriceFormProps) => {
    const { data: currencies } = useGetCurrencyQuery(null)

    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="license_id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} placeholder="99.99" step="0.01" min={1} stringMode />
            </Form.Item>

            <Form.Item rules={[{ required: true }]} label="Currency" name="currency_id">
                <Select showSearch allowClear optionFilterProp="children" placeholder="Currency">
                    {currencies
                        ? currencies.map(currency => {
                              return (
                                  <Select.Option key={currency.id} value={currency.id}>
                                      {currency.name}
                                  </Select.Option>
                              )
                          })
                        : null}
                </Select>
            </Form.Item>
        </Form>
    )
}

export default LicensePriceForm

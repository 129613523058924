import styled from 'styled-components'
import { Layout } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { Content } from 'antd/es/layout/layout'

export const StyledPanel = styled(Layout)`
    min-height: 100vh;
    flex-direction: row;
`

export const StyledPanelWrapper = styled(Layout)`
    min-height: 100vh;
    flex-direction: row;
`

export const StyledLogoAmaspace = styled.div`
    font-size: 30px;
    line-height: 32px;
    margin: 16px 0;
    font-family: 'Satoshi', 'sans-serif';
    padding-left: 24px;
    font-weight: 700;
    color: #fff;
`

export const StyledLogoWebDisruptors = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    height: 64px;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    margin: 0 0 16px;
    padding: 8px 24px;
    color: #090909;
`

export const StyledPanelHeader = styled(Header)`
    background-color: #ffffff;
`

export const StyledPanelContent = styled(Content)`
    background-color: #ffffff;
    min-height: 280px;
    margin: 24px 16px;
    padding: 24px;
`

export const StyledPanelLoader = styled.div`
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

import { Navigate, Route, Routes } from 'react-router-dom'
import ProtectedRoute, {rolePermissions} from './ProtectedRoute'
import { useAppSelector } from '../hooks'
import { routesUrl } from './urls'
import LicenseActivation from "../pages/license/LicenseActivation";
import AssetsList from "../pages/assets/AssetsList/AssetsList";
import AssetViewer from "../pages/assets/viewer/assetViewer";
import AssetEditor from "../pages/assets/editor/assetEditor";
import CreateAsset from "../pages/assets/createAsset";
import Industries from "../pages/business/industry/Industries";
import Brands from "../pages/business/brand/Brands";
import DealerDomains from "../pages/business/dealerDomain/DealerDomains";
import Companies from "../pages/business/company/Companies";
import StyleGuides from "../pages/business/styleGuide/StyleGuides";
import Dealers from "../pages/users/dealer/Dealers";
import Dealer from "../pages/users/dealer/Dealer";
import Admins from "../pages/users/admin/Admins";
import AccountManagers from "../pages/users/accountManager/AccountManagers";
import Account from "../pages/account/Account";
import SetupTFA from "../pages/account/SetupTFA";
import DealerAccount from "../pages/account/DealerAccount";
import DealerInvoices from "../pages/invoice/DealerInvoices";
import DealerAnalytics from "../pages/analytics/DealerAnalytics";
import DealerPayment from "../pages/payment/DealerPayment";
import CompanyPayment from "../pages/payment/CompanyPayment";
import DealerProducts from "../pages/products/dealer/DealerProducts";
import DealerLicensesProducts from "../pages/products/dealer/DealerLicensesProducts";
import DealerProductsArchived from "../pages/products/dealer/DealerProductsArchived";
import DealerProductView from "../pages/products/dealer/DealerProductView";
import DealerProductCreate from "../pages/products/dealer/DealerProductCreate";
import DealerProductEdit from "../pages/products/dealer/DealerProductEdit";
import ApoDealer from "../pages/apo/dealer/ApoDealer";
import ApoCompany from "../pages/apo/company/ApoCompany";
import CompanyInvoices from "../pages/invoice/CompanyInvoices";
import Licenses from "../pages/license/Licenses";
import DealerLicenses from "../pages/license/DealerLicenses";
import ActivatedLicenses from "../pages/license/activated/ActivatedLicenses";
import LicensesBilling from "../pages/license/billing/LicensesBilling";
import AvailableLicenses from "../pages/license/available/AvailableLicenses";
import LicensePrices from "../pages/license/price/LicensePrices";
import CompanyAnalytics from "../pages/analytics/CompanyAnalytics";
import CompanyProducts from 'pages/products/company/CompanyProducts';
import CompanyProductCreate from "../pages/products/company/CompanyProductCreate";
import CompanyProductView from "../pages/products/company/CompanyProductView";
import CompanyProductEdit from "../pages/products/company/CompanyProductEdit";
import NotFoundPage from "../pages/NotFoundPage";

const PanelRouter = () => {
    const user = useAppSelector(state => state.user)
    const role = user.role === 'admin' && user.is_only_company ? 'company_admin' : user.role

    return (
        <Routes>
            <Route path="" element={<Navigate to={rolePermissions[role][0]} />} />
            <Route
                path={routesUrl.licenseActivation}
                element={<ProtectedRoute element={<LicenseActivation/>} path={routesUrl.licenseActivation}/>}
            />

            <Route
                path={`${routesUrl.assets}/*`}
                element={<ProtectedRoute element={<AssetsList />} path={routesUrl.assets} />}
            />
            <Route
                path={`${routesUrl.assetsView}/*`}
                element={<ProtectedRoute element={<AssetViewer />} path={routesUrl.assetsView} />}
            />
            <Route
                path={`${routesUrl.assetsEdit}/*`}
                element={<ProtectedRoute element={<AssetEditor />} path={routesUrl.assetsEdit} />}
            />
            <Route
                path={`${routesUrl.assetsCreate}/*`}
                element={<ProtectedRoute element={<CreateAsset />} path={routesUrl.assetsCreate} />}
            />

            <Route
                path={routesUrl.industries}
                element={<ProtectedRoute element={<Industries />} path={routesUrl.industries} />}
            />
            <Route
                path={routesUrl.brands}
                element={<ProtectedRoute element={<Brands />} path={routesUrl.brands} />}
            />
            <Route
                path={routesUrl.domains}
                element={<ProtectedRoute element={<DealerDomains />} path={routesUrl.domains} />}
            />
            <Route
                path={routesUrl.companies}
                element={<ProtectedRoute element={<Companies />} path={routesUrl.companies} />}
            />
            <Route
                path={routesUrl.styleGuides}
                element={<ProtectedRoute element={<StyleGuides />} path={routesUrl.styleGuides} />}
            />

            <Route
                path={routesUrl.dealers}
                element={<ProtectedRoute element={<Dealers />} path={routesUrl.dealers} />}
            />
            <Route
                path={`${routesUrl.dealers}/:id`}
                element={<ProtectedRoute element={<Dealer />} path={routesUrl.dealers} />}
            />
            <Route
                path={routesUrl.admins}
                element={<ProtectedRoute element={<Admins />} path={routesUrl.admins} />}
            />
            <Route
                path={routesUrl.accountManagers}
                element={<ProtectedRoute element={<AccountManagers />} path={routesUrl.accountManagers} />}
            />
            {/*<Route path={routesUrl.saleManagers} element={<ProtectedRoute element={<SaleManagersLazy/>} path={routesUrl.saleManagers}/>}/>*/}

            <Route
                path={routesUrl.account}
                element={<ProtectedRoute element={<Account />} path={routesUrl.account} />}
            />
            <Route path={routesUrl.tfa} element={<ProtectedRoute element={<SetupTFA />} path={routesUrl.tfa} />} />

            <Route
                path={routesUrl.dealerAccount}
                element={<ProtectedRoute element={<DealerAccount />} path={routesUrl.dealerAccount} />}
            />
            <Route
                path={routesUrl.dealerInvoices}
                element={<ProtectedRoute element={<DealerInvoices />} path={routesUrl.dealerInvoices} />}
            />
            <Route
                path={routesUrl.dealerAnalytics}
                element={<ProtectedRoute element={<DealerAnalytics />} path={routesUrl.dealerAnalytics} />}
            />
            <Route path={`${routesUrl.payment}`} element={
                user.role === 'dealer'
                    ? <ProtectedRoute element={<DealerPayment />} path={routesUrl.dealerPayment} />
                    : <ProtectedRoute element={<CompanyPayment />} path={routesUrl.companyPayment} />
            }/>
            <Route
                path={routesUrl.dealerCatalog}
                element={<ProtectedRoute element={<DealerProducts />} path={routesUrl.dealerCatalog} />}
            />
            <Route
                path={routesUrl.dealerCatalogProducts}
                element={
                    <ProtectedRoute element={<DealerLicensesProducts />} path={routesUrl.dealerCatalogProducts} />
                }
            />
            <Route
                path={routesUrl.dealerCatalogArchive}
                element={
                    <ProtectedRoute element={<DealerProductsArchived />} path={routesUrl.dealerCatalogArchive} />
                }
            />
            <Route
                path={`${routesUrl.dealerCatalogView}/:id`}
                element={<ProtectedRoute element={<DealerProductView />} path={routesUrl.dealerCatalogView} />}
            />
            <Route
                path={routesUrl.dealerCatalogCreate}
                element={<ProtectedRoute element={<DealerProductCreate />} path={routesUrl.dealerCatalogCreate} />}
            />
            <Route
                path={`${routesUrl.dealerCatalogEdit}/:id`}
                element={<ProtectedRoute element={<DealerProductEdit />} path={routesUrl.dealerCatalogEdit} />}
            />

            <Route
                path={`${routesUrl.apoDealer}/:id`}
                element={<ProtectedRoute element={<ApoDealer />} path={routesUrl.apoDealer} />}
            />
            <Route
                path={`${routesUrl.apoCompany}/:id`}
                element={<ProtectedRoute element={<ApoCompany />} path={routesUrl.apoCompany} />}
            />

            <Route
                path={`${routesUrl.invoicesDealer}/:id`}
                element={<ProtectedRoute element={<DealerInvoices />} path={routesUrl.invoicesDealer} />}
            />
            <Route
                path={`${routesUrl.invoicesCompany}/:id`}
                element={<ProtectedRoute element={<CompanyInvoices />} path={routesUrl.invoicesCompany} />}
            />

            <Route
                path={routesUrl.licenses}
                element={<ProtectedRoute element={<Licenses />} path={routesUrl.licenses} />}
            />
            <Route
                path={`${routesUrl.licensesDealer}/:id`}
                element={<ProtectedRoute element={<DealerLicenses />} path={routesUrl.licensesDealer} />}
            />
            <Route
                path={routesUrl.licensesActivated}
                element={<ProtectedRoute element={<ActivatedLicenses />} path={routesUrl.licensesActivated} />}
            />
            <Route
                path={routesUrl.licensesBilling}
                element={<ProtectedRoute element={<LicensesBilling />} path={routesUrl.licensesBilling} />}
            />
            <Route
                path={routesUrl.licensesAvailable}
                element={<ProtectedRoute element={<AvailableLicenses />} path={routesUrl.licensesAvailable} />}
            />
            <Route
                path={`${routesUrl.licensesPrice}/:id`}
                element={<ProtectedRoute element={<LicensePrices />} path={routesUrl.licensesPrice} />}
            />

            <Route
                path={routesUrl.companyInvoices}
                element={<ProtectedRoute element={<CompanyInvoices />} path={routesUrl.companyInvoices} />}
            />
            <Route
                path={routesUrl.companyAnalytics}
                element={<ProtectedRoute element={<CompanyAnalytics />} path={routesUrl.companyAnalytics} />}
            />
            <Route
                path={routesUrl.companyCatalog}
                element={<ProtectedRoute element={<CompanyProducts />} path={routesUrl.companyCatalog} />}
            />
            <Route
                path={routesUrl.companyCatalogCreate}
                element={
                    <ProtectedRoute element={<CompanyProductCreate />} path={routesUrl.companyCatalogCreate} />
                }
            />
            <Route
                path={`${routesUrl.companyCatalogView}/:id`}
                element={<ProtectedRoute element={<CompanyProductView />} path={routesUrl.companyCatalogView} />}
            />
            <Route
                path={`${routesUrl.companyCatalogEdit}/:id`}
                element={<ProtectedRoute element={<CompanyProductEdit />} path={routesUrl.companyCatalogEdit} />}
            />

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default PanelRouter

import {useAppSelector} from '../hooks'
import {UserRole} from '../api/userApi'
import {Outlet} from 'react-router-dom'
import {routesUrl, RoutesUrlType} from './urls'
import React from "react";
import NotFoundPage from "../pages/NotFoundPage";

type ProtectedRouteProps = {
    element: React.JSX.Element
    path: RoutesUrlType
}

const ProtectedRoute = ({element, path}: ProtectedRouteProps) => {
    const user = useAppSelector(state => state.user)
    const Element = element ? element : <Outlet/>
    const role = user.role === 'admin' && user.is_only_company ? 'company_admin' : user.role
    return path && rolePermissions[role].includes(path) ? Element : <NotFoundPage/>
}

export default ProtectedRoute

export const rolePermissions: { [key in UserRole]: RoutesUrlType[] } = {
    'super admin': [
        routesUrl.companies,
        routesUrl.industries,
        routesUrl.brands,
        routesUrl.styleGuides,

        routesUrl.admins,
        routesUrl.accountManagers,

        routesUrl.account,
        routesUrl.tfa
    ],

    admin: [
        routesUrl.assets,
        routesUrl.assetsView,
        routesUrl.assetsEdit,
        routesUrl.assetsCreate,

        routesUrl.dealers,

        routesUrl.licenses,
        routesUrl.licensesDealer,

        routesUrl.companyInvoices,
        routesUrl.companyCatalog,
        routesUrl.companyAnalytics,
        routesUrl.companyPayment,
        routesUrl.companyCatalogView,
        routesUrl.companyCatalogCreate,
        routesUrl.companyCatalogEdit,

        routesUrl.account,
        routesUrl.tfa,
    ],

    'company_admin': [
        routesUrl.assets,
        routesUrl.assetsView,
        routesUrl.assetsEdit,
        routesUrl.assetsCreate,

        routesUrl.domains,

        routesUrl.companyInvoices,
        routesUrl.companyCatalog,
        routesUrl.companyAnalytics,
        routesUrl.companyPayment,
        routesUrl.companyCatalogView,
        routesUrl.companyCatalogCreate,
        routesUrl.companyCatalogEdit,

        routesUrl.account,
        routesUrl.tfa,
    ],

    'account manager': [
        routesUrl.licenses,
        routesUrl.licensesPrice,
        routesUrl.licensesDealer,

        routesUrl.dealers,
        routesUrl.companies,

        routesUrl.invoicesDealer,
        routesUrl.invoicesCompany,

        routesUrl.apoCompany,
        routesUrl.apoDealer,

        routesUrl.account,
        routesUrl.tfa
    ],

    dealer: [
        routesUrl.licenseActivation,
        routesUrl.licensesActivated,
        routesUrl.licensesAvailable,
        routesUrl.licensesBilling,
        routesUrl.saleManagers,
        routesUrl.domains,

        routesUrl.dealerInvoices,
        routesUrl.dealerAnalytics,
        routesUrl.dealerPayment,
        routesUrl.dealerCatalog,
        routesUrl.dealerCatalogArchive,
        routesUrl.dealerCatalogView,
        routesUrl.dealerCatalogCreate,
        routesUrl.dealerCatalogEdit,

        routesUrl.dealerCatalogProducts,
        routesUrl.dealerAccount,

        routesUrl.tfa
    ],

    'sale member': [routesUrl.account, routesUrl.tfa]
}

const theme = {
    light: {
        background: '#e3eef5b8'
    },
    dark: {
        background: '#000000b8'
    }
}

export default theme

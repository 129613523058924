import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Button, Dropdown, Form, Input, List, Space, Spin, Table, Typography} from 'antd'
import RulesConditionItem from './RulesConditionItem'
import {
    AssetAnnotationType,
    AssetAttributeType,
    AssetRuleActionType,
    AssetRuleConditionType,
    AssetRuleType,
    useCreateAssetRuleMutation,
    useGetAssetAnnotationsQuery,
    useGetAssetAttributesQuery
} from '../../../../../api/assetApi'
import {DownOutlined, DragOutlined} from '@ant-design/icons'
import { actionRuleItems } from './LogicRules'
import { v4 as uuid } from 'uuid'
import { ActionTypeId, LogicEditorTypeId } from '../LogicEditor'
import RulesActionItem from './RulesActionItem'
import { TAsset } from '../../../../../types'
import { utilsEditor } from '@amaspace-editor/editor-3d'
import { AssetsForAttributes } from '../../assetEditor'
import ReactDragListView from "react-drag-listview";
import {onDragEndAttributes} from "../../../../../utils";

const { Title } = Typography
const { Item } = List

type LogicRulesCreatorProps = {
    assetId: number
    modelPart: TAsset[]
    assets: AssetsForAttributes
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const LogicRulesCreator = ({ setIsOpen, assetId, assets, modelPart }: LogicRulesCreatorProps) => {
    const editor3D = utilsEditor()

    const [rule, setRule] = useState<AssetRuleType>({
        name: '',
        asset_id: assetId,
        action: [],
        condition: []
    })

    const { data: assetAttributes, isLoading: isLoadingAttributes } = useGetAssetAttributesQuery({ id: assetId })
    const [createRule, result] = useCreateAssetRuleMutation()
    const { isLoading } = result

    const { data: annotations, isLoading: isLoadingAnnotations } = useGetAssetAnnotationsQuery({ id: assetId })

    const handleCreateRule = async () => {
        await createRule(rule)
        setIsOpen(false)

        if (editor3D.config.rules) {
            editor3D.createRuleConfig(rule)
        }
    }
    console.log('@rule', rule)
    const handleAddCondition = () => {
        const newCondition: AssetRuleConditionType = {
            appId: uuid(),
            value: '',
            type_condition: 0,
            connection_id: 0,
            connection_type: 0
        }

        setRule(prevState => ({
            ...prevState,
            condition: [...prevState.condition, newCondition]
        }))
    }

    const handleDeleteCondition = (item: AssetRuleConditionType) => {
        if (item.appId) {
            const filtered = rule.condition.filter(data => data.appId !== item.appId)
            setRule(prevState => ({ ...prevState, condition: filtered }))
        } else {
            const filtered = rule.condition.filter(data => data.id !== item.id)
            setRule(prevState => ({ ...prevState, condition: filtered }))
        }
    }

    const handleDeleteAction = (item: AssetRuleActionType) => {
        const filtered = rule.action.filter(data => data.appId !== item.appId)
        setRule(prevState => ({ ...prevState, action: filtered }))
    }

    const handleChangeAction = (item: AssetRuleActionType, value: string): void => {
        if (item.appId) {
            const updatedActions = rule.action.map(data => {
                if (data.appId === item.appId) {
                    return { ...data, value }
                }
                return data
            })
            setRule(prevState => ({ ...prevState, action: updatedActions }))
        } else {
            const updatedActions = rule.action.map(data => {
                if (data.id === item.id) {
                    return { ...data, value }
                }
                return data
            })
            setRule(prevState => ({ ...prevState, action: updatedActions }))
        }
    }

    const handleAddAction = (type: any) => {
        const type_action = LogicEditorTypeId[type.key as keyof typeof ActionTypeId]

        let sorting = 1
        rule.action.forEach(item => {
            if (item.sorting > sorting) {
                sorting = item.sorting;
            }
        });

        const newAction: AssetRuleActionType = {
            appId: uuid(),
            value: '',
            type_action,
            sorting,
        }

        setRule(prevState => ({
            ...prevState,
            action: [...prevState.action, newAction]
        }))
    }

    function getKeyByValue(object: { [key: string]: string | number }, value: string | number) {
        return Object.keys(object).find(key => object[key] === value)
    }

    return (
        <>
            <Title level={4}>Create Rule</Title>

            {isLoadingAnnotations || isLoadingAttributes ? (
                <Spin size="large" />
            ) : (
                <>
                    <div className="rules-actions" style={{ marginBottom: 16 }}>
                        <Form.Item>
                            <Input
                                maxLength={50}
                                value={rule.name}
                                onChange={e => {
                                    setRule(prevState => ({ ...prevState, name: e.target.value }))
                                }}
                                placeholder="Name"
                            />
                        </Form.Item>

                        <Form.Item>
                            <List
                                className="logic__list logic__list_action"
                                itemLayout="vertical"
                                size="large"
                                dataSource={rule.condition}
                                renderItem={item => (
                                    <Item key={item.appId}>
                                        <p>{getKeyByValue(LogicEditorTypeId, item.type_condition)}</p>
                                        <RulesConditionItem
                                            item={item}
                                            setRule={setRule}
                                            modelPart={modelPart}
                                            deleteCondition={handleDeleteCondition}
                                            annotations={annotations as AssetAnnotationType[]}
                                        />
                                    </Item>
                                )}
                                header={
                                    <Button onClick={handleAddCondition} style={{ width: '100%' }}>
                                        <Space>
                                            Add Condition <DownOutlined />
                                        </Space>
                                    </Button>
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            <ReactDragListView
                                nodeSelector=".rules-actions .ant-list-item"
                                handleSelector=".rules-actions .attr-dragdrop"
                                lineClassName="dragLine"
                                onDragEnd={(fromIndex, toIndex) => {
                                    setRule(prevState => {
                                        const action = onDragEndAttributes<AssetRuleActionType>(fromIndex, toIndex, prevState.action)
                                        return {...prevState, action}
                                    })
                                }
                                }>
                                <List
                                    className="logic__list logic__list_action"
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={rule.action}
                                    renderItem={item => (
                                        <Item key={item.appId || item.id}>
                                            <Space>
                                                <div className="attr-dragdrop">
                                                    <DragOutlined/>
                                                </div>
                                                <div>{getKeyByValue(LogicEditorTypeId, item.type_action)}</div>
                                            </Space>
                                            <RulesActionItem
                                                item={item}
                                                assets={assets}
                                                attributes={assetAttributes as AssetAttributeType[]}
                                                modelPart={modelPart}
                                                changeAction={handleChangeAction}
                                                deleteAction={handleDeleteAction}
                                            />
                                        </Item>
                                    )}
                                    header={
                                        <Dropdown menu={{ items: actionRuleItems, onClick: handleAddAction }}>
                                            <Button style={{ width: '100%' }}>
                                                <Space>
                                                    Add Action <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    }
                                />
                            </ReactDragListView>
                        </Form.Item>
                    </div>

                    <Space>
                        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button loading={isLoading} onClick={handleCreateRule} type="primary">
                            Create Rule
                        </Button>
                    </Space>
                </>
            )}
        </>
    )
}

export default LogicRulesCreator

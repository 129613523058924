import { Button, Space } from 'antd'
import * as React from 'react'
import { TAsset } from 'types'

export type TBulkAction = {
    disabled?: boolean
    label: string
    onclick: () => void
}

export type TBulkActions = {
    selection: TAsset[]
    actions: TBulkAction[]
}

const BulkActions: React.FC<TBulkActions> = ({ selection, actions }) => {
    if (!actions.length || !selection.length) {
        return null
    }

    return (
        <div>
            <Space size={[8, 0]}>
                {actions.map(({ disabled, label, onclick }) => {
                    return (
                        <Button
                            type={'primary'}
                            key={label}
                            disabled={disabled}
                            onClick={() => {
                                onclick()
                            }}
                            style={{
                                minWidth: 80
                            }}>
                            {label}
                        </Button>
                    )
                })}
            </Space>
        </div>
    )
}

export default BulkActions

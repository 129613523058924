import { Dropdown, Col } from 'antd'
import { useState, useCallback, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'
import * as THREE from 'three'
import _ from 'underscore'
import { ActionType } from '../assetEditorProps'

export const RenderColorPicker = ({ data, onChange }: ActionType) => {
    const [pickerShown, setPickerShown] = useState(false)
    const [color, setColor] = useState(data.value)

    let newValue = data.value as string

    if (data.value instanceof THREE.Color) {
        newValue = data.value.getStyle()
    }
    const throttledChangeHandler = useCallback(
        _.debounce((value: string) => onChange(data.key, value), 300),
        [data.value, data.key, onChange]
    )

    const changeHandler = useCallback(
        (value: string) => {
            setColor(value)
            throttledChangeHandler(value)
        },
        [throttledChangeHandler]
    )

    useEffect(() => {
        setColor(data.value)
    }, [data.value])

    const items = [
        {
            key: 'color',
            label: (
                <ChromePicker
                    color={color || ''}
                    onChange={color => {
                        const {
                            rgb: { r, g, b, a }
                        } = color
                        changeHandler(`rgba(${r}, ${g}, ${b}, ${a})`)
                    }}
                />
            )
        }
    ]
    return (
        <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <Dropdown
                open={pickerShown}
                menu={{
                    items,
                    onMouseLeave: () => setPickerShown(false)
                }}>
                <ColorIcon backgroundColor={newValue} onClick={() => setPickerShown(!pickerShown)} />
            </Dropdown>
        </div>
    )
}

const DefaultIcon = styled.div`
    width: 60px;
    height: 30px;
    border: 1px solid #000;
`

const ColorIcon = styled(DefaultIcon)<{ backgroundColor: string }>`
    background: ${props => `${props?.backgroundColor} !important`};
`

import {
    AMAMaterialType,
    isCustomShapes,
    isMeshAMA,
    isSpecificObject,
    isStandardMaterial,
    MaterialProps,
    utilsEditor
} from '@amaspace-editor/editor-3d'
import * as THREE from 'three'
import { isLight } from '../../../../../utils'
import { TAssetEditorProp } from '../../assetEditorProps'

const currentMaterialMesh = (editor3D: ReturnType<typeof utilsEditor>): TAssetEditorProp[] => {
    if (!editor3D.activeMesh) return [] as TAssetEditorProp[]

    if (isMeshAMA(editor3D.activeMesh)) {
        if (isLight(editor3D.activeMesh.children[0])) return [] as TAssetEditorProp[]
        if (editor3D.activeMesh.children[0] instanceof THREE.PerspectiveCamera) return [] as TAssetEditorProp[]

        const current = editor3D.getCurrentConfigObj(editor3D.activeMesh)

        if (!isCustomShapes(current) && !isSpecificObject(current, 'Mesh')) return []
        const currentMaterial = editor3D.getCurrent().sceneMaterials.find(i => i.id === current?.material?.id)

        if (currentMaterial) {
            return [
                { type: 'attach', label: 'Material', value: currentMaterial, key: 'map' },
                {
                    type: 'select',
                    label: 'Type',
                    key: 'material_type',
                    value: {
                        options: Object.keys(AMAMaterialType).map(el => ({
                            key: el,
                            value: el,
                            label: el
                        })),
                        value: currentMaterial?.type
                    }
                },
                ...(MaterialProps.getProperties(currentMaterial, true) as any)
            ]
        } else {
            let value = null
            if (isStandardMaterial(editor3D.activeMesh.material)) value = editor3D.activeMesh.material.userData

            return [{ type: 'attach', label: 'Material', value: value, key: 'map' }]
        }
    }
    return [] as TAssetEditorProp[]
}
export { currentMaterialMesh }

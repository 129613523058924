import { Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import PageBuilder from '../../../components/panel/PageBuilder'
import {
    DealerProductFilter,
    useGetDealerArchivedProductsQuery,
    useGetDealerProductTagsQuery
} from '../../../api/dealerCatalogApi'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import {useSearchParams} from "react-router-dom";

const DealerProductsArchived = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: productTags } = useGetDealerProductTagsQuery(null)

    const [filter, setFilter] = useState<DealerProductFilter>({
        page: 1,
        name: '',
        name_sort: '',
        tags: [],

        date_created_range_start: '',
        date_created_range_end: '',
        date_range_created_sort: '',

        date_updated_range_start: '',
        date_updated_range_end: '',
        date_range_updated_sort: ''
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const name_sort = searchParams.get("name_sort")
        const date_created_range_start = searchParams.get("date_created_range_start")
        const date_created_range_end = searchParams.get("date_created_range_end")
        const date_range_created_sort = searchParams.get("date_range_created_sort")

        const date_updated_range_start = searchParams.get("date_updated_range_start")
        const date_updated_range_end = searchParams.get("date_updated_range_end")
        const date_range_updated_sort = searchParams.get("date_range_updated_sort")

        const tags = searchParams.get("tags")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            name_sort: name_sort ?? '',

            date_created_range_start: date_created_range_start ?? '',
            date_created_range_end: date_created_range_end ?? '',
            date_range_created_sort: date_range_created_sort ?? '',

            date_updated_range_start: date_updated_range_start ?? '',
            date_updated_range_end: date_updated_range_end ?? '',
            date_range_updated_sort: date_range_updated_sort ?? '',

            tags: tags ? tags.split(',') : [],
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetDealerArchivedProductsQuery(filter)

    const [filterByText, filterByData, filterBySelect] = useFilterTable<DealerProductFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name', 'name_sort')
        },
        {
            title: 'Date created',
            dataIndex: 'date_created',
            ...filterByData('date_created_range_start', 'date_created_range_end', 'date_range_created_sort')
        },
        {
            title: 'Date updated',
            dataIndex: 'date_updated',
            ...filterByData('date_updated_range_start', 'date_updated_range_end', 'date_range_updated_sort')
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            ...filterBySelect('tags', productTags!, 'name'),
            render: (tags: string[]) => (
                <Space size={[0, 'small']} wrap>
                    {tags.map(tag => (
                        <Tag key={tag} color="processing">
                            {tag}
                        </Tag>
                    ))}
                </Space>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder breadcrumbs={['products', 'archive']} title="Archived Products" documentTitle="Archived Products">
            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="products"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />
        </PageBuilder>
    )
}

export default DealerProductsArchived

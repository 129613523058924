import { AMAMode, AMAModelConfig, EditorConstructor, utilsEditor } from '@amaspace-editor/editor-3d'
import { Spin, Tabs, TabsProps } from 'antd'
import assetApi, { AssetsType, ASSET_TYPE, useGetAssetByIdQuery, useGetAssetsQuery } from 'api/assetApi'
import PageBuilder from 'components/panel/PageBuilder'
import { useAppDispatch } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BreadcrumbsType } from '../../../components/panel/Breadcrumbs'
import { pxToEm } from '../../../theme/stylesMixins'
import { TAsset } from '../../../types'
import { getBreadcrumbsByPath } from '../../../utils'
import AssetAnnotations from './AssetAnnotations'
import AssetLayout from './AssetLayout'
import AssetLogic from './AssetLogic'
import AssetEditorMaterial from './editor3D/assetEditorMaterial'
import EditorTopPanel from './EditorTopPanel'

const AssetEditor: React.FC = () => {
    const { '*': splat } = useParams()
    const list = splat?.split('/')
    const id = list?.pop()

    const [asset, setAsset] = useState<TAsset | null>(null)
    const [modelPart, setModelPart] = useState<TAsset[]>([])
    const [modelData, setModelData] = useState<AMAModelConfig | undefined>(undefined)
    const [assets, setAssets] = useState<AssetsForAttributes>({
        models: [],
        textures: [],
        materials: [],
        allMaterials: [],
        allModels: []
    })
    const is3D = asset?.type === ASSET_TYPE.Scene || asset?.type === ASSET_TYPE.Model
    const isMaterial = asset?.type === ASSET_TYPE.Material
    const editor3D = utilsEditor()
    const dispatch = useAppDispatch()

    const fetchAssetByIdResponse = useGetAssetByIdQuery({ id: id as string }, { refetchOnMountOrArgChange: true })
    const { data: assetsMaterial, isSuccess: isSuccessAssets } = useGetAssetsQuery({ type: 'material' })
    const { data: assetsModel, isSuccess: isSuccessModels } = useGetAssetsQuery({ type: 'model' })
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>(['assets'])

    useEffect(() => {
        if (isSuccessAssets) {
            const allMaterials = assetsMaterial ? assetsMaterial : []
            setAssets({ ...assets, allMaterials })
        }
    }, [isSuccessAssets])

    useEffect(() => {
        if (isSuccessModels) {
            const allModels = assetsModel ? assetsModel : []
            setAssets({ ...assets, allModels })
        }
    }, [isSuccessModels])

    useEffect(() => {
        const { data } = fetchAssetByIdResponse

        if (!data && !assetsMaterial) return

        setModelData(structuredClone(data) as AMAModelConfig)
        const { assets: assetsData, path } = data as { assets: TAsset[]; path: string }

        const modelPart = assetsData.filter(asset => asset.type === 'Composite')
        setModelPart(modelPart)

        const models = assetsData.filter(asset => asset.type === 'model')
        const textures = assetsData.filter(asset => asset.type === 'texture')
        const materials = assetsData.filter(asset => asset.type === 'material')

        setAssets({ ...assets, models, materials, textures })

        const targetAsset = assetsData[0]

        if (path) {
            const [paths, folderPaths] = getBreadcrumbsByPath(path, splat as string)

            setBreadcrumbs(() => {
                const newState = paths.map((path, index) => {
                    return { id: `assets${folderPaths[index]}`, name: path }
                })

                return ['assets', ...newState]
            })
        } else {
            setBreadcrumbs(['assets'])
        }

        if (targetAsset) setAsset(targetAsset)
    }, [fetchAssetByIdResponse])

    const changeTab = (tab: string) => {
        if (!editor3D) return

        switch (tab) {
            case 'annotations':
            case 'layout':
                editor3D.changeMode(AMAMode.EDITOR)
                break
            default:
                editor3D.changeMode(AMAMode.VIEWER)
        }
    }

    const items: TabsProps['items'] = [
        {
            key: 'layout',
            label: `Layout`,
            children: <AssetLayout asset={asset as TAsset} allAssets={assets} />
        },
        {
            key: 'logic',
            label: `Logic`,
            children: <AssetLogic assets={assets} modelPart={modelPart} asset={asset as TAsset} />
        },
        {
            key: 'annotations',
            label: `Annotations`,
            children: <AssetAnnotations asset={asset as TAsset} />
        }
    ]
    const fetchCb = async (id: string | number) => {
        if (typeof id === 'number') id = id.toString()
        const { data } = await dispatch(assetApi.endpoints.getAssetById.initiate({ id: id }))
        return structuredClone(data)
    }

    const render3D = () => {
        return (
            <StyledWrapperEditor>
                <EditorConstructor
                    type={asset!.type as 'model' | 'material'}
                    // assetId={data.assets[0].id}
                    data={modelData}
                    // fetchCb={fetchCb}
                    customRequests={{
                        fetchDataCb: fetchCb
                    }}
                    presetConfig={{
                        mode: AMAMode.EDITOR,
                        startAnimation: false,
                        applyProductRules: false,
                        asynchronousPackages: false,
                        customDomain: 'localhost',
                        isProd: true
                    }}
                />
            </StyledWrapperEditor>
        )
    }

    return (
        <PageBuilder breadcrumbs={breadcrumbs} documentTitle={`Asset Edit: ${id}`}>
            {asset ? (
                <>
                    <EditorTopPanel asset={asset} />
                    <div style={{ position: 'relative' }}>
                        {isMaterial ? (
                            <AssetEditorMaterial asset={asset} />
                        ) : (
                            <Tabs
                                style={{ overflow: 'hidden' }}
                                type="card"
                                onChange={changeTab}
                                destroyInactiveTabPane
                                items={items}
                                defaultActiveKey={items[0].key}
                            />
                        )}
                        {is3D && modelData ? render3D() : null}
                    </div>
                </>
            ) : (
                <Spin size="large" />
            )}
        </PageBuilder>
    )
}

export default AssetEditor

export type AssetsForAttributes = {
    models: TAsset[]
    textures: TAsset[]
    materials: TAsset[]
    allMaterials: AssetsType
    allModels: AssetsType
}
const StyledWrapperEditor = styled.div`
    position: absolute;

    height: calc(100vh - ${pxToEm(380)});
    width: 100%;

    top: ${pxToEm(110)};
    left: 50%;
    transform: translateX(-50%);
`

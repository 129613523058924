import { Response } from 'miragejs'
import logGotQueryParams from '../logGotQueryParams'

function mockAssetsSearch(server) {
    const endpoint = 'asset'

    server.get(endpoint, (scheme, request) => {
        const page = Number(request.queryParams.page)
        const perPage = Number(request.queryParams['per-page'] || 20)
        const pageCount = 4

        logGotQueryParams(endpoint, ['page', 'group_id'], request)

        function createItem(id) {
            return {
                id: id,
                parent: '',
                name: 'folder1',
                type: 'folder',
                url: '',
                preview: '',
                props: {},
                patch: {},
                created_at: '2022-12-23 12:04:33',
                created_by: 1
            }
        }

        return new Response(
            200,
            {},
            {
                root: Array.from(Array(perPage)).map((i, key) => createItem(key + (page - 1) * perPage)),
                _meta: {
                    total_count: perPage * pageCount,
                    page_size: perPage,
                    page_count: pageCount,
                    current_page: page
                }
            }
        )
    })
}

export default mockAssetsSearch

import React, { useContext, useEffect, useState } from 'react'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { Button, Form, Space, Tag } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import PageBuilder from '../../../components/panel/PageBuilder'
import {
    BrandFilter,
    BrandInfoType,
    BrandType,
    useCreateBrandMutation,
    useDeleteBrandMutation,
    useGetBrandsQuery,
    useUpdateBrandMutation
} from '../../../api/brandApi'
import ModalWithForm from '../../users/ModalWithForm'
import BrandForm from './BrandForm'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { useFetchByPagination } from '../../../hooks'
import { StyleGuideType, useGetStyleGuideListMutation } from '../../../api/styleGuideApi'
import { IndustryType, useGetIndustryListMutation } from '../../../api/industryApi'
import toast from 'react-hot-toast'
import {UserStatus} from "../../../api/accountManagerApi";
import {useSearchParams} from "react-router-dom";

const Brands = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showModal } = useContext(ModalConfirmContext)

    const [formCreate] = Form.useForm<BrandInfoType>()
    const [formUpdate] = Form.useForm<BrandInfoType>()

    const [styleGuides] = useFetchByPagination<StyleGuideType>(useGetStyleGuideListMutation)
    const [industries] = useFetchByPagination<IndustryType>(useGetIndustryListMutation)

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [create, resultsCreate] = useCreateBrandMutation()
    const [update, resultsUpdate] = useUpdateBrandMutation()
    const [deleteItem] = useDeleteBrandMutation()

    const [filter, setFilter] = useState<BrandFilter>({
        page: 1,
        name: '',
        industry_id: null
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const industry_id = searchParams.get("industry_id")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            industry_id: industry_id ?? null,
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetBrandsQuery(filter)

    const [filterByText, _, filterBySelect] = useFilterTable<BrandFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
            ...filterBySelect('industry_id', industries, 'id', false),
            render: (industry: BrandType['industry']) => <Tag color="processing">{industry?.name}</Tag>
        },
        {
            title: 'Style Guide',
            dataIndex: 'style_guide',
            key: 'style_guide',
            render: (styleGuide: BrandType['style_guide']) => <Tag color="cyan">{styleGuide?.name}</Tag>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, brand: BrandType) => (
                <>
                    <Button
                        onClick={() => {
                            const { industry, style_guide, ...otherFields } = brand
                            formUpdate.setFieldsValue({
                                ...otherFields,
                                industry_id: industry?.id,
                                style_guide_id: style_guide?.id
                            })
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const handleDelete = async () => {
                                const { id } = brand
                                const res = await deleteItem({ id })
                                if ('error' in res) return
                                toast.success('Brand is successfully deleted')
                                refetch()
                            }

                            showModal('delete this brand', handleDelete)
                        }}
                        type="link"
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Brands" documentTitle="Brands">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <PlusOutlined /> Create Brand
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Brand"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                submit={formCreate.submit}
                isLoading={resultsCreate.isLoading}>
                <BrandForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                    industries={industries}
                    styleGuides={styleGuides}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Brand"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                submit={formUpdate.submit}
                isLoading={resultsUpdate.isLoading}>
                <BrandForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                    industries={industries}
                    styleGuides={styleGuides}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Brands

import React, { useEffect, useRef } from 'react'
import { useDrag, useDragLayer, useDrop } from 'react-dnd'
import { AssetDataType, AssetType, TAsset } from 'types'
import classNames from 'classnames'
import CustomDragLayer from './CustomDragLayer'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useAppSelector } from 'hooks'
import { selectSelectedRows } from 'selectors/assetsSliceSelectors'
import { useAssetsListContext } from './useAssetsListContext'

export interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    index: number
    moveRow: (dragIndex: number, hoverIndex: number) => void
}

export const type = 'DraggableBodyRow'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
    const { moveAssets } = useAssetsListContext()

    let record: AssetDataType | null = null

    if (restProps.children && Array.isArray(restProps.children)) {
        record = restProps.children[0].props.record
    }

    const recordId = record ? record.id : ''

    const selectedRows = useAppSelector(selectSelectedRows)

    const isSelected = selectedRows.findIndex(r => r.id === recordId) !== -1
    const isDroppable = Boolean(record && record.type === AssetType.Folder && !isSelected)

    // @ts-ignore
    const isDraggable = selectedRows.findIndex(r => r.id === restProps['data-row-key']) > -1

    const ref = useRef<HTMLTableRowElement>(null)

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        canDrop: () => isDroppable,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {}
            if (dragIndex === index) return {}

            return {
                isOver: monitor.isOver(),
                dropClassName: isDroppable ? 'dnd-table__row_drop-over' : ''
            }
        },
        drop: (item: { index: number }) => {
            if (restProps && Array.isArray(restProps.children)) {
                const target: TAsset = restProps.children[0].props.record
                moveAssets(target.id)
            }
        }
    })

    const [, drag, preview] = useDrag({
        type,
        item: { index },
        canDrag: isDraggable,
        collect: monitor => ({ isDragging: monitor.isDragging() })
    })

    const { isDraggingAny } = useDragLayer(monitor => ({ isDraggingAny: monitor.isDragging() }))

    drop(drag(ref))

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    function render(ref?: React.RefObject<HTMLTableRowElement> | null, styles?: any, additionalClassName?: string) {
        const draggableStyles = { cursor: 'move', ...style }

        return (
            <tr
                ref={ref}
                className={classNames([
                    className,
                    { [String(dropClassName)]: isOver },
                    { 'dnd-table__row_being-dragged': isDraggingAny && isDraggable && ref },
                    additionalClassName
                ])}
                {...restProps}
                style={{ ...(isDraggable ? draggableStyles : {}), ...styles }}
            />
        )
    }

    return (
        <>
            {render(ref)}
            {/*@ts-ignore*/}
            <CustomDragLayer
                id={recordId}
                rowRef={ref}
                render={(styles: any, className: string) => render(null, styles, className)}
            />
        </>
    )
}

export default DraggableBodyRow

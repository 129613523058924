import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AssetAttributeType, AssetRuleActionType } from '../../../../../../api/assetApi'
import { utilsEditor } from '@amaspace-editor/editor-3d'
const { Option } = Select

type ActionActivateAnimationProps = {
    item: AssetRuleActionType
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
    attributes: AssetAttributeType[]
}
type AnimationActionType = {
    animation: string | null
    attribute: AssetAttributeType | null
}
const ActionActivateAnimation = ({ deleteAction, item, changeAction, attributes }: ActionActivateAnimationProps) => {
    const editor3D = utilsEditor()
    const [action, setAction] = useState<AnimationActionType>(
        item.value ? JSON.parse(item.value) : { animation: '', attribute: null }
    )

    const validAnimationData = React.useMemo(() => {
        if (editor3D?.config?.animations) {
            return editor3D.animationList().map(item => ({ value: item, label: item }))
        }
    }, [editor3D])

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    return (
        <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
            <Col flex="auto">
                <Select
                    value={action.animation}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Animation"
                    onChange={value => {
                        setAction({ ...action, animation: value })
                    }}>
                    {validAnimationData &&
                        validAnimationData.map(item => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                </Select>
            </Col>
            <Select
                value={action.attribute?.id}
                showSearch
                optionFilterProp="children"
                placeholder="Select Attribute"
                onChange={value => {
                    const item = attributes.filter(attr => attr.id === value)
                    setAction({ ...action, attribute: item[0] })
                }}>
                {attributes.map(item => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
            <Col span={3}>
                <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
            </Col>
        </Row>
    )
}

export default ActionActivateAnimation

import React, { useContext, useEffect, useState } from 'react'
import {Button, Dropdown, List, Space, Table} from 'antd'
import {CopyOutlined, DeleteOutlined, DownOutlined, DragOutlined, EditOutlined} from '@ant-design/icons'
import {
    AssetAttributeType, useCloneAssetAttributeMutation,
    useDeleteAssetAttributesMutation,
    useGetAssetAttributesQuery, useUpdateAssetAttributeMutation
} from '../../../../../api/assetApi'
import { ModalConfirmContext } from '../../../../../providers/ModalConfirmProvider'
import toast from "react-hot-toast";
import {useCloneCompanyProductMutation} from "../../../../../api/companyCatalogApi";
import ReactDragListView from "react-drag-listview";
import {onDragEndAttributes} from "../../../../../utils";
import {StyledCatalogBtnWrapper} from "../../../../products/components/tabs/ProductInfo";
import ProductRule from "../../../../products/components/rules/ProductRule";
import {ProductRuleType} from "../../../../products/components/tabs/ProductRules";
import {TAsset} from "../../../../../types";

type LogicAttributesProps = {
    id: number
    onClick: (item: any) => void
    editAttribute: (item: AssetAttributeType) => void
}

const LogicAttributes = ({ onClick, editAttribute, id }: LogicAttributesProps) => {
    const { showModal } = useContext(ModalConfirmContext)
    const [dataSource, setDataSource] = useState<AssetAttributeType[]>([])

    const [isUpdating, setIsUpdating] = useState(false)
    const { data: assetAttributes, isLoading, refetch: refetchAssetAttribute } = useGetAssetAttributesQuery({ id })
    const [deleteAttribute, result] = useDeleteAssetAttributesMutation()
    const { isLoading: isLoadingDeleted, isSuccess: isSuccessDeleted } = result
    const [cloneItems] = useCloneAssetAttributeMutation()
    const [updateAttribute] = useUpdateAssetAttributeMutation()

    useEffect(() => {
        if (assetAttributes) {
            setDataSource(assetAttributes)
        }
    }, [assetAttributes])

    useEffect(() => {
        if (isSuccessDeleted) refetchAssetAttribute()
    }, [isSuccessDeleted])

    const uploadUpdatedItems = async (items: AssetAttributeType[]) => {
        const updatedItems = items.filter(item => item.status === 'changed')
        setIsUpdating (true)

        for (let i = 0; i < updatedItems.length; i++) {
            const res = await updateAttribute({id: updatedItems[i].id!, attribute: {...updatedItems[i], asset_id: id}})
            if ('error' in res) {
                setIsUpdating(false)
                return false
            }
            toast.success('Updated!')

            if (typeof res.data === 'string') {
                toast.error(res.data)
            }
        }

        setIsUpdating(false)
        refetchAssetAttribute()

        return true
    }

    const columns = [
        {
            dataIndex: 'DragAndDrop',
            key: 'DragAndDrop',
            render: () => (
                <span className="attr-dragdrop">
                    <DragOutlined/>
                </span>
            )
        },
        {
            dataIndex: 'name',
            key: 'name'
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, info: AssetAttributeType) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        type="link"
                        onClick={() => {
                            editAttribute(assetAttributes!.find(attr => attr.id === info.id) as AssetAttributeType)
                        }}
                        size="small">
                        <EditOutlined />
                    </Button>
                    <Button
                        onClick={() => {
                            const handleDuplicate = async () => {
                                const loader = toast.loading('Progressing...')
                                const res = await cloneItems({id: info.id as number})
                                toast.dismiss(loader)
                                if ('error' in res) return
                                toast.success('Attribute is successfully duplicated!')
                                refetchAssetAttribute()
                            }

                            showModal('duplicate attribute', handleDuplicate)
                        }}
                        type="link"
                        size="small">
                        <CopyOutlined/>
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            showModal('delete this attribute', () => deleteAttribute([info.id as number]))
                        }}
                        size="small">
                        <DeleteOutlined />
                    </Button>
                </div>
            )
        }
    ]

    return (
        <>
            <div className='logic-wrapper' style={{ marginBottom: 16 }}>
                <ReactDragListView
                    nodeSelector=".logic-wrapper .ant-table-row"
                    handleSelector=".logic-wrapper .attr-dragdrop"
                    lineClassName="dragLine"
                    onDragEnd={(fromIndex, toIndex) => {
                        setDataSource(onDragEndAttributes<AssetAttributeType>(fromIndex, toIndex, dataSource))
                    }
                    }>
                    <Table
                        loading={isLoading || isLoadingDeleted}
                        pagination={false}
                        showHeader={false}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </ReactDragListView>
            </div>

            <Space>
                {
                    dataSource.filter(item => item.status === 'changed').length != 0 &&
                    <Button loading={isUpdating} type="primary" onClick={() => uploadUpdatedItems(dataSource)}>Update Attributes</Button>
                }

                <Dropdown menu={{ items, onClick }}>
                    <Button>
                        <Space>
                            Add Attribute <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Space>
        </>
    )
}

export default LogicAttributes

type DataSourceType = { key: number; name: string }

const items: { label: string; key: string }[] = [
    {
        label: 'Asset',
        key: '5'
    },
    {
        label: 'Boolean',
        key: '2'
    },
    {
        label: 'Number',
        key: '3'
    },
    {
        label: 'String',
        key: '4'
    }
]

import { Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { useResetPasswordMutation } from 'api/userApi'
import AuthWrapper from './components/AuthWrapper'
import { StyledAuthButton } from './styles'
import AuthSuccessAlert from './components/AuthSuccessAlert'
import toast from 'react-hot-toast'
import { routesUrl } from '../../router/urls'

const messages = ['Please check your email for a confirmation link to complete the password reset process']

const ForgotPassword = () => {
    const [resetPassword, result] = useResetPasswordMutation()

    return (
        <AuthWrapper title="Forgot Password">
            {result.isSuccess ? (
                <AuthSuccessAlert messages={messages} />
            ) : (
                <Form
                    validateMessages={{ required: 'Field is required!' }}
                    layout="vertical"
                    onFinish={async e => {
                        const loader = toast.loading('Progressing...')
                        const res = await resetPassword(e)
                        toast.dismiss(loader)
                        if ('error' in res) return
                    }}>
                    <Form.Item name="email" rules={[{ required: true }]}>
                        <Input maxLength={40} type="email" placeholder="Account Email" />
                    </Form.Item>
                    <StyledAuthButton type="primary" htmlType="submit" loading={result.isLoading}>
                        Send Recovery Email
                    </StyledAuthButton>
                    Or <Link to={routesUrl.login}>sign in now!</Link>
                </Form>
            )}
        </AuthWrapper>
    )
}

export default ForgotPassword

import React from 'react'
import { LogicEditorTypeId } from '../LogicEditor'
import ActionActivateAnimation from './actions/ActionActivateAnimation'
import { AssetAttributeType, AssetRuleActionType } from '../../../../../api/assetApi'
import { TAsset } from '../../../../../types'
import ActionSetVisibility from './actions/ActionSetVisibility'
import { AssetsForAttributes } from '../../assetEditor'
import ActionSetMaterial from './actions/ActionSetMaterial'
import ActionSetActiveCamera from './actions/ActionSetActiveCamera'
import ActionSetModel from './actions/ActionSetModel'
import ActionSetVisibilityOne from './actions/ActionSetVisibilityOne'
import ActionChangeObjectProperty from './actions/ActionChangeObjectProperty'

type RulesActionItemProps = {
    item: AssetRuleActionType
    assets: AssetsForAttributes
    attributes: AssetAttributeType[]
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
    modelPart: TAsset[]
}

const RulesActionItem = ({ item, deleteAction, attributes, assets, modelPart, changeAction }: RulesActionItemProps) => {
    return (
        <>
            {item.type_action === LogicEditorTypeId['ActivateAnimation'] && (
                <ActionActivateAnimation
                    item={item}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                    attributes={attributes}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetVisibility'] && (
                <ActionSetVisibility
                    item={item}
                    attributes={attributes}
                    modelPart={modelPart}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetVisibilityOne'] && (
                <ActionSetVisibilityOne
                    item={item}
                    attributes={attributes}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetMaterial'] && (
                <ActionSetMaterial
                    item={item}
                    assets={assets}
                    attributes={attributes}
                    modelPart={modelPart}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetModel'] && (
                <ActionSetModel
                    item={item}
                    assets={assets}
                    attributes={attributes}
                    modelPart={modelPart}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetActiveCamera'] && (
                <ActionSetActiveCamera
                    item={item}
                    attributes={attributes}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}

            {item.type_action === LogicEditorTypeId['SetDefaultCamera'] && (
                <ActionSetActiveCamera
                    item={item}
                    attributes={attributes}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}
            {item.type_action === LogicEditorTypeId['ChangeObjectProperty'] && (
                <ActionChangeObjectProperty
                    item={item}
                    attributes={attributes}
                    modelPart={modelPart}
                    changeAction={changeAction}
                    deleteAction={deleteAction}
                />
            )}
        </>
    )
}

export default RulesActionItem

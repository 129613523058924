import React, { ChangeEvent } from 'react'
import { AssetAttributeType } from '../../../../../api/assetApi'
import { Form, Input, Select } from 'antd'
const { Option } = Select

type LogicAttributeStringEditProps = {
    attribute: AssetAttributeType
    defaultValue: string[]
    options: { value: string; label: string }[]
    changeName: (e: ChangeEvent<HTMLInputElement>) => void
    changeValue: (value: string[]) => void
}

const LogicAttributeStringEdit = ({
    attribute,
    changeName,
    defaultValue,
    changeValue,
    options
}: LogicAttributeStringEditProps) => {
    return (
        <div style={{ marginBottom: 16 }}>
            <Form.Item>
                <Input maxLength={50} value={attribute.name} onChange={changeName} placeholder="Name" />
            </Form.Item>

            <Form.Item>
                <Select
                    mode="tags"
                    maxTagCount="responsive"
                    placeholder="Values"
                    showSearch
                    value={defaultValue}
                    allowClear
                    onChange={value => changeValue(value)}
                    optionFilterProp="children">
                    {options.map(option => (
                        <Option value={option.value} key={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    )
}

export default LogicAttributeStringEdit

import React from 'react'
import ReactDOM from 'react-dom/client'
import 'assets/styles/global.scss'
import App from './App'
import store from 'redux/store'
import { Provider } from 'react-redux'
import initServerMocks from './server-mock/initServerMocks'
import { Toaster } from 'react-hot-toast'
import 'antd/dist/antd.css'

if (process.env.NODE_ENV === 'development') {
    initServerMocks(process.env.REACT_APP_ENABLED_SERVER_FEATURES_MOCKS)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <Toaster position={'top-right'} />
        <App />
    </Provider>
)

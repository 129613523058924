import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row, Select, Switch } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { TAsset } from '../../../../../../types'
import { AssetsForAttributes } from '../../../assetEditor'
import { utilsEditor } from '@amaspace-editor/editor-3d'
import { AssetAttributeType, AssetRuleActionType, AssetType, useGetAssetsQuery } from '../../../../../../api/assetApi'

const { Option } = Select

type ActionSetMaterialProps = {
    item: AssetRuleActionType
    modelPart: TAsset[]
    attributes: AssetAttributeType[]
    assets: AssetsForAttributes
    deleteAction: (item: AssetRuleActionType) => void
    changeAction: (item: AssetRuleActionType, value: string) => void
}

type MaterialActionType = {
    modelPart: CameraData | null
    attribute: AssetAttributeType | null
    value: AssetType | null
    switcher: boolean
}
type CameraData = {
    value: string
    label?: string
}
const ActionSetMaterial = ({
    item,
    deleteAction,
    attributes,
    assets,
    modelPart,
    changeAction
}: ActionSetMaterialProps) => {
    const editor3D = utilsEditor()

    const [action, setAction] = useState<MaterialActionType>(
        item.value
            ? JSON.parse(item.value)
            : {
                  modelPart: null,
                  value: null,
                  attribute: null,
                  switcher: true
              }
    )

    const nodesData = React.useMemo((): CameraData[] | undefined => {
        if (editor3D?.config?.objects) {
            if (editor3D.uploadedModelsConfig.length) {
                const objects = editor3D.uploadedModelsConfig.map(config => {
                    return config?.objects.map(item => ({ value: item.userData.id, label: item.name }))
                })
                const innerConfig = editor3D?.config?.objects.map(item => ({
                    value: item.userData.id,
                    label: item.name
                }))
                return [...objects.flat(), ...innerConfig]
            }
            return editor3D?.config?.objects.map(item => ({ value: item.userData.id, label: item.name }))
        }
    }, [editor3D])

    useEffect(() => {
        changeAction(item, JSON.stringify(action))
    }, [action])

    return (
        <Row gutter={[0, 16]}>
            <Col span={24}>
                <Select
                    value={action.modelPart?.value}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Model Part"
                    onChange={value => {
                        const foundModel = nodesData && nodesData.find(item => item.value === value)
                        setAction({ ...action, modelPart: foundModel ? foundModel : null })
                    }}>
                    {nodesData &&
                        nodesData.map(item => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                </Select>
            </Col>

            <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Switch
                    checkedChildren="Material"
                    onChange={() =>
                        setAction({
                            ...action,
                            value: null,
                            attribute: null,
                            switcher: !action.switcher
                        })
                    }
                    unCheckedChildren="Attribute"
                    defaultChecked={action.switcher}
                />
            </Col>

            <Col span={24}>
                <Row gutter={[8, 0]}>
                    {action.switcher ? (
                        <Col span={20}>
                            <Select
                                value={action.value?.id}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select Material"
                                onChange={value => {
                                    const foundMaterial = assets.allMaterials.find(item => item.id === value)
                                    if (!foundMaterial) return
                                    const final = {
                                        ...foundMaterial,
                                        patch: JSON.parse(foundMaterial.patch)
                                    } as any
                                    setAction({ ...action, value: final })
                                }}>
                                {assets.allMaterials.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    ) : (
                        <Col span={20}>
                            <Select
                                value={action.attribute?.id}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select Attribute"
                                onChange={value => {
                                    const item = attributes.filter(attr => attr.id === value)
                                    setAction({ ...action, attribute: item[0] })
                                }}>
                                {attributes.map(item => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}

                    <Col span={3}>
                        <Button size="small" type="link" onClick={() => deleteAction(item)} icon={<DeleteOutlined />} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ActionSetMaterial

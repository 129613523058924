import { Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CatalogContext } from '../../../../../providers/CatalogProvider'
import { ProductActionType, ProductConditionType } from '../../tabs/ProductRules'
import {isArray, isObject} from 'underscore'
import { fetchProductsByTags } from '../../attributes/items/ProductAttributeProductsTagsItem'
import {ProductsAndTagsType} from "../../tabs/ProductAttributes";

type RuleValueItemProps<T extends ProductConditionType | ProductActionType> = {
    item: T
    itemKey: keyof T
    handleChange: (item: T) => void
}

const RuleValueItem = <T extends ProductConditionType | ProductActionType>({
    item,
    itemKey,
    handleChange
}: RuleValueItemProps<T>) => {
    const { attributes, requests } = useContext(CatalogContext)

    const { products, getProductsByTags } = requests

    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState<{ id: string; name: string }[]>([])

    useEffect(() => {
        if (!item.attribute_id) {
            setOptions([])
            return
        }

        const foundAttribute = attributes.find(attr => attr.id === item.attribute_id)

        if (!foundAttribute?.value) {
            setOptions([])
            return
        }

        const { value } = foundAttribute

        if (typeof value === 'string') {
            setOptions([{ id: value, name: value }])

            if (foundAttribute.type === 'single') {
                const foundProduct = products.find(product => product.id.toString() === value)
                setOptions([{ id: value, name: foundProduct?.name ?? 'Empty' }])
            }
        } else if (isArray(value)) {
            setOptions(value.map(item => ({ id: item, name: item })))
        } else if (isObject(value) && (value as {min: number; max: number; product_id: number}).product_id) {
            const {product_id} = value as {min: number; max: number; product_id: number}
            const foundProduct = products.find(product => product.id.toString() === product_id?.toString())
            setOptions([{ id: product_id?.toString(), name: foundProduct?.name ?? 'Empty' }])
        } else {
            const { products: attrProducts, tags: attrTags } = value as ProductsAndTagsType

            const newAttrProducts = attrProducts ? attrProducts.map(product => product) : []
            const foundProducts = products.filter(product => newAttrProducts.includes(product.id))

            if (attrTags && attrTags.length !== 0) {
                const getProducts = async () => {
                    setIsLoading(true)
                    const products = await fetchProductsByTags(getProductsByTags, attrTags)

                    const combinedProducts = [...foundProducts, ...products]
                    const uniqueObjects = Array.from(new Set(combinedProducts.map(obj => obj.id!))).map(
                        id => combinedProducts.find(obj => obj.id === id)!
                    )

                    setOptions(uniqueObjects.map(obj => ({ id: obj.id.toString(), name: obj.name })))

                    setIsLoading(false)
                }

                getProducts()
            } else {
                setOptions(foundProducts.map(obj => ({ id: obj.id.toString(), name: obj.name })))
            }
        }
    }, [item.attribute_id])

    const handleChangeConditionValue = (value: string) => {
        handleChange({ ...item, [itemKey]: value })
    }

    return (
        <Select
            showSearch
            allowClear
            loading={isLoading}
            optionFilterProp="children"
            placeholder="Select Value"
            value={item[itemKey] ? (item[itemKey] as string).toString() : null}
            onChange={handleChangeConditionValue}>
            {options.map(item => {
                return (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

export default RuleValueItem

import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'
import ProductAttributeAssetItem from './items/ProductAttributeAssetItem'
import ProductAttributeDescriptionItem from './items/ProductAttributeDescription'
import ProductAttributeDiscountItem from './items/ProductAttributeDiscountItem'
import ProductAttributeIsHideItem from './items/ProductAttributeIsHideItem'
import ProductAttributeIsSome from './items/ProductAttributeIsSome'
import ProductAttributeNameItem from './items/ProductAttributeNameItem'
import ProductAttributeParentIdItem from './items/ProductAttributeParentIdItem'
import ProductAttributeProductsTagsItem from './items/ProductAttributeProductsTagsItem'

type ProductAttributeColorProps = {
    attr: ProductAttributeType
    isHiddenAssetAttributes: boolean
}

const ProductAttributeColor = ({ attr, isHiddenAssetAttributes }: ProductAttributeColorProps) => {
    return (
        <div style={{ width: '100%' }}>
            <StyledAttributeItem repeat={2}>
                <ProductAttributeNameItem attr={attr} />

                <ProductAttributeParentIdItem attr={attr} />

                {!isHiddenAssetAttributes && <ProductAttributeAssetItem attr={attr} />}

                <ProductAttributeProductsTagsItem attr={attr} />

                <ProductAttributeDescriptionItem attr={attr} />
                <ProductAttributeDiscountItem attr={attr} />
            </StyledAttributeItem>

            <ProductAttributeIsHideItem attr={attr} />
            <ProductAttributeIsSome attr={attr} keyValue={'is_color'} title={'is Color picker'} />
        </div>
    )
}

export default ProductAttributeColor

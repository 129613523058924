import {Input} from 'antd'
import {ProductAttributeType} from '../../tabs/ProductAttributes'
import {useContext, useState} from 'react'
import {CatalogContext} from '../../../../../providers/CatalogProvider'
import {useDebouncedCallback} from "use-debounce";

type ProductAttributeDescriptionItemProps = {
    attr: ProductAttributeType
}

const ProductAttributeDescriptionItem = ({ attr }: ProductAttributeDescriptionItemProps) => {
    const { attributes, setAttributes } = useContext(CatalogContext)
    const [value, setValue] = useState(attr.description || '');

    const debounced = useDebouncedCallback(
        (description) => setAttributes(
            attributes.map(item => ({
                ...item,
                description: item.id === attr.id ? description : item.description,
                status: item.id === attr.id ? 'changed' : item.status
            }))
        ),
        500
    );

    const handleChangeDescription: HandleChangeAttributeDescription = text => {
        setValue(text)
        debounced(text)
    }

    return (
        <Input maxLength={255} placeholder="Description" value={value} onChange={e => handleChangeDescription(e.target.value)} />
    )
}

export default ProductAttributeDescriptionItem

type HandleChangeAttributeDescription = (value: string) => void

import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'
import ProductAttributeAssetItem from './items/ProductAttributeAssetItem'
import ProductAttributeDescriptionItem from './items/ProductAttributeDescription'
import ProductAttributeDiscountItem from './items/ProductAttributeDiscountItem'
import ProductAttributeIsHideItem from './items/ProductAttributeIsHideItem'
import ProductAttributeNameItem from './items/ProductAttributeNameItem'
import ProductAttributeParentIdItem from './items/ProductAttributeParentIdItem'
import ProductAttributeProductsTagsItem from './items/ProductAttributeProductsTagsItem'

type ProductAttributeImageProps = {
    attr: ProductAttributeType
    isHiddenAssetAttributes: boolean
}

const ProductAttributeImage = ({ attr, isHiddenAssetAttributes }: ProductAttributeImageProps) => {
    return (
        <div style={{ width: '100%' }}>
            {/* <ProductAttributeImageItem attr={attr} /> */}

            <StyledAttributeItem repeat={2}>
                <ProductAttributeNameItem attr={attr} />

                <ProductAttributeParentIdItem attr={attr} />

                {!isHiddenAssetAttributes && <ProductAttributeAssetItem attr={attr} />}

                <ProductAttributeProductsTagsItem attr={attr} />

                <ProductAttributeDescriptionItem attr={attr} />
                <ProductAttributeDiscountItem attr={attr} />
            </StyledAttributeItem>

            <ProductAttributeIsHideItem attr={attr} />
        </div>
    )
}

export default ProductAttributeImage

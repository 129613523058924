import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'
import ProductAttributeAssetItem from './items/ProductAttributeAssetItem'
import ProductAttributeDescriptionItem from './items/ProductAttributeDescription'
import ProductAttributeDiscountItem from './items/ProductAttributeDiscountItem'
import ProductAttributeIsHideItem from './items/ProductAttributeIsHideItem'
import ProductAttributeIsSome from './items/ProductAttributeIsSome'
import ProductAttributeNameItem from './items/ProductAttributeNameItem'
import ProductAttributeParentIdItem from './items/ProductAttributeParentIdItem'
import ProductAttributeProductItem from './items/ProductAttributeProductItem'
import ProductAttributeValueItem from './items/ProductAttributeValueItem'

type ProductAttributeNumberProps = {
    attr: ProductAttributeType
    isHiddenAssetAttributes: boolean
}

const ProductAttributeNumber = ({ attr, isHiddenAssetAttributes }: ProductAttributeNumberProps) => {
    return (
        <div style={{ width: '100%' }}>
            <StyledAttributeItem repeat={2}>
                <ProductAttributeNameItem attr={attr} />

                <ProductAttributeParentIdItem attr={attr} />

                {!isHiddenAssetAttributes && <ProductAttributeAssetItem attr={attr} />}

                <ProductAttributeProductItem type="number" attr={attr} />

                <ProductAttributeValueItem type="number" attr={attr} field="min" />
                <ProductAttributeValueItem type="number" attr={attr} field="max" />

                <ProductAttributeDescriptionItem attr={attr} />
                <ProductAttributeDiscountItem attr={attr} />
            </StyledAttributeItem>

            <ProductAttributeIsHideItem attr={attr} />
            <ProductAttributeIsSome attr={attr} keyValue={'is_morph_target'} title={'is morph target'} />
        </div>
    )
}

export default ProductAttributeNumber

import { useParams } from 'react-router-dom'
import AvailableLicensesTable from './available/AvailableLicensesTable'
import PageBuilder from '../../components/panel/PageBuilder'
import Title from 'antd/lib/typography/Title'
import ActivatedLicensesTable from './activated/ActivatedLicensesTable'
import { Divider } from 'antd'

const DealerLicenses = () => {
    const { id } = useParams()

    return (
        <PageBuilder breadcrumbs={['dealers', 'dealer\'s licenses']} title="Dealer License" documentTitle="Dealer License">
            <Title level={4}>Licenses Available</Title>
            <AvailableLicensesTable dealerId={id!} />

            <Divider />

            <Title level={4}>Licenses Active</Title>
            <ActivatedLicensesTable dealerId={id!} />
        </PageBuilder>
    )
}

export default DealerLicenses

import React, { useContext, useEffect, useState } from 'react'
import { ModalConfirmContext } from '../../../providers/ModalConfirmProvider'
import { Button, Form, Space, Tag } from 'antd'
import { CreditCardOutlined, DeleteOutlined, EditOutlined, PlusOutlined, ProjectOutlined } from '@ant-design/icons'
import PageBuilder from '../../../components/panel/PageBuilder'
import ModalWithForm from '../../users/ModalWithForm'
import {
    CompanyFilter,
    CompanyInfoType,
    CompanyType,
    useCreateCompanyMutation,
    useDeleteCompanyMutation,
    useGetCompaniesQuery,
    useUpdateCompanyMutation
} from 'api/companyApi'
import CompanyForm, { howToPayOptions } from './CompanyForm'
import { useAppSelector } from '../../../hooks'
import {useNavigate, useSearchParams} from 'react-router-dom'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import { BrandFilter } from '../../../api/brandApi'
import toast from 'react-hot-toast'
import { routesUrl } from '../../../router/urls'
import {UserStatus} from "../../../api/accountManagerApi";

const Companies = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showModal } = useContext(ModalConfirmContext)

    const navigate = useNavigate()
    const { role } = useAppSelector(state => state.user)

    const [formCreate] = Form.useForm<CompanyInfoType>()
    const [formUpdate] = Form.useForm<CompanyInfoType>()

    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)

    const [create, resultsCreate] = useCreateCompanyMutation()
    const [update, resultsUpdate] = useUpdateCompanyMutation()
    const [deleteItem] = useDeleteCompanyMutation()

    const [filter, setFilter] = useState<CompanyFilter>({
        page: 1,
        name: '',
        email: ''
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const email = searchParams.get("email")

        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            email: email ?? '',
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetCompaniesQuery(filter)

    const [filterByText] = useFilterTable<CompanyFilter>()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...filterByText('email')
        },
        {
            title: 'How To Pay',
            dataIndex: 'how_pay',
            key: 'how_pay',
            render: (method: number) => howToPayOptions.find(opt => opt.value === method)?.label
        },
        {
            title: 'Brands',
            dataIndex: 'brands',
            key: 'brands',
            render: (brands: CompanyType['brands']) => (
                <Space size={[0, 'small']} wrap>
                    {brands.map(brand => (
                        <Tag key={brand.id} color="processing">
                            {brand.name}
                        </Tag>
                    ))}
                </Space>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, company: CompanyType) => (
                <>
                    {role === 'super admin' && (
                        <>
                            <Button
                                onClick={() => {
                                    const { brands, ...otherFields } = company
                                    const brandsId = brands.map(brand => brand.id)
                                    formUpdate.setFieldsValue({ ...otherFields, brands: brandsId })
                                    setIsModalOpenUpdate(true)
                                }}
                                type="link"
                                size="small">
                                <EditOutlined />
                            </Button>

                            <Button
                                onClick={() => {
                                    const handleDelete = async () => {
                                        const { id } = company
                                        const res = await deleteItem({ id })
                                        if ('error' in res) return
                                        toast.success('Company is successfully deleted')
                                        refetch()
                                    }

                                    showModal('delete this company', handleDelete)
                                }}
                                type="link"
                                size="small">
                                <DeleteOutlined />
                            </Button>
                        </>
                    )}

                    {role === 'account manager' && (
                        <>
                            <Button
                                onClick={() => navigate(`${routesUrl.apoCompany}/${company.id}`)}
                                type="link"
                                size="small">
                                <ProjectOutlined />
                            </Button>

                            <Button
                                onClick={() => navigate(`${routesUrl.invoicesCompany}/${company.id}`)}
                                type="link"
                                size="small">
                                <CreditCardOutlined />
                            </Button>
                        </>
                    )}
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Companies" documentTitle="Companies">
            {role === 'super admin' && (
                <Space style={{ marginBottom: 20 }}>
                    <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                        <PlusOutlined /> Create Company
                    </Button>
                </Space>
            )}

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title="Create Company"
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                submit={formCreate.submit}
                isLoading={resultsCreate.isLoading}>
                <CompanyForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title="Update Company"
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                submit={formUpdate.submit}
                isLoading={resultsUpdate.isLoading}>
                <CompanyForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>
        </PageBuilder>
    )
}

export default Companies

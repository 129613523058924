import { Form, Input, Spin } from 'antd'
import React, { ChangeEvent, useContext } from 'react'
import { CatalogContext, ProductType } from '../../../../providers/CatalogProvider'

type ProductInputItemProps = {
    itemKey: keyof Pick<ProductType, 'name' | 'make' | 'year' | 'model' | 'description' | 'color'>
    maxLength: number
    type?: 'small' | 'large'
    required?: boolean
}

const ProductInputItem = ({ itemKey, maxLength, type = 'small', required }: ProductInputItemProps) => {
    const { product, isLoadingProduct, setProduct } = useContext(CatalogContext)
    const placeholder = itemKey.charAt(0).toUpperCase() + itemKey.slice(1)

    const handleChangeValue = (key: keyof ProductType, value: string) => {
        setProduct({ ...product, [key]: value })
    }

    const props = {
        placeholder,
        value: product[itemKey],
        maxLength,
        onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeValue(itemKey, e.target.value)
    }

    switch (type) {
        case 'large':
            return (
                <Form.Item required={required} label={placeholder}>
                    {isLoadingProduct ? <Spin size="small" /> : <Input.TextArea {...props} />}
                </Form.Item>
            )
        case 'small':
            return (
                <Form.Item required={required} label={placeholder}>
                    {isLoadingProduct ? <Spin size="small" /> : <Input {...props} />}
                </Form.Item>
            )
    }
}

export default ProductInputItem

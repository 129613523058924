import { Form, Select, Spin } from 'antd'
import { useContext } from 'react'
import { CatalogContext } from '../../../../providers/CatalogProvider'

const ProductKeywordsItem = () => {
    const { requests } = useContext(CatalogContext)

    const { productKeywords, isLoadingProductKeywords } = requests

    const { product, setProduct } = useContext(CatalogContext)

    const handleAddKeywords = (keywords: string[]) => {
        setProduct({ ...product, keywords })
    }

    return (
        <Form.Item label="Keywords">
            {isLoadingProductKeywords ? (
                <Spin size="small" />
            ) : (
                <Select
                    mode="tags"
                    maxTagTextLength={20}
                    maxTagCount="responsive"
                    placeholder="Keywords"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={product.keywords}
                    onChange={handleAddKeywords}>
                    {productKeywords &&
                        productKeywords.map(item => {
                            return (
                                <Select.Option key={item.id} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            )
                        })}
                </Select>
            )}
        </Form.Item>
    )
}

export default ProductKeywordsItem

import { utilsEditor } from '@amaspace-editor/editor-3d'
import { Tree, TreeDataNode, Typography } from 'antd'
import { DataNode, TreeProps } from 'antd/lib/tree'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

type DraggableFn = (node: DataNode) => boolean;
interface DraggableConfig {
  icon?: React.ReactNode | false;
  nodeDraggable?: DraggableFn;
}

type TAssetEditorTree = {
    nodes: DataNode[]
    onSelect: (selectedKeys: React.Key[], info: any) => void
    defaultExpandedKeys?: string[]
    selectedKeys?: string[]
    draggable?: boolean | DraggableFn | DraggableConfig
    onDragEndCb?: any
    title?: string
}

const AssetEditorTree: React.FC<TAssetEditorTree> = ({
    nodes,
    defaultExpandedKeys,
    onSelect,
    selectedKeys,
    draggable = false,
    title,
    onDragEndCb = () => {}
}) => {
    const editor3d = utilsEditor()
    const treeState = useMemo(() => {
        return nodes
    }, [nodes])

    const [selectedKey, setSelectedKey] = useState(selectedKeys)

    const getAllKeys = useCallback((data: DataNode[]): any[] => {
        const nestedKeys = data.map(node => {
            let childKeys = []
            if (node.children) {
                childKeys = getAllKeys(node.children)
            }
            return [childKeys, node.key]
        })
        return nestedKeys.flat(Infinity)
    }, [])
    const [allKeys, setAllKeys] = useState<any[]>(getAllKeys(nodes))
    const [gData, setGData] = useState(nodes)

    useEffect(() => {
        setAllKeys(getAllKeys(nodes))
    }, [getAllKeys, nodes])

    useEffect(() => {
        setGData(nodes)
    }, [nodes])

    useEffect(() => {
        setSelectedKey(selectedKeys)
    }, [selectedKeys])

    const selectedMesh = useCallback(
        (children: DataNode): string | number | undefined => {
            //@ts-ignore
            if (children.id === editor3d.activeMesh?.userData.id) {
                if (typeof children.key === 'string') {
                    setSelectedKey([children.key])
                }
                return children.key as string
            }

            if (!children?.children) return

            for (const item of children.children) {
                const result = selectedMesh(item)
                if (result !== undefined) {
                    return result
                }
            }
            return undefined
        },
        [editor3d.activeMesh?.userData.id]
    )

    useEffect(() => {
        if (editor3d.activeMesh) {
            treeState.map(item => selectedMesh(item))
        }
        return () => {
            setSelectedKey([''])
        }
    }, [editor3d.activeMesh, selectedMesh, treeState])

    const onDrop: TreeProps['onDrop'] = info => {
        const dropKey = info.node.key
        const dragKey = info.dragNode.key
        const dropPos = info.node.pos.split('-')
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]) // the drop position relative to the drop node, inside 0, top -1, bottom 1

        const loop = (
            data: TreeDataNode[],
            key: React.Key,
            callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data)
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback)
                }
            }
        }
        const data = [...gData]

        // Find dragObject
        let dragObj: TreeDataNode
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1)
            dragObj = item
        })

        if (!info.dropToGap) {
            // Drop on the content
            return
            // loop(data, dropKey, item => {
            //     item.children = item.children || []
            //     // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
            //     item.children.unshift(dragObj)
            // })
        } else {
            let ar: TreeDataNode[] = []
            let i: number
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr
                i = index
            })
            if (dropPosition === -1) {
                // Drop on the top of the drop node
                ar.splice(i!, 0, dragObj!)
            } else {
                // Drop on the bottom of the drop node
                ar.splice(i! + 1, 0, dragObj!)
            }
        }
        setGData(data)
    }
    const onDragEnd: TreeProps['onDragEnd'] = info => {
        // setExpandedKeys(info.expandedKeys)
        onDragEndCb(info)
    }

    return (
        <>
            <Typography.Title level={3}>{title ?? 'Nodes'}</Typography.Title>

            <Tree
                defaultExpandAll={true}
                draggable={draggable}
                expandedKeys={allKeys}
                onExpand={expandedKeys => {
                    setAllKeys(expandedKeys)
                }}
                onSelect={onSelect}
                selectedKeys={selectedKey}
                showLine={false}
                switcherIcon={false}
                showIcon={true}
                treeData={gData}
                onDrop={onDrop}
                onDragEnd={onDragEnd}
            />
        </>
    )
}

export default AssetEditorTree

import styled from 'styled-components'
import {Button, Checkbox, Col, Dropdown, Form, Input, List, Row, Space} from 'antd'
import React, { useContext } from 'react'
import { DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { ProductRuleActions } from './ProductRule'
import { ProductActionType, ProductRuleType } from '../tabs/ProductRules'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import RuleAttributeItem from './items/RuleAttributeItem'
import RuleCheckboxItem from './items/RuleCheckboxItem'
import RuleValueItem from './items/RuleValueItem'
import Item = List.Item
import RuleAttributeToValueItem from './items/RuleAttributeToValueItem'

type ProductRuleActionProps = {
    rule: ProductRuleType
}

const ProductRuleAction = ({ rule }: ProductRuleActionProps) => {
    const { rules, setRules } = useContext(CatalogContext)

    const handleCreateAction = (rule: ProductRuleType, key: string) => {
        const { id: type_action } = productRuleActions[key]

        const newAction: ProductActionType = {
            id: Date.now(),
            newAction: 'new',
            attribute_id: null,
            type_action,
            option_id: '',
            value: ''
        }

        setRules(
            rules.map(state => {
                return {
                    ...state,
                    action: state.id === rule.id ? [...state.action, newAction] : state.action,
                    status: state.id === rule.id ? 'changed' : state.status
                }
            })
        )
    }

    const handleDeleteAction = (action: ProductActionType) => {
        setRules(
            rules.map(item => {
                return {
                    ...item,
                    action: item.id === rule.id ? item.action.filter(item => item.id !== action.id) : item.action,
                    status: item.id === rule.id ? 'changed' : item.status
                }
            })
        )
    }

    const handleChangeAction = (newAction: ProductActionType) => {
        setRules(
            rules.map(prevRule => {
                return {
                    ...prevRule,
                    action:
                        prevRule.id === rule.id
                            ? prevRule.action.map(prevAction => {
                                  return prevAction.id === newAction.id ? newAction : prevAction
                              })
                            : prevRule.action,
                    status: prevRule.id === rule.id ? 'changed' : prevRule.status
                }
            })
        )
    }

    return (
        <Form.Item label="Actions">
            <List
                className="logic__list"
                itemLayout="vertical"
                dataSource={rule.action}
                renderItem={action => (
                    <Item key={action.id}>
                        <div style={{ width: '100%' }}>
                            <StyledTitleAction>
                                {getLabelById(+action.type_action!, productRuleActions)}
                            </StyledTitleAction>
                            <Row style={{ width: '100%', margin: 0 }} gutter={[8, 0]}>
                                {+action.type_action! === productRuleActions['SetAttributeEnabled'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                        <RuleCheckboxItem action={action} handleChangeAction={handleChangeAction} />
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeVisible'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                        <RuleCheckboxItem action={action} handleChangeAction={handleChangeAction} />
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeValue'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="value"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <Col flex="auto">
                                            <RuleValueItem
                                                itemKey="value"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeValueVisibility'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <Col flex="auto">
                                            <RuleValueItem
                                                itemKey="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <RuleCheckboxItem action={action} handleChangeAction={handleChangeAction} />
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeValueEnabled'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <Col flex="auto">
                                            <RuleValueItem
                                                itemKey="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <RuleCheckboxItem action={action} handleChangeAction={handleChangeAction} />
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeExclude'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                        <Col flex="auto">
                                            <RuleAttributeToValueItem handleChange={handleChangeAction} item={action} />
                                        </Col>
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetColorParent'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="value"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                        <Col flex="auto">
                                            <RuleAttributeToValueItem handleChange={handleChangeAction} item={action} />
                                        </Col>
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetAttributeExcludeMessage'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="value"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>
                                        <Col flex="auto">
                                            <RuleAttributeToValueItem handleChange={handleChangeAction} item={action} />
                                        </Col>
                                    </>
                                )}

                                {+action.type_action! === productRuleActions['SetTooltipMessage'].id && (
                                    <>
                                        <Col flex="auto">
                                            <RuleAttributeItem
                                                clearField="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <Col flex="auto">
                                            <RuleValueItem
                                                itemKey="option_id"
                                                item={action}
                                                handleChange={handleChangeAction}
                                            />
                                        </Col>

                                        <Col flex="auto">
                                            <Input
                                                maxLength={40}
                                                placeholder="Message"
                                                value={action.value as string}
                                                onChange={e => {
                                                    handleChangeAction({ ...action, value: e.target.value })
                                                }}
                                            />
                                        </Col>
                                    </>
                                )}

                                <StyledWrapperFlexCol span={3}>
                                    <Button onClick={() => handleDeleteAction(action)} type="link" size="small">
                                        <DeleteOutlined />
                                    </Button>
                                </StyledWrapperFlexCol>
                            </Row>
                        </div>
                    </Item>
                )}
                header={
                    <Dropdown
                        menu={{
                            items: convertToObjectArray(productRuleActions),
                            onClick: type => handleCreateAction(rule, type.key)
                        }}>
                        <Button style={{ width: '100%' }}>
                            <Space>
                                Add Action <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                }
            />
        </Form.Item>
    )
}

export default ProductRuleAction

function getLabelById(id: number, obj: ProductRuleActions): string {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const currentObject = obj[key]
            if (currentObject.id === id) {
                return currentObject.label
            }
        }
    }
    return ''
}

function convertToObjectArray(obj: ProductRuleActions) {
    const resultArray: { key: string; label: string; disabled: boolean }[] = []

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const { label, disabled } = obj[key]
            resultArray.push({ label, key, disabled })
        }
    }

    return resultArray
}

export const productRuleActions: ProductRuleActions = {
    SetAttributeVisible: { id: 19, label: 'Set Attribute Visible', disabled: false },
    SetAttributeEnabled: { id: 20, label: 'Set Attribute Enabled', disabled: false },
    SetAttributeValue: { id: 21, label: 'Set Attribute Value', disabled: false },
    SetAttributeValueVisibility: { id: 22, label: 'Set Attribute Value Visibility', disabled: false },
    SetAttributeValueEnabled: { id: 23, label: 'Set Attribute Value Enabled', disabled: false },
    SetAttributeExclude: { id: 25, label: 'Set Attribute Exclude', disabled: false },
    SetColorParent: { id: 26, label: 'Set Color Parent', disabled: false },
    SetAttributeExcludeMessage: { id: 27, label: 'Set Attribute Exclude Message', disabled: false },
    SetTooltipMessage: { id: 28, label: 'Set Tooltip Message', disabled: false },
    CustomScript: { id: 24, label: 'Custom Script', disabled: true }
} as const

const StyledTitleAction = styled.div`
    font-size: 11px;
`

export const StyledWrapperFlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
`

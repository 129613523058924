import api from './index'
import { TableMetaType } from '../components/ExtendedTable'

const INDUSTRY = 'industry'

const industryApi = api.injectEndpoints({
    endpoints: build => ({
        getIndustries: build.query<GetIndustriesType, IndustryFilter>({
            query: body => {
                const { page, name } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name
                })

                return `${INDUSTRY}?${params}`
            }
        }),

        getIndustryList: build.mutation<GetIndustriesType, IndustryFilter>({
            query: body => {
                const { page } = body
                const params = new URLSearchParams({ page: page.toString() })

                return `${INDUSTRY}?${params}`
            }
        }),

        createIndustry: build.mutation<null, IndustryInfoType>({
            query: body => ({
                url: INDUSTRY,
                method: 'POST',
                body
            })
        }),

        updateIndustry: build.mutation<null, IndustryInfoType>({
            query: body => {
                const { id, ...newBody } = body
                return {
                    url: `${INDUSTRY}/${id}`,
                    method: 'PUT',
                    body: newBody
                }
            }
        }),

        deleteIndustry: build.mutation<null, Pick<IndustryType, 'id'>>({
            query: body => ({
                url: `${INDUSTRY}/${body.id}`,
                method: 'DELETE'
            })
        })
    }),
    overrideExisting: false
})

export type IndustryType = {
    id: number
    name: string
    description: string
}

export type IndustryInfoType = IndustryType

export type GetIndustriesType = {
    items: IndustryType[]
    _meta: TableMetaType
}

export type IndustryFilter = {
    page: number
    name: string
}

export const {
    useCreateIndustryMutation,
    useDeleteIndustryMutation,
    useUpdateIndustryMutation,
    useGetIndustriesQuery,
    useGetIndustryListMutation
} = industryApi

export default industryApi

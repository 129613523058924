import { CatalogPreviewItemType } from '../../../../api/shareApi'
import styled from 'styled-components'
import { pxToEm } from '../../../../theme/stylesMixins'
import { ShareProductType } from './ShareProductSelect'
import { CopyBlock, dracula } from 'react-code-blocks'
import React from 'react'

export type Asset3DType = Extract<CatalogPreviewItemType['asset_type'], 'model' | 'material'>

type ShareProductCode = {
    licenseId: string
    productName: string
    token: string
    type: Asset3DType
    share: NonNullable<ShareProductType>
}

const ShareProductCode = ({ licenseId, token, type, share, productName }: ShareProductCode) => {
    const shareText = {
        Embed: `
<div id="app"></div>
<script src="https://unpkg.com/@amaspace-editor/editor-3d@0.0.222/dist/bundle.umd.js"></script>
<script>
    window.playerInit({
        el: document.getElementById("app"),
        assetId: "${licenseId}",
        apiKey: "${token}",
        type: "${type}",
    })
</script>`,
        Iframe: `<iframe 
    width="100%" 
    height="100%"
    title="${productName}"
    allowfullscreen 
    src="https://app.amaspace.co/iframe/${token}?productID=${licenseId}"
></iframe>`
    }

    return (
        <StyledShareWrapper>
            <div style={{ width: '100%' }}>
                <CopyBlock
                    language="html"
                    text={shareText[share]}
                    showLineNumbers={true}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                />
            </div>
        </StyledShareWrapper>
    )
}

export default ShareProductCode

const StyledShareWrapper = styled.div`
    display: flex;
    font-size: var(--mainFontSize);
    margin-top: ${pxToEm(10)};
    background: rgb(40, 42, 54);
    border-radius: ${pxToEm(5)};
`

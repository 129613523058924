import { Button, Dropdown, Form, InputNumber, List, MenuProps, Space, Spin } from 'antd'
import React, {useContext, useEffect} from 'react'
import { DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { useGetCurrencyQuery } from '../../../../api/priceApi'
import { CatalogContext, ProductPriceType } from '../../../../providers/CatalogProvider'
import {isArray} from "underscore";

const ProductCurrenciesItem = () => {
    const { product, setProduct } = useContext(CatalogContext)

    const productPrice = isArray(product.price) ? product.price : [product.price as unknown as ProductPriceType]

    const { data: currencies, isLoading } = useGetCurrencyQuery(null)

    const minCurrencyAmount = '0'

    const items =
        currencies &&
        currencies.map(item => {
            const { id, name, code } = item

            if (!productPrice || (productPrice && (productPrice as unknown as ProductPriceType).code)) {
                return {
                    label: name,
                    key: id,
                    disabled: (productPrice as unknown as ProductPriceType).code === code
                }
            }
            return {
                label: name,
                key: id,
                disabled: !!(productPrice as ProductPriceType[]).find(currency => currency.code === code)
            }
        })

    const onClick: MenuProps['onClick'] = e => {
        const foundItem = currencies && currencies.find(currency => currency.id.toString() === e.key)

        if (isArray(productPrice)) {
            setProduct({
                ...product,
                price: [
                    ...(productPrice as ProductPriceType[]),
                    {
                        id: +e.key,
                        amount: minCurrencyAmount,
                        code: foundItem!.code,
                        discount: 0,
                    }
                ]
            })
        } else {
            setProduct({
                ...product,
                price: [
                    productPrice,
                    {
                        id: +e.key,
                        amount: minCurrencyAmount,
                        code: foundItem!.code,
                        discount: 0,
                    }
                ]
            })
        }
    }

    useEffect(() => {
      if (product.discount.type !== 2) {
        const filteredCurrency: ProductPriceType[] = (
          productPrice as ProductPriceType[]
        ).map(currency => {
          const { amount, id, code, discount } = currency
          return { id, code, amount, discount: 0 }
        })

        setProduct(prevState => ({ ...prevState, price: [...filteredCurrency] }))
      }
    }, [product.discount.type])

    return (
        <Form.Item label="Currency">
            {isLoading ? (
                <Spin size="small" />
            ) : (
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={productPrice as ProductPriceType[]}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <span>{item.code}</span>

                                <InputNumber
                                    value={item.amount}
                                    style={{ width: '100%' }}
                                    placeholder="99.99"
                                    step="0.01"
                                    stringMode
                                    onChange={e => {
                                        const filteredCurrency: ProductPriceType[] = (
                                            productPrice as ProductPriceType[]
                                        ).map(currency => {
                                            const { amount, id, code, discount } = currency
                                            return { id, code, discount, amount: id === item.id && e ? e : amount }
                                        })

                                        setProduct(prevState => ({ ...prevState, price: [...filteredCurrency] }))
                                    }}
                                />

                                <Button
                                    onClick={() => {
                                        const filteredCurrency = (productPrice as ProductPriceType[]).filter(
                                            currency => currency.id !== item.id
                                        )

                                        setProduct(prevState => ({ ...prevState, price: filteredCurrency }))
                                    }}
                                    type="link"
                                    size="small">
                                    <DeleteOutlined />
                                </Button>
                            </div>
                            {product.discount.type === 2 &&
                                <div style={{ display: 'flex', gap: 5, alignItems: 'center', marginTop: '1rem' }}>
                                    <span style={{whiteSpace: 'nowrap'}} >Fixed discount: </span>
                                    <InputNumber
                                        value={item.discount}
                                        style={{ width: '100%' }}
                                        placeholder="99.99"
                                        step="0.01"
                                        min={0}
                                        max={parseFloat(item.amount) || 0}
                                        stringMode
                                        onChange={e => {
                                            const filteredCurrency: ProductPriceType[] = (
                                                productPrice as ProductPriceType[]
                                            ).map(currency => {
                                                const { amount, id, code, discount } = currency
                                                return { id, code, amount, discount: id === item.id && e ? e : discount }
                                            })

                                            setProduct(prevState => ({ ...prevState, price: [...filteredCurrency] }))
                                        }}
                                    />
                                </div>
                            }
                        </List.Item>
                    )}
                    footer={
                        <Dropdown menu={{ items, onClick }}>
                            <Button style={{ width: '100%' }}>
                                <Space>
                                    Add Currency <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    }
                />
            )}
        </Form.Item>
    )
}

export default ProductCurrenciesItem

import { AMATransformMode, customCameras, customLights, customObjects, utilsEditor } from '@amaspace-editor/editor-3d'
import { TAssetEditorAction } from '../../assetEditorActions'
import { v4 as uuid } from 'uuid'
import { ASSET_TYPE } from '../../../../../api/assetApi'
import { TAsset } from '../../../../../types'
import { SendAssetType } from '../../../../../types/Assets'

type ActionHandlerProps<T> = {
    newItems: ReturnType<typeof customObjects>
    newCameras: ReturnType<typeof customCameras>
    newLights: ReturnType<typeof customLights>
    editor3D: ReturnType<typeof utilsEditor>
    customActions: T
    createAsset: any
}

function actionsHandlers<T>({
    newItems,
    newCameras,
    newLights,
    editor3D,
    customActions,
    createAsset
}: ActionHandlerProps<T>) {
    const defaultAssetData = (id: string): SendAssetType => {
        return {
            name: `Custom ${id}`,
            id: id,
            parent: '',
            type: ASSET_TYPE.Custom,
            url: '',
            preview: '',
            props: {},
            patch: {
                meshId: id
            },
            tags: []
        }
    }
    return {
        createBox: async () => {
            if (!newItems) return
            const nodeId = uuid()
            const asset = defaultAssetData(nodeId)
            await createAsset({ assets: [asset] })
            newItems.create.box(nodeId)
        },
        createTarget: async () => {
            if (!newItems) return
            const nodeId = uuid()
            // const asset = defaultAssetData(nodeId)
            // await createAsset({ assets: [asset] })
            newItems.create.target(nodeId)
        },
        createSphere: async () => {
            if (!newItems) return
            const nodeId = uuid()
            const asset = defaultAssetData(nodeId)
            await createAsset({ assets: [asset] })
            newItems.create.sphere(nodeId)
        },
        createPlane: async () => {
            if (!newItems) return
            const nodeId = uuid()
            const asset = defaultAssetData(nodeId)
            await createAsset({ assets: [asset] })
            newItems.create.plane(nodeId)
        },
        createDirectionalLight: () => {
            if (!newLights) return
            newLights.create.directional()
        },
        createPointLight: () => {
            if (!newLights) return
            newLights.create.point()
        },
        createSpotLight: () => {
            if (!newLights) return
            newLights.create.spot()
        },
        createHemisphereLight: () => {
            if (!newLights) return
            newLights.create.hemisphere()
        },
        camera: () => {
            if (!newCameras) return
            newCameras.create.perspective()
        },
        changeModeRotate: () => {
            editor3D.updateTransformMode(AMATransformMode.ROTATE)
        },
        changeModeScale: () => {
            editor3D.updateTransformMode(AMATransformMode.SCALE)
        },
        unselect: () => {
            editor3D.unselect()
        },
        changeModeTranslate: () => {
            editor3D.updateTransformMode(AMATransformMode.TRANSLATE)
        },
        ...customActions
    }
}

const actionsUtils: TAssetEditorAction[] = [
    { label: 'Box', icon: null, key: 'createBox', folder: 'shapes' },
    { label: 'Target', icon: null, key: 'createTarget', folder: 'shapes' },
    { label: 'Sphere', icon: null, key: 'createSphere', folder: 'shapes' },
    { label: 'Plane', icon: null, key: 'createPlane', folder: 'shapes' },
    { label: 'Directional light', icon: null, key: 'createDirectionalLight', folder: 'lights' },
    { label: 'Point light', icon: null, key: 'createPointLight', folder: 'lights' },
    { label: 'Spot light', icon: null, key: 'createSpotLight', folder: 'lights' },
    { label: 'Hemisphere light', icon: null, key: 'createHemisphereLight', folder: 'lights' },
    { label: 'Rotate', icon: null, key: 'changeModeRotate', folder: 'mode' },
    { label: 'Scale', icon: null, key: 'changeModeScale', folder: 'mode' },
    { label: 'Translate', icon: null, key: 'changeModeTranslate', folder: 'mode' },
    { label: 'Remove item', icon: null, key: 'removeItem', folder: 'settings' },
    { label: 'Reset Model', icon: null, key: 'resetModel', folder: 'settings' },
    { label: 'unselect', icon: null, key: 'unselect', folder: 'settings' },
    { label: 'Create camera', icon: null, key: 'camera', folder: 'cameras' }
]

export { actionsUtils, actionsHandlers }

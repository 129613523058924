import React, {useContext, useEffect, useState} from 'react'
import { Button, Form, Space, Tag } from 'antd'
import {
    AccountManagerType,
    useChangeAccountManagerStatusMutation,
    useCreateAccountManagerMutation,
    useGetAccountManagersQuery,
    useUpdateAccountManagerMutation,
    AccountManagerFilter,
    UserStatusInfo,
    AccountManagerInfoType,
    UserStatus,
    useDeleteAccountManagerMutation,
    useChangeAccountManagerEmailMutation,
    useResendAccountManagerEmailConfirmationMutation
} from '../../../api/accountManagerApi'
import {
    DeleteOutlined,
    EditOutlined,
    MailOutlined,
    SendOutlined,
    UserAddOutlined,
    UserSwitchOutlined
} from '@ant-design/icons'
import PageBuilder from '../../../components/panel/PageBuilder'
import UserChangeStatusModal from '../UserChangeStatusModal'
import ModalWithForm from '../ModalWithForm'
import AccountManagerForm from './AccountManagerForm'
import ExtendedTable from '../../../components/ExtendedTable'
import { useFilterTable } from '../../../hooks/useFilterTable'
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import {
    AdminType,
    useChangeAdminEmailMutation,
    useDeleteAdminMutation,
    useResendAdminEmailConfirmationMutation
} from "../../../api/adminApi";
import {ModalConfirmContext} from "../../../providers/ModalConfirmProvider";
import UserChangeEmailModal from "../UserChangeEmailModal";
import {useDeleteUserMutation} from "../../../api/userApi";

const AccountManagers = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {showModal} = useContext(ModalConfirmContext)

    const [formChangeEmail] = Form.useForm<Pick<AccountManagerType, 'email' | 'id'>>()
    const [formCreate] = Form.useForm<AccountManagerInfoType>()
    const [formUpdate] = Form.useForm<AccountManagerInfoType>()
    const [formChangeStatus] = Form.useForm<UserStatusInfo>()

    const [isModalOpenChangeEmail, setIsModalOpenChangeEmail] = useState(false)
    const [isModalOpenCreate, setIsModalOpenCreate] = useState(false)
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false)
    const [isModalOpenChangeStatus, setIsModalOpenChangeStatus] = useState(false)

    const [create, resultsCreate] = useCreateAccountManagerMutation()
    const [deleteItem] = useDeleteUserMutation()
    const [changeEmail, resultsChangeEmail] = useChangeAccountManagerEmailMutation()
    const [resendConfirmationEmail] = useResendAccountManagerEmailConfirmationMutation()
    const [update, resultsUpdate] = useUpdateAccountManagerMutation()
    const [changeStatus, resultsChangeStatus] = useChangeAccountManagerStatusMutation()

    const [filter, setFilter] = useState<AccountManagerFilter>({
        page: 1,
        name: '',
        email: '',
        lastname: '',
        status: null
    })

    useEffect(() => {
        const pageParam = searchParams.get("page")
        const name = searchParams.get("name")
        const lastname = searchParams.get("lastname")
        const email = searchParams.get("email")
        const status = searchParams.get("status") as UserStatus
        const page = parseInt(pageParam ?? '1')

        setFilter({
            page: Number.isNaN(page) ? 1 : page,
            name: name ?? '',
            lastname: lastname ?? '',
            email: email ?? '',
            status: status ?? null,
        })
    }, [searchParams]);

    const { data, isLoading, refetch } = useGetAccountManagersQuery(filter)

    const [filterByText, _, filterBySelect] = useFilterTable<AccountManagerFilter>()

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...filterByText('email')
        },
        {
            title: 'First Name',
            dataIndex: 'name',
            key: 'name',
            ...filterByText('name')
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
            ...filterByText('lastname')
        },
        {
            title: 'Companies',
            dataIndex: 'companies',
            key: 'companies',
            render: (companies: AccountManagerType['companies']) => (
                <Space size={[0, 'small']} wrap>
                    {companies.map(company => (
                        <Tag key={company.id} color="processing">
                            {company.name}
                        </Tag>
                    ))}
                </Space>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...filterBySelect('status', statusFilterOptions, 'id', false)
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: void, user: AccountManagerType) => (
                <>
                    <Button
                        onClick={() => {
                            const { id } = user
                            formChangeStatus.setFieldsValue({ id })
                            setIsModalOpenChangeStatus(true)
                        }}
                        type="link"
                        size="small">
                        <UserSwitchOutlined />
                    </Button>

                    <Button
                        onClick={() => {
                            const { companies, ...otherFields } = user
                            const companiesId = companies.map(company => company.id)
                            formUpdate.setFieldsValue({ ...otherFields, companies: companiesId })
                            setIsModalOpenUpdate(true)
                        }}
                        type="link"
                        size="small">
                        <EditOutlined />
                    </Button>

                    {
                        user.status === 'Sign up email confirmation' && <>
                            <Button
                                onClick={() => {
                                    const handleResend = async () => {
                                        const {id} = user
                                        const res = await resendConfirmationEmail({id})
                                        if ('error' in res) return
                                        toast.success('Email Confirmation is successfully delivered')
                                        refetch()
                                    }

                                    showModal('resend email confirmation', handleResend)
                                }}
                                type="link"
                                size="small">
                                <SendOutlined />
                            </Button>

                            <Button
                                onClick={() => {
                                    const {id, email} = user
                                    formChangeEmail.setFieldsValue({id, email})
                                    setIsModalOpenChangeEmail(true)
                                }}
                                type="link"
                                size="small">
                                <MailOutlined />
                            </Button>
                        </>
                    }

                    {
                        user.status !== 'Deleted' && <Button
                            onClick={() => {
                                const handleDelete = async () => {
                                    const { id } = user
                                    const res = await deleteItem({ id })
                                    if ('error' in res) return
                                    toast.success('Account Manager is successfully deleted')
                                    refetch()
                                }

                                showModal('delete this account manager', handleDelete)
                            }}
                            type="link"
                            size="small"
                        >
                            <DeleteOutlined />
                        </Button>
                    }
                </>
            )
        }
    ]

    useEffect(() => {
        refetch()
    }, [filter])

    return (
        <PageBuilder title="Account Managers" documentTitle="Account Managers">
            <Space style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={() => setIsModalOpenCreate(true)}>
                    <UserAddOutlined /> Create Account Manager
                </Button>
            </Space>

            <ExtendedTable
                data={data}
                isLoading={isLoading}
                dataKey="items"
                columns={columns}
                onChange={page => setSearchParams(`page=${page}`)}
            />

            <ModalWithForm
                title={'Create Account Manager'}
                isOpen={isModalOpenCreate}
                setIsOpen={setIsModalOpenCreate}
                isLoading={resultsCreate.isLoading}
                submit={formCreate.submit}>
                <AccountManagerForm
                    setIsModalOpen={setIsModalOpenCreate}
                    refetch={refetch}
                    form={formCreate}
                    onFinish={create}
                />
            </ModalWithForm>

            <ModalWithForm
                title={'Update Account Manager'}
                isOpen={isModalOpenUpdate}
                setIsOpen={setIsModalOpenUpdate}
                isLoading={resultsUpdate.isLoading}
                submit={formUpdate.submit}>
                <AccountManagerForm
                    setIsModalOpen={setIsModalOpenUpdate}
                    refetch={refetch}
                    form={formUpdate}
                    onFinish={update}
                />
            </ModalWithForm>

            <UserChangeStatusModal
                form={formChangeStatus}
                changeStatus={changeStatus}
                isModalOpen={isModalOpenChangeStatus}
                setIsModalOpen={setIsModalOpenChangeStatus}
                isLoading={resultsChangeStatus.isLoading}
                refetch={refetch}
            />

            <UserChangeEmailModal
                form={formChangeEmail}
                changeEmail={changeEmail}
                isModalOpen={isModalOpenChangeEmail}
                setIsModalOpen={setIsModalOpenChangeEmail}
                isLoading={resultsChangeEmail.isLoading}
                refetch={refetch}
            />
        </PageBuilder>
    )
}

export default AccountManagers

export const statusFilterOptions: { id: UserStatus; name: string }[] = [
    { id: 'Sign up email confirmation', name: 'Sign up email confirmation' },
    { id: 'Active', name: 'Active' },
    { id: 'Inactive', name: 'Inactive' },
    { id: 'Wait approving', name: 'Wait approving' },
    { id: 'Deleted', name: 'Deleted' }
]

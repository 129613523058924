import { Form, FormInstance, Input } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { StyleGuideInfoType } from '../../../api/styleGuideApi'

type StyleGuideFormProps = {
    form: FormInstance<StyleGuideInfoType>
    onFinish: (e: StyleGuideInfoType) => Promise<any>
    refetch: () => void
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const StyleGuideForm = ({ form, setIsModalOpen, refetch, onFinish }: StyleGuideFormProps) => {
    return (
        <Form
            validateMessages={{ required: 'Field is required!' }}
            layout="vertical"
            form={form}
            onFinish={async e => {
                const loader = toast.loading('Progressing...')
                const res = await onFinish(e)
                toast.dismiss(loader)
                if ('error' in res) return
                toast.success('Form is successfully submitted!')
                setIsModalOpen(false)
                refetch()
                form.resetFields()
            }}>
            <Form.Item name="id" noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input maxLength={40} placeholder="Name" />
            </Form.Item>

            <Form.Item label="Path" name="path" rules={[{ required: true }]}>
                <Input maxLength={150} placeholder="Path" />
            </Form.Item>
        </Form>
    )
}

export default StyleGuideForm

import api from './index'
import { UserStatus, UserStatusInfo } from './accountManagerApi'
import { TableMetaType } from '../components/ExtendedTable'

const ADMIN = 'company-admin'

const adminApi = api.injectEndpoints({
    endpoints: build => ({
        getAdmins: build.query<GetAdminsType, AdminFilter>({
            query: body => {
                const { page, name, lastname, email, status } = body
                const params = new URLSearchParams({
                    page: page.toString(),
                    name,
                    lastname,
                    email,
                    status: status ?? ''
                })

                return `${ADMIN}?${params}`
            }
        }),
        createAdmin: build.mutation<null, AdminInfoType>({
            query: body => ({
                url: ADMIN,
                method: 'POST',
                body
            })
        }),
        updateAdmin: build.mutation<null, AdminInfoType>({
            query: body => {
                const { name, lastname, company_id, email, id } = body
                return {
                    url: `${ADMIN}/${id}`,
                    method: 'PUT',
                    body: { name, lastname, email, company_id }
                }
            }
        }),
        changeAdminStatus: build.mutation<null, UserStatusInfo>({
            query: body => {
                const { status, id } = body

                return {
                    url: `${ADMIN}/${id}/change-status`,
                    method: 'PUT',
                    body: { status }
                }
            }
        }),

        deleteAdmin: build.mutation<null, { id: number }>({
            query: body => ({
                url: `${ADMIN}/${body.id}`,
                method: 'DELETE'
            })
        }),

        changeAdminEmail: build.mutation<null, { id: string | number, email: string }>({
            query: body => {
                const {id, email} = body
                return {
                    url: `${ADMIN}/${id}/email-change`,
                    method: 'POST',
                    body: {email}
                }
            }
        }),

        resendAdminEmailConfirmation: build.mutation<null, { id: number }>({
            query: body => {
                const {id} = body
                return {
                    url: `${ADMIN}/${id}/confirmation-resend`,
                    method: 'POST',
                }
            }
        }),
    }),
    overrideExisting: false
})

export type AdminType = {
    id: number
    name: string
    lastname: string
    email: string
    status: UserStatus
    company: { id: number; name: string }
}

export type GetAdminsType = {
    items: AdminType[]
    _meta: TableMetaType
}

export type AdminFilter = {
    page: number
    email: string
    name: string
    lastname: string
    status: UserStatus | null
}

export type AdminInfoType = Omit<AdminType, 'status' | 'companies'> & { company_id: number }

export const {
    useChangeAdminStatusMutation,
    useDeleteAdminMutation,
    useCreateAdminMutation,
    useGetAdminsQuery,
    useUpdateAdminMutation,
    useChangeAdminEmailMutation,
    useResendAdminEmailConfirmationMutation,
} =
    adminApi

export default adminApi

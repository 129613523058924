import { AMAMode, AnnotationAMA, utilsAnnotation, utilsEditor } from '@amaspace-editor/editor-3d'
import { Button, Col, Input, InputNumber, Row, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import Quill from 'quill'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { AssetAnnotationType } from '../../../../api/assetApi'
import Utils from '../../../../utils'
//@ts-ignore
import ImageUploader from 'quill-image-uploader'
import 'quill-image-uploader/dist/quill.imageUploader.min.css'
import AnnotationTexture from './AnnotationTexture'

Quill.register('modules/imageUploader', ImageUploader)

export interface AnnotationTextureData {
    textureOriginal: string
    textureModified: string
    brightness: number
    contrast: number
    hue: number
    invert: boolean
    saturation: number
}

interface AnnotationsEditorProps {
    id: number | string
    data: AssetAnnotationType[]
    setData: React.Dispatch<React.SetStateAction<AssetAnnotationType[]>>
    isLoading: boolean
    updateAnnotation: (data: AssetAnnotationType) => Promise<any>
    createAnnotation: (data: AssetAnnotationType) => Promise<any>
}

export type TAnnotationParams =
    | 'brightness'
    | 'contrast'
    | 'hover'
    | 'hue'
    | 'invert'
    | 'opacity'
    | 'saturation'
    | 'size'
    | 'textureOriginal'
    | 'textureModified'

export type TPatchParams<T extends string | number> = {
    [P in T]: string | number | boolean
}

const AnnotationsEditor = ({
    id,
    data,
    setData,
    isLoading,
    updateAnnotation,
    createAnnotation
}: AnnotationsEditorProps) => {
    const editor3d = utilsEditor()
    const annotationUtils = utilsAnnotation()
    const fileRef = useRef<HTMLInputElement | null>(null)
    const reactQuill = useRef<any>(null)
    const [htmlValue, setHtmlValue] = useState<string>('')
    const [annotationPatch, setAnnotationPatch] = useState<TPatchParams<TAnnotationParams>>({
        brightness: 100,
        contrast: 100,
        hover: 32,
        hue: 0,
        invert: false,
        opacity: 0,
        saturation: 100,
        size: 16,
        textureOriginal: '',
        textureModified: '',
        ...(data.find(item => item.id === id)?.patch || {})
    })

    const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.files?.length) return

        Utils.uploadImage(e.target.files[0] as File).then(res => {
            const imageUrl = res.body.data
            const quillObj = reactQuill?.current?.getEditor()
            const range = quillObj?.getSelection()

            quillObj.insertEmbed(range.index, 'image', imageUrl)
        })
    }

    const modulesQuill = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ color: [] }, { background: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image', 'video'],
                    ['clean']
                ]
            },
            imageUploader: {
                upload: async (file: File) => {
                    const image = await Utils.createAssetsFromFile(file)

                    return image[0].url
                }
                // upload: (file: File) => {
                //   return new Promise((resolve, reject) => {
                //     const formData = new FormData()
                //     formData.append('image', file)
                //
                //     fetch(
                //       'https://api.imgbb.com/1/upload?key=28e6571504d3090a4365a1a4e7590e88',
                //       {
                //         method: 'POST',
                //         body: formData
                //       }
                //     )
                //       .then(response => response.json())
                //       .then(result => {
                //         console.log(result)
                //         resolve(result.data.url)
                //       })
                //       .catch(error => {
                //         reject('Upload failed')
                //         console.error('Error:', error)
                //       })
                //   })
                // }
            },
            clipboard: {
                matchVisual: true
            }
        }),
        []
    )
    function generateImageFromUrl(assetName: string, dataUrl: string): Promise<File> {
        return new Promise(async (resolve, reject) => {
            const base64Response = await fetch(dataUrl)
            const blob = await base64Response.blob()
            const image = new Image()
            image.src = URL.createObjectURL(blob)
            await new Promise(resolve => {
                image.onload = resolve
            })

            const fileImage = new File([blob], `${assetName}`, { type: 'image/png' })
            resolve(fileImage)
        })
    }

    const base64ToFile = async (base64WithPrefix: string, filename: string): Promise<File> => {
        const base64String = base64WithPrefix.split(',')[1]
        if (!base64String) return await generateImageFromUrl(filename, base64WithPrefix)
        return new Promise(resolve => {
            const byteString = atob(base64String)
            const arrayBuffer = new ArrayBuffer(byteString.length)
            const intArray = new Uint8Array(arrayBuffer)
            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i)
            }
            const mimeType = base64WithPrefix.match(/data:(.*?);base64/)?.[1] || 'application/octet-stream'
            const blob = new Blob([intArray], { type: mimeType })
            const file = new File([blob], filename, { type: mimeType })
            resolve(file)
        })
    }

    const handleSaveAnnotation = async () => {
        let annotation = data.find(item => item.id === id)
        if (!annotation) {
            alert('Annotation did not found!')
            return
        }
        if (annotation.patch.textureModified) {
            const file = await base64ToFile(annotation.patch.textureModified, 'image.png')
            const assetResult = await Utils.createAssetsFromFile(file)
            if (!assetResult) {
                return
            }
            annotation = {
                ...annotation,
                patch: {
                    ...annotation.patch,
                    textureModified: assetResult[0].url
                }
            }
        }
        const { type } = annotation
        annotationUtils.removeBlankAnnotation()
        if (type) {
            const filteredAnnotation = {
                ...annotation,
                value: htmlValue
            }
            filteredAnnotation.position = JSON.stringify(editor3d?.newAnnotation?.position?.toArray())
            const { data } = await createAnnotation(filteredAnnotation)

            if (editor3d && data) {
                const final = {
                    ...filteredAnnotation,
                    id: data,
                    position: JSON.stringify(editor3d?.newAnnotation?.position?.toArray())
                } as any

                annotationUtils.createHotspot(new AnnotationAMA(final))
            }
        } else {
            const currentAnnotation = editor3d.getMeshById(annotation.id)
            const final: AssetAnnotationType = {
                ...annotation,
                position: JSON.stringify(currentAnnotation?.position?.toArray())
            }
            const res = await updateAnnotation(final)

            if (editor3d && res && currentAnnotation) {
                const patchData = { value: annotation.value }

                editor3d.updateObjectData(currentAnnotation, patchData)
            }
        }
        editor3d.changeMode(AMAMode.VIEWER)
    }

    const handleChangeContent = (html: string) => {
        setHtmlValue(html)
        setData(prevState =>
            prevState.map(item => {
                if (item.id === id)
                    return {
                        ...item,
                        value: html
                    }

                return item
            })
        )
    }

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prevState =>
            prevState.map(item => {
                if (item.id === id)
                    return {
                        ...item,
                        name: e.target.value
                    }
                return item
            })
        )
    }

    /*
    const handleChangePatch = (e: string | number, key: TAnnotationParams) => {
        setData(prevState =>
            prevState.map(item => {
                if (item.id === id) {
                    const patch = JSON.parse(JSON.stringify(item.patch)) || {}
                    setAnnotationPatch({ ...annotationPatch, [key]: e })
                    patch[key] = e
                    return {
                        ...item,
                        patch: { ...patch }
                    }
                }

                return item
            })
        )
    }
    */

    useEffect(() => {
        setData(current => {
            return current.map(item => {
                if (item.id === id) {
                    const patch = JSON.parse(
                        JSON.stringify({
                            ...item.patch,
                            ...annotationPatch
                        })
                    )
                    return {
                        ...item,
                        patch: { ...patch }
                    }
                }
                return item
            })
        })
    }, [annotationPatch, id, setData])

    useEffect(() => {
        const annotation = data.find(item => item.id === id)
        if (!annotation) {
            return
        }
        const currentAnnotation = editor3d.getMeshById(annotation.id)
        if (!currentAnnotation) {
            return
        }
        editor3d.updateObjectData(currentAnnotation, {
            patch: { ...annotationPatch } as any
        })
    }, [annotationPatch, data, id])

    return (
        <div style={{ overflowY: 'scroll', height: '100%' }}>
            <Button onClick={handleSaveAnnotation} type="primary">
                Save Annotation
            </Button>
            {isLoading ? (
                <Spin size="large" />
            ) : (
                <>
                    <Input
                        style={{ margin: '16px 0' }}
                        placeholder="Annotation Name"
                        value={data.find(item => item.id === id)?.name}
                        onChange={handleChangeName}
                        maxLength={40}
                    />
                    <AnnotationTexture annotationPatch={annotationPatch} setAnnotationPatch={setAnnotationPatch} />
                    <Title level={4} children="Annotation Custom" />
                    <Col>
                        <Row justify="space-between" style={{ padding: '0 .5rem .5rem 0', gap: '.5rem' }}>
                            <span>Custom size</span>
                            <InputNumber
                                style={{ flex: '1 1 100%' }}
                                value={Number(annotationPatch.size)}
                                onChange={v => {
                                    if (!v) {
                                        return
                                    }
                                    setAnnotationPatch(current => {
                                        return {
                                            ...current,
                                            size: v
                                        }
                                    })
                                }}
                            />
                        </Row>
                        <Row justify="space-between" style={{ padding: '0 .5rem .5rem 0', gap: '.5rem' }}>
                            <span>Hover size</span>
                            <InputNumber
                                style={{ flex: '1 1 100%' }}
                                value={Number(annotationPatch.hover)}
                                onChange={v => {
                                    if (!v) {
                                        return
                                    }
                                    setAnnotationPatch(current => {
                                        return {
                                            ...current,
                                            hover: v
                                        }
                                    })
                                }}
                            />
                        </Row>
                        <Row justify="space-between" style={{ padding: '0 .5rem .5rem 0', gap: '.5rem' }}>
                            <span>Opacity</span>
                            <InputNumber
                                style={{ flex: '1 1 100%' }}
                                value={Number(annotationPatch.opacity)}
                                min={0}
                                step={0.01}
                                onChange={v => {
                                    if (!v) {
                                        return
                                    }
                                    setAnnotationPatch(current => {
                                        return {
                                            ...current,
                                            opacity: v
                                        }
                                    })
                                }}
                            />
                        </Row>
                    </Col>
                    <Title level={4} children="Annotation Content" />
                    <div style={{ height: '250px' }}>
                        <ReactQuill
                            style={{ height: '100%' }}
                            ref={reactQuill}
                            modules={modulesQuill}
                            onChange={handleChangeContent}
                            value={data.find(item => item.id === id)?.value}
                        />
                    </div>

                    <input onChange={uploadImage} ref={fileRef} type="file" style={{ display: 'none' }} />
                </>
            )}
        </div>
    )
}

export default AnnotationsEditor

import { Input, Select } from 'antd'
import { useGetAssetByIdQuery } from 'api/assetApi'
import { useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { ProductAttributeType, StyledAttributeItem } from '../tabs/ProductAttributes'

const { Option } = Select

type ProductAttributeCategoryProps = {
    category: ProductAttributeType
}

const ProductAttributeCategory = ({ category }: ProductAttributeCategoryProps) => {
    const { categories, setCategories, product } = useContext(CatalogContext)
    const [value, setValue] = useState(category.name || '')
    const [description, setDescription] = useState(category.description || '')
    const [assetAnnotations, setAssetAnnotations] = useState<any[]>([])

    const resAsset = useGetAssetByIdQuery({
        id: product.asset_customizer_id!
    })

    const debounced = useDebouncedCallback(
        (value: ProductAttributeType[keyof ProductAttributeType], key: keyof ProductAttributeType) =>
            updateCategories(value, key),
        500
    )
    const updateCategories = (
        value: ProductAttributeType[keyof ProductAttributeType],
        key: keyof ProductAttributeType
    ) => {
        setCategories(
            categories.map(item => ({
                ...item,
                [key]: item.id === category.id ? value ?? null : item[key],
                status: item.id === category.id ? 'changed' : item.status
            }))
        )
    }
    const handleChangeName: HandleChangeCategoryName = name => {
        setValue(name)
        debounced(name, 'name')
    }

    const handleChangeDescription: HandleChangeCategoryName = name => {
        setDescription(name)
        debounced(name, 'description')
    }

    const handleChangeCategory = (value: ProductAttributeType['parent_id']) => {
        updateCategories(value, 'parent_id')
    }

    const handleChangeAnnotation = (value: ProductAttributeType['annotation_id']) => {
        updateCategories(value, 'annotation_id')
    }

    useEffect(() => {
        if (resAsset.isSuccess) {
            const asset = resAsset.data?.assets[0]
            setAssetAnnotations(asset?.annotation)
        }
    }, [resAsset.isSuccess])

    return (
        <StyledAttributeItem repeat={4}>
            <Input maxLength={40} placeholder="Name" value={value} onChange={e => handleChangeName(e.target.value)} />

            <Input
                maxLength={255}
                placeholder="Description"
                value={description}
                onChange={e => handleChangeDescription(e.target.value)}
            />
            <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Select Annotation"
                value={category.annotation_id}
                onChange={handleChangeAnnotation}>
                {assetAnnotations.map(item => {
                    return (
                        <Option key={item.id} value={item.id}>
                            {item.name ?? 'Empty Name'}
                        </Option>
                    )
                })}
            </Select>
            <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Select Category"
                disabled={!!categories.find(item => category.id === item.parent_id)}
                value={category.parent_id}
                onChange={handleChangeCategory}>
                {categories.map(item => {
                    if (item.id === category.id || item.parent_id || item.newItem) return null

                    return (
                        <Option key={item.id} value={item.id}>
                            {item.name ?? 'Empty Name'}
                        </Option>
                    )
                })}
            </Select>
            {/* <ProductAttributeImageItem attr={category} /> */}
        </StyledAttributeItem>
    )
}

export default ProductAttributeCategory

type HandleChangeCategoryName = (value: string) => void

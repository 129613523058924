import React, { ReactNode, useContext, useState } from 'react'
import { Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { pxToEm } from '../../../../theme/stylesMixins'
import { CatalogContext } from '../../../../providers/CatalogProvider'
import { routesUrl } from '../../../../router/urls'

type ProductInfoProps = {
    url: 'dealer' | 'company'
    children: ReactNode
}

const ProductInfo = ({ children, url }: ProductInfoProps) => {
    const navigate = useNavigate()
    const { id: productId } = useParams()
    const { product, fetchProduct, requests } = useContext(CatalogContext)

    const { getProduct, createProduct, updateProduct, fetchInfoItems } = requests

    const [isLoadingBtn, setIsLoadingBtn] = useState(false)

    const handleCreateProduct = async () => {
        setIsLoadingBtn(true)

        const res = await createProduct(product)
        if ('error' in res) {
            setIsLoadingBtn(false)
            return
        }
        toast.success('Created!')
        navigate(url === 'dealer' ? `${routesUrl.dealerCatalogEdit}/${res.data.id}` : `${routesUrl.companyCatalogEdit}/${res.data.id}`)

        setIsLoadingBtn(false)
    }

    const handleUpdateProduct = async () => {
        setIsLoadingBtn(true)

        const res = await updateProduct(product)
        if ('error' in res) {
            setIsLoadingBtn(false)
            return
        }
        toast.success('Updated!')

        if (typeof res.data === 'string') {
            toast.error(res.data)
        }

        await fetchProduct(getProduct)
        fetchInfoItems()

        setIsLoadingBtn(false)
    }

    return (
        <>
            {children}

            <StyledCatalogBtnWrapper>
                {productId ? (
                    <Button loading={isLoadingBtn} onClick={handleUpdateProduct} type="primary">
                        Update
                    </Button>
                ) : (
                    <Button loading={isLoadingBtn} onClick={handleCreateProduct} type="primary">
                        Create
                    </Button>
                )}
            </StyledCatalogBtnWrapper>
        </>
    )
}

export default ProductInfo

export const StyledCatalogBtnWrapper = styled.div`
    display: flex;
    gap: ${pxToEm(10)};
    margin-top: ${pxToEm(16)};
`

export const StyledCatalog = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`
export const StyledCatalogList = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`

import React from 'react'
import { Form, Input } from 'antd'

const AuthConfirmPassword = () => {
    return (
        <>
            <Form.Item name="password" rules={[{ required: true }]}>
                <Input.Password maxLength={40} placeholder="Password" />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) return Promise.resolve()
                            return Promise.reject(new Error('The new password that you entered do not match!'))
                        }
                    })
                ]}>
                <Input.Password maxLength={40} placeholder="Confirm Password" />
            </Form.Item>
        </>
    )
}

export default AuthConfirmPassword

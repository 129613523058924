import React, { useState } from 'react'
import { Tabs, Typography } from 'antd'
import LogicAttributes from './attributes/LogicAttributes'
import LogicRulesEditor from './rules/LogicRulesEditor'
import LogicRules from './rules/LogicRules'
import TAsset from '../../../../types/TAsset'
import LogicRulesCreator from './rules/LogicRulesCreator'
import { AssetAttributeType, AssetRuleType } from '../../../../api/assetApi'
import LogicAttributesCreator from './attributes/LogicAttributesCreator'
import { AssetsForAttributes } from '../assetEditor'
import LogicAttributeEditor from './attributes/LogicAttributeEditor'

const { Title } = Typography

type LogicEditorProps = {
    asset: TAsset
    assets: AssetsForAttributes
    modelPart: TAsset[]
}

const LogicEditor = ({ asset, assets, modelPart }: LogicEditorProps) => {
    const [selectedTab, setSelectedTab] = useState<LogicEditorTabsType>('attributes')
    const [attributesCreatorOpen, setAttributesCreatorOpen] = useState<string | null>(null)

    const [selectedEditRule, setSelectedEditRule] = useState<AssetRuleType>()
    const [rulesEditorOpen, setRulesEditorOpen] = useState<boolean>(false)
    const [rulesCreatorOpen, setRulesCreatorOpen] = useState<boolean>(false)
    const [attributesEditorOpen, setAttributesEditorOpen] = useState<boolean>(false)
    const [selectedEditAttribute, setSelectedEditAttribute] = useState<AssetAttributeType>()

    const handleAddNewAttribute = (item: { key: string }) => {
        setAttributesCreatorOpen(item.key)
    }

    const handleEditAttribute = (item: AssetAttributeType) => {
        setAttributesEditorOpen(true)
        setSelectedEditAttribute(item)
    }

    const handleAddRule = () => {
        setRulesCreatorOpen(true)
    }

    const handleEditRule = (rule: AssetRuleType) => {
        setRulesEditorOpen(true)
        setSelectedEditRule(rule)
    }

    const handleChangeTab = (key: string) => {
        setSelectedTab(key as LogicEditorTabsType)
    }

    const items = [
        {
            key: 'attributes',
            label: 'Attributes',
            children: (
                <LogicAttributes id={asset.dbId} editAttribute={handleEditAttribute} onClick={handleAddNewAttribute} />
            )
        },
        {
            key: 'rules',
            label: 'Rules',
            children: <LogicRules id={asset.dbId} editRule={handleEditRule} addRule={handleAddRule} />
        }
    ]

    return (
        <>
            <Title level={3} children="Logic Editor" />

            {!attributesCreatorOpen && !rulesCreatorOpen && !rulesEditorOpen && !attributesEditorOpen && (
                <Tabs defaultActiveKey={selectedTab} destroyInactiveTabPane onChange={handleChangeTab} items={items} />
            )}

            {rulesCreatorOpen && (
                <LogicRulesCreator
                    assetId={asset.dbId}
                    assets={assets}
                    modelPart={modelPart}
                    setIsOpen={setRulesCreatorOpen}
                />
            )}

            {rulesEditorOpen && (
                <LogicRulesEditor
                    ruleInfo={selectedEditRule as AssetRuleType}
                    assetId={asset.dbId}
                    assets={assets}
                    modelPart={modelPart}
                    setIsOpen={setRulesEditorOpen}
                />
            )}

            {attributesCreatorOpen && (
                <LogicAttributesCreator
                    assetId={asset.dbId}
                    assets={assets}
                    isOpen={attributesCreatorOpen}
                    setIsOpen={setAttributesCreatorOpen}
                />
            )}

            {attributesEditorOpen && (
                <LogicAttributeEditor
                    assetId={asset.dbId}
                    assets={assets}
                    setIsOpen={setAttributesEditorOpen}
                    item={selectedEditAttribute as AssetAttributeType}
                />
            )}
        </>
    )
}

export default LogicEditor

export type LogicEditorTabsType = 'attributes' | 'rules'

export const AttributeTypeId = {
    Asset: 1,
    Boolean: 2,
    Number: 3,
    String: 4
} as const

const AttributeAssetTypeId = {
    Model: 5,
    Texture: 6,
    Material: 7
} as const

const ConditionTypeId = {
    OnPageLoad: 8,
    OnClickAnnotation: 9,
    OnClickModelPart: 10,
    Equal: 11,
    NotEqual: 12,
    GreaterThen: 13,
    LessThen: 14,
    GreaterOrEqual: 15,
    LessOrEqual: 16,
    OnChangeMorphTarget: 17,
    OnHoverModelPart: 18
} as const

const ConnectionTypeId = {
    Attribute: 17,
    Annotation: 18,
    CustomizerId: 31
} as const

export const ActionTypeId = {
    SetAttributeVisible: 19,
    SetAttributeEnabled: 20,
    SetAttributeValue: 21,
    SetAttributeValueVisibility: 22,
    SetAttributeValueEnabled: 23,
    CustomScript: 24,
    SetVisibility: 25,
    SetMaterial: 26,
    SetModel: 27,
    SetProperty: 28,
    SetActiveCamera: 29,
    ActivateAnimation: 30,
    SetVisibilityOne: 31,
    SetDefaultCamera: 32,
    ChangeObjectProperty: 33
} as const

export const ProductActionTypeId = {
    SetAttributeVisible: '19',
    SetAttributeEnabled: '20',
    SetAttributeValue: '21',
    SetAttributeValueVisibility: '22',
    SetAttributeValueEnabled: '23',
    CustomScript: '24',
    SetAttributeExclude: '25',
    SetColorParent: '26',
    SetAttributeExcludeMessage: '27'
} as const

export const ProductConditionTypeId = {
    Equal: '11',
    NotEqual: '12'
} as const

export const LogicEditorTypeId = {
    ...ProductActionTypeId,
    ...AttributeTypeId,
    ...AttributeAssetTypeId,
    ...ConditionTypeId,
    ...ConnectionTypeId,
    ...ActionTypeId
}

export type ILogicEditorTypeId = keyof typeof LogicEditorTypeId
